import styled from 'styled-components';
import { PointsDisplay } from 'ui-system/components/points-display';

import { LoyaltyRewardsLogo } from 'pages/loyalty/loyalty-rewards-logo';
import { brandFont } from 'styles/brand-font';
import { primitive } from 'styles/constants/primitives';

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-inline-end: ${primitive.$spacing1};
  align-items: center;
  cursor: pointer;
`;

export const StyledRewardsLogo = styled(LoyaltyRewardsLogo)`
  margin-block-start: ${primitive.$spacing2};
  width: 7.2rem;
  height: 2rem;
`;

export const PointsContainer = styled.div`
  align-items: center;
  color: ${p => p.theme.token('text-default')};
  display: flex;
  font: ${brandFont.headerFour};
  margin-block: 0;
  margin-inline-start: 0;
  margin-inline-end: ${primitive.$spacing2};
`;

export const StyledPointsDisplay = styled(PointsDisplay)`
  font: ${brandFont.headerThree};
`;

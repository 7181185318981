import React, { ComponentType } from 'react';

import { catchPromise, compose } from 'utils';

import { ErrorNetwork } from 'components/error-network';

import { LazyRoute, RouteConfig } from './types';

// used with route components
export const lazyWithFallback = compose(
  React.lazy,
  catchPromise<Error, { default: ComponentType<any> }, { default: ComponentType<any> }>(() => ({
    default: () => <ErrorNetwork />,
  }))
);

// used with non-route components
export const lazyWithNullFallback = compose(
  React.lazy,
  catchPromise<Error, { default: ComponentType<any> }, { default: ComponentType<any> }>(() => ({
    default: () => null,
  }))
);

export const createLazyRoutes = <T,>(config: RouteConfig<T>) => {
  const importNames = Object.keys(config) as Array<keyof T>;
  return importNames.reduce(
    (acc, key) => {
      acc[key] = lazyWithFallback(async () => {
        const module = await config[key];
        return {
          default: module[key as keyof typeof module] as React.ComponentType<any>,
        };
      });
      return acc;
    },
    {} as Record<keyof T, LazyRoute>
  );
};

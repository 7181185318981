import styled from 'styled-components';

import { ScrollView } from 'components/scroll-view';
import { View } from 'components/view';

export const AppContainer = styled(View)`
  height: 100%;
  width: 100%;
  flex: 1;
`;

export const LayoutContainerScrollView = styled(ScrollView)`
  background: ${Styles.color.background};
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  &:focus {
    inset-block-start: 0.2rem;
    inset-inline-start: 0.2rem;
    opacity: 1;
  }
`;

export const LayoutMain = styled.main`
  height: 100%;
`;

export const ContentContainer = styled(View)`
  background: ${Styles.color.background};
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const ContentLoadingContainer = styled(View)`
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: ${Styles.color.background};
`;

export const RouterWrapperContainer = styled(View)`
  background: ${Styles.color.background};
  flex-grow: 1;
  height: 100%;
`;

import React, { FC } from 'react';

import Carousel from 'nuka-carousel';
import { useIntl } from 'react-intl';

import { IWidgetElement } from 'pages/loyalty/loyalty-widget-components/types';

import {
  Container,
  HowToEarnCarouselItem,
  HowToEarnContainer,
  HowToEarnDescription,
  HowToEarnStep,
  HowToEarnText,
  HowToEarnTitle,
  StyledCrownIcon,
  StyledFriesIcon,
} from '../loyalty-cart-drawer.styled';

import { KioskIcon } from './icons/KioskIcon';

interface QRCodeDrawerProps {
  widgets: Array<IWidgetElement>;
}

export const QRCodeDrawer: FC<QRCodeDrawerProps> = ({ widgets }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <HowToEarnContainer>
        <HowToEarnTitle>
          {formatMessage({ id: 'yourInRestaurantCartInfoPageTitle' })}
        </HowToEarnTitle>
        <Carousel
          slidesToShow={1}
          wrapAround={true}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
        >
          <HowToEarnCarouselItem>
            <HowToEarnText>
              <HowToEarnStep step={1} />
              <HowToEarnDescription>
                {formatMessage({ id: 'yourInRestaurantCartInfoPageStepOne' })}
              </HowToEarnDescription>
            </HowToEarnText>
            <KioskIcon />
          </HowToEarnCarouselItem>
          <HowToEarnCarouselItem>
            <HowToEarnText>
              <HowToEarnStep step={2} />
              <HowToEarnDescription>
                {formatMessage({ id: 'yourInRestaurantCartInfoPageStepTwo' })}
              </HowToEarnDescription>
            </HowToEarnText>
            <StyledCrownIcon />
          </HowToEarnCarouselItem>
          <HowToEarnCarouselItem>
            <HowToEarnText>
              <HowToEarnStep step={3} />
              <HowToEarnDescription>
                {formatMessage({ id: 'yourInRestaurantCartInfoPageStepThree' })}
              </HowToEarnDescription>
            </HowToEarnText>
            <StyledFriesIcon />
          </HowToEarnCarouselItem>
        </Carousel>
      </HowToEarnContainer>

      <Container>{widgets.map(({ element }) => element)}</Container>
    </>
  );
};

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
  LoyaltyID: { input: any; output: any };
  LoyaltyJSON: { input: any; output: any };
}

/** Add a First Data account (payment method) for the current user */
export interface IAddAccountInput {
  /** billingAddress associated to card */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /** First 6 digits of credit card */
  readonly ccBin: Scalars['String']['input'];
  /** cc token from tokenex required for chase direct pay enrollment */
  readonly ccToken?: InputMaybe<Scalars['String']['input']>;
  /** chase id from TH endpoint to add to DynamoDB */
  readonly chaseProfileId?: InputMaybe<Scalars['String']['input']>;
  /** cc security code */
  readonly cvv?: InputMaybe<Scalars['String']['input']>;
  /** Access token which was used to request nonce */
  readonly fdAccessToken: Scalars['String']['input'];
  /** Nonce provided by FD linking to the new payment method */
  readonly fdNonce: Scalars['String']['input'];
  /** Name On Card */
  readonly fullName?: InputMaybe<Scalars['String']['input']>;
  /** Make this new account the user's default account */
  readonly makeDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** Session ID as set by the FE. This should be derived from mParticle. */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  /** threeDSOptions to resume account creation */
  readonly threeDSOptions?: InputMaybe<IThreeDsOptions>;
}

/** Add Adyen (payment method) for the current user */
export interface IAddAdyenAccountInput {
  /** Encrypted Payment Payload */
  readonly encryptedPayload: IAdyenEncryptedInfo;
}

/** Add Orbital (payment method) for the current user */
export interface IAddOrbitalAccountInput {
  readonly bin: Scalars['String']['input'];
  readonly cardBrand: Scalars['String']['input'];
  readonly encryptedCardNum: Scalars['String']['input'];
  readonly encryptedCvv: Scalars['String']['input'];
  readonly expiryMonth: Scalars['String']['input'];
  readonly expiryYear: Scalars['String']['input'];
  readonly pieFormat: Scalars['String']['input'];
  readonly pieIntegrityCheck: Scalars['String']['input'];
  readonly pieKeyID: Scalars['String']['input'];
  readonly pieMode: Scalars['String']['input'];
  readonly piePhaseId: Scalars['String']['input'];
  readonly pieSubscriberId: Scalars['String']['input'];
}

export interface IAddPaymentMethodInput {
  readonly billingAddress: IPaymentBillingAddressInput;
  readonly currency?: InputMaybe<Scalars['String']['input']>;
  readonly paymentMethods: Scalars['String']['input'];
}

export type IAddPaymentMethodPayload =
  | IAddPaymentMethodSuccess
  | IThreeDsChallenge
  | IThreeDsFrictionless;

export interface IAddPaymentMethodSuccess {
  readonly __typename?: 'AddPaymentMethodSuccess';
  readonly storedPaymentMethod: IStoredPaymentMethod;
}

export interface IAdditionalTimeSlot {
  readonly __typename?: 'AdditionalTimeSlot';
  /** Type AdditionalTimeSlot */
  readonly _type: Maybe<Scalars['String']['output']>;
  /** Close additional hours */
  readonly close: Maybe<Scalars['String']['output']>;
  /** Open additional hours */
  readonly open: Maybe<Scalars['String']['output']>;
}

export interface IAdyenEncryptedInfo {
  /** Encrypted credit card number */
  readonly encryptedCN: Scalars['String']['input'];
  /** Encrypted credit card expiry month */
  readonly encryptedEM: Scalars['String']['input'];
  /** Encrypted credit card expiry year */
  readonly encryptedEY: Scalars['String']['input'];
  /** Encrypted credit card security code */
  readonly encryptedSC: Scalars['String']['input'];
}

export interface IAdyenPayment {
  /**
   * Represents a saved funding source. (i.e. fdAccountId or recurringDetailReference)
   * If passed this will be used to process payment. Either accountIdentifier or
   * encrypted payload is required.
   */
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Encrypted Payment Payload. If accountIdentifier is not passed this will be used. */
  readonly encryptedPayload?: InputMaybe<IAdyenEncryptedInfo>;
  /** Denotes the payment type */
  readonly paymentType?: InputMaybe<AdyenPaymentType>;
  /** When true, payment details will be saved as a recurring payment method in Adyen */
  readonly savePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum AdyenPaymentType {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  TOKENIZED_CREDIT_CARD = 'TOKENIZED_CREDIT_CARD',
}

export interface IApiMetadata {
  readonly __typename?: 'ApiMetadata';
  /** Pong! Simple health check. */
  readonly ping: Scalars['String']['output'];
}

/** Details from Apple Pay used for Apple Pay funding. */
export interface IApplePay {
  /** Data of the payment method used for Apple Pay. */
  readonly PaymentMethodData?: InputMaybe<IPaymentMethodData>;
  /** Application data as passed through from Apple Pay */
  readonly applicationData: Scalars['String']['input'];
  readonly billingAddress: IBillingAddressInput;
  /** part of the billing address from apple pay */
  readonly country: CountryCode;
  readonly data: Scalars['String']['input'];
  readonly decryptAlias: Scalars['String']['input'];
  readonly ephemeralPublicKey: Scalars['String']['input'];
  readonly formatted: Scalars['String']['input'];
  /** bundle all apple pay details into an encrypted token. */
  readonly paymentData?: InputMaybe<Scalars['String']['input']>;
  readonly primary: Scalars['Boolean']['input'];
  readonly publicKeyHash: Scalars['String']['input'];
  /** Signature as passed through from Apple Pay */
  readonly signature: Scalars['String']['input'];
  readonly transactionId: Scalars['String']['input'];
  /** type of the billing address set in apple pay e.g. work, home, etc */
  readonly type: Scalars['String']['input'];
  readonly version: Scalars['String']['input'];
}

export interface IApplePayPaymentConfig {
  readonly __typename?: 'ApplePayPaymentConfig';
  readonly prepaidMerchantId: Maybe<Scalars['String']['output']>;
  readonly schemeMerchantId: Maybe<Scalars['String']['output']>;
}

export interface IAppliedOffer {
  readonly cartId?: InputMaybe<Scalars['ID']['input']>;
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId?: InputMaybe<Scalars['ID']['input']>;
  readonly swap?: InputMaybe<ISwapInput>;
  readonly type?: InputMaybe<OfferType>;
}

export interface IAppliedReward {
  readonly cartId: Scalars['ID']['input'];
  readonly price?: InputMaybe<Scalars['Int']['input']>;
  readonly rewardId: Scalars['ID']['input'];
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId?: InputMaybe<Scalars['ID']['input']>;
  readonly timesApplied: Scalars['Int']['input'];
}

export interface IAssociateGuestUserResponse {
  readonly __typename?: 'AssociateGuestUserResponse';
  /** Date and time when the the identifier got activated */
  readonly activationDateTime: Scalars['String']['output'];
  readonly guestBalance: Scalars['Int']['output'];
  /** Date and time when the linking between the identifier and the loyalty user happened */
  readonly linkingDateTime: Scalars['String']['output'];
  readonly totalBalance: Scalars['Int']['output'];
}

export interface IBaseIncentive {
  readonly cartRequirements: Maybe<ReadonlyArray<Maybe<ReadonlyArray<Maybe<ICartRequirement>>>>>;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<IRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<IExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly maxRedemptionsDuration: Maybe<DurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<PaymentMethod>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<ServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<IStoreRestrictions>;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<BirthdayDuration>;
  readonly userEnrollment: Maybe<IDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<DayOfTheWeek>>;
}

export interface IBenefit {
  readonly type: BenefitType;
}

export interface IBenefitReference extends IBenefit {
  readonly __typename?: 'BenefitReference';
  readonly type: BenefitType;
  readonly value: Scalars['String']['output'];
}

export interface IBenefitSwap extends IBenefit {
  readonly __typename?: 'BenefitSwap';
  readonly type: BenefitType;
  readonly value: ISwap;
}

export enum BenefitType {
  REFERENCE = 'REFERENCE',
  SWAP = 'SWAP',
}

export interface IBillingAddressInput {
  /** Country */
  readonly country?: InputMaybe<Scalars['String']['input']>;
  /** City */
  readonly locality?: InputMaybe<Scalars['String']['input']>;
  /** Zip Code */
  readonly postalCode: Scalars['String']['input'];
  /** State */
  readonly region?: InputMaybe<Scalars['String']['input']>;
  /** Street Address */
  readonly streetAddress?: InputMaybe<Scalars['String']['input']>;
  /** Apt or Unit Number */
  readonly unitNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IBillingCustomerInput {
  /** Email associated with bank account */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** First name associated with bank account */
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  /** Last name associated with bank account */
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number associated with bank account */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export enum BirthdayDuration {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export interface IBlueLightCardInput {
  /** Loyalty ID associated to the RBI User */
  readonly loyaltyId: Scalars['String']['input'];
  /** Data required for Blue Light Card linking */
  readonly metadata: IBlueLightCardMetadata;
  readonly partnerName: Partner;
  readonly region: IsoCountryCode2Char;
  /** RBI User ID */
  readonly userId: Scalars['String']['input'];
}

export interface IBlueLightCardMetadata {
  /** Blue Light Card guest name on card */
  readonly cardHolderName: Scalars['String']['input'];
  /** Blue Light Card guest card number */
  readonly cardNumber: Scalars['String']['input'];
}

export interface IBlueLightCardMetadataOutput {
  readonly __typename?: 'BlueLightCardMetadataOutput';
  /** Blue Light Card guest name on card */
  readonly cardHolderName: Scalars['String']['output'];
  /** Blue Light Card guest card number */
  readonly cardNumber: Scalars['String']['output'];
}

export enum Brand {
  BK = 'BK',
  FHS = 'FHS',
  LTW = 'LTW',
  PLK = 'PLK',
  TH = 'TH',
}

export interface IBrazeUserPhone {
  readonly __typename?: 'BrazeUserPhone';
  readonly phone: Maybe<Scalars['String']['output']>;
}

export interface IBrowserInformation {
  /** The accept header in the client app. */
  readonly acceptHeader: Scalars['String']['input'];
  /** The color depth of the shoppers' browser in bits per pixel. */
  readonly colorDepth: Scalars['Int']['input'];
  /** Value indicating if the customer's browser is able to execute Java. */
  readonly javaEnabled: Scalars['Boolean']['input'];
  /** Value indicating the browser language as defined in IETF BCP47. eg en-US */
  readonly language: Scalars['String']['input'];
  /** The total height of the customers' device screen in pixels. */
  readonly screenHeight: Scalars['Int']['input'];
  /** The total width of the customers' device screen in pixels. */
  readonly screenWidth: Scalars['Int']['input'];
  /** Time difference between UTC time and the cardholder browser local time, in minutes. */
  readonly timeZoneOffset: Scalars['Int']['input'];
  /** The user agent value of the customer's browser */
  readonly userAgent: Scalars['String']['input'];
}

export interface ICbaCouponResponse extends ICouponResponse {
  readonly __typename?: 'CBACouponResponse';
  readonly code: Scalars['String']['output'];
  readonly isRedeemed: Maybe<Scalars['Boolean']['output']>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly message: Scalars['String']['output'];
  readonly offerId: Maybe<Scalars['String']['output']>;
  readonly reason: Maybe<Scalars['String']['output']>;
  readonly shouldRedeem: Maybe<Scalars['Boolean']['output']>;
}

export interface ICbaRedeemCouponResponse extends IRedeemedCouponResponse {
  readonly __typename?: 'CBARedeemCouponResponse';
  readonly code: Scalars['String']['output'];
  readonly isRedeemed: Scalars['Boolean']['output'];
  readonly message: Maybe<Scalars['String']['output']>;
  readonly offerId: Maybe<Scalars['String']['output']>;
  readonly reason: Maybe<Scalars['String']['output']>;
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface ICalories {
  readonly __typename?: 'Calories';
  readonly default: Maybe<Scalars['Int']['output']>;
  readonly max: Maybe<Scalars['Int']['output']>;
  readonly min: Maybe<Scalars['Int']['output']>;
}

export interface ICancelOrderInput {
  /** number of seconds from now to inject order */
  readonly fireOrderIn: Scalars['Int']['input'];
  readonly rbiOrderId: Scalars['ID']['input'];
}

export enum CardBrand {
  AMEX = 'AMEX',
  DINERS_CLUB = 'DINERS_CLUB',
  DISCOVER = 'DISCOVER',
  JCB = 'JCB',
  MADA = 'MADA',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export interface ICart {
  readonly __typename?: 'Cart';
  readonly appliedOffers: Maybe<ReadonlyArray<Maybe<ICartAppliedOffer>>>;
  readonly brand: Brand;
  readonly cartEntries: Maybe<ReadonlyArray<ICartEntries>>;
  readonly cartVersion: Maybe<Scalars['Int']['output']>;
  readonly customerLocale: Maybe<Scalars['String']['output']>;
  readonly customerName: Scalars['String']['output'];
  readonly deviceOS: Maybe<Scalars['String']['output']>;
  readonly discounts: Maybe<ReadonlyArray<Maybe<IDiscount>>>;
  readonly donations: Maybe<ReadonlyArray<Maybe<IDonation>>>;
  readonly fees: Maybe<ReadonlyArray<Maybe<IFees>>>;
  readonly guestDetails: Maybe<IGuestDetails>;
  readonly guestId: Maybe<Scalars['String']['output']>;
  /**
   * (Deprecated) Used for CBA Offers
   * @deprecated CBA Offers were deprecated
   */
  readonly offersFeedback: Maybe<ReadonlyArray<Maybe<ICouponUserOffersFeedbackEntry>>>;
  readonly payment: Maybe<IPayment>;
  readonly platform: Maybe<Platform>;
  readonly posVendor: PosVendor;
  /**
   * ISO Timestamp from POS in local timezone e.g 2019-07-08T23:02:17.203Z
   * @deprecated Only for internal use
   */
  readonly processedAt: Maybe<Scalars['String']['output']>;
  /**
   * Cart entries for reordering.
   * This is usually the same as cartEntries except for when there are swaps.
   */
  readonly reorderCartEntries: Maybe<ReadonlyArray<ICartEntries>>;
  readonly requestedAmountCents: Scalars['Int']['output'];
  readonly rewardsApplied: Maybe<ReadonlyArray<Maybe<ICartAppliedReward>>>;
  readonly serviceMode: ServiceMode;
  readonly storeAddress: IStoreAddress;
  readonly storeDetails: Maybe<IStoreDetails>;
  readonly storeId: Scalars['String']['output'];
  readonly storePosId: Maybe<Scalars['String']['output']>;
  readonly subTotalCents: Maybe<Scalars['Int']['output']>;
  /** @deprecated Not needed anymore since it is included in AppliedOffer */
  readonly swaps: Maybe<ReadonlyArray<Maybe<ICartSwap>>>;
  readonly taxCents: Maybe<Scalars['Int']['output']>;
  readonly ticketNumber: Maybe<Scalars['String']['output']>;
  readonly totalCents: Maybe<Scalars['Int']['output']>;
  readonly unavailableCartEntries: Maybe<ReadonlyArray<Maybe<IUnavailableCartEntry>>>;
  readonly userDetails: Maybe<IUserDetails>;
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly vatNumber: Maybe<Scalars['String']['output']>;
  readonly wasLoyaltyEarned: Maybe<Scalars['Boolean']['output']>;
}

export interface ICartAppliedOffer {
  readonly __typename?: 'CartAppliedOffer';
  readonly cartId: Maybe<Scalars['ID']['output']>;
  readonly id: Scalars['ID']['output'];
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId: Maybe<Scalars['ID']['output']>;
  readonly swap: Maybe<ICartSwap>;
  readonly type: OfferType;
}

/** AppliedReward is duplicated here to avoid breaking changes as above definition serves as an input. */
export interface ICartAppliedReward {
  readonly __typename?: 'CartAppliedReward';
  readonly cartId: Scalars['ID']['output'];
  readonly price: Maybe<Scalars['Int']['output']>;
  readonly rewardId: Scalars['ID']['output'];
  /** This should be required but for the moment we will add it as an optional input to not introduce a breaking change */
  readonly sanityId: Maybe<Scalars['ID']['output']>;
  readonly timesApplied: Scalars['Int']['output'];
}

export interface ICartDiscount {
  readonly __typename?: 'CartDiscount';
  readonly type: CartDiscountType;
  readonly value: Scalars['Float']['output'];
}

export interface ICartDiscountInput {
  readonly type: CartDiscountType;
  readonly value: Scalars['Float']['input'];
}

export enum CartDiscountType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export interface ICartEntries {
  readonly __typename?: 'CartEntries';
  readonly children: Maybe<ReadonlyArray<Maybe<ICartEntries>>>;
  readonly image: Maybe<Scalars['String']['output']>;
  readonly isDonation: Maybe<Scalars['Boolean']['output']>;
  readonly isExtra: Maybe<Scalars['Boolean']['output']>;
  readonly isInMenu: Maybe<Scalars['Boolean']['output']>;
  readonly lineId: Scalars['String']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly offerVendorConfigs: Maybe<IVendorConfigs>;
  readonly pickerSelections: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<IPlu>;
  readonly price: Maybe<Scalars['Int']['output']>;
  readonly quantity: Scalars['Int']['output'];
  readonly redeemable: Maybe<Scalars['Boolean']['output']>;
  readonly sanityId: Scalars['String']['output'];
  readonly type: CartEntryType;
  readonly url: Maybe<Scalars['String']['output']>;
  readonly vendorConfigs: Maybe<IVendorConfigs>;
}

export interface ICartEntryInput {
  readonly children?: InputMaybe<ReadonlyArray<ICartEntryInput>>;
  readonly image?: InputMaybe<Scalars['String']['input']>;
  readonly isDonation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isExtra?: InputMaybe<Scalars['Boolean']['input']>;
  readonly lineId: Scalars['String']['input'];
  readonly markerItemPlu?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly offerVendorConfigs?: InputMaybe<IVendorConfigsInput>;
  readonly pickerSelections?: InputMaybe<Scalars['String']['input']>;
  readonly plu?: InputMaybe<IPluInput>;
  readonly price?: InputMaybe<Scalars['Int']['input']>;
  readonly productHierarchy?: InputMaybe<IProductHierarchyInput>;
  readonly quantity: Scalars['Int']['input'];
  readonly sanityId: Scalars['String']['input'];
  readonly type: CartEntryType;
  readonly url?: InputMaybe<Scalars['String']['input']>;
  readonly vendorConfigs?: InputMaybe<IVendorConfigsInput>;
}

export enum CartEntryType {
  COMBO = 'Combo',
  COMBOSLOT = 'ComboSlot',
  ITEM = 'Item',
  ITEMOPTION = 'ItemOption',
  ITEMOPTIONMODIFIER = 'ItemOptionModifier',
  OFFER = 'Offer',
  OFFERCOMBO = 'OfferCombo',
  OFFERDISCOUNT = 'OfferDiscount',
  OFFERITEM = 'OfferItem',
  REWARD = 'Reward',
}

export enum CartOfferState {
  ACTIVE = 'ACTIVE',
  CONSUMED = 'CONSUMED',
  INACTIVE = 'INACTIVE',
}

export enum CartPaymentCardType {
  AMEX = 'AMEX',
  APPLEPAYLINK = 'APPLEPAYLINK',
  APPLE_PAY = 'APPLE_PAY',
  BIZUM = 'BIZUM',
  CARTES_BANCAIRES = 'CARTES_BANCAIRES',
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  DINERS = 'DINERS',
  DINERS_CLUB = 'DINERS_CLUB',
  DISCOVER = 'DISCOVER',
  GIFT_CARD = 'GIFT_CARD',
  GOOGLE_PAY = 'GOOGLE_PAY',
  IDEAL = 'IDEAL',
  JCB = 'JCB',
  MADA = 'MADA',
  MAESTRO = 'MAESTRO',
  MASTERCARD = 'MASTERCARD',
  MBWAY = 'MBWAY',
  PAYPAL = 'PAYPAL',
  TWINT = 'TWINT',
  VISA = 'VISA',
  WAYLET = 'WAYLET',
}

export enum CartPaymentType {
  APPLE_PAY = 'APPLE_PAY',
  BLIK = 'BLIK',
  CASH = 'CASH',
  CREDIT_ANONYMOUS = 'CREDIT_ANONYMOUS',
  GOOGLE_PAY = 'GOOGLE_PAY',
  IDEAL = 'IDEAL',
  MBWAY = 'MBWAY',
  PAYPAL = 'PAYPAL',
  TWINT = 'TWINT',
  VAULTED_ACCOUNT = 'VAULTED_ACCOUNT',
}

export interface ICartRequirement {
  readonly __typename?: 'CartRequirement';
  readonly id: Scalars['String']['output'];
  readonly quantity: Scalars['Int']['output'];
}

export interface ICartSwap {
  readonly __typename?: 'CartSwap';
  readonly cartId: Scalars['String']['output'];
  readonly from: Scalars['String']['output'];
  readonly swapType: Scalars['String']['output'];
  readonly to: Scalars['String']['output'];
}

export interface ICatering {
  readonly __typename?: 'Catering';
  readonly declineReason: Maybe<CateringDeclineReasons>;
  readonly notes: Maybe<Scalars['String']['output']>;
  readonly pickupTime: Scalars['String']['output'];
}

export enum CateringDecision {
  ACCEPT = 'Accept',
  DECLINE = 'Decline',
}

export enum CateringDeclineReasons {
  CATERING_ORDER_TIME_OUT = 'CATERING_ORDER_TIME_OUT',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVENTORY = 'INVENTORY',
  STAFFING = 'STAFFING',
  TIMING = 'TIMING',
}

export interface ICateringInfoInput {
  /**
   * Pickup Time - in ISO format.
   * Only ISO-formatted datetime values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly pickupTime: Scalars['String']['input'];
}

export enum Channel {
  BITENINJA = 'biteninja',
  BOLT = 'bolt',
  CATERING = 'catering',
  DOORDASH = 'doordash',
  GFO = 'gfo',
  GLOVO = 'glovo',
  GRUBHUB = 'grubhub',
  IN_STORE = 'in_store',
  JUST_EAT = 'just_eat',
  SKIP = 'skip',
  UBER = 'uber',
  WAITR = 'waitr',
  WHITELABEL = 'whitelabel',
}

export interface ICheckoutDotComPayment {
  /** Order Description (the first item name of the cart) */
  readonly orderDescription?: InputMaybe<Scalars['String']['input']>;
  /** Payment Token */
  readonly paymentToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface ICognitoCredentials {
  readonly __typename?: 'CognitoCredentials';
  readonly challengeCode: Scalars['String']['output'];
  readonly email: Maybe<Scalars['String']['output']>;
  readonly sessionId: Scalars['String']['output'];
}

export interface ICommitDeliveryInput {
  /**
   * drop off location for delivery (user's address)
   * this can be marked as required when launch darkly
   * flag is no longer in use
   *
   * @deprecated(reason: "needs to be named dropoff")
   */
  readonly dropOff?: InputMaybe<IDeliveryWaypointInput>;
  readonly dropoff?: InputMaybe<IDeliveryWaypointInput>;
  readonly instructions?: InputMaybe<Scalars['String']['input']>;
  /** boolean flag to mark notification setting */
  readonly pushNotification?: InputMaybe<Scalars['Boolean']['input']>;
  /** The time slot selected for scheduled delivery */
  readonly selectedPreOrderTimeSlot?: InputMaybe<IPreOrderTimeSlotInput>;
  readonly tipCents?: InputMaybe<Scalars['Int']['input']>;
}

export interface ICommitOrderInput {
  /** The Additional details provided to pass Additional Info from FE to Partners API. */
  readonly additionalDetails?: InputMaybe<Scalars['String']['input']>;
  /**
   * Details from Apple Pay for payment.
   * @deprecated(reason: "Use payment object")
   */
  readonly applePayDetails?: InputMaybe<IApplePay>;
  /**
   * Billing address Comes in the following format
   * billingStreetAddress, billingApt, billingCity, billingState, billingZip
   * @deprecated(reason: "Use payment object")
   */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /**
   * First 6 digits of credit card.
   * Required for anonymous payment.
   * @deprecated(reason: "Use payment object")
   * @constraint(pattern: "^[0-9]{6}$")
   */
  readonly ccBin?: InputMaybe<Scalars['String']['input']>;
  /**
   * Credit card 2 digit expiry month
   * @deprecated(reason: "Use payment object")
   */
  readonly ccExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  /**
   * Credit card 2 digit expiry year
   * @deprecated(reason: "Use payment object")
   */
  readonly ccExpiryYear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Last 6 digits of credit card.
   * Required for anonymous payment.
   * @deprecated(reason: "Use payment object")
   * @constraint(pattern: "^[0-9]{4}$")
   */
  readonly ccLast4?: InputMaybe<Scalars['String']['input']>;
  /**
   * chase id from TH endpoint to trigger direct payment
   * @deprecated(reason: "Use payment object")
   */
  readonly chaseProfileId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The payment provider type eg. (VISA/MASTERCARD/DISCOVER/AMEX)
   * @deprecated(reason: "Use payment object")
   */
  readonly creditType?: InputMaybe<CartPaymentCardType>;
  /** DeviceSessionId for Checkout.com risk assessment */
  readonly deviceSessionId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Valid access token for accessing First Data API
   * @deprecated(reason: "Use firstDataInput on payment object")
   */
  readonly fdAccessToken?: InputMaybe<Scalars['String']['input']>;
  /**
   * The First Data payment method ID which must be associated to the user
   * making the request.
   * @deprecated(reason: "Use firstDataInput on payment object")
   */
  readonly fdAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Nonce retrieved from First Data. Sale will be performed with the payment
   * method associated with the nonce.
   * @deprecated(reason: "Use firstDataInput on payment object")
   */
  readonly fdNonce?: InputMaybe<Scalars['String']['input']>;
  /** Takes in a time in seconds to delay the order commit */
  readonly fireOrderIn?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Customer full name
   * @deprecated(reason: "Use payment object")
   */
  readonly fullName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Details from Google Pay for payment.
   * @deprecated(reason: "Use payment object")
   */
  readonly googlePayDetails?: InputMaybe<IGooglePay>;
  /** The email address provided for Guest Order purposes. */
  readonly guestEmail?: InputMaybe<Scalars['String']['input']>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   * @deprecated(reason: "Use payment object")
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /** Contains information to submit payment for this order */
  readonly payment?: InputMaybe<IPaymentInput>;
  /** ID for a previous order that has been priced */
  readonly rbiOrderId: Scalars['ID']['input'];
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /** Flag that indicates that the commit order request should only be saved to be used by the backend commit order */
  readonly saveCommitOrderOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Unique Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   *
   * @deprecated(reason: "Use kountSessionId on payment object")
   */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  /** Store email address */
  readonly storeEmail?: InputMaybe<Scalars['String']['input']>;
  /** Store ID, derived from MDM. This value will be used for LD restrictions. */
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  /** Details from 3DS transactions, relates to properties needed to resume sale transactions. */
  readonly threeDSDetails?: InputMaybe<IResumeThreeDSaleTransaction>;
}

export interface ICommunicationPreference {
  readonly __typename?: 'CommunicationPreference';
  /** Database id of the preference (i.e. language ) */
  readonly id: Maybe<Scalars['String']['output']>;
  /** Value the preference is set to */
  readonly value: Maybe<Scalars['String']['output']>;
}

export interface IConfirmCateringOrderInput {
  /** Catering JWT containing catering order info */
  readonly cateringJwt: Scalars['String']['input'];
  /** Accept or Decline Order */
  readonly decision: CateringDecision;
  /** Decline Reason */
  readonly declineReason?: InputMaybe<CateringDeclineReasons>;
}

/**
 * https://relay.dev/graphql/connections.htm#sec-Connection-Types
 *
 * A connection as defined by the GraphQL Cursor Connections Specifications
 *
 * Provides totalCount if there is an efficient way to determine the total
 * pre-paginated results.
 *
 * Provides an array of nodes for consumers that do not need each node's cursor.
 */
export interface IConnection {
  readonly edges: ReadonlyArray<IEdge>;
  readonly nodes: ReadonlyArray<INode>;
  readonly pageInfo: IPageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

/**
 * Coordinates is used by restaurants search to filter
 * by a radius and the current user's coordinates
 */
export interface ICoordinates {
  readonly searchRadius?: InputMaybe<Scalars['Float']['input']>;
  readonly userLat?: InputMaybe<Scalars['Float']['input']>;
  readonly userLng?: InputMaybe<Scalars['Float']['input']>;
}

export enum CountryCode {
  AE = 'AE',
  CA = 'CA',
  CH = 'CH',
  GB = 'GB',
  MX = 'MX',
  SA = 'SA',
  US = 'US',
  ZA = 'ZA',
}

/** To be removed after old clients update */
export interface ICouponEvaluationFeedback {
  readonly __typename?: 'CouponEvaluationFeedback';
  /** @deprecated CBA Offers were deprecated */
  readonly code: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly condition: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly expiresOn: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly message: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redeemableForSeconds: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redeemableInSeconds: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly ruleSetIndex: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly ruleSetType: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly sanityId: Maybe<Scalars['String']['output']>;
}

/** To be removed after old clients update */
export interface ICouponRedemptionEligibility {
  readonly __typename?: 'CouponRedemptionEligibility';
  /** @deprecated CBA Offers were deprecated */
  readonly evaluationFeedback: Maybe<ReadonlyArray<Maybe<ICouponEvaluationFeedback>>>;
  /** @deprecated CBA Offers were deprecated */
  readonly expiresOn: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly isRedeemable: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly isValid: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redeemedOn: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly validationErrors: Maybe<ReadonlyArray<Maybe<ICouponValidationError>>>;
}

export interface ICouponResponse {
  readonly code: Scalars['String']['output'];
  readonly isRedeemed: Maybe<Scalars['Boolean']['output']>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly message: Scalars['String']['output'];
  readonly reason: Maybe<Scalars['String']['output']>;
  readonly shouldRedeem: Maybe<Scalars['Boolean']['output']>;
}

/** To be removed after old clients update */
export interface ICouponUserOffersFeedbackEntry {
  readonly __typename?: 'CouponUserOffersFeedbackEntry';
  /** @deprecated CBA Offers were deprecated */
  readonly cartEntry: Maybe<ICartEntries>;
  /** @deprecated CBA Offers were deprecated */
  readonly couponId: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly isLoyalty: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly offerDetails: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly offerState: Maybe<CartOfferState>;
  /** @deprecated CBA Offers were deprecated */
  readonly offerVariables: Maybe<ReadonlyArray<Maybe<IOfferVariable>>>;
  /** @deprecated CBA Offers were deprecated */
  readonly rank: Maybe<Scalars['Int']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly redemptionEligibility: Maybe<ICouponRedemptionEligibility>;
  /** @deprecated CBA Offers were deprecated */
  readonly tokenId: Maybe<Scalars['String']['output']>;
}

/** To be removed after old clients update */
export interface ICouponValidationError {
  readonly __typename?: 'CouponValidationError';
  /** @deprecated CBA Offers were deprecated */
  readonly code: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly message: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly ruleSetType: Maybe<Scalars['String']['output']>;
}

export interface ICreateGuestOtpInput {
  /** The email of the user requesting a One-Time Password. Only one of either */
  readonly email: Scalars['String']['input'];
  /** Requestor's platform */
  readonly platform: Platform;
  /**
   * A unique identifier to associate with the one-time password, to be provided on
   * validation attempts
   */
  readonly sessionId: Scalars['ID']['input'];
}

export interface ICreateOtp {
  readonly __typename?: 'CreateOTP';
  /**
   * The maximum validation attempts allowed for the created OTP
   * @deprecated Use maxValidateAttempts
   */
  readonly maxLoginAttempts: Scalars['Int']['output'];
  /** The maximum validation attempts allowed for the created OTP */
  readonly maxValidateAttempts: Scalars['Int']['output'];
  /** Whether the login has been performed in US and requires redirection */
  readonly redirectToUS: Maybe<Scalars['Boolean']['output']>;
  /** The time-to-live for the one-time password, in seconds */
  readonly ttl: Scalars['Int']['output'];
}

export interface ICreateOtpInput {
  /**
   * The email of the user requesting a One-Time Password. Only one of either
   * email or phoneNumber can be provided. One or the other is required.
   * @todo - only email is implemented at this time
   */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /**
   * The phone number of the user requesting a One-Time Password. Only one of either
   * email or phoneNumber can be provided. One or the other is required.
   * @todo - not implemented
   */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Requestor's platform */
  readonly platform: Platform;
  /**
   * A unique identifier to associate with the one-time password, to be provided on
   * validation attempts
   */
  readonly sessionId: Scalars['ID']['input'];
}

export interface ICreatePaymentIntentInput {
  readonly amount: Scalars['Int']['input'];
  readonly brand: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly currency: Scalars['String']['input'];
  readonly referenceId: Scalars['String']['input'];
  readonly store: Scalars['String']['input'];
}

export interface ICreditCardMetadataInput {
  /**
   * First 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{6}$")
   */
  readonly ccBin?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry month */
  readonly ccExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry year */
  readonly ccExpiryYear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Last 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{4}$")
   */
  readonly ccLast4?: InputMaybe<Scalars['String']['input']>;
  /** The payment provider type eg. (VISA/MASTERCARD/DISCOVER/AMEX) */
  readonly creditType?: InputMaybe<CartPaymentCardType>;
}

export interface ICybersourcePayment {
  /** Session ID sent to Decision Manager to obtain the device fingerprint information. */
  readonly fingerprintSessionId?: InputMaybe<Scalars['String']['input']>;
  /** The session id of the payer authentication setup session. */
  readonly payerAuthenticationReferenceId?: InputMaybe<Scalars['String']['input']>;
  /** Payment Token */
  readonly paymentToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IDateSpan {
  readonly __typename?: 'DateSpan';
  readonly endDate: Maybe<Scalars['String']['output']>;
  readonly startDate: Maybe<Scalars['String']['output']>;
}

export interface IDay {
  readonly __typename?: 'Day';
  readonly close: Maybe<Scalars['String']['output']>;
  readonly open: Maybe<Scalars['String']['output']>;
}

export enum DayOfTheWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export interface IDayPart {
  readonly __typename?: 'DayPart';
  readonly displayName: Maybe<Scalars['String']['output']>;
  readonly hours: Maybe<IOperatingHours>;
  readonly key: Maybe<Scalars['String']['output']>;
}

/** Delete an account (payment method) from the current user */
export interface IDeletePaymentMethodInput {
  /**
   * Represents a saved funding source. (i.e. fdAccountId or recurringDetailReference)
   * Use this field instead.
   */
  readonly storedPaymentMethodId: Scalars['String']['input'];
}

export interface IDeletePaymentMethodPayload {
  readonly __typename?: 'DeletePaymentMethodPayload';
  readonly success: Scalars['Boolean']['output'];
}

export interface IDelivery {
  readonly __typename?: 'Delivery';
  readonly assignedFleetName: Maybe<Scalars['String']['output']>;
  readonly baseDeliveryFeeCents: Maybe<Scalars['Int']['output']>;
  readonly cancellation: Maybe<IDeliveryCancellation>;
  readonly customerId: Maybe<Scalars['String']['output']>;
  readonly deliverySurchargeFeeCents: Maybe<Scalars['Int']['output']>;
  readonly driver: Maybe<IDeliveryDriver>;
  readonly dropoff: IDeliveryWaypoint;
  readonly estimateId: Maybe<Scalars['String']['output']>;
  readonly expiration: Maybe<Scalars['String']['output']>;
  readonly feeCents: Scalars['Int']['output'];
  readonly feeDiscountCents: Maybe<Scalars['Int']['output']>;
  readonly fleetId: Maybe<Scalars['String']['output']>;
  readonly geographicalFeeCents: Maybe<Scalars['Int']['output']>;
  readonly instructions: Maybe<Scalars['String']['output']>;
  readonly pickup: IDeliveryWaypoint;
  readonly pincode: Maybe<Scalars['String']['output']>;
  readonly preOrderTimeSlots: Maybe<ReadonlyArray<Maybe<IPreOrderTimeSlot>>>;
  readonly quoteId: Maybe<Scalars['String']['output']>;
  readonly quotedFeeCents: Maybe<Scalars['Int']['output']>;
  readonly serviceFeeCents: Maybe<Scalars['Int']['output']>;
  readonly serviceFeeDiscountCents: Maybe<Scalars['Int']['output']>;
  readonly shareUuid: Maybe<Scalars['String']['output']>;
  readonly smallCartFeeCents: Maybe<Scalars['Int']['output']>;
  readonly status: DeliveryStatus;
  readonly task: Maybe<IDeliveryTaskDetails>;
  readonly tipCents: Maybe<Scalars['Int']['output']>;
  readonly unavailabilityReason: Maybe<Scalars['String']['output']>;
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly vendorProperties: Maybe<IVendor>;
}

export interface IDeliveryAddress {
  readonly __typename?: 'DeliveryAddress';
  /** Address Line limited to 150 chars */
  readonly addressLine1: Scalars['String']['output'];
  /** Address Line 2 limited to 150 chars */
  readonly addressLine2: Maybe<Scalars['String']['output']>;
  /** Alias to identify an address e.g home, office etc */
  readonly alias: Maybe<Scalars['String']['output']>;
  /** city name limited to 150 chars */
  readonly city: Scalars['String']['output'];
  /** User's Country code in ISO 3166 alpha-3 format, e.g. GBR, DEU, CHE, NZL. */
  readonly country: Maybe<Scalars['String']['output']>;
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  /** 10 digit phone number spaces don't matter */
  readonly phoneNumber: Scalars['String']['output'];
  readonly route: Maybe<Scalars['String']['output']>;
  /** state abbreviation FL, GA, etc */
  readonly state: Maybe<Scalars['String']['output']>;
  readonly streetNumber: Maybe<Scalars['String']['output']>;
  /** zipCode 5-10 digits no spaces */
  readonly zip: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryAddressFulfilment {
  readonly __typename?: 'DeliveryAddressFulfilment';
  /** Address Line limited to 150 chars */
  readonly addressLine1: Scalars['String']['output'];
  /** Address Line 2 limited to 150 chars */
  readonly addressLine2: Maybe<Scalars['String']['output']>;
  /** Alias to identify an address e.g home, office etc */
  readonly alias: Maybe<Scalars['String']['output']>;
  /** city name limited to 150 chars */
  readonly city: Scalars['String']['output'];
  /** Country code in ISO 3166 alpha-3 format, e.g. GBR, DEU, CHE, NZL. */
  readonly country: Maybe<Scalars['String']['output']>;
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  /** 10 digit phone number spaces don't matter */
  readonly phoneNumber: Scalars['String']['output'];
  readonly route: Maybe<Scalars['String']['output']>;
  /** state abbreviation FL, GA, etc */
  readonly state: Maybe<Scalars['String']['output']>;
  readonly streetNumber: Maybe<Scalars['String']['output']>;
  /** zipCode 5-10 digits no spaces */
  readonly zip: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryAddressResponse {
  readonly __typename?: 'DeliveryAddressResponse';
  readonly deliveryAddresses: Maybe<ReadonlyArray<IDeliveryAddress>>;
}

export interface IDeliveryCancellation {
  readonly __typename?: 'DeliveryCancellation';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly details: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['Int']['output']>;
  readonly reason: Maybe<Scalars['String']['output']>;
  readonly text: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryDriver {
  readonly __typename?: 'DeliveryDriver';
  readonly location: Maybe<ILocation>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly profileImageUrl: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryOrderAmountLimit {
  readonly __typename?: 'DeliveryOrderAmountLimit';
  readonly deliveryOrderAmountLimit: Maybe<Scalars['Float']['output']>;
  readonly deliveryOrderAmountLimitEnabled: Maybe<Scalars['Boolean']['output']>;
  readonly deliveryOrderRepeatedFailureLimitation: Maybe<Scalars['Boolean']['output']>;
  readonly firstDeliveryOrder: Maybe<Scalars['Float']['output']>;
  readonly firstDeliveryOrderEnabled: Maybe<Scalars['Boolean']['output']>;
}

export interface IDeliveryRestaurant {
  readonly __typename?: 'DeliveryRestaurant';
  /** Restaurant delivery surcharge fee cents. */
  readonly deliverySurchargeFeeCents: Maybe<Scalars['Int']['output']>;
  /** Next available delivery time for store */
  readonly nextEarliestOpen: Maybe<Scalars['String']['output']>;
  /** Status of delivery order */
  readonly quote: Maybe<DeliveryStatus>;
  /** the quoteId related to the current delivery restaurant */
  readonly quoteId: Maybe<Scalars['String']['output']>;
  /** Extended restaurant data containing all fields from Sanity + Dynamo */
  readonly restaurant: Maybe<IRestaurantNode>;
  /** Status of delivery store */
  readonly storeStatus: DeliveryStoreStatus;
  /** the unavailabilityReason related to delivery restaurant service paused reason */
  readonly unavailabilityReason: Maybe<Scalars['String']['output']>;
}

export enum DeliveryStatus {
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_AT_CUSTOMER = 'DRIVER_AT_CUSTOMER',
  DRIVER_AT_STORE = 'DRIVER_AT_STORE',
  DRIVER_STARTING = 'DRIVER_STARTING',
  DRIVER_UNASSIGNED = 'DRIVER_UNASSIGNED',
  ORDER_ABANDONED = 'ORDER_ABANDONED',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_DROPPED_OFF = 'ORDER_DROPPED_OFF',
  ORDER_ERROR = 'ORDER_ERROR',
  ORDER_PICKED_UP = 'ORDER_PICKED_UP',
  ORDER_TIMEOUT = 'ORDER_TIMEOUT',
  QUOTE_ERROR = 'QUOTE_ERROR',
  QUOTE_REQUESTED = 'QUOTE_REQUESTED',
  QUOTE_SUCCESSFUL = 'QUOTE_SUCCESSFUL',
  QUOTE_UNAVAILABLE = 'QUOTE_UNAVAILABLE',
}

export enum DeliveryStoreStatus {
  CLOSED = 'CLOSED',
  NO_DELIVERY = 'NO_DELIVERY',
  OPEN = 'OPEN',
  PAUSED = 'PAUSED',
}

export interface IDeliveryTaskDetails {
  readonly __typename?: 'DeliveryTaskDetails';
  readonly createdAt: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<Scalars['String']['output']>;
  readonly uuid: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryWaypoint {
  readonly __typename?: 'DeliveryWaypoint';
  readonly addressLine1: Scalars['String']['output'];
  readonly addressLine2: Maybe<Scalars['String']['output']>;
  readonly afterTime: Maybe<Scalars['String']['output']>;
  readonly arrivalTime: Maybe<Scalars['String']['output']>;
  readonly beforeTime: Maybe<Scalars['String']['output']>;
  readonly city: Scalars['String']['output'];
  readonly country: Maybe<Scalars['String']['output']>;
  readonly id: Maybe<Scalars['String']['output']>;
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly phoneNumber: Scalars['String']['output'];
  readonly shareUuid: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['String']['output']>;
  readonly trackingUrl: Maybe<Scalars['String']['output']>;
  readonly zip: Maybe<Scalars['String']['output']>;
}

export interface IDeliveryWaypointInput {
  readonly addressLine1: Scalars['String']['input'];
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly latitude?: InputMaybe<Scalars['Float']['input']>;
  readonly longitude?: InputMaybe<Scalars['Float']['input']>;
  readonly phoneNumber: Scalars['String']['input'];
  readonly route?: InputMaybe<Scalars['String']['input']>;
  /** flag to determine wether or not the waypoint should be saved */
  readonly shouldSave?: InputMaybe<Scalars['Boolean']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber?: InputMaybe<Scalars['String']['input']>;
  readonly zip?: InputMaybe<Scalars['String']['input']>;
}

export interface IDiscount {
  readonly __typename?: 'Discount';
  readonly name: Maybe<DiscountTypes>;
  /** values are represented as positive integers in cents */
  readonly value: Maybe<Scalars['Int']['output']>;
}

export enum DiscountTypes {
  ALL_DISCOUNTS = 'ALL_DISCOUNTS',
  COMBO_AND_OFFER_DISCOUNTS = 'COMBO_AND_OFFER_DISCOUNTS',
  REWARDS_DISCOUNTS = 'REWARDS_DISCOUNTS',
}

export interface IDonation {
  readonly __typename?: 'Donation';
  readonly id: Maybe<Scalars['String']['output']>;
  /** Name of donation */
  readonly name: Maybe<Scalars['String']['output']>;
  /** Total are represented as positive integers in cents */
  readonly totalCents: Maybe<Scalars['Int']['output']>;
}

export enum DurationPeriod {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

/**
 * https://relay.dev/graphql/connections.htm#sec-Edge-Types
 *
 * An edge provides an association between a node and it's associated cursor.
 */
export interface IEdge {
  readonly cursor: Scalars['String']['output'];
  readonly node: INode;
}

export interface IEntity {
  readonly __typename?: 'Entity';
  readonly calories: Maybe<ICalories>;
  readonly id: Scalars['String']['output'];
  readonly isAvailable: Maybe<Scalars['Boolean']['output']>;
  readonly options: Maybe<ReadonlyArray<IOption>>;
  readonly price: Maybe<IPrice>;
}

export interface IEvaluateIncentivesInput {
  readonly appliedIncentives: ReadonlyArray<IPromotionInput>;
  readonly cartEntries?: InputMaybe<ReadonlyArray<IRbiCartEntryInput>>;
  readonly paymentMethod?: InputMaybe<PaymentMethod>;
  readonly serviceMode?: InputMaybe<ServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * DEPRECATED
 * Incentive evaluation errors are now provided as part of the incentive data.
 */
export interface IEvaluatedIncentives {
  readonly __typename?: 'EvaluatedIncentives';
  readonly invalidOffers: ReadonlyArray<IInvalidIncentives>;
  readonly invalidRewards: ReadonlyArray<IInvalidIncentives>;
}

export interface IEvertecInitiatePayment {
  readonly callbackUrl: Scalars['String']['input'];
  readonly description: Scalars['String']['input'];
  readonly userLocale?: InputMaybe<Scalars['String']['input']>;
}

export interface IEvertecPayment {
  /** CallbackUrl for the payment */
  readonly callbackUrl?: InputMaybe<Scalars['String']['input']>;
  /** Order Description (the first item name of the cart) */
  readonly orderDescription?: InputMaybe<Scalars['String']['input']>;
  /** Payment Token */
  readonly paymentToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IExchangeOtpCodeForCredentialsInput {
  /** The code associated with the user's current OTP, for validation */
  readonly code: Scalars['String']['input'];
  /**
   * The email of the user requesting a One-Time Password. Optional, one of either
   * email or phoneNumber must always be provided
   * @todo - only email is implemented at this time
   */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /**
   * The phone number of the user logging in with a One-Time Password. Optional, one of either
   * email or phoneNumber must always be provided.
   * @todo - not implemented
   */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** The unique identifier provided when the OTP was created */
  readonly sessionId: Scalars['ID']['input'];
}

export interface IExpirationBucket {
  readonly __typename?: 'ExpirationBucket';
  readonly expirationDate: Scalars['String']['output'];
  readonly pointsToExpire: Scalars['Int']['output'];
}

export interface IExternalIdentifier {
  readonly __typename?: 'ExternalIdentifier';
  readonly type: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
}

export interface IExternalIdentifiers {
  readonly __typename?: 'ExternalIdentifiers';
  readonly source: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
}

export interface IFavorite {
  readonly __typename?: 'Favorite';
  readonly createdAt: Maybe<Scalars['String']['output']>;
  readonly entries: Maybe<ReadonlyArray<Maybe<ICartEntries>>>;
  readonly favoriteId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly ref: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Maybe<Scalars['String']['output']>;
}

export interface IFavoriteInput {
  readonly entries?: InputMaybe<ReadonlyArray<InputMaybe<ICartEntryInput>>>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly ref?: InputMaybe<Scalars['String']['input']>;
}

export interface IFavoriteList {
  readonly __typename?: 'FavoriteList';
  readonly count: Maybe<Scalars['Int']['output']>;
  readonly favorites: Maybe<ReadonlyArray<Maybe<IFavorite>>>;
  readonly resumeAfter: Maybe<Scalars['String']['output']>;
}

export interface IFavoriteStore {
  readonly __typename?: 'FavoriteStore';
  /** Favorite Store ID */
  readonly storeId: Maybe<Scalars['String']['output']>;
  /** Favorite Store Number */
  readonly storeNumber: Maybe<Scalars['String']['output']>;
}

export interface IFavoriteStoreInput {
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly storeNumber: Scalars['String']['input'];
}

export interface IFees {
  readonly __typename?: 'Fees';
  readonly name: Maybe<Scalars['String']['output']>;
  readonly priceCents: Maybe<Scalars['Int']['output']>;
  readonly quantity: Maybe<Scalars['Int']['output']>;
  readonly totalCents: Maybe<Scalars['Int']['output']>;
}

export enum FilterRestaurantType {
  FAVORITE = 'FAVORITE',
  NEARBY = 'NEARBY',
  RECENT = 'RECENT',
}

export interface IFirstDataPayment {
  /**
   * Represents a saved funding source. (i.e. fdAccountId or recurringDetailReference)
   * If passed this will be used to process payment. Either accountIdentifier or fdNonce
   * and fdAccessToken should be passed.
   */
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Valid access token for accessing First Data API */
  readonly fdAccessToken?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of customer received from First Data */
  readonly fdCustomerId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Nonce retrieved from First Data. Sale will be performed with the payment
   * method associated with the nonce. If accountIdentifier is not passed this must
   * be passed.
   */
  readonly fdNonce?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IFirstpayPayment {
  /** Merchant Account */
  readonly merchantAccount?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export enum FrontendIntegration {
  ADYEN = 'ADYEN',
  CHASE_ORBITAL = 'CHASE_ORBITAL',
  CHASE_TH_DIGITAL = 'CHASE_TH_DIGITAL',
  FIRSTDATA = 'FIRSTDATA',
  VRPAYMENT = 'VRPAYMENT',
  WORLDPAY = 'WORLDPAY',
}

export interface IFulfillmentDetails {
  readonly __typename?: 'FulfillmentDetails';
  readonly pickupPin: Maybe<Scalars['String']['output']>;
  readonly pickupReady: Maybe<Scalars['Boolean']['output']>;
  readonly pickupType: Maybe<FulfillmentPickupType>;
}

export enum FulfillmentPickupType {
  BEEPER = 'BEEPER',
  LOCKER = 'LOCKER',
}

export interface IFutureServiceHours {
  readonly __typename?: 'FutureServiceHours';
  readonly serviceMode: Maybe<ServiceMode>;
  readonly timeSlots: Maybe<ReadonlyArray<Maybe<IRestaurantTimeSlot>>>;
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export interface IGenerateGuestTokenInput {
  readonly country: Scalars['String']['input'];
  readonly platform: Platform;
  readonly stage: Stage;
}

export interface IGetFeesByTierInput {
  readonly brand?: InputMaybe<Brand>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly platform?: InputMaybe<Platform>;
  readonly serviceMode?: InputMaybe<ServiceMode>;
  readonly storeAddress?: InputMaybe<IStoreAddressInput>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly userId?: InputMaybe<Scalars['String']['input']>;
}

export interface IGetFeesByTierResponse {
  readonly __typename?: 'GetFeesByTierResponse';
  readonly deliveryFee: Maybe<Scalars['Int']['output']>;
  readonly maxTier: Maybe<Scalars['Int']['output']>;
  readonly minTier: Maybe<Scalars['Int']['output']>;
  readonly serviceFee: Maybe<Scalars['Int']['output']>;
  readonly totalDeliveryFee: Maybe<Scalars['Int']['output']>;
  readonly totalServiceFee: Maybe<Scalars['Int']['output']>;
}

export interface IGetPaymentIntentInput {
  readonly paymentIntentId: Scalars['ID']['input'];
}

/** First Data prepaid account */
export interface IGiftCard {
  readonly __typename?: 'GiftCard';
  /** last 4 digits of prepaid gift card number */
  readonly alias: Scalars['String']['output'];
  readonly cardNumber: Scalars['String']['output'];
  /** amount card was loaded with (cents) upon creation */
  readonly currentBalance: Maybe<Scalars['Int']['output']>;
  /** Two digit expiry month */
  readonly expiryMonth: Scalars['String']['output'];
  /** Two digit expiry year */
  readonly expiryYear: Scalars['String']['output'];
  readonly feFormattedCurrentBalance: Maybe<Scalars['Int']['output']>;
  /** Use to generate barcode */
  readonly shuffledCardNumber: Scalars['String']['output'];
}

/** Details from Google Pay used for Google Pay funding. */
export interface IGooglePay {
  /** Data of the payment method used for Google Pay. */
  readonly PaymentMethodData?: InputMaybe<IPaymentMethodData>;
  /** Billing address from Google Pay */
  readonly billingAddress: IBillingAddressInput;
  /** Country Code from user account */
  readonly country: CountryCode;
  /** Signature message passed through from Google Pay */
  readonly data: Scalars['String']['input'];
  /** Formatted billing address from Google Pay */
  readonly formatted: Scalars['String']['input'];
  /** Encrypted token including signature and signature message as passed through from Google Pay. */
  readonly paymentData?: InputMaybe<Scalars['String']['input']>;
  readonly primary: Scalars['Boolean']['input'];
  /** Signature as passed through from Google Pay */
  readonly signature: Scalars['String']['input'];
  readonly type: Scalars['String']['input'];
  readonly version: Scalars['String']['input'];
}

export interface IGooglePayPaymentConfig {
  readonly __typename?: 'GooglePayPaymentConfig';
  readonly prepaidMerchantId: Maybe<Scalars['String']['output']>;
  readonly schemeMerchantId: Maybe<Scalars['String']['output']>;
}

export interface IGuestCognitoCredentials {
  readonly __typename?: 'GuestCognitoCredentials';
  readonly challengeCode: Maybe<Scalars['String']['output']>;
  readonly email: Scalars['String']['output'];
  readonly sessionId: Maybe<Scalars['String']['output']>;
}

export interface IGuestDetails {
  readonly __typename?: 'GuestDetails';
  /** User's Country code in ISO 3166 alpha-3 format, e.g. GBR, DEU, CHE, NZL. */
  readonly isoCountryCode: Maybe<Scalars['String']['output']>;
}

export interface IInRestaurantIncentive {
  readonly __typename?: 'InRestaurantIncentive';
  /** Loyalty engine ID of the incentive */
  readonly id: Scalars['ID']['output'];
  /** Type of incentive */
  readonly type: InRestaurantIncentiveType;
}

export interface IInRestaurantIncentiveInput {
  /** The presentational description of the incentive item */
  readonly description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies incentive should apply a cart level discount */
  readonly discount?: InputMaybe<ICartDiscountInput>;
  /** Loyalty engine ID of the incentive */
  readonly id: Scalars['ID']['input'];
  /** List of incentive's vendor configs */
  readonly incentiveVendorConfigs?: InputMaybe<ReadonlyArray<IRbiVendorConfigsInput>>;
  /** The presentational name of the incentive item */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** The offer short code */
  readonly offerCode?: InputMaybe<Scalars['String']['input']>;
  /** Type of incentive */
  readonly type: InRestaurantIncentiveType;
}

export enum InRestaurantIncentiveType {
  OFFER = 'OFFER',
  REWARD = 'REWARD',
}

export interface IInRestaurantOrderLineItem {
  /**
   * ID of incentive (e.g. offer / reward / promotion)
   * Should not be sent if line item is not in the loyalty engine
   */
  readonly incentive?: InputMaybe<IInRestaurantIncentiveInput>;
  /** The offer 'short code' */
  readonly incentivePlu?: InputMaybe<Scalars['String']['input']>;
  /** CMS id */
  readonly productId?: InputMaybe<Scalars['String']['input']>;
  /** Quantity of items */
  readonly quantity: Scalars['Int']['input'];
  /** A unique id for internal reference purposes */
  readonly referenceId: Scalars['ID']['input'];
  /** Restaurant vendorConfigs */
  readonly vendorConfigs: IRbiVendorConfigsInput;
}

export interface IInRestaurantOrderLineItemType {
  readonly __typename?: 'InRestaurantOrderLineItemType';
  /**
   * ID of incentive (e.g. offer / reward / promotion)
   * Should not be sent if line item is not in the loyalty engine
   */
  readonly incentive: Maybe<IInRestaurantIncentive>;
  /** Quantity of items */
  readonly quantity: Scalars['Int']['output'];
  /** A unique id for internal reference purposes */
  readonly referenceId: Scalars['ID']['output'];
  /** Restaurant vendorConfigs */
  readonly vendorConfigs: IRbiVendorConfigs;
}

export interface IInitiatePaymentAttemptInput {
  readonly paymentIntentId: Scalars['ID']['input'];
  readonly paymentMethodType: PaymentMethodEnum;
  readonly pspSpecific: Scalars['String']['input'];
}

export interface IInitiatePaymentInput {
  readonly evertecData?: InputMaybe<IEvertecInitiatePayment>;
  readonly paymentMethodType: Scalars['String']['input'];
  readonly rbiOrderId: Scalars['ID']['input'];
}

export interface IInitiatePaymentResponse {
  readonly __typename?: 'InitiatePaymentResponse';
  readonly processUrl: Scalars['String']['output'];
}

/**
 * DEPRECATED
 * Incentive evaluation errors are now provided as part of the incentive data.
 */
export interface IInvalidIncentives {
  readonly __typename?: 'InvalidIncentives';
  readonly errors: ReadonlyArray<IRuleConditionEvaluation>;
  readonly id: Scalars['ID']['output'];
}

/** Supported (ISO 3166-1 alpha-2) Country Code associated to the RBI User */
export enum IsoCountryCode2Char {
  AE = 'AE',
  AQ = 'AQ',
  AT = 'AT',
  AU = 'AU',
  BH = 'BH',
  CA = 'CA',
  CH = 'CH',
  CZ = 'CZ',
  DE = 'DE',
  EG = 'EG',
  ES = 'ES',
  FR = 'FR',
  GB = 'GB',
  IE = 'IE',
  KR = 'KR',
  KW = 'KW',
  MA = 'MA',
  MY = 'MY',
  NL = 'NL',
  NZ = 'NZ',
  OM = 'OM',
  PH = 'PH',
  PL = 'PL',
  PT = 'PT',
  QA = 'QA',
  RO = 'RO',
  SA = 'SA',
  SG = 'SG',
  TW = 'TW',
  US = 'US',
  ZA = 'ZA',
}

export enum Language {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR',
}

export interface ILocation {
  readonly __typename?: 'Location';
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
}

export interface ILoyaltyAssociateGuestUserResponse {
  readonly __typename?: 'LoyaltyAssociateGuestUserResponse';
  /** Date and time when the the identifier got activated */
  readonly activationDateTime: Scalars['String']['output'];
  readonly guestBalance: Scalars['Int']['output'];
  /** Date and time when the linking between the identifier and the loyalty user happened */
  readonly linkingDateTime: Scalars['String']['output'];
  readonly totalBalance: Scalars['Int']['output'];
}

export interface ILoyaltyBaseIncentive {
  readonly cartRequirements: Maybe<
    ReadonlyArray<Maybe<ReadonlyArray<Maybe<ILoyaltyCartRequirement>>>>
  >;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<ILoyaltyRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<ILoyaltyExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['LoyaltyID']['output'];
  readonly maxRedemptionsDuration: Maybe<LoyaltyDurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<LoyaltyPaymentMethod>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<LoyaltyServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<ILoyaltyStoreRestrictions>;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<LoyaltyBirthdayDuration>;
  readonly userEnrollment: Maybe<ILoyaltyDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<LoyaltyDayOfTheWeek>>;
}

export interface ILoyaltyBenefit {
  readonly type: LoyaltyBenefitType;
}

export interface ILoyaltyBenefitReference extends ILoyaltyBenefit {
  readonly __typename?: 'LoyaltyBenefitReference';
  readonly type: LoyaltyBenefitType;
  readonly value: Scalars['String']['output'];
}

export interface ILoyaltyBenefitSwap extends ILoyaltyBenefit {
  readonly __typename?: 'LoyaltyBenefitSwap';
  readonly type: LoyaltyBenefitType;
  readonly value: ILoyaltySwap;
}

export enum LoyaltyBenefitType {
  REFERENCE = 'REFERENCE',
  SWAP = 'SWAP',
}

export enum LoyaltyBirthdayDuration {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export interface ILoyaltyCardTransaction {
  readonly __typename?: 'LoyaltyCardTransaction';
  /** The taxes of the basket. */
  readonly basketTaxes: Maybe<Scalars['Int']['output']>;
  /** The total of the basket including taxes. */
  readonly basketTotal: Maybe<Scalars['Int']['output']>;
  /** Bonus points earned */
  readonly bonusPoints: Maybe<Scalars['Int']['output']>;
  /** City of store where loyalty was redeemed */
  readonly city: Maybe<Scalars['String']['output']>;
  /** The total amount discounted */
  readonly discountAmount: Maybe<Scalars['Int']['output']>;
  readonly expiredPoints: Maybe<ILoyaltyExpiredPoints>;
  /** True if this visit was given from customer support. False otherwise. */
  readonly isCustomerServiceVisit: Maybe<Scalars['Boolean']['output']>;
  /** True if the transaction counted as a visit. */
  readonly isVisit: Maybe<Scalars['Boolean']['output']>;
  /** Offer that was associated with the transaction */
  readonly offerId: Maybe<Scalars['Int']['output']>;
  /** Loyalty points earned from transaction */
  readonly pointsEarned: Maybe<Scalars['Int']['output']>;
  /** Loyalty points used from transaction */
  readonly pointsUsed: Maybe<Scalars['Int']['output']>;
  /** True if this transaction earned a rewards */
  readonly rewardEarned: Maybe<Scalars['Boolean']['output']>;
  /** Reward redeemed upon transaction */
  readonly rewardRedeemed: Maybe<Scalars['Boolean']['output']>;
  /** State or Province of store where loyalty was redeemed */
  readonly stateOrProvince: Maybe<Scalars['String']['output']>;
  /** Store ID where loyalty was redeemed */
  readonly storeId: Maybe<Scalars['String']['output']>;
  /**
   * Tag string indicating the source of the rewards earned
   * e.g. FREQUENCY_CHALLENGE_COMPLETE, HOCKEY_1
   */
  readonly tag: Maybe<Scalars['String']['output']>;
  /** The time of the transaction in UTC. */
  readonly timestamp: Maybe<Scalars['String']['output']>;
  /** A unique identifier for transaction */
  readonly transactionId: Maybe<Scalars['String']['output']>;
  /**
   * A tag for the type of visit. This will be null for normal transactions.
   * When a visit or reward is issued through the IssueVisit/IssueReward endpoints
   * a VisitType may be specified that will surface in this field
   */
  readonly visitType: Maybe<Scalars['String']['output']>;
  /**
   * True if the discount was active or available for this transaction.
   * False if no discount was applied.
   */
  readonly wasDiscountActive: Maybe<Scalars['Boolean']['output']>;
}

export interface ILoyaltyCartDiscount {
  readonly __typename?: 'LoyaltyCartDiscount';
  readonly type: LoyaltyCartDiscountType;
  readonly value: Scalars['Float']['output'];
}

export interface ILoyaltyCartDiscountInput {
  readonly type: LoyaltyCartDiscountType;
  readonly value: Scalars['Float']['input'];
}

export enum LoyaltyCartDiscountType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export interface ILoyaltyCartRequirement {
  readonly __typename?: 'LoyaltyCartRequirement';
  readonly id: Scalars['String']['output'];
  readonly quantity: Scalars['Int']['output'];
}

export interface ILoyaltyCouponResponse extends ICouponResponse {
  readonly __typename?: 'LoyaltyCouponResponse';
  readonly code: Scalars['String']['output'];
  readonly configId: Maybe<Scalars['String']['output']>;
  readonly isRedeemed: Maybe<Scalars['Boolean']['output']>;
  readonly isValid: Scalars['Boolean']['output'];
  readonly message: Scalars['String']['output'];
  readonly personalizedOfferId: Maybe<Scalars['String']['output']>;
  readonly reason: Maybe<Scalars['String']['output']>;
  readonly shouldRedeem: Maybe<Scalars['Boolean']['output']>;
}

export interface ILoyaltyCreateOrderSelectionInput {
  /** Unique engine identifier for the user this order selection belongs to */
  readonly loyaltyId: Scalars['String']['input'];
  /** The incentives selected to be redeemed w/ this order selection */
  readonly selectedIncentives: ReadonlyArray<ILoyaltyOrderSelectionIncentiveInput>;
  /** The store or location that this order selection will be used at */
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  /** The classification of order selection - otp | lyid */
  readonly type: LoyaltyOrderSelectionType;
}

export interface ILoyaltyCreateOrderSelectionResponse {
  readonly __typename?: 'LoyaltyCreateOrderSelectionResponse';
  readonly key: Scalars['String']['output'];
  readonly orderSelection: ILoyaltyOrderSelection;
}

export interface ILoyaltyDateSpan {
  readonly __typename?: 'LoyaltyDateSpan';
  readonly endDate: Maybe<Scalars['String']['output']>;
  readonly startDate: Maybe<Scalars['String']['output']>;
}

export enum LoyaltyDayOfTheWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
}

export interface ILoyaltyDeleteOrderSelectionInput {
  /** The primary identifier for this order selection */
  readonly key: Scalars['String']['input'];
  /** Unique engine identifier for the user this order selection belongs to */
  readonly loyaltyId: Scalars['String']['input'];
  /** The classification of order selection - otp | lyid */
  readonly type: LoyaltyOrderSelectionType;
}

export enum LoyaltyDurationPeriod {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export interface ILoyaltyEvaluateIncentivesInput {
  readonly appliedIncentives: ReadonlyArray<ILoyaltyPromotionInput>;
  readonly cartEntries?: InputMaybe<ReadonlyArray<ILoyaltyRbiCartEntryInput>>;
  readonly paymentMethod?: InputMaybe<LoyaltyPaymentMethod>;
  readonly serviceMode?: InputMaybe<LoyaltyServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * DEPRECATED
 * Incentive evaluation errors are now provided as part of the incentive data.
 */
export interface ILoyaltyEvaluatedIncentives {
  readonly __typename?: 'LoyaltyEvaluatedIncentives';
  readonly invalidOffers: ReadonlyArray<ILoyaltyInvalidIncentives>;
  readonly invalidRewards: ReadonlyArray<ILoyaltyInvalidIncentives>;
}

export interface ILoyaltyExpirationBucket {
  readonly __typename?: 'LoyaltyExpirationBucket';
  readonly expirationDate: Scalars['String']['output'];
  readonly pointsToExpire: Scalars['Int']['output'];
}

export interface ILoyaltyExpiredPoints {
  readonly __typename?: 'LoyaltyExpiredPoints';
  /** Loyalty card barcode */
  readonly barcode: Maybe<Scalars['String']['output']>;
  readonly expirationDate: Maybe<Scalars['String']['output']>;
  readonly points: Maybe<Scalars['Int']['output']>;
  readonly ttl: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyExternalIdentifier {
  readonly __typename?: 'LoyaltyExternalIdentifier';
  readonly type: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
}

export interface ILoyaltyExternalIdentifiers {
  readonly __typename?: 'LoyaltyExternalIdentifiers';
  readonly source: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
}

export interface ILoyaltyInRestaurantIncentive {
  readonly __typename?: 'LoyaltyInRestaurantIncentive';
  /** Loyalty engine ID of the incentive */
  readonly id: Scalars['LoyaltyID']['output'];
  /** Type of incentive */
  readonly type: LoyaltyInRestaurantIncentiveType;
}

export interface ILoyaltyInRestaurantIncentiveInput {
  /** The presentational description of the incentive item */
  readonly description?: InputMaybe<Scalars['String']['input']>;
  /** Specifies incentive should apply a cart level discount */
  readonly discount?: InputMaybe<ILoyaltyCartDiscountInput>;
  /** Loyalty engine ID of the incentive */
  readonly id: Scalars['LoyaltyID']['input'];
  /** List of incentive's vendor configs */
  readonly incentiveVendorConfigs?: InputMaybe<ReadonlyArray<ILoyaltyRbiVendorConfigsInput>>;
  /** The presentational name of the incentive item */
  readonly name?: InputMaybe<Scalars['String']['input']>;
  /** The offer short code */
  readonly offerCode?: InputMaybe<Scalars['String']['input']>;
  /** Type of incentive */
  readonly type: LoyaltyInRestaurantIncentiveType;
}

export enum LoyaltyInRestaurantIncentiveType {
  OFFER = 'OFFER',
  REWARD = 'REWARD',
}

export interface ILoyaltyInRestaurantOrderLineItem {
  /**
   * ID of incentive (e.g. offer / reward / promotion)
   * Should not be sent if line item is not in the loyalty engine
   */
  readonly incentive?: InputMaybe<ILoyaltyInRestaurantIncentiveInput>;
  /** The offer 'short code' */
  readonly incentivePlu?: InputMaybe<Scalars['String']['input']>;
  /** CMS id */
  readonly productId?: InputMaybe<Scalars['String']['input']>;
  /** Quantity of items */
  readonly quantity: Scalars['Int']['input'];
  /** A unique id for internal reference purposes */
  readonly referenceId: Scalars['LoyaltyID']['input'];
  /** Restaurant vendorConfigs */
  readonly vendorConfigs: ILoyaltyRbiVendorConfigsInput;
}

export interface ILoyaltyInRestaurantOrderLineItemType {
  readonly __typename?: 'LoyaltyInRestaurantOrderLineItemType';
  /**
   * ID of incentive (e.g. offer / reward / promotion)
   * Should not be sent if line item is not in the loyalty engine
   */
  readonly incentive: Maybe<ILoyaltyInRestaurantIncentive>;
  /** Quantity of items */
  readonly quantity: Scalars['Int']['output'];
  /** A unique id for internal reference purposes */
  readonly referenceId: Scalars['LoyaltyID']['output'];
  /** Restaurant vendorConfigs */
  readonly vendorConfigs: ILoyaltyRbiVendorConfigs;
}

/**
 * DEPRECATED
 * Incentive evaluation errors are now provided as part of the incentive data.
 */
export interface ILoyaltyInvalidIncentives {
  readonly __typename?: 'LoyaltyInvalidIncentives';
  readonly errors: ReadonlyArray<ILoyaltyRuleConditionEvaluation>;
  readonly id: Scalars['LoyaltyID']['output'];
}

export enum LoyaltyLanguage {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR',
}

export interface ILoyaltyObjectType {
  readonly __typename?: 'LoyaltyObjectType';
  readonly key: Maybe<Scalars['String']['output']>;
  readonly value: Maybe<Scalars['LoyaltyJSON']['output']>;
}

export interface ILoyaltyOffer extends ILoyaltyBaseIncentive {
  readonly __typename?: 'LoyaltyOffer';
  readonly benefits: Maybe<ReadonlyArray<ILoyaltyBenefit>>;
  readonly cartRequirements: Maybe<
    ReadonlyArray<Maybe<ReadonlyArray<Maybe<ILoyaltyCartRequirement>>>>
  >;
  readonly configId: Maybe<Scalars['LoyaltyID']['output']>;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<ILoyaltyRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<ILoyaltyExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['LoyaltyID']['output'];
  /** Deprecated - Please configure offer stackability by redemption type in the loyalty configuration. */
  readonly isStackable: Maybe<Scalars['Boolean']['output']>;
  readonly loyaltyId: Maybe<Scalars['LoyaltyID']['output']>;
  readonly maxRedemptionsDuration: Maybe<LoyaltyDurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly metadata: Maybe<ReadonlyArray<Maybe<ILoyaltyObjectType>>>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly minimumTotalSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<LoyaltyPaymentMethod>;
  readonly questId: Maybe<Scalars['String']['output']>;
  readonly redemptionType: Maybe<LoyaltyOfferRedemptionType>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<LoyaltyServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<ILoyaltyStoreRestrictions>;
  readonly templateId: Maybe<Scalars['LoyaltyID']['output']>;
  readonly type: LoyaltyOfferType;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<LoyaltyBirthdayDuration>;
  readonly userEnrollment: Maybe<ILoyaltyDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<LoyaltyDayOfTheWeek>>;
}

export interface ILoyaltyOfferChallengeStatus {
  readonly __typename?: 'LoyaltyOfferChallengeStatus';
  readonly goalId: Scalars['String']['output'];
  readonly goals: Maybe<ILoyaltyOfferGoalType>;
  readonly reward: Maybe<ILoyaltyOfferRewardType>;
  readonly status: Scalars['String']['output'];
}

export interface ILoyaltyOfferGoalType {
  readonly __typename?: 'LoyaltyOfferGoalType';
  readonly category: Maybe<Scalars['String']['output']>;
  readonly daypart: Maybe<Scalars['String']['output']>;
  readonly foodType: Maybe<Scalars['String']['output']>;
  readonly minSpend: Maybe<Scalars['Int']['output']>;
}

export interface ILoyaltyOfferRedemptionAvailability {
  readonly __typename?: 'LoyaltyOfferRedemptionAvailability';
  readonly availableAfter: Maybe<Scalars['String']['output']>;
  readonly transactionId: Maybe<Scalars['String']['output']>;
}

export enum LoyaltyOfferRedemptionType {
  PROMO_CODE = 'PROMO_CODE',
  STANDARD = 'STANDARD',
  SURPRISE = 'SURPRISE',
  SWAP = 'SWAP',
}

export interface ILoyaltyOfferRewardType {
  readonly __typename?: 'LoyaltyOfferRewardType';
  readonly points: Maybe<Scalars['Int']['output']>;
}

export enum LoyaltyOfferType {
  GLOBAL = 'GLOBAL',
  PERSONALIZED = 'PERSONALIZED',
}

export interface ILoyaltyOffersInput {
  readonly appliedIncentives?: InputMaybe<ReadonlyArray<ILoyaltyPromotionInput>>;
  readonly appliedPromotions?: InputMaybe<ReadonlyArray<ILoyaltyPromotionInput>>;
  readonly cartEntries?: InputMaybe<ReadonlyArray<ILoyaltyRbiCartEntryInput>>;
  readonly configId?: InputMaybe<Scalars['LoyaltyID']['input']>;
  readonly global?: InputMaybe<Scalars['Boolean']['input']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['LoyaltyID']['input']>>;
  readonly ignoreRules?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  readonly paymentMethod?: InputMaybe<LoyaltyPaymentMethod>;
  readonly redemptionTypes?: InputMaybe<ReadonlyArray<LoyaltyOfferRedemptionType>>;
  readonly serviceMode?: InputMaybe<LoyaltyServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  readonly totalAmount?: InputMaybe<Scalars['Int']['input']>;
}

export interface ILoyaltyOrderSelection {
  readonly __typename?: 'LoyaltyOrderSelection';
  /** Creation time of order selection */
  readonly createdAt: Scalars['String']['output'];
  /** Expiry time in seconds for order selection from when the order selection was created */
  readonly expiry: Maybe<Scalars['Int']['output']>;
  /** Unique engine identifier for the user this order selection belongs to */
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  /** 6-digit code to identify this order selection */
  readonly otp: Maybe<Scalars['String']['output']>;
  /** The incentives selected to be redeemed w/ this order selection */
  readonly selectedIncentives: ReadonlyArray<ILoyaltyOrderSelectionIncentive>;
  /** The status of the order selection */
  readonly status: LoyaltyOrderSelectionStatus;
  /** The store or location that this order selection will be used at */
  readonly storeId: Maybe<Scalars['String']['output']>;
  /** The resulting transaction when the order selection is identified */
  readonly transactionId: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyOrderSelectionIncentive {
  readonly __typename?: 'LoyaltyOrderSelectionIncentive';
  /**
   * The output of the incentive
   *
   * I.E what the customer receives for redeeming the incentive
   */
  readonly benefits: ReadonlyArray<ILoyaltyOrderSelectionIncentiveBenefit>;
  /**
   * Specifies incentive should apply a cart level discount
   *
   * NOTE - While this is a "benefit" of the incentive, a discount has very specific fields that does not conform to the common benefit structure
   * nor will it produce an order-line as it applies at a cart level and not an individual line-item level
   */
  readonly cartDiscount: Maybe<ICartDiscount>;
  /** optional description for incentive */
  readonly description: Maybe<Scalars['String']['output']>;
  /** Unique internal engine id for incentive */
  readonly id: Scalars['String']['output'];
  /** Unique cart identifier for incentive */
  readonly lineId: Scalars['String']['output'];
  /** Display name for incentive */
  readonly name: Scalars['String']['output'];
  /** The Sanity ID of the incentive */
  readonly productId: Scalars['String']['output'];
  /** Count of many of the incentive is to be redeemed */
  readonly quantity: Scalars['Int']['output'];
  /** Incentive classification - Offer | Reward */
  readonly type: LoyaltyOrderSelectionIncentiveType;
}

export interface ILoyaltyOrderSelectionIncentiveBenefit {
  readonly __typename?: 'LoyaltyOrderSelectionIncentiveBenefit';
  /** optional description for incentive benefit */
  readonly description: Maybe<Scalars['String']['output']>;
  /** Display name of order selection benefit */
  readonly name: Scalars['String']['output'];
  /** The Sanity ID of the incentive's benefit */
  readonly productId: Scalars['String']['output'];
}

export interface ILoyaltyOrderSelectionIncentiveBenefitInput {
  /** optional description for incentive benefit */
  readonly description?: InputMaybe<Scalars['String']['input']>;
  /** Display name of order selection benefit */
  readonly name: Scalars['String']['input'];
  /** The Sanity ID of the incentive's benefit */
  readonly productId: Scalars['String']['input'];
  /** Sanity Vendor PLU configurations for the incentive's benefit */
  readonly vendorConfigs: ILoyaltyVendorConfigsInput;
}

export interface ILoyaltyOrderSelectionIncentiveInput {
  /**
   * The output of the incentive
   *
   * I.E what the customer receives for redeeming the incentive
   */
  readonly benefits: ReadonlyArray<ILoyaltyOrderSelectionIncentiveBenefitInput>;
  /**
   * Specifies incentive should apply a cart level discount
   *
   * NOTE - While this is a "benefit" of the incentive, a discount has very specific fields that does not conform to the common benefit structure
   * nor will it produce an order-line as it applies at a cart level and not an individual line-item level
   */
  readonly cartDiscount?: InputMaybe<ICartDiscountInput>;
  /** optional description for incentive */
  readonly description?: InputMaybe<Scalars['String']['input']>;
  /** Unique internal engine id for incentive */
  readonly id: Scalars['String']['input'];
  /** Unique cart identifier for incentive */
  readonly lineId: Scalars['String']['input'];
  /** Display name for incentive */
  readonly name: Scalars['String']['input'];
  /** The Sanity ID of the incentive */
  readonly productId: Scalars['String']['input'];
  /** Count of how many of the incentive is to be redeemed */
  readonly quantity: Scalars['Int']['input'];
  /**
   * 4 digit short code for the incentive to present to the cashier
   * FOR US this is typically the plu unless the plu is longer than 4 digits, in which case its the truncated plu
   */
  readonly shortCode?: InputMaybe<Scalars['String']['input']>;
  /** Incentive classification - Offer | Reward */
  readonly type: LoyaltyOrderSelectionIncentiveType;
  /** Sanity Vendor PLU configurations for the incentive */
  readonly vendorConfigs: IRbiVendorConfigsInput;
}

export enum LoyaltyOrderSelectionIncentiveType {
  OFFER = 'OFFER',
  REWARD = 'REWARD',
}

export enum LoyaltyOrderSelectionStatus {
  CLAIMED = 'CLAIMED',
  PENDING = 'PENDING',
}

export interface ILoyaltyOrderSelectionTransfer {
  readonly __typename?: 'LoyaltyOrderSelectionTransfer';
  readonly createdAt: Scalars['String']['output'];
  readonly expiry: Maybe<Scalars['Int']['output']>;
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly order: ReadonlyArray<ILoyaltyInRestaurantOrderLineItemType>;
  readonly otp: Maybe<Scalars['String']['output']>;
  readonly restaurantId: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<OrderSelectionStatus>;
  readonly storeHasLoyalty: Maybe<Scalars['Boolean']['output']>;
  readonly transactionId: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Maybe<Scalars['String']['output']>;
}

export enum LoyaltyOrderSelectionType {
  LOYALTY_ID = 'LOYALTY_ID',
  OTP = 'OTP',
}

export interface ILoyaltyOrderlessIncentiveInput {
  readonly id: Scalars['String']['input'];
  readonly metadata?: InputMaybe<Scalars['LoyaltyJSON']['input']>;
  readonly type: LoyaltyPromotionType;
}

export interface ILoyaltyOrderlessTransactionInput {
  /** Represents the source of the transaction i.e web | mobile | support | campaign | receipt etc. */
  readonly channel: Scalars['String']['input'];
  readonly cognitoId?: InputMaybe<Scalars['String']['input']>;
  readonly incentives: ReadonlyArray<ILoyaltyOrderlessIncentiveInput>;
  readonly loyaltyId?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
}

export interface ILoyaltyOstCreationPayload {
  readonly __typename?: 'LoyaltyOstCreationPayload';
  readonly expirationTime: Scalars['String']['output'];
  readonly key: Scalars['String']['output'];
}

export enum LoyaltyOstType {
  LOYALTY_ID = 'LOYALTY_ID',
  OTP = 'OTP',
}

export interface ILoyaltyOtp {
  readonly __typename?: 'LoyaltyOtp';
  readonly expirationTime: Scalars['String']['output'];
  readonly password: Scalars['String']['output'];
}

export interface ILoyaltyParentChildPluInput {
  readonly childPlu?: InputMaybe<Scalars['String']['input']>;
  readonly plu?: InputMaybe<Scalars['String']['input']>;
}

export interface ILoyaltyPaymentExpiration {
  readonly __typename?: 'LoyaltyPaymentExpiration';
  readonly month: Maybe<Scalars['Int']['output']>;
  readonly year: Maybe<Scalars['Int']['output']>;
}

export enum LoyaltyPaymentMethod {
  AMEX = 'AMEX',
  APPLE_PAY = 'APPLE_PAY',
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  DINERSCLUB = 'DINERSCLUB',
  DISCOVER = 'DISCOVER',
  GIFT_CARD = 'GIFT_CARD',
  GOOGLE_PAY = 'GOOGLE_PAY',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export interface ILoyaltyPerson {
  readonly __typename?: 'LoyaltyPerson';
  readonly email: Maybe<Scalars['String']['output']>;
  readonly externalIds: Maybe<ReadonlyArray<Maybe<ILoyaltyExternalIdentifier>>>;
  readonly phone: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyPickupContact {
  readonly __typename?: 'LoyaltyPickupContact';
  readonly hasArrived: Scalars['Boolean']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly vehicle: Maybe<ILoyaltyPickupContactVehicle>;
}

export interface ILoyaltyPickupContactVehicle {
  readonly __typename?: 'LoyaltyPickupContactVehicle';
  readonly color: Maybe<Scalars['String']['output']>;
  readonly licensePlate: Maybe<Scalars['String']['output']>;
  readonly make: Maybe<Scalars['String']['output']>;
  readonly model: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyPointExpiry {
  readonly __typename?: 'LoyaltyPointExpiry';
  readonly expirationDate: Scalars['String']['output'];
  readonly points: Scalars['Int']['output'];
}

export interface ILoyaltyProcessSmgCodeResponse {
  readonly __typename?: 'LoyaltyProcessSmgCodeResponse';
  readonly pointsEarned: Scalars['Int']['output'];
  readonly status: LoyaltySmgCodeStatus;
}

export interface ILoyaltyPromotion {
  readonly __typename?: 'LoyaltyPromotion';
  readonly id: Scalars['String']['output'];
  readonly lineId: Maybe<Scalars['String']['output']>;
  readonly type: LoyaltyPromotionType;
}

export interface ILoyaltyPromotionInput {
  readonly id: Scalars['String']['input'];
  readonly lineId?: InputMaybe<Scalars['String']['input']>;
  readonly swap?: InputMaybe<ILoyaltyPromotionSwapInput>;
  readonly type: LoyaltyPromotionType;
}

export interface ILoyaltyPromotionSwapInput {
  readonly from: Scalars['String']['input'];
  readonly lineId: Scalars['String']['input'];
  readonly to: Scalars['String']['input'];
  readonly type: LoyaltySwapType;
}

export enum LoyaltyPromotionType {
  OFFER = 'OFFER',
  REWARD = 'REWARD',
  SYSTEMWIDE_PROMOTION = 'SYSTEMWIDE_PROMOTION',
}

export interface ILoyaltyPromotionsInput {
  readonly omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  readonly serviceMode?: InputMaybe<LoyaltyServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
}

export interface ILoyaltyQuantityPluInput {
  readonly plu?: InputMaybe<Scalars['String']['input']>;
  readonly qualifier?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
}

export interface ILoyaltyQuest {
  readonly __typename?: 'LoyaltyQuest';
  readonly bonusPoints: Maybe<Scalars['Int']['output']>;
  readonly completions: Maybe<ReadonlyArray<Maybe<ILoyaltyQuestCompletion>>>;
  readonly configId: Maybe<Scalars['LoyaltyID']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<ILoyaltyExternalIdentifiers>>>;
  readonly id: Scalars['LoyaltyID']['output'];
  readonly incentiveId: Maybe<Scalars['LoyaltyID']['output']>;
  readonly incentiveRedeemed: Scalars['Boolean']['output'];
  readonly incentiveType: LoyaltyQuestIncentiveType;
  readonly lastCompletion: Maybe<ILoyaltyQuestCompletion>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['String']['output'];
  readonly status: LoyaltyQuestStatus;
  readonly steps: Maybe<ReadonlyArray<ILoyaltyQuestStep>>;
}

export interface ILoyaltyQuestCompletion {
  readonly __typename?: 'LoyaltyQuestCompletion';
  readonly benefitId: Scalars['String']['output'];
  readonly completedAt: Scalars['String']['output'];
  readonly redeemed: Scalars['Boolean']['output'];
}

export enum LoyaltyQuestIncentiveType {
  BONUS_POINTS = 'BONUS_POINTS',
  OFFER = 'OFFER',
  REWARD = 'REWARD',
}

export enum LoyaltyQuestStatus {
  ACTIVATED = 'ACTIVATED',
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
  NOT_ENROLLED = 'NOT_ENROLLED',
}

export interface ILoyaltyQuestStep {
  readonly __typename?: 'LoyaltyQuestStep';
  readonly cartRequirements: Maybe<
    ReadonlyArray<Maybe<ReadonlyArray<Maybe<ILoyaltyCartRequirement>>>>
  >;
  readonly completedAt: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly status: LoyaltyQuestStepStatus;
}

export enum LoyaltyQuestStepStatus {
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export interface ILoyaltyRbiCartEntryInput {
  readonly children?: InputMaybe<ReadonlyArray<ILoyaltyRbiCartEntryInput>>;
  readonly lineId?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['Int']['input']>;
  readonly quantity: Scalars['Int']['input'];
  readonly sanityId: Scalars['String']['input'];
}

export enum LoyaltyRbiChannel {
  APP = 'APP',
  ORDERLESS = 'ORDERLESS',
  RECEIPT = 'RECEIPT',
  REMEDIATION = 'REMEDIATION',
  RESTAURANT = 'RESTAURANT',
  WEB = 'WEB',
}

export interface ILoyaltyRbiParentChildPlu {
  readonly __typename?: 'LoyaltyRbiParentChildPlu';
  readonly childPlu: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyRbiParentChildPluInput {
  readonly childPlu?: InputMaybe<Scalars['String']['input']>;
  readonly plu?: InputMaybe<Scalars['String']['input']>;
}

export interface ILoyaltyRbiQuantityPlu {
  readonly __typename?: 'LoyaltyRbiQuantityPlu';
  readonly plu: Maybe<Scalars['String']['output']>;
  readonly qualifier: Maybe<Scalars['String']['output']>;
  readonly quantity: Maybe<Scalars['Int']['output']>;
}

export interface ILoyaltyRbiQuantityPluInput {
  readonly plu?: InputMaybe<Scalars['String']['input']>;
  readonly qualifier?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
}

export interface ILoyaltyRbiSizeBasedPlu {
  readonly __typename?: 'LoyaltyRbiSizeBasedPlu';
  readonly comboPlu: Maybe<Scalars['String']['output']>;
  readonly comboSize: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyRbiSizeBasedPluInput {
  readonly comboPlu?: InputMaybe<Scalars['String']['input']>;
  readonly comboSize?: InputMaybe<Scalars['String']['input']>;
}

export interface ILoyaltyRbiVendorConfig {
  readonly __typename?: 'LoyaltyRbiVendorConfig';
  readonly constantPlu: Maybe<Scalars['String']['output']>;
  readonly discountPlu: Maybe<Scalars['String']['output']>;
  readonly multiConstantPlus: Maybe<ReadonlyArray<Maybe<ILoyaltyRbiQuantityPlu>>>;
  readonly parentChildPlu: Maybe<ILoyaltyRbiParentChildPlu>;
  readonly parentSanityId: Maybe<Scalars['String']['output']>;
  readonly pluType: Maybe<Scalars['String']['output']>;
  readonly pullUpLevels: Maybe<Scalars['Int']['output']>;
  readonly quantityBasedPlu: Maybe<ReadonlyArray<Maybe<ILoyaltyRbiQuantityPlu>>>;
  readonly sizeBasedPlu: Maybe<ILoyaltyRbiSizeBasedPlu>;
}

export interface ILoyaltyRbiVendorConfigInput {
  readonly constantPlu?: InputMaybe<Scalars['String']['input']>;
  readonly discountPlu?: InputMaybe<Scalars['String']['input']>;
  readonly multiConstantPlus?: InputMaybe<ReadonlyArray<InputMaybe<ILoyaltyRbiQuantityPluInput>>>;
  readonly parentChildPlu?: InputMaybe<ILoyaltyRbiParentChildPluInput>;
  readonly parentSanityId?: InputMaybe<Scalars['String']['input']>;
  readonly pluType?: InputMaybe<Scalars['String']['input']>;
  readonly pullUpLevels?: InputMaybe<Scalars['Int']['input']>;
  readonly quantityBasedPlu?: InputMaybe<ReadonlyArray<InputMaybe<ILoyaltyRbiQuantityPluInput>>>;
  readonly sizeBasedPlu?: InputMaybe<ILoyaltyRbiSizeBasedPluInput>;
}

export interface ILoyaltyRbiVendorConfigs {
  readonly __typename?: 'LoyaltyRbiVendorConfigs';
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrols: Maybe<ILoyaltyRbiVendorConfig>;
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrolsDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  /** HDX is not currently supported */
  readonly hdx: Maybe<ILoyaltyRbiVendorConfig>;
  readonly ncr: Maybe<ILoyaltyRbiVendorConfig>;
  readonly ncrDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  /** Deprecated */
  readonly oheics: Maybe<ILoyaltyRbiVendorConfig>;
  /** Deprecated */
  readonly oheicsDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  readonly partner: Maybe<ILoyaltyRbiVendorConfig>;
  readonly partnerDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  readonly productNumber: Maybe<ILoyaltyRbiVendorConfig>;
  readonly productNumberDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  readonly qdi: Maybe<ILoyaltyRbiVendorConfig>;
  readonly qdiDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  /** QST is not currently supported */
  readonly qst: Maybe<ILoyaltyRbiVendorConfig>;
  readonly rpos: Maybe<ILoyaltyRbiVendorConfig>;
  readonly rposDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  readonly sicom: Maybe<ILoyaltyRbiVendorConfig>;
  readonly sicomDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  readonly tablet: Maybe<ILoyaltyRbiVendorConfig>;
  readonly tabletDelivery: Maybe<ILoyaltyRbiVendorConfig>;
  readonly toshibaLoyalty: Maybe<ILoyaltyRbiVendorConfig>;
}

export interface ILoyaltyRbiVendorConfigsInput {
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrols?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrolsDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  /** HDX is not currently supported */
  readonly hdx?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly ncr?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly ncrDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  /** Deprecated */
  readonly oheics?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  /** Deprecated */
  readonly oheicsDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly partner?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly partnerDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly productNumber?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly productNumberDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly qdi?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly qdiDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  /** QST is not currently supported */
  readonly qst?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly rpos?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly rposDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly sicom?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly sicomDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly tablet?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly tabletDelivery?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
  readonly toshibaLoyalty?: InputMaybe<ILoyaltyRbiVendorConfigInput>;
}

export interface ILoyaltyRedeemCouponResponse extends IRedeemedCouponResponse {
  readonly __typename?: 'LoyaltyRedeemCouponResponse';
  readonly code: Scalars['String']['output'];
  readonly configId: Maybe<Scalars['String']['output']>;
  readonly isRedeemed: Scalars['Boolean']['output'];
  readonly message: Maybe<Scalars['String']['output']>;
  readonly reason: Maybe<Scalars['String']['output']>;
}

export interface ILoyaltyRedeemPromoCodeInput {
  readonly code: Scalars['String']['input'];
  readonly loyaltyId: Scalars['String']['input'];
  readonly personalizedOfferId: Scalars['String']['input'];
}

export interface ILoyaltyReward extends ILoyaltyBaseIncentive {
  readonly __typename?: 'LoyaltyReward';
  readonly cartRequirements: Maybe<
    ReadonlyArray<Maybe<ReadonlyArray<Maybe<ILoyaltyCartRequirement>>>>
  >;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<ILoyaltyRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<ILoyaltyExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['LoyaltyID']['output'];
  readonly limitPerOrder: Maybe<Scalars['Int']['output']>;
  readonly locked: Maybe<Scalars['Boolean']['output']>;
  readonly maxRedemptionsDuration: Maybe<LoyaltyDurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly metadata: Maybe<ReadonlyArray<Maybe<ILoyaltyObjectType>>>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly minimumTotalSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<LoyaltyPaymentMethod>;
  readonly pointCost: Scalars['Int']['output'];
  readonly redemptionPercentage: Maybe<Scalars['Int']['output']>;
  readonly remainingPointsNeededForRedemption: Maybe<Scalars['Int']['output']>;
  readonly requiredLoyaltyTier: Maybe<LoyaltyTierKeyV2>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly rewardBenefitId: Scalars['String']['output'];
  readonly rewardPrice: Maybe<Scalars['Int']['output']>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<LoyaltyServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<ILoyaltyStoreRestrictions>;
  readonly type: LoyaltyRewardType;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<LoyaltyBirthdayDuration>;
  readonly userEnrollment: Maybe<ILoyaltyDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<LoyaltyDayOfTheWeek>>;
}

export enum LoyaltyRewardType {
  GLOBAL = 'GLOBAL',
  PERSONALIZED = 'PERSONALIZED',
}

export interface ILoyaltyRewardsInput {
  readonly appliedIncentives?: InputMaybe<ReadonlyArray<ILoyaltyPromotionInput>>;
  readonly cartEntries?: InputMaybe<ReadonlyArray<ILoyaltyRbiCartEntryInput>>;
  readonly configId?: InputMaybe<Scalars['LoyaltyID']['input']>;
  readonly ignorePointBalance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly ignoreRules?: InputMaybe<Scalars['Boolean']['input']>;
  readonly incentiveIds?: InputMaybe<ReadonlyArray<Scalars['LoyaltyID']['input']>>;
  readonly isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  readonly paymentMethod?: InputMaybe<LoyaltyPaymentMethod>;
  readonly rewardIds?: InputMaybe<ReadonlyArray<Scalars['LoyaltyID']['input']>>;
  readonly serviceMode?: InputMaybe<LoyaltyServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  readonly totalAmount?: InputMaybe<Scalars['Int']['input']>;
}

export interface ILoyaltyRuleConditionEvaluation {
  readonly __typename?: 'LoyaltyRuleConditionEvaluation';
  readonly code: Scalars['String']['output'];
  readonly currentValue: Maybe<Scalars['LoyaltyJSON']['output']>;
  readonly message: Maybe<Scalars['String']['output']>;
  readonly ruleId: Scalars['String']['output'];
  readonly targetValue: Maybe<Scalars['LoyaltyJSON']['output']>;
}

export interface ILoyaltySecondaryIdentifier {
  readonly __typename?: 'LoyaltySecondaryIdentifier';
  readonly createdAt: Maybe<Scalars['String']['output']>;
  readonly identifier: Scalars['String']['output'];
  readonly identifierType: LoyaltySecondaryIdentifierType;
  readonly loyaltyId: Maybe<Scalars['LoyaltyID']['output']>;
  readonly verified: Maybe<Scalars['Boolean']['output']>;
}

export enum LoyaltySecondaryIdentifierType {
  INVITATIONCODE = 'INVITATIONCODE',
  LOYALTY12DIGITSWIPECARD = 'LOYALTY12DIGITSWIPECARD',
  LOYALTYSWIPECARD = 'LOYALTYSWIPECARD',
  PANTOKEN = 'PANTOKEN',
  PHONENUMBER = 'PHONENUMBER',
}

export enum LoyaltyServiceMode {
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CATERING_PICKUP = 'CATERING_PICKUP',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TABLE_SERVICE = 'TABLE_SERVICE',
  TAKEOUT = 'TAKEOUT',
}

export interface ILoyaltySizeBasedPluInput {
  readonly comboPlu?: InputMaybe<Scalars['String']['input']>;
  readonly comboSize?: InputMaybe<Scalars['String']['input']>;
}

export enum LoyaltySmgCodeStatus {
  ACCEPTED = 'ACCEPTED',
  CODE_EXISTS = 'CODE_EXISTS',
  EXPIRED = 'EXPIRED',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_RESTAURANT = 'INVALID_RESTAURANT',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  NOT_FOUND = 'NOT_FOUND',
  RETRY_REQUESTED = 'RETRY_REQUESTED',
  THROTTLING_ERROR = 'THROTTLING_ERROR',
  TRANSACTION_CLAIMED = 'TRANSACTION_CLAIMED',
}

export interface ILoyaltyStoreRestrictions {
  readonly __typename?: 'LoyaltyStoreRestrictions';
  readonly ids: ReadonlyArray<Maybe<Scalars['String']['output']>>;
  readonly inclusive: Scalars['Boolean']['output'];
}

export interface ILoyaltySwap {
  readonly __typename?: 'LoyaltySwap';
  readonly from: Scalars['String']['output'];
  readonly to: Scalars['String']['output'];
  readonly type: LoyaltySwapType;
}

export enum LoyaltySwapType {
  UPSIZE = 'UPSIZE',
}

export interface ILoyaltySystemWidePromotion extends ILoyaltyBaseIncentive {
  readonly __typename?: 'LoyaltySystemWidePromotion';
  readonly bonusPoints: Maybe<Scalars['Int']['output']>;
  readonly cartRequirements: Maybe<
    ReadonlyArray<Maybe<ReadonlyArray<Maybe<ILoyaltyCartRequirement>>>>
  >;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<ILoyaltyRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<ILoyaltyExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['LoyaltyID']['output'];
  readonly maxRedemptionsDuration: Maybe<LoyaltyDurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly metadata: Maybe<Scalars['LoyaltyJSON']['output']>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<LoyaltyPaymentMethod>;
  readonly pointMultiplier: Maybe<Scalars['Int']['output']>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<LoyaltyServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<ILoyaltyStoreRestrictions>;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<LoyaltyBirthdayDuration>;
  readonly userEnrollment: Maybe<ILoyaltyDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<LoyaltyDayOfTheWeek>>;
}

export interface ILoyaltyTier {
  readonly __typename?: 'LoyaltyTier';
  readonly loyaltyTierExpiryDate: Scalars['String']['output'];
  readonly loyaltyTierKey: LoyaltyTierKey;
  readonly pointsEarnedInTimeConstraint: Maybe<Scalars['Int']['output']>;
}

export enum LoyaltyTierKey {
  TIER_1 = 'Tier_1',
  TIER_2 = 'Tier_2',
}

export enum LoyaltyTierKeyV2 {
  TIER_1 = 'Tier_1',
  TIER_2 = 'Tier_2',
}

export interface ILoyaltyTierV2 {
  readonly __typename?: 'LoyaltyTierV2';
  readonly loyaltyTierExpiryDate: Scalars['String']['output'];
  readonly loyaltyTierKey: LoyaltyTierKeyV2;
  readonly pointsEarnedInTimeConstraint: Maybe<Scalars['Int']['output']>;
}

export interface ILoyaltyTransaction {
  readonly __typename?: 'LoyaltyTransaction';
  /** Bonus points earned. */
  readonly bonusPointsEarned: Scalars['Int']['output'];
  readonly id: Scalars['String']['output'];
  /** Points balance after this transaction. */
  readonly pointsBalance: Scalars['Int']['output'];
  /** Points earned. */
  readonly pointsEarned: Scalars['Int']['output'];
  /** Points used. */
  readonly pointsUsed: Scalars['Int']['output'];
  /** Number of rewards used. */
  readonly rewardsUsed: Scalars['Int']['output'];
}

export interface ILoyaltyTransactionAdditionalReferenceId {
  readonly __typename?: 'LoyaltyTransactionAdditionalReferenceId';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
}

export interface ILoyaltyTransactionDetails {
  readonly __typename?: 'LoyaltyTransactionDetails';
  readonly currency: Scalars['String']['output'];
  readonly fulfillment: ILoyaltyTransactionFulfillment;
  readonly language: Maybe<LoyaltyLanguage>;
  readonly orders: ReadonlyArray<ILoyaltyTransactionOrder>;
  readonly payments: ReadonlyArray<ILoyaltyTransactionPayment>;
  readonly person: Maybe<ILoyaltyPerson>;
  readonly pickupContact: Maybe<ILoyaltyPickupContact>;
  readonly posVendor: Maybe<ILoyaltyTransactionPosVendor>;
  readonly promotions: Maybe<ReadonlyArray<ILoyaltyPromotion>>;
}

export interface ILoyaltyTransactionFulfillment {
  readonly __typename?: 'LoyaltyTransactionFulfillment';
  readonly autoRelease: Maybe<Scalars['Boolean']['output']>;
  readonly type: Scalars['String']['output'];
}

export interface ILoyaltyTransactionOrder {
  readonly __typename?: 'LoyaltyTransactionOrder';
  readonly parentReferenceId: Maybe<Scalars['String']['output']>;
  readonly price: Scalars['Float']['output'];
  readonly productId: Maybe<Scalars['String']['output']>;
  readonly quantity: Scalars['Int']['output'];
}

export interface ILoyaltyTransactionPayment {
  readonly __typename?: 'LoyaltyTransactionPayment';
  readonly amount: Scalars['Float']['output'];
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expiration: Maybe<ILoyaltyPaymentExpiration>;
  readonly token: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
}

export interface ILoyaltyTransactionPosVendor {
  readonly __typename?: 'LoyaltyTransactionPosVendor';
  readonly id: Maybe<Scalars['String']['output']>;
  readonly transactionId: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
}

export enum LoyaltyTransactionStatus {
  CLAIMED = 'CLAIMED',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  UNCLAIMED = 'UNCLAIMED',
  VOID = 'VOID',
}

export interface ILoyaltyTransactionV2 {
  readonly __typename?: 'LoyaltyTransactionV2';
  readonly additionalReferenceIds: Maybe<ReadonlyArray<ILoyaltyTransactionAdditionalReferenceId>>;
  readonly bonusPointsEarned: Scalars['Int']['output'];
  readonly channel: Scalars['String']['output'];
  readonly createdAt: Scalars['String']['output'];
  readonly dateUpdated: Maybe<Scalars['String']['output']>;
  readonly hasRewards: Scalars['Boolean']['output'];
  readonly id: Scalars['LoyaltyID']['output'];
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  readonly maxTransactionLimitReached: Scalars['Boolean']['output'];
  readonly pointsDeducted: Maybe<Scalars['Int']['output']>;
  readonly pointsEarned: Scalars['Int']['output'];
  readonly pointsUsed: Scalars['Int']['output'];
  readonly shortId: Scalars['String']['output'];
  readonly status: LoyaltyTransactionStatus;
  readonly transactionDetails: Maybe<ILoyaltyTransactionDetails>;
}

export interface ILoyaltyTransactionsInput {
  readonly excludedChannels?: InputMaybe<ReadonlyArray<LoyaltyRbiChannel>>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['LoyaltyID']['input']>>;
  readonly statuses?: InputMaybe<ReadonlyArray<LoyaltyTransactionStatus>>;
}

export interface ILoyaltyUser {
  readonly __typename?: 'LoyaltyUser';
  readonly bestPromotion: Maybe<ILoyaltySystemWidePromotion>;
  readonly createdAt: Scalars['String']['output'];
  readonly dateOfBirth: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  /**
   * DEPRECATED
   * Incentive evaluation errors are now provided as part of the incentive data.
   */
  readonly evaluatedIncentives: ILoyaltyEvaluatedIncentives;
  readonly expirationBuckets: Maybe<ReadonlyArray<ILoyaltyExpirationBucket>>;
  readonly id: Scalars['LoyaltyID']['output'];
  readonly loyaltyTier: Maybe<ILoyaltyTierV2>;
  readonly metadata: Maybe<ReadonlyArray<Maybe<ILoyaltyObjectType>>>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly offerRedemptionAvailability: Maybe<ILoyaltyOfferRedemptionAvailability>;
  /**
   * DEPRECATED
   * This field is replaced by field offerRedemptionAvailability
   */
  readonly offerRedemptionAvailableAfter: Maybe<Scalars['String']['output']>;
  readonly offers: Maybe<ReadonlyArray<ILoyaltyOffer>>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly points: Scalars['Int']['output'];
  readonly pointsExpiryDateKey: Maybe<Scalars['String']['output']>;
  readonly promotions: Maybe<ReadonlyArray<ILoyaltySystemWidePromotion>>;
  readonly quests: Maybe<ReadonlyArray<ILoyaltyQuest>>;
  readonly rewards: Maybe<ReadonlyArray<ILoyaltyReward>>;
  readonly secondaryIdentifiers: Maybe<ReadonlyArray<Maybe<ILoyaltySecondaryIdentifier>>>;
  readonly transactions: Maybe<ReadonlyArray<ILoyaltyTransactionV2>>;
}

export interface ILoyaltyUserBestPromotionArgs {
  where?: InputMaybe<ILoyaltyPromotionsInput>;
}

export interface ILoyaltyUserEvaluatedIncentivesArgs {
  where: ILoyaltyEvaluateIncentivesInput;
}

export interface ILoyaltyUserOffersArgs {
  where?: InputMaybe<ILoyaltyOffersInput>;
}

export interface ILoyaltyUserPromotionsArgs {
  where?: InputMaybe<ILoyaltyPromotionsInput>;
}

export interface ILoyaltyUserRewardsArgs {
  where?: InputMaybe<ILoyaltyRewardsInput>;
}

export interface ILoyaltyUserTransactionsArgs {
  where?: InputMaybe<ILoyaltyTransactionsInput>;
}

export interface ILoyaltyValidatePromoCodeInput {
  readonly code: Scalars['String']['input'];
  readonly loyaltyId: Scalars['String']['input'];
  readonly shouldRedeem?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface ILoyaltyVendorConfigInput {
  readonly constantPlu?: InputMaybe<Scalars['String']['input']>;
  readonly discountPlu?: InputMaybe<Scalars['String']['input']>;
  readonly multiConstantPlus?: InputMaybe<ReadonlyArray<InputMaybe<ILoyaltyQuantityPluInput>>>;
  readonly parentChildPlu?: InputMaybe<ILoyaltyParentChildPluInput>;
  readonly parentSanityId?: InputMaybe<Scalars['String']['input']>;
  readonly pluType?: InputMaybe<Scalars['String']['input']>;
  readonly pullUpLevels?: InputMaybe<Scalars['Int']['input']>;
  readonly quantityBasedPlu?: InputMaybe<ReadonlyArray<InputMaybe<ILoyaltyQuantityPluInput>>>;
  readonly sizeBasedPlu?: InputMaybe<ILoyaltySizeBasedPluInput>;
}

export interface ILoyaltyVendorConfigsInput {
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrols?: InputMaybe<ILoyaltyVendorConfigInput>;
  readonly ncr?: InputMaybe<ILoyaltyVendorConfigInput>;
  readonly partner?: InputMaybe<ILoyaltyVendorConfigInput>;
  readonly qdi?: InputMaybe<ILoyaltyVendorConfigInput>;
  readonly rpos?: InputMaybe<ILoyaltyVendorConfigInput>;
  readonly sicom?: InputMaybe<ILoyaltyVendorConfigInput>;
  readonly tablet?: InputMaybe<ILoyaltyVendorConfigInput>;
}

export interface IMakePaymentAttemptInput {
  readonly paymentAttemptId: Scalars['ID']['input'];
  readonly paymentIntentId: Scalars['ID']['input'];
  readonly pspSpecific: Scalars['String']['input'];
}

export interface IMarket {
  readonly __typename?: 'Market';
  readonly brand: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly marketId: Scalars['String']['output'];
  readonly store: Scalars['String']['output'];
}

/**
 * MenuEntityWithHours represents the store menu items with availability hours from the Connector menu.
 * Availability is propagated from items to top level structures (pickers, sections, etc) algorithmically.
 */
export interface IMenuEntityWithHours {
  readonly __typename?: 'MenuEntityWithHours';
  readonly calories: Maybe<ICalories>;
  readonly hours: ReadonlyArray<IMenuItemHours>;
  readonly id: Scalars['String']['output'];
  readonly isAvailable: Maybe<Scalars['Boolean']['output']>;
  readonly options: Maybe<ReadonlyArray<IOption>>;
  readonly price: Maybe<IPrice>;
}

/** DEPRECATED */
export interface IMenuItem {
  readonly __typename?: 'MenuItem';
  readonly plu: Scalars['String']['output'];
  readonly price: Scalars['String']['output'];
}

/** MenuItemHours represents the consolidated times windows during which the item is available for orders, expressed in restaurant local time. */
export interface IMenuItemHours {
  readonly __typename?: 'MenuItemHours';
  /** End time of the available hours in ISO8601 time format - Thh:mm. */
  readonly end: Scalars['String']['output'];
  /** Start time of the available hours in ISO8601 time format - Thh:mm. */
  readonly start: Scalars['String']['output'];
}

export interface IMercadoPagoPayment {
  /** CallbackUrl for the payment */
  readonly callbackUrl?: InputMaybe<Scalars['String']['input']>;
  /** Order Description (the first item name of the cart) */
  readonly orderDescription?: InputMaybe<Scalars['String']['input']>;
  /** Payment Token */
  readonly paymentToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

/** Merge two prepaid gift cards into one. Source gets deleted. */
export interface IMergePrepaidInput {
  /** Number of physical Gift Card which is the funding source (card being deactivated) */
  readonly sourceCardNumber: Scalars['String']['input'];
  /** Pin number for physical gift card which is the funding source (card being deactivated) */
  readonly sourcePin?: InputMaybe<Scalars['String']['input']>;
}

export interface IMergePrepaidPayload {
  readonly __typename?: 'MergePrepaidPayload';
  readonly prepaidDetails: IPrepaidDetails;
  readonly storedPaymentMethodId: Scalars['String']['output'];
}

export type IMetadataOutput = IBlueLightCardMetadataOutput;

export interface IMissingItem {
  /** Line Id of item */
  readonly lineId: Scalars['String']['input'];
  /** Quantity of items requested for refund */
  readonly refundQuantity: Scalars['Int']['input'];
  /** Total quantity of items in a Combo or OfferCombo */
  readonly totalQuantity?: InputMaybe<Scalars['Int']['input']>;
}

export interface IMissingOrderRefundDetails {
  readonly __typename?: 'MissingOrderRefundDetails';
  /** Amount refunded in cents */
  readonly approvedAmount: Scalars['Int']['output'];
  /** Date time when refund was issued */
  readonly transactionDateTime: Scalars['String']['output'];
}

export interface IMissingOrderResolution {
  readonly __typename?: 'MissingOrderResolution';
  /** Refund details */
  readonly refund: Maybe<IMissingOrderRefundDetails>;
  /** Knowledge Force or Zendesk support ticket */
  readonly ticket: Maybe<Scalars['String']['output']>;
}

export interface IModifier {
  readonly __typename?: 'Modifier';
  readonly id: Scalars['String']['output'];
  readonly isAvailable: Maybe<Scalars['Boolean']['output']>;
  readonly price: Maybe<Scalars['Int']['output']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutation {
  readonly __typename?: 'Mutation';
  readonly _empty: Maybe<Scalars['String']['output']>;
  readonly _loyaltyEmpty: Maybe<Scalars['String']['output']>;
  readonly activateQuest: Maybe<IQuest>;
  /** Add payment method to user */
  readonly addPaymentMethod: IAddPaymentMethodPayload;
  /** Associate guestId with loyaltyId. */
  readonly associateGuestUser: Maybe<IAssociateGuestUserResponse>;
  /** Associates a secondary id to a loyalty user without needing a guest user */
  readonly associateSecondaryId: Maybe<ISecondaryIdentifier>;
  /**
   * Cancel an order using the store's PoS.
   *
   * This endpoint _must_ be called after committing the order.
   */
  readonly cancelOrder: IOrder;
  /**
   * Collect payments and commit the order to the PoS.
   *
   * If the service mode in the previously priced order was DRIVE_THRU or DELIVERY,
   * then the PoS will immediately start cooking the order. Else the PoS
   * will not cook the order until the customer arrives.
   *
   * (Please note that despite the name this is not a "transactional" flow)
   */
  readonly commitOrder: IOrder;
  /** Initiate a payment */
  readonly createEvertecPaymentSession: IInitiatePaymentResponse;
  /** Create a favorite */
  readonly createFavorite: IFavorite;
  /** Request a one-time password for the a guest */
  readonly createGuestOTP: ICreateOtp;
  /** Request a jwt to auth for a given vendor */
  readonly createLoginForVendor: Maybe<Scalars['String']['output']>;
  /** Request a one-time password for the given user */
  readonly createLoginOTP: ICreateOtp;
  /**
   * Update a user default payment method
   * @deprecated Use createLoginOTP
   */
  readonly createOTP: ICreateOtp;
  /** Creates an order selection for this loyaltyId */
  readonly createOrderSelectionByType: IOstCreationPayload;
  /**
   * Allows the creation of a transaction not tied to an order, which is useful to redeem static code incentives while enforcing redemption rules.
   * Returns null if the transaction is successfully created.
   */
  readonly createOrderlessTransaction: ITransaction;
  readonly createPaymentIntent: Maybe<IPaymentIntent>;
  /** Create a support case */
  readonly createSupportCase: Maybe<ISupportCase>;
  /** Delete a favorite */
  readonly deleteFavorite: IFavorite;
  /** Delete current user */
  readonly deleteMe: Maybe<Scalars['Boolean']['output']>;
  /**
   * Deletes the order selection linked to the OTP code
   * If it is an OTP code, attempts to make the code available again.
   * Doesn't return a payload.
   */
  readonly deleteOrderSelection: Maybe<Scalars['Boolean']['output']>;
  /**
   * Deletes the order selection linked to a key based on  the ostType
   * Doesn't return a payload.
   */
  readonly deleteOrderSelectionByType: Maybe<Scalars['Boolean']['output']>;
  /** Delete a customer's account */
  readonly deletePaymentMethod: IDeletePaymentMethodPayload;
  /**
   * Validates a previously-created OTP code and then exchanges the
   * valid code for Cognito credentials
   */
  readonly exchangeOTPCodeForCognitoCredentials: ICognitoCredentials;
  /** Exit Virtual Queue and Cancel Order */
  readonly exitQueue: IUpdateWaitTimeResult;
  /** Generates an Guest Order JWT token and returns it */
  readonly generateGuestToken: Maybe<Scalars['String']['output']>;
  /** generates OTP code associated with incentive items for in-store redemption */
  readonly generateOtp: IOtp;
  readonly initiatePaymentAttempt: Maybe<IPaymentAttempt>;
  /** Join Virtual Queue */
  readonly joinQueue: IUpdateWaitTimeResult;
  readonly linkBlueLightCardMembership: IPartnerMembershipOutput;
  readonly loyaltyActivateQuest: Maybe<ILoyaltyQuest>;
  /** Associate guestId with loyaltyId. */
  readonly loyaltyAssociateGuestUser: Maybe<ILoyaltyAssociateGuestUserResponse>;
  /** Associates a secondary id to a loyalty user without needing a guest user */
  readonly loyaltyAssociateSecondaryId: Maybe<ILoyaltySecondaryIdentifier>;
  readonly loyaltyCreateOrderSelection: ILoyaltyCreateOrderSelectionResponse;
  /** Creates an order selection for this loyaltyId */
  readonly loyaltyCreateOrderSelectionByType: ILoyaltyOstCreationPayload;
  /**
   * Allows the creation of a transaction not tied to an order, which is useful to redeem static code incentives while enforcing redemption rules.
   * Returns null if the transaction is successfully created.
   */
  readonly loyaltyCreateOrderlessTransaction: ILoyaltyTransactionV2;
  readonly loyaltyDeleteOrderSelection: Scalars['Boolean']['output'];
  /**
   * Deletes the order selection linked to a key based on  the ostType
   * Doesn't return a payload.
   */
  readonly loyaltyDeleteOrderSelectionByType: Maybe<Scalars['Boolean']['output']>;
  /** generates OTP code associated with incentive items for in-store redemption */
  readonly loyaltyGenerateOtp: ILoyaltyOtp;
  /**
   * processes a transaction's smg code with the following possible outcomes
   *   - accept -> transaction is claimed and points are granted
   *   - reject -> error is thrown with rejection status
   *   - retry_request -> a request is added to the retry-table and points may/may not be granted depending on result of retry attempts
   *   - not_found -> temporary response while process-retry work is in progress
   */
  readonly loyaltyProcessSmgCode: ILoyaltyProcessSmgCodeResponse;
  /** Redeem promo code for Loyalty */
  readonly loyaltyRedeemPromoCode: Maybe<ILoyaltyRedeemCouponResponse>;
  readonly loyaltyRemoveSecondaryIdAssociation: Maybe<Scalars['Boolean']['output']>;
  /** Validate promo code for Loyalty */
  readonly loyaltyValidatePromoCode: Maybe<ILoyaltyCouponResponse>;
  readonly makePaymentAttempt: Maybe<IPaymentAttempt>;
  /** Merge customer's prepaid accounts */
  readonly mergePrepaid: IMergePrepaidPayload;
  /**
   * Price an order using the store's PoS.
   *
   * This endpoint _must_ be called previous to finalizing the order.
   */
  readonly priceOrder: IOrder;
  /**
   * processes a transaction's smg code with the following possible outcomes
   *   - accept -> transaction is claimed and points are granted
   *   - reject -> error is thrown with rejection status
   *   - retry_request -> a request is added to the retry-table and points may/may not be granted depending on result of retry attempts
   *   - not_found -> temporary response while process-retry work is in progress
   */
  readonly processSmgCode: IProcessSmgCodeResponse;
  /** Purchase a new prepaid card */
  readonly purchasePrepaid: IPurchasePrepaidPayload;
  /** Redeem promo code for legacy CBA */
  readonly redeemPromoCode: Maybe<ICbaRedeemCouponResponse>;
  /** Reload an existing prepaid card */
  readonly reloadPrepaid: IReloadPrepaidPayload;
  /** Remove a delivery address */
  readonly removeDeliveryAddress: IDeliveryAddressResponse;
  readonly removeSecondaryIdAssociation: Maybe<Scalars['Boolean']['output']>;
  /** Kicks off email with all data we have on the user */
  readonly requestMyInfo: Maybe<Scalars['Boolean']['output']>;
  /** Resends the current OTP code for the guest user via email */
  readonly resendCurrentGuestOTP: ICreateOtp;
  /** Re-sends the current OTP code for the given user email/phone number */
  readonly resendCurrentLoginOTP: ICreateOtp;
  /** Re-sends the verification email to the current user */
  readonly resendVerificationEmail: Maybe<Scalars['Boolean']['output']>;
  /** Resume add payment method */
  readonly resumeAddPaymentMethod: IAddPaymentMethodPayload;
  /** Save a delivery address */
  readonly saveDeliveryAddress: IDeliveryAddressResponse;
  /** Re-sends the verification email to the current user */
  readonly sendPhoneVerification: Maybe<Scalars['Boolean']['output']>;
  /** Sends the user attributes to the CDP service */
  readonly sendUpdateUserAttributesEvent: Maybe<Scalars['Boolean']['output']>;
  /** Kicks off part one of Login Flow */
  readonly signInJWT: Maybe<Scalars['Boolean']['output']>;
  /** Sign up user with cognito returns a JWT */
  readonly signUp: Maybe<Scalars['String']['output']>;
  /** Sign up user that already validated the OTP */
  readonly signUpVerifiedGuest: IGuestCognitoCredentials;
  /** Social login with provider token */
  readonly socialLogin: Maybe<ICognitoCredentials>;
  /** Update a user default payment method */
  readonly updateDefaultPaymentMethod: IUpdateDefaultPaymentMethodPayload;
  /** Update a delivery address */
  readonly updateDeliveryAddress: IDeliveryAddressResponse;
  /** Update a favorite */
  readonly updateFavorite: IFavorite;
  /**
   * Update an order using the store's PoS.
   *
   * This endpoint _must_ be called after committing the order.
   */
  readonly updateOrder: IOrder;
  /** Validate the RBI generated JWT and exchange for cognito credentials */
  readonly validateAuthJwt: ICognitoCredentials;
  /**
   * Validates a previously-created OTP code and then exchanges the
   * valid code for Cognito credentials if the user is signed up
   */
  readonly validateGuestOTPCode: IGuestCognitoCredentials;
  /** Validate promo code for legacy CBA */
  readonly validatePromoCode: Maybe<ICbaCouponResponse>;
  /** Verifies the phone number for the given user */
  readonly verifyPhoneNumber: Maybe<Scalars['Boolean']['output']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationActivateQuestArgs {
  loyaltyId: Scalars['ID']['input'];
  questId: Scalars['ID']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationAddPaymentMethodArgs {
  input: IAddPaymentMethodInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationAssociateGuestUserArgs {
  identifier: Scalars['String']['input'];
  loyaltyId: Scalars['ID']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationAssociateSecondaryIdArgs {
  identifier: Scalars['String']['input'];
  loyaltyId: Scalars['ID']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCancelOrderArgs {
  input: ICancelOrderInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCommitOrderArgs {
  delivery?: InputMaybe<ICommitDeliveryInput>;
  input: ICommitOrderInput;
  skipCoolingPeriod?: InputMaybe<Scalars['Boolean']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateEvertecPaymentSessionArgs {
  input: IInitiatePaymentInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateFavoriteArgs {
  input: IFavoriteInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateGuestOtpArgs {
  input: ICreateGuestOtpInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateLoginForVendorArgs {
  vendor: Scalars['String']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateLoginOtpArgs {
  input: ICreateOtpInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateOtpArgs {
  input: ICreateOtpInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateOrderSelectionByTypeArgs {
  inRestaurantOrder: ReadonlyArray<IInRestaurantOrderLineItem>;
  loyaltyId: Scalars['ID']['input'];
  ostType: OstType;
  restaurantId?: InputMaybe<Scalars['ID']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateOrderlessTransactionArgs {
  input: IOrderlessTransactionInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreatePaymentIntentArgs {
  input: ICreatePaymentIntentInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationCreateSupportCaseArgs {
  caseInfo: ISupportCaseInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationDeleteFavoriteArgs {
  favoriteId: Scalars['String']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationDeleteOrderSelectionArgs {
  loyaltyId?: InputMaybe<Scalars['ID']['input']>;
  otp: Scalars['String']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationDeleteOrderSelectionByTypeArgs {
  key: Scalars['String']['input'];
  loyaltyId?: InputMaybe<Scalars['ID']['input']>;
  ostType: OstType;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationDeletePaymentMethodArgs {
  input: IDeletePaymentMethodInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationExchangeOtpCodeForCognitoCredentialsArgs {
  input: IExchangeOtpCodeForCredentialsInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationExitQueueArgs {
  orderId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationGenerateGuestTokenArgs {
  guestInfo: IGenerateGuestTokenInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationGenerateOtpArgs {
  inRestaurantOrder: ReadonlyArray<IInRestaurantOrderLineItem>;
  loyaltyId: Scalars['ID']['input'];
  restaurantId?: InputMaybe<Scalars['ID']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationInitiatePaymentAttemptArgs {
  input: IInitiatePaymentAttemptInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationJoinQueueArgs {
  orderId: Scalars['String']['input'];
  storeId: Scalars['String']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLinkBlueLightCardMembershipArgs {
  input: IBlueLightCardInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyActivateQuestArgs {
  loyaltyId: Scalars['LoyaltyID']['input'];
  questId: Scalars['LoyaltyID']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyAssociateGuestUserArgs {
  identifier: Scalars['String']['input'];
  loyaltyId: Scalars['LoyaltyID']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyAssociateSecondaryIdArgs {
  identifier: Scalars['String']['input'];
  loyaltyId: Scalars['LoyaltyID']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyCreateOrderSelectionArgs {
  input: ILoyaltyCreateOrderSelectionInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyCreateOrderSelectionByTypeArgs {
  inRestaurantOrder: ReadonlyArray<ILoyaltyInRestaurantOrderLineItem>;
  loyaltyId: Scalars['LoyaltyID']['input'];
  ostType: LoyaltyOstType;
  restaurantId?: InputMaybe<Scalars['LoyaltyID']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyCreateOrderlessTransactionArgs {
  input: ILoyaltyOrderlessTransactionInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyDeleteOrderSelectionArgs {
  input: ILoyaltyDeleteOrderSelectionInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyDeleteOrderSelectionByTypeArgs {
  key: Scalars['String']['input'];
  loyaltyId?: InputMaybe<Scalars['LoyaltyID']['input']>;
  ostType: LoyaltyOstType;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyGenerateOtpArgs {
  inRestaurantOrder: ReadonlyArray<ILoyaltyInRestaurantOrderLineItem>;
  loyaltyId: Scalars['LoyaltyID']['input'];
  restaurantId?: InputMaybe<Scalars['LoyaltyID']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyProcessSmgCodeArgs {
  loyaltyId: Scalars['LoyaltyID']['input'];
  smgCode: Scalars['String']['input'];
  transactionDate?: InputMaybe<Scalars['String']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyRedeemPromoCodeArgs {
  input?: InputMaybe<ILoyaltyRedeemPromoCodeInput>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyRemoveSecondaryIdAssociationArgs {
  identifier: Scalars['String']['input'];
  identifierType: LoyaltySecondaryIdentifierType;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationLoyaltyValidatePromoCodeArgs {
  input?: InputMaybe<ILoyaltyValidatePromoCodeInput>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationMakePaymentAttemptArgs {
  input: IMakePaymentAttemptInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationMergePrepaidArgs {
  input: IMergePrepaidInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationPriceOrderArgs {
  delivery?: InputMaybe<IPriceDeliveryInput>;
  input: IPriceOrderInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationProcessSmgCodeArgs {
  loyaltyId: Scalars['ID']['input'];
  smgCode: Scalars['String']['input'];
  transactionDate?: InputMaybe<Scalars['String']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationPurchasePrepaidArgs {
  input: IPurchasePrepaidInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationRedeemPromoCodeArgs {
  code: Scalars['String']['input'];
  cognitoId: Scalars['String']['input'];
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationReloadPrepaidArgs {
  input: IReloadPrepaidInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationRemoveDeliveryAddressArgs {
  input: IRemoveDeliveryAddressInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationRemoveSecondaryIdAssociationArgs {
  identifier: Scalars['String']['input'];
  identifierType: SecondaryIdentifierType;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationResendCurrentGuestOtpArgs {
  input: IResendOtpInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationResendCurrentLoginOtpArgs {
  input: IResendOtpInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationResendVerificationEmailArgs {
  input: IResendVerificationEmailInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationResumeAddPaymentMethodArgs {
  input: IResumeAddPaymentMethodInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationSaveDeliveryAddressArgs {
  input: ISaveDeliveryAddressInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationSendPhoneVerificationArgs {
  input: IResendPhoneVerificationInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationSendUpdateUserAttributesEventArgs {
  input: IUpdateUserAttributesEventInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationSignInJwtArgs {
  userInfo: ISignInUserInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationSignUpArgs {
  userInfo: ISignUpUserInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationSignUpVerifiedGuestArgs {
  userInfo: ISignUpUserInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationSocialLoginArgs {
  userInfo: ISocialLoginInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationUpdateDefaultPaymentMethodArgs {
  input: IUpdateDefaultPaymentMethodInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationUpdateDeliveryAddressArgs {
  input: IUpdateDeliveryAddressInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationUpdateFavoriteArgs {
  favoriteId: Scalars['String']['input'];
  input: IFavoriteInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationUpdateOrderArgs {
  input: IUpdateOrderInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationValidateAuthJwtArgs {
  authInput: IValidateAuthJwtInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationValidateGuestOtpCodeArgs {
  input: IValidateGuestOtpInput;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationValidatePromoCodeArgs {
  code: Scalars['String']['input'];
  cognitoId: Scalars['String']['input'];
  offers: ReadonlyArray<Scalars['String']['input']>;
  shouldRedeem?: InputMaybe<Scalars['Boolean']['input']>;
}

/**
 * TODO: make the legacy CBA mutations adhere to most up to date GQL standards defined here:
 * https://rbictg.atlassian.net/wiki/spaces/CPT/pages/3790832340/GraphQL+Schema+Guidelines
 */
export interface IMutationVerifyPhoneNumberArgs {
  input: IVerifyPhoneNumberInput;
}

/**
 * https://relay.dev/graphql/connections.htm#sec-Node
 *
 * Interface that is implemented by an application data node.
 */
export interface INode {
  readonly id: Maybe<Scalars['ID']['output']>;
}

export interface IObjectType {
  readonly __typename?: 'ObjectType';
  readonly key: Maybe<Scalars['String']['output']>;
  readonly value: Maybe<Scalars['JSON']['output']>;
}

export interface IOffer extends IBaseIncentive {
  readonly __typename?: 'Offer';
  readonly benefits: Maybe<ReadonlyArray<IBenefit>>;
  readonly cartRequirements: Maybe<ReadonlyArray<Maybe<ReadonlyArray<Maybe<ICartRequirement>>>>>;
  readonly configId: Maybe<Scalars['ID']['output']>;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<IRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<IExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  /** Deprecated - Please configure offer stackability by redemption type in the loyalty configuration. */
  readonly isStackable: Maybe<Scalars['Boolean']['output']>;
  readonly loyaltyId: Maybe<Scalars['ID']['output']>;
  readonly maxRedemptionsDuration: Maybe<DurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly metadata: Maybe<ReadonlyArray<Maybe<IObjectType>>>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly minimumTotalSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<PaymentMethod>;
  readonly questId: Maybe<Scalars['String']['output']>;
  readonly redemptionType: Maybe<OfferRedemptionType>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<ServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<IStoreRestrictions>;
  readonly templateId: Maybe<Scalars['ID']['output']>;
  readonly type: OfferType;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<BirthdayDuration>;
  readonly userEnrollment: Maybe<IDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<DayOfTheWeek>>;
}

export interface IOfferRedemptionAvailability {
  readonly __typename?: 'OfferRedemptionAvailability';
  readonly availableAfter: Maybe<Scalars['String']['output']>;
  readonly transactionId: Maybe<Scalars['String']['output']>;
}

export enum OfferRedemptionType {
  PROMO_CODE = 'PROMO_CODE',
  STANDARD = 'STANDARD',
  SURPRISE = 'SURPRISE',
  SWAP = 'SWAP',
}

export enum OfferType {
  GLOBAL = 'GLOBAL',
  PERSONALIZED = 'PERSONALIZED',
}

/** To be removed after old clients update */
export interface IOfferVariable {
  readonly __typename?: 'OfferVariable';
  /** @deprecated CBA Offers were deprecated */
  readonly key: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly type: Maybe<Scalars['String']['output']>;
  /** @deprecated CBA Offers were deprecated */
  readonly value: Maybe<Scalars['String']['output']>;
}

export interface IOffersInput {
  readonly appliedIncentives?: InputMaybe<ReadonlyArray<IPromotionInput>>;
  readonly appliedPromotions?: InputMaybe<ReadonlyArray<IPromotionInput>>;
  readonly cartEntries?: InputMaybe<ReadonlyArray<IRbiCartEntryInput>>;
  readonly configId?: InputMaybe<Scalars['ID']['input']>;
  readonly global?: InputMaybe<Scalars['Boolean']['input']>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly ignoreRules?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  readonly paymentMethod?: InputMaybe<PaymentMethod>;
  readonly redemptionTypes?: InputMaybe<ReadonlyArray<OfferRedemptionType>>;
  readonly serviceMode?: InputMaybe<ServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  readonly totalAmount?: InputMaybe<Scalars['Int']['input']>;
}

/**
 * Operating hours for a restaurant in local time formatted
 * as "YYYY-MM-DD hh:mm:ss" (the date component should be ignored)
 */
export interface IOperatingHours {
  readonly __typename?: 'OperatingHours';
  /** Friday Additional Hours */
  readonly friAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Friday operating hours close */
  readonly friClose: Maybe<Scalars['String']['output']>;
  /** Friday operating hours open */
  readonly friOpen: Maybe<Scalars['String']['output']>;
  /** Monday Additional Hours */
  readonly monAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Monday operating hours close */
  readonly monClose: Maybe<Scalars['String']['output']>;
  /** Monday operating hours open */
  readonly monOpen: Maybe<Scalars['String']['output']>;
  /** Saturday Additional Hours */
  readonly satAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Saturday operating hours close */
  readonly satClose: Maybe<Scalars['String']['output']>;
  /** Saturday operating hours open */
  readonly satOpen: Maybe<Scalars['String']['output']>;
  /** Sunday Additional Hours */
  readonly sunAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Sunday operating hours close */
  readonly sunClose: Maybe<Scalars['String']['output']>;
  /** Sunday operating hours open */
  readonly sunOpen: Maybe<Scalars['String']['output']>;
  /** Thursday Additional Hours */
  readonly thrAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Thursday operating hours close */
  readonly thrClose: Maybe<Scalars['String']['output']>;
  /** Thursday operating hours open */
  readonly thrOpen: Maybe<Scalars['String']['output']>;
  /** Tuesday Additional Hours */
  readonly tueAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Tuesday operating hours close */
  readonly tueClose: Maybe<Scalars['String']['output']>;
  /** Tuesday operating hours open */
  readonly tueOpen: Maybe<Scalars['String']['output']>;
  /** Wednesday Additional Hours */
  readonly wedAdditionalTimeSlot: Maybe<IAdditionalTimeSlot>;
  /** Wednesday operating hours close */
  readonly wedClose: Maybe<Scalars['String']['output']>;
  /** Wednesday operating hours open */
  readonly wedOpen: Maybe<Scalars['String']['output']>;
}

export enum OperationalStatus {
  CLOSED = 'CLOSED',
  CLOSED_TEMPORARILY = 'CLOSED_TEMPORARILY',
  OPEN = 'OPEN',
  PROJECTED = 'PROJECTED',
}

export interface IOption {
  readonly __typename?: 'Option';
  readonly id: Scalars['String']['output'];
  readonly options: Maybe<ReadonlyArray<IModifier>>;
}

export interface IOrbitalPayment {
  /**
   * Represents a saved funding source. (i.e. orbitalIdentifier or recurringDetailReference)
   * If passed this will be used to process payment. Either accountIdentifier or
   * encrypted payload is required.
   */
  readonly accountIdentifier?: InputMaybe<Scalars['String']['input']>;
  /** Encrypted Payment Payload. If accountIdentifier is not passed this will be used. */
  readonly encryptedPayload?: InputMaybe<IAddOrbitalAccountInput>;
  /** When true, payment details will be saved as a recurring payment method in Adyen */
  readonly savePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IOrder {
  readonly __typename?: 'Order';
  readonly cart: ICart;
  readonly catering: Maybe<ICatering>;
  readonly createdAt: Scalars['String']['output'];
  readonly delivery: Maybe<IDelivery>;
  readonly earliestPickupTimeInSeconds: Maybe<Scalars['Int']['output']>;
  readonly fireOrderIn: Maybe<Scalars['Int']['output']>;
  readonly fulfillmentDetails: Maybe<IFulfillmentDetails>;
  /** Same value as rbiOrderId, used for Apollo Cache */
  readonly id: Maybe<Scalars['String']['output']>;
  readonly loyaltyTransaction: Maybe<ILoyaltyTransaction>;
  readonly order: Maybe<IRbiOrder>;
  readonly orderErrors: Maybe<IOrderErrors>;
  readonly orderNumberFormatted: Maybe<Scalars['String']['output']>;
  readonly paymentStatus: Maybe<PaymentStatus>;
  readonly pk: Scalars['String']['output'];
  readonly posOrderId: Maybe<Scalars['String']['output']>;
  readonly posVendor: PosVendor;
  readonly rbiOrderId: Scalars['String']['output'];
  readonly revision: Scalars['Int']['output'];
  readonly sk: Scalars['String']['output'];
  readonly status: RbiOrderStatus;
  readonly updatedAt: Scalars['String']['output'];
  readonly userId: Maybe<Scalars['String']['output']>;
  readonly userSortKey: Scalars['String']['output'];
}

export interface IOrderErrors {
  readonly __typename?: 'OrderErrors';
  readonly orderInjectionErrors: IOrderInjectionErrors;
  readonly posErrors: Maybe<ReadonlyArray<Maybe<IRbiError>>>;
}

export interface IOrderInjectionErrors {
  readonly __typename?: 'OrderInjectionErrors';
  readonly isConfigurationError: Scalars['Boolean']['output'];
  readonly isMenuError: Scalars['Boolean']['output'];
  readonly isStoreAvailabilityError: Scalars['Boolean']['output'];
  readonly isTimeoutError: Scalars['Boolean']['output'];
  readonly isUnknownError: Scalars['Boolean']['output'];
}

export interface IOrderListResponse {
  readonly __typename?: 'OrderListResponse';
  readonly count: Scalars['Int']['output'];
  readonly orders: Maybe<ReadonlyArray<Maybe<IOrder>>>;
  readonly resumeAfter: Maybe<Scalars['String']['output']>;
}

export enum OrderSelectionStatus {
  CLAIMED = 'CLAIMED',
  PENDING = 'PENDING',
}

export interface IOrderSelectionTransfer {
  readonly __typename?: 'OrderSelectionTransfer';
  readonly createdAt: Scalars['String']['output'];
  readonly expiry: Maybe<Scalars['Int']['output']>;
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly order: ReadonlyArray<IInRestaurantOrderLineItemType>;
  readonly otp: Maybe<Scalars['String']['output']>;
  readonly restaurantId: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<OrderSelectionStatus>;
  readonly storeHasLoyalty: Maybe<Scalars['Boolean']['output']>;
  readonly transactionId: Maybe<Scalars['String']['output']>;
  readonly updatedAt: Maybe<Scalars['String']['output']>;
}

export enum OrderingStatus {
  ALPHA = 'alpha',
  BETA = 'beta',
  DEV = 'dev',
  LIVE = 'live',
  PROD = 'prod',
  TEMPORARILY_UNAVAILABLE = 'temporarily_unavailable',
  TEMPORARY_UNAVAILABLE = 'temporary_unavailable',
}

export interface IOrderlessIncentiveInput {
  readonly id: Scalars['String']['input'];
  readonly metadata?: InputMaybe<Scalars['JSON']['input']>;
  readonly type: PromotionType;
}

export interface IOrderlessTransactionInput {
  /** Represents the source of the transaction i.e web | mobile | support | campaign | receipt etc. */
  readonly channel: Scalars['String']['input'];
  readonly cognitoId?: InputMaybe<Scalars['String']['input']>;
  readonly incentives: ReadonlyArray<IOrderlessIncentiveInput>;
  readonly loyaltyId?: InputMaybe<Scalars['String']['input']>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
}

export interface IOstCreationPayload {
  readonly __typename?: 'OstCreationPayload';
  readonly expirationTime: Scalars['String']['output'];
  readonly key: Scalars['String']['output'];
}

export enum OstType {
  LOYALTY_ID = 'LOYALTY_ID',
  OTP = 'OTP',
}

export interface IOtp {
  readonly __typename?: 'Otp';
  readonly expirationTime: Scalars['String']['output'];
  readonly password: Scalars['String']['output'];
}

export interface IOverride {
  readonly __typename?: 'Override';
  readonly key: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly price: Maybe<Scalars['Int']['output']>;
}

/**
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 *
 * Page information to determine if there are more results, and what node,
 * determined by it's cursor, is at the start and end of the page returned.
 */
export interface IPageInfo {
  readonly __typename?: 'PageInfo';
  readonly endCursor: Maybe<Scalars['String']['output']>;
  readonly hasNextPage: Scalars['Boolean']['output'];
  readonly hasPreviousPage: Scalars['Boolean']['output'];
  readonly startCursor: Maybe<Scalars['String']['output']>;
}

export interface IParentChildPlu {
  readonly __typename?: 'ParentChildPlu';
  readonly childPlu: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<Scalars['String']['output']>;
}

export interface IParentChildPluInput {
  readonly childPlu?: InputMaybe<Scalars['String']['input']>;
  readonly plu?: InputMaybe<Scalars['String']['input']>;
}

/** Supported Partner Internal ID */
export enum Partner {
  AMAZON_PRIME = 'AMAZON_PRIME',
  BLUE_LIGHT_CARD = 'BLUE_LIGHT_CARD',
}

export interface IPartnerMembershipOutput {
  readonly __typename?: 'PartnerMembershipOutput';
  readonly createdAt: Scalars['String']['output'];
  readonly loyaltyId: Scalars['String']['output'];
  readonly membershipExpirationDate: Scalars['String']['output'];
  readonly metadata: IMetadataOutput;
  readonly partnerName: Scalars['String']['output'];
  readonly region: Scalars['String']['output'];
  readonly updatedAt: Scalars['String']['output'];
  readonly userId: Scalars['String']['output'];
}

export interface IPayMarkPayment {
  /** Merchant Account */
  readonly merchantAccount?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IPayPalFastStoredPaymentMethod extends IStoredPaymentMethod {
  readonly __typename?: 'PayPalFastStoredPaymentMethod';
  /** Stored payment billing agreement id */
  readonly billingAgreementId: Scalars['String']['output'];
  /** Stored payment method id */
  readonly id: Scalars['ID']['output'];
  /** Type of payment method */
  readonly type: PaymentMethodType;
}

export interface IPaycometPayment {
  /** Whether the order originated from Call Center */
  readonly isCallCenterOrder?: InputMaybe<Scalars['Boolean']['input']>;
  /** Order Description (the first item name of the cart) */
  readonly orderDescription?: InputMaybe<Scalars['String']['input']>;
  /** Payment Type */
  readonly paymentType?: InputMaybe<Scalars['String']['input']>;
  /** PaytpvToken */
  readonly paytpvToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Should update credit card billing address */
  readonly shouldUpdateBillingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
  /** User Payment details */
  readonly userPaymentDetails?: InputMaybe<IUserPaymentDetails>;
}

export interface IPayment {
  readonly __typename?: 'Payment';
  readonly approvalNumber: Maybe<Scalars['String']['output']>;
  readonly cardType: Maybe<Scalars['String']['output']>;
  readonly ccLast4: Maybe<Scalars['String']['output']>;
  readonly fdCorrelationId: Maybe<Scalars['String']['output']>;
  readonly fdSaleId: Scalars['String']['output'];
  readonly panToken: Maybe<Scalars['String']['output']>;
  readonly paymentMethodBrand: Maybe<PaymentMethodBrand>;
  readonly paymentRequestTimeLimit: Maybe<Scalars['String']['output']>;
  readonly paymentType: Maybe<CartPaymentType>;
}

export interface IPaymentAttempt {
  readonly __typename?: 'PaymentAttempt';
  readonly paymentAttemptId: Scalars['ID']['output'];
  readonly paymentIntentId: Scalars['ID']['output'];
  readonly paymentMethodType: PaymentMethodEnum;
  readonly processorId: Scalars['String']['output'];
  readonly pspSpecific: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
}

export interface IPaymentBillingAddressInput {
  readonly administrativeArea?: InputMaybe<Scalars['String']['input']>;
  readonly locality?: InputMaybe<Scalars['String']['input']>;
  readonly postalCode?: InputMaybe<Scalars['String']['input']>;
  readonly regionCode: Scalars['String']['input'];
  readonly route?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IPaymentConfig {
  readonly __typename?: 'PaymentConfig';
  readonly allowedPaymentMethods: ReadonlyArray<PaymentMethodType>;
  readonly applePay: Maybe<IApplePayPaymentConfig>;
  readonly frontendIntegration: FrontendIntegration;
  readonly googlePay: Maybe<IGooglePayPaymentConfig>;
}

export interface IPaymentConfiguration {
  readonly __typename?: 'PaymentConfiguration';
  readonly enableCreateShopper: Scalars['Boolean']['output'];
  readonly enableRetrieveVaultedPaymentMethods: Scalars['Boolean']['output'];
  readonly enableVaultPaymentMethod: Scalars['Boolean']['output'];
  readonly market: IMarket;
  readonly paymentMethodTypeId: Scalars['String']['output'];
  readonly processorId: Scalars['String']['output'];
  readonly status: Scalars['String']['output'];
}

export interface IPaymentExpiration {
  readonly __typename?: 'PaymentExpiration';
  readonly month: Maybe<Scalars['Int']['output']>;
  readonly year: Maybe<Scalars['Int']['output']>;
}

export enum PaymentFlowType {
  ONETIME = 'ONETIME',
  ONETIME_PRE_AUTHORIZATION = 'ONETIME_PRE_AUTHORIZATION',
  PAID_ON_REDIRECTING = 'PAID_ON_REDIRECTING',
  PAYMENT_LINK = 'PAYMENT_LINK',
  PAYPAL = 'PAYPAL',
  VAULTED = 'VAULTED',
  VAULTED_PRE_AUTHORIZATION = 'VAULTED_PRE_AUTHORIZATION',
}

export interface IPaymentInput {
  /** Adyen payment data */
  readonly adyenInput?: InputMaybe<IAdyenPayment>;
  /** Details from Apple Pay for payment. */
  readonly applePayDetails?: InputMaybe<IApplePay>;
  /** Billing address associated to card */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /** Billing customer information associated to card */
  readonly billingCustomer?: InputMaybe<IBillingCustomerInput>;
  /** Customer's browser information */
  readonly browserInformation?: InputMaybe<IBrowserInformation>;
  /** Denotes a cash payment */
  readonly cashPayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** Contains supplemental data for CC transactions */
  readonly ccMetadata?: InputMaybe<ICreditCardMetadataInput>;
  /** chase id from TH endpoint to trigger direct payment */
  readonly chaseProfileId?: InputMaybe<Scalars['String']['input']>;
  /** Checkout.com Payment data */
  readonly checkoutDotComInput?: InputMaybe<ICheckoutDotComPayment>;
  /** CyberSource Payment data */
  readonly cybersourceInput?: InputMaybe<ICybersourcePayment>;
  /** DeviceSessionId for Checkout.com risk assessment */
  readonly deviceSessionId?: InputMaybe<Scalars['String']['input']>;
  /** Evertec Payment data */
  readonly evertecInput?: InputMaybe<IEvertecPayment>;
  /** First Data payment data */
  readonly firstDataInput?: InputMaybe<IFirstDataPayment>;
  /** Firstpay Payment data */
  readonly firstPayInput?: InputMaybe<IFirstpayPayment>;
  /** Name on credit card */
  readonly fullName: Scalars['String']['input'];
  /** Details from Google Pay for payment. */
  readonly googlePayDetails?: InputMaybe<IGooglePay>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /** MercadoPago Payment data */
  readonly mercadoPagoInput?: InputMaybe<IMercadoPagoPayment>;
  /** Orbital Payment data */
  readonly orbitalInput?: InputMaybe<IOrbitalPayment>;
  /** PayMark Payment data */
  readonly payMarkInput?: InputMaybe<IPayMarkPayment>;
  /** Paycomet Payment data */
  readonly paycometInput?: InputMaybe<IPaycometPayment>;
  /** Payment flow type (vaulted, onetime, paypal...) */
  readonly paymentFlowType?: InputMaybe<PaymentFlowType>;
  /** Payment Method Brand */
  readonly paymentMethodBrand?: InputMaybe<PaymentMethodBrand>;
  /** VR Payment data */
  readonly vrPaymentInput?: InputMaybe<IVrPayment>;
  /** Worldpay Payment data */
  readonly worldpayInput?: InputMaybe<IWorldpayPayment>;
}

export interface IPaymentIntent {
  readonly __typename?: 'PaymentIntent';
  readonly amount: Scalars['Int']['output'];
  readonly clientApp: Scalars['String']['output'];
  readonly clientUserId: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly paymentIntentId: Scalars['ID']['output'];
  readonly paymentMethodTypeConfigurations: ReadonlyArray<IPaymentConfiguration>;
  readonly referenceId: Scalars['String']['output'];
}

export enum PaymentMethod {
  AMEX = 'AMEX',
  APPLE_PAY = 'APPLE_PAY',
  CASH = 'CASH',
  CREDIT = 'CREDIT',
  DINERSCLUB = 'DINERSCLUB',
  DISCOVER = 'DISCOVER',
  GIFT_CARD = 'GIFT_CARD',
  GOOGLE_PAY = 'GOOGLE_PAY',
  JCB = 'JCB',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export enum PaymentMethodBrand {
  APPLEPAYLINK = 'APPLEPAYLINK',
  BIZUM = 'BIZUM',
  CHEQUE_GOURMET = 'CHEQUE_GOURMET',
  CHEQUE_GOURMET_VOUCHER = 'CHEQUE_GOURMET_VOUCHER',
  MAESTRO = 'MAESTRO',
  PAYMENT_ON_DELIVERY_CARD = 'PAYMENT_ON_DELIVERY_CARD',
  SODEXO = 'SODEXO',
  SODEXO_VOUCHER = 'SODEXO_VOUCHER',
  TICKET_RESTAURANT_CARD = 'TICKET_RESTAURANT_CARD',
  TICKET_RESTAURANT_VOUCHER = 'TICKET_RESTAURANT_VOUCHER',
  TWINT = 'TWINT',
  WAYLET = 'WAYLET',
}

export interface IPaymentMethodData {
  /** Card display name with last 4 digits of the credit card */
  readonly displayName?: InputMaybe<Scalars['String']['input']>;
  /** Card type used for digital pay */
  readonly paymentMethodType?: InputMaybe<Scalars['String']['input']>;
  /** Payment type used for digital pay */
  readonly paymentType?: InputMaybe<Scalars['String']['input']>;
}

export enum PaymentMethodEnum {
  APPLE_PAY = 'APPLE_PAY',
  CASH = 'CASH',
  CREDIT_CARD_ONE_TIME = 'CREDIT_CARD_ONE_TIME',
  CREDIT_CARD_VAULTED = 'CREDIT_CARD_VAULTED',
  GIFTCARD = 'GIFTCARD',
  GOOGLE_PAY = 'GOOGLE_PAY',
  IDEAL = 'IDEAL',
  PAYPAL = 'PAYPAL',
  PAYPAL_VAULTED = 'PAYPAL_VAULTED',
  UNPAID = 'UNPAID',
}

export enum PaymentMethodType {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  PAYPAL_FAST = 'PAYPAL_FAST',
  PREPAID = 'PREPAID',
  SCHEME = 'SCHEME',
}

export interface IPaymentMethods {
  readonly __typename?: 'PaymentMethods';
  readonly isOnlinePayment: Maybe<Scalars['Boolean']['output']>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly paymentMethodBrand: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['Boolean']['output']>;
}

export interface IPaymentPreferences {
  readonly __typename?: 'PaymentPreferences';
  /** Is auto reload enabled */
  readonly autoReload: Scalars['Boolean']['output'];
  /** Auto reload threshold */
  readonly autoReloadThreshold: Maybe<Scalars['Int']['output']>;
  /** Default prepaid reload amount */
  readonly reloadAmount: Maybe<Scalars['Int']['output']>;
  /** Default scan and say account identifier */
  readonly scanAndPayStoredPaymentMethodId: Maybe<Scalars['String']['output']>;
  /** Default payment account id */
  readonly storedPaymentMethodId: Maybe<Scalars['String']['output']>;
}

export enum PaymentStatus {
  AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR',
  AUTHORIZATION_REQUESTED = 'AUTHORIZATION_REQUESTED',
  AUTHORIZATION_SUCCESSFUL = 'AUTHORIZATION_SUCCESSFUL',
  CANCEL_ERROR = 'CANCEL_ERROR',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  CANCEL_SUCCESSFUL = 'CANCEL_SUCCESSFUL',
  CAPTURE_ERROR = 'CAPTURE_ERROR',
  CAPTURE_REQUESTED = 'CAPTURE_REQUESTED',
  CAPTURE_SUCCESSFUL = 'CAPTURE_SUCCESSFUL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_PENDING_3DS = 'PAYMENT_PENDING_3DS',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  REFUND_ERROR = 'REFUND_ERROR',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_SUCCESSFUL = 'REFUND_SUCCESSFUL',
  VOID_ERROR = 'VOID_ERROR',
  VOID_REQUESTED = 'VOID_REQUESTED',
  VOID_SUCCESSFUL = 'VOID_SUCCESSFUL',
}

export interface IPerson {
  readonly __typename?: 'Person';
  readonly email: Maybe<Scalars['String']['output']>;
  readonly externalIds: Maybe<ReadonlyArray<Maybe<IExternalIdentifier>>>;
  readonly phone: Maybe<Scalars['String']['output']>;
}

export interface IPhysicalAddress {
  readonly __typename?: 'PhysicalAddress';
  readonly address1: Maybe<Scalars['String']['output']>;
  readonly address2: Maybe<Scalars['String']['output']>;
  readonly city: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly postalCode: Maybe<Scalars['String']['output']>;
  readonly stateProvince: Maybe<Scalars['String']['output']>;
  readonly stateProvinceShort: Maybe<Scalars['String']['output']>;
}

export interface IPickupContact {
  readonly __typename?: 'PickupContact';
  readonly hasArrived: Scalars['Boolean']['output'];
  readonly name: Maybe<Scalars['String']['output']>;
  readonly phone: Maybe<Scalars['String']['output']>;
  readonly vehicle: Maybe<IPickupContactVehicle>;
}

export interface IPickupContactVehicle {
  readonly __typename?: 'PickupContactVehicle';
  readonly color: Maybe<Scalars['String']['output']>;
  readonly licensePlate: Maybe<Scalars['String']['output']>;
  readonly make: Maybe<Scalars['String']['output']>;
  readonly model: Maybe<Scalars['String']['output']>;
}

export interface IPlaceCateringOrderInput {
  /**
   * Billing address Comes in the following format
   * billingStreetAddress, billingApt, billingCity, billingState, billingZip
   */
  readonly billingAddress?: InputMaybe<IBillingAddressInput>;
  /**
   * Catering Information
   * contains Datetime for the catering
   */
  readonly cateringInfo: ICateringInfoInput;
  /**
   * First 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{6}$")
   */
  readonly ccBin?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry month */
  readonly ccExpiryMonth?: InputMaybe<Scalars['String']['input']>;
  /** Credit card 2 digit expiry year */
  readonly ccExpiryYear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Last 6 digits of credit card.
   * Required for anonymous payment.
   * @constraint(pattern: "^[0-9]{4}$")
   */
  readonly ccLast4?: InputMaybe<Scalars['String']['input']>;
  /** The payment provider type eg. (VISA/MASTERCARD/DISCOVER/AMEX) */
  readonly creditType?: InputMaybe<CartPaymentCardType>;
  /** Valid access token for accessing First Data API */
  readonly fdAccessToken?: InputMaybe<Scalars['String']['input']>;
  /**
   * The First Data payment method ID which must be associated to the user
   * making the request.
   */
  readonly fdAccountId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Nonce retrieved from First Data. Sale will be performed with the payment
   * method associated with the nonce.
   */
  readonly fdNonce?: InputMaybe<Scalars['String']['input']>;
  /** Customer full name */
  readonly fullName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Unique Kount Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   */
  readonly kountSessionId?: InputMaybe<Scalars['String']['input']>;
  /** ID for a previous order that has been priced */
  readonly rbiOrderId: Scalars['ID']['input'];
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /**
   * Unique Session ID
   * 32 characters long
   * Example: faa6370074b53928bc51ef913441e0cd (one continuous string with no spaces)
   *
   * Note that the "sessionId" field is deprecated and will be ignored.
   */
  readonly sessionId?: InputMaybe<Scalars['String']['input']>;
  /** Store email address */
  readonly storeEmail?: InputMaybe<Scalars['String']['input']>;
  /** Details from 3DS transactions, relates to properties needed to resume sale transactions. */
  readonly threeDSDetails?: InputMaybe<IResumeThreeDSaleTransaction>;
}

export enum Platform {
  APP = 'app',
  KIOSK = 'kiosk',
  WEB = 'web',
}

export interface IPlu {
  readonly __typename?: 'Plu';
  readonly connector: Maybe<Scalars['String']['output']>;
  readonly ncr: Maybe<Scalars['String']['output']>;
  readonly ncrDelivery: Maybe<Scalars['String']['output']>;
  readonly oheics: Maybe<Scalars['String']['output']>;
  readonly oheicsDelivery: Maybe<Scalars['String']['output']>;
  readonly partner: Maybe<Scalars['String']['output']>;
  readonly partnerDelivery: Maybe<Scalars['String']['output']>;
  readonly productNumber: Maybe<Scalars['String']['output']>;
  readonly productNumberDelivery: Maybe<Scalars['String']['output']>;
  readonly qst: Maybe<Scalars['String']['output']>;
  readonly qstDelivery: Maybe<Scalars['String']['output']>;
  readonly sicom: Maybe<Scalars['String']['output']>;
  readonly sicomDelivery: Maybe<Scalars['String']['output']>;
  readonly simplyDelivery: Maybe<Scalars['String']['output']>;
  readonly simplyDeliveryDelivery: Maybe<Scalars['String']['output']>;
  readonly toshibaLoyalty: Maybe<Scalars['String']['output']>;
}

export interface IPluInput {
  readonly connector?: InputMaybe<Scalars['String']['input']>;
  readonly ncr?: InputMaybe<Scalars['String']['input']>;
  readonly ncrDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly oheics?: InputMaybe<Scalars['String']['input']>;
  readonly oheicsDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly partner?: InputMaybe<Scalars['String']['input']>;
  readonly partnerDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly productNumber?: InputMaybe<Scalars['String']['input']>;
  readonly qst?: InputMaybe<Scalars['String']['input']>;
  readonly qstDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly rpos?: InputMaybe<Scalars['String']['input']>;
  readonly rposDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly sicom?: InputMaybe<Scalars['String']['input']>;
  readonly sicomDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly simplyDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly simplyDeliveryDelivery?: InputMaybe<Scalars['String']['input']>;
  readonly toshibaLoyalty?: InputMaybe<Scalars['String']['input']>;
}

/** PluPrice represents the price of a menu entity. */
export interface IPluPrice {
  readonly __typename?: 'PluPrice';
  /**
   * Price Lookup Unit (PLU) is a vendor-specific menu entry identifier.
   *
   * "Compound" PLUs are individual PLUs joined with the "-" character, and
   * represent the price of entity in the context of another parent entity. For
   * example the compound PLU "123-456" represents the price of menu item "456"
   * in the combo "123".
   */
  readonly plu: Scalars['String']['output'];
  /** PLU price in cents. */
  readonly price: Scalars['Int']['output'];
}

export enum PosDataServiceMode {
  DELIVERY = 'delivery',
  PICKUP = 'pickup',
}

export enum PosVendor {
  CARROLS = 'CARROLS',
  CONNECTOR = 'CONNECTOR',
  NCR = 'NCR',
  OHEICS = 'OHEICS',
  PARTNER = 'PARTNER',
  PRODUCT_NUMBER = 'PRODUCT_NUMBER',
  QDI = 'QDI',
  QST = 'QST',
  RPOS = 'RPOS',
  SICOM = 'SICOM',
  SIMPLY_DELIVERY = 'SIMPLY_DELIVERY',
  TABLET = 'TABLET',
}

export interface IPreOrderTimeSlot {
  readonly __typename?: 'PreOrderTimeSlot';
  readonly end: Scalars['String']['output'];
  readonly start: Scalars['String']['output'];
}

export interface IPreOrderTimeSlotInput {
  readonly end: Scalars['String']['input'];
  readonly start: Scalars['String']['input'];
}

export interface IPrepaidDetails {
  readonly __typename?: 'PrepaidDetails';
  readonly balance: Scalars['Int']['output'];
  readonly cardNumber: Scalars['String']['output'];
  readonly currency: Scalars['String']['output'];
  readonly expiryMonth: Scalars['String']['output'];
  readonly expiryYear: Scalars['String']['output'];
  readonly last4: Scalars['String']['output'];
}

export interface IPrepaidStoredPaymentMethod extends IStoredPaymentMethod {
  readonly __typename?: 'PrepaidStoredPaymentMethod';
  /** Balance in the smallest currency unit (e.g. USD Cent) */
  readonly balance: Scalars['Int']['output'];
  /** Currency */
  readonly currency: Scalars['String']['output'];
  /** Stored payment method id */
  readonly id: Scalars['ID']['output'];
  /** Last 4 digits of card number */
  readonly last4: Scalars['String']['output'];
  /** Shuffled card number */
  readonly shuffledCardNumber: Scalars['String']['output'];
  /** Type of payment method */
  readonly type: PaymentMethodType;
}

export interface IPrepaidTransactions {
  readonly __typename?: 'PrepaidTransactions';
  readonly amount: Scalars['Int']['output'];
  readonly currency: Scalars['String']['output'];
  readonly info: Scalars['String']['output'];
  readonly timestamp: Scalars['String']['output'];
}

export interface IPrice {
  readonly __typename?: 'Price';
  readonly default: Maybe<Scalars['Int']['output']>;
  readonly max: Maybe<Scalars['Int']['output']>;
  readonly min: Maybe<Scalars['Int']['output']>;
  readonly overrides: Maybe<ReadonlyArray<IOverride>>;
}

export interface IPriceDeliveryInput {
  readonly dropoff: IDeliveryWaypointInput;
  readonly instructions?: InputMaybe<Scalars['String']['input']>;
  readonly quoteId?: InputMaybe<Scalars['String']['input']>;
  readonly tipCents?: InputMaybe<Scalars['Int']['input']>;
}

export interface IPriceOrderInput {
  /** Offers applied to cart */
  readonly appliedOffers?: InputMaybe<ReadonlyArray<InputMaybe<IAppliedOffer>>>;
  /** The brand of the store. */
  readonly brand: Brand;
  /** The order cart entries to be injected into the POS. */
  readonly cartEntries?: InputMaybe<ReadonlyArray<ICartEntryInput>>;
  /** The cart shape version. */
  readonly cartVersion?: InputMaybe<Scalars['Int']['input']>;
  /** The locale of the customer. */
  readonly customerLocale?: InputMaybe<Scalars['String']['input']>;
  /**
   * The name of the customer. If provided, this will
   * supersede the user's name on this order.
   */
  readonly customerName?: InputMaybe<Scalars['String']['input']>;
  /**
   * (Deprecated) Tim Hortons user's loyalty card barcode to apply reward/visit
   * @deprecated(reason: "TH Loyalty was deprecated")
   */
  readonly loyaltyBarcode?: InputMaybe<Scalars['String']['input']>;
  /** payment method - for cash orders */
  readonly paymentMethod?: InputMaybe<CartPaymentCardType>;
  /** The Platform */
  readonly platform?: InputMaybe<Platform>;
  /** The POS Vendor */
  readonly posVendor: PosVendor;
  /** the quoteId related to the current delivery restaurant */
  readonly quotedId?: InputMaybe<Scalars['String']['input']>;
  /**
   * (Deprecated) Tim Hortons flag for reward redemption, if true must provide loyaltyBarcode
   * @deprecated(reason: "TH Loyalty was deprecated")
   */
  readonly redeemReward?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /** Requested sale amount in cents (USD) */
  readonly requestedAmountCents: Scalars['Int']['input'];
  /** Reward ids being applied to cart */
  readonly rewardsApplied?: InputMaybe<ReadonlyArray<InputMaybe<IAppliedReward>>>;
  /** How the order will be executed. */
  readonly serviceMode: ServiceMode;
  /** Store Address, derived from MDM. */
  readonly storeAddress: IStoreAddressInput;
  /** Store ID, derived from MDM. This value will be validated within First Data. */
  readonly storeId: Scalars['String']['input'];
  /** The internal Store ID. */
  readonly storePosId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Cart Item swaps being applied to cart
   * @deprecated(reason: "Not needed anymore since it is included in AppliedOffer")
   */
  readonly swaps?: InputMaybe<ReadonlyArray<InputMaybe<ISwapInput>>>;
  /** vat number on the restaurant document for BK UK */
  readonly vatNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IProcessSmgCodeResponse {
  readonly __typename?: 'ProcessSmgCodeResponse';
  readonly pointsEarned: Scalars['Int']['output'];
  readonly status: SmgCodeStatus;
}

export interface IProductHierarchyInput {
  readonly L1?: InputMaybe<Scalars['String']['input']>;
  readonly L2?: InputMaybe<Scalars['String']['input']>;
  readonly L3?: InputMaybe<Scalars['String']['input']>;
  readonly L4?: InputMaybe<Scalars['String']['input']>;
  readonly L5?: InputMaybe<Scalars['String']['input']>;
}

export interface IPromotion {
  readonly __typename?: 'Promotion';
  readonly id: Scalars['String']['output'];
  readonly lineId: Maybe<Scalars['String']['output']>;
  readonly type: PromotionType;
}

export interface IPromotionInput {
  readonly id: Scalars['String']['input'];
  readonly lineId?: InputMaybe<Scalars['String']['input']>;
  readonly swap?: InputMaybe<IPromotionSwapInput>;
  readonly type: PromotionType;
}

export interface IPromotionSwapInput {
  readonly from: Scalars['String']['input'];
  readonly lineId: Scalars['String']['input'];
  readonly to: Scalars['String']['input'];
  readonly type: SwapType;
}

export enum PromotionType {
  OFFER = 'OFFER',
  REWARD = 'REWARD',
  SYSTEMWIDE_PROMOTION = 'SYSTEMWIDE_PROMOTION',
}

export interface IPromotionsInput {
  readonly omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  readonly serviceMode?: InputMaybe<ServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
}

export enum ProviderType {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export interface IPurchasePrepaidAuthorized {
  readonly __typename?: 'PurchasePrepaidAuthorized';
  readonly prepaidDetails: IPrepaidDetails;
}

export interface IPurchasePrepaidInput {
  /** Payment amount */
  readonly amount: Scalars['Int']['input'];
  /** Billing address if applicable */
  readonly billingAddress?: InputMaybe<IPaymentBillingAddressInput>;
  /** Payment currency */
  readonly currency: Scalars['String']['input'];
  /** PSP formatted payment details (stringified) */
  readonly paymentMethod: Scalars['String']['input'];
  /** Platform used by user to purchase prepaid */
  readonly platform?: InputMaybe<Platform>;
  /** Fraud risk data (stringified) */
  readonly riskData: Scalars['String']['input'];
}

export type IPurchasePrepaidPayload = IPurchasePrepaidAuthorized | IPurchasePrepaidRefused;

export enum PurchasePrepaidRefusalCode {
  BANK_DECLINED = 'BANK_DECLINED',
}

export interface IPurchasePrepaidRefused {
  readonly __typename?: 'PurchasePrepaidRefused';
  readonly code: PurchasePrepaidRefusalCode;
}

export interface IQuantityPlu {
  readonly __typename?: 'QuantityPlu';
  readonly plu: Maybe<Scalars['String']['output']>;
  readonly qualifier: Maybe<Scalars['String']['output']>;
  readonly quantity: Maybe<Scalars['Int']['output']>;
}

export interface IQuantityPluInput {
  readonly plu?: InputMaybe<Scalars['String']['input']>;
  readonly qualifier?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
}

export interface IQuery {
  readonly __typename?: 'Query';
  readonly _empty: Maybe<Scalars['String']['output']>;
  readonly _loyaltyEmpty: Maybe<Scalars['String']['output']>;
  /** Returns an available delivery restaurant based on vendor quote given provided address and search radius. */
  readonly deliveryRestaurant: IDeliveryRestaurant;
  readonly getPaymentIntent: Maybe<IPaymentIntent>;
  readonly getUserMemberships: ReadonlyArray<IPartnerMembershipOutput>;
  /** Create a get list fees by tier */
  readonly listFeesByTier: ReadonlyArray<Maybe<IGetFeesByTierResponse>>;
  readonly loyaltyGetOrderSelection: Maybe<ILoyaltyOrderSelection>;
  /** Get offers for both authenticated and unauthenticated users */
  readonly loyaltyOffers: Maybe<ReadonlyArray<IOffer>>;
  /** Get offers for both authenticated and unauthenticated users */
  readonly loyaltyOffersV2: Maybe<ReadonlyArray<ILoyaltyOffer>>;
  /** gets Order Transfer Selection by associated OTP code */
  readonly loyaltyOrderSelection: Maybe<ILoyaltyOrderSelectionTransfer>;
  /** gets Order Transfer Selection by key based on the ostType */
  readonly loyaltyOrderSelectionByType: Maybe<ILoyaltyOrderSelectionTransfer>;
  /** Get quests by loyaltyId. */
  readonly loyaltyQuests: Maybe<ReadonlyArray<IQuest>>;
  /** Get quests by loyaltyId. */
  readonly loyaltyQuestsV2: Maybe<ReadonlyArray<ILoyaltyQuest>>;
  /**
   * Get rewards by loyaltyId and / or reward ids
   * optionally accepts service mode and store id for rule evaluation
   */
  readonly loyaltyRewards: Maybe<ReadonlyArray<IReward>>;
  /**
   * Get rewards by loyaltyId and / or reward ids
   * optionally accepts service mode and store id for rule evaluation
   */
  readonly loyaltyRewardsV2: Maybe<ReadonlyArray<ILoyaltyReward>>;
  /** Get loyalty user by loyalty id */
  readonly loyaltyUser: Maybe<IUser>;
  /** Get loyalty user by loyalty id */
  readonly loyaltyUserV2: Maybe<ILoyaltyUser>;
  /** Query an order by order id for the current user. */
  readonly order: Maybe<IOrder>;
  /** gets Order Transfer Selection by associated OTP code */
  readonly orderSelection: Maybe<IOrderSelectionTransfer>;
  /** gets Order Transfer Selection by key based on the ostType */
  readonly orderSelectionByType: Maybe<IOrderSelectionTransfer>;
  readonly paymentConfig: IPaymentConfig;
  readonly paymentPreferences: IPaymentPreferences;
  /** DEPRECATED! Use plusV2 instead. */
  readonly plus: Maybe<ReadonlyArray<IMenuItem>>;
  /** Returns the PLU-price map for a store. */
  readonly plusV2: Maybe<ReadonlyArray<IPluPrice>>;
  readonly prepaidByNumber: IPrepaidDetails;
  readonly prepaidTransactionsById: ReadonlyArray<IPrepaidTransactions>;
  readonly prepaidTransactionsByNumber: ReadonlyArray<Maybe<IPrepaidTransactions>>;
  /** Returns a restaurant entity given store id */
  readonly restaurant: IRestaurant;
  /**
   * Returns a list of restaurants based on provided filters.
   * @todo: Return result with pagination metadata
   */
  readonly restaurants: Maybe<IRestaurantsConnection>;
  readonly signUpComplete: Maybe<Scalars['Boolean']['output']>;
  readonly storeMenu: Maybe<ReadonlyArray<IEntity>>;
  readonly storeMenuWithConnectorHours: Maybe<ReadonlyArray<IMenuEntityWithHours>>;
  readonly storedPaymentMethods: ReadonlyArray<IStoredPaymentMethod>;
  readonly uniqueStores: IOrderListResponse;
  /** Get a user favorite */
  readonly userFavorite: IFavorite;
  /** List of user favorites */
  readonly userFavorites: IFavoriteList;
  readonly userGeo: IUserGeo;
  /**
   * Query all orders for current user.
   *
   * Note that the "userId" field is deprecated and will be ignored.
   */
  readonly userOrders: IOrderListResponse;
  /** Get user phone from braze */
  readonly userPhoneFromBraze: Maybe<IBrazeUserPhone>;
  /** Returns a restaurant entity given store id */
  readonly waitTime: Maybe<IWaitTime>;
  /**
   * Returns a list of restaurants based on provided filters.
   * @todo: Return result with pagination metadata
   */
  readonly waitTimes: Maybe<ReadonlyArray<Maybe<IWaitTime>>>;
}

export interface IQueryDeliveryRestaurantArgs {
  dropoff: IDeliveryWaypointInput;
  platform?: InputMaybe<Platform>;
  searchRadius: Scalars['Float']['input'];
}

export interface IQueryGetPaymentIntentArgs {
  input: IGetPaymentIntentInput;
}

export interface IQueryGetUserMembershipsArgs {
  userId: Scalars['String']['input'];
}

export interface IQueryListFeesByTierArgs {
  options: IGetFeesByTierInput;
}

export interface IQueryLoyaltyGetOrderSelectionArgs {
  key: Scalars['String']['input'];
  type: LoyaltyOrderSelectionType;
}

export interface IQueryLoyaltyOffersArgs {
  configId?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  loyaltyId?: InputMaybe<Scalars['ID']['input']>;
  omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  redemptionTypes?: InputMaybe<ReadonlyArray<OfferRedemptionType>>;
  serviceMode?: InputMaybe<ServiceMode>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IOffersInput>;
}

export interface IQueryLoyaltyOffersV2Args {
  configId?: InputMaybe<Scalars['LoyaltyID']['input']>;
  ids?: InputMaybe<ReadonlyArray<Scalars['LoyaltyID']['input']>>;
  isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  loyaltyId?: InputMaybe<Scalars['LoyaltyID']['input']>;
  omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  redemptionTypes?: InputMaybe<ReadonlyArray<LoyaltyOfferRedemptionType>>;
  serviceMode?: InputMaybe<LoyaltyServiceMode>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ILoyaltyOffersInput>;
}

export interface IQueryLoyaltyOrderSelectionArgs {
  otp: Scalars['String']['input'];
}

export interface IQueryLoyaltyOrderSelectionByTypeArgs {
  key: Scalars['String']['input'];
  ostType: LoyaltyOstType;
}

export interface IQueryLoyaltyQuestsArgs {
  loyaltyId?: InputMaybe<Scalars['ID']['input']>;
}

export interface IQueryLoyaltyQuestsV2Args {
  loyaltyId?: InputMaybe<Scalars['LoyaltyID']['input']>;
}

export interface IQueryLoyaltyRewardsArgs {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  loyaltyId?: InputMaybe<Scalars['ID']['input']>;
  omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  serviceMode?: InputMaybe<ServiceMode>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IRewardsInput>;
}

export interface IQueryLoyaltyRewardsV2Args {
  ids?: InputMaybe<ReadonlyArray<Scalars['LoyaltyID']['input']>>;
  isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  loyaltyId?: InputMaybe<Scalars['LoyaltyID']['input']>;
  omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  serviceMode?: InputMaybe<LoyaltyServiceMode>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ILoyaltyRewardsInput>;
}

export interface IQueryLoyaltyUserArgs {
  loyaltyId: Scalars['ID']['input'];
}

export interface IQueryLoyaltyUserV2Args {
  loyaltyId: Scalars['LoyaltyID']['input'];
}

export interface IQueryOrderArgs {
  handleCommitError?: InputMaybe<Scalars['Boolean']['input']>;
  rbiOrderId: Scalars['ID']['input'];
}

export interface IQueryOrderSelectionArgs {
  otp: Scalars['String']['input'];
}

export interface IQueryOrderSelectionByTypeArgs {
  key: Scalars['String']['input'];
  ostType: OstType;
}

export interface IQueryPlusArgs {
  serviceMode?: InputMaybe<PosDataServiceMode>;
  storeId: Scalars['ID']['input'];
}

export interface IQueryPlusV2Args {
  serviceMode?: InputMaybe<PosDataServiceMode>;
  storeId: Scalars['ID']['input'];
}

export interface IQueryPrepaidByNumberArgs {
  cardNumber: Scalars['String']['input'];
}

export interface IQueryPrepaidTransactionsByIdArgs {
  storedPaymentMethodId: Scalars['String']['input'];
}

export interface IQueryPrepaidTransactionsByNumberArgs {
  cardNumber: Scalars['String']['input'];
}

export interface IQueryRestaurantArgs {
  localDateTime?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  storeNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IQueryRestaurantsArgs {
  input?: InputMaybe<IRestaurantsInput>;
}

export interface IQuerySignUpCompleteArgs {
  jwt: Scalars['String']['input'];
}

export interface IQueryStoreMenuArgs {
  channel: Channel;
  region: Scalars['String']['input'];
  serviceMode?: InputMaybe<PosDataServiceMode>;
  storeId: Scalars['ID']['input'];
}

export interface IQueryStoreMenuWithConnectorHoursArgs {
  channel: Channel;
  region: Scalars['String']['input'];
  serviceMode?: InputMaybe<PosDataServiceMode>;
  storeId: Scalars['ID']['input'];
}

export interface IQueryUniqueStoresArgs {
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus>>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IQueryUserFavoriteArgs {
  favoriteId: Scalars['String']['input'];
}

export interface IQueryUserFavoritesArgs {
  limit?: InputMaybe<Scalars['Int']['input']>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IQueryUserOrdersArgs {
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus>>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  serviceModes?: InputMaybe<ReadonlyArray<InputMaybe<ServiceMode>>>;
  shouldFilterByRegion?: InputMaybe<Scalars['Boolean']['input']>;
  sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
}

export interface IQueryUserPhoneFromBrazeArgs {
  brazeId?: InputMaybe<Scalars['String']['input']>;
}

export interface IQueryWaitTimeArgs {
  orderId?: InputMaybe<Scalars['String']['input']>;
  storeId: Scalars['String']['input'];
}

export interface IQueryWaitTimesArgs {
  storeIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
}

export interface IQuest {
  readonly __typename?: 'Quest';
  readonly bonusPoints: Maybe<Scalars['Int']['output']>;
  readonly completions: Maybe<ReadonlyArray<Maybe<IQuestCompletion>>>;
  readonly configId: Maybe<Scalars['ID']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<IExternalIdentifiers>>>;
  readonly id: Scalars['ID']['output'];
  readonly incentiveId: Maybe<Scalars['ID']['output']>;
  readonly incentiveRedeemed: Scalars['Boolean']['output'];
  readonly incentiveType: QuestIncentiveType;
  readonly lastCompletion: Maybe<IQuestCompletion>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly startDate: Scalars['String']['output'];
  readonly status: QuestStatus;
  readonly steps: Maybe<ReadonlyArray<IQuestStep>>;
}

export interface IQuestCompletion {
  readonly __typename?: 'QuestCompletion';
  readonly benefitId: Scalars['String']['output'];
  readonly completedAt: Scalars['String']['output'];
  readonly redeemed: Scalars['Boolean']['output'];
}

export enum QuestIncentiveType {
  BONUS_POINTS = 'BONUS_POINTS',
  OFFER = 'OFFER',
  REWARD = 'REWARD',
}

export enum QuestStatus {
  ACTIVATED = 'ACTIVATED',
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
  NOT_ENROLLED = 'NOT_ENROLLED',
}

export interface IQuestStep {
  readonly __typename?: 'QuestStep';
  readonly cartRequirements: Maybe<ReadonlyArray<Maybe<ReadonlyArray<Maybe<ICartRequirement>>>>>;
  readonly completedAt: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly status: QuestStepStatus;
}

export enum QuestStepStatus {
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export interface IRbiCartEntryInput {
  readonly children?: InputMaybe<ReadonlyArray<IRbiCartEntryInput>>;
  readonly lineId?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['Int']['input']>;
  readonly quantity: Scalars['Int']['input'];
  readonly sanityId: Scalars['String']['input'];
}

export enum RbiChannel {
  APP = 'APP',
  ORDERLESS = 'ORDERLESS',
  RECEIPT = 'RECEIPT',
  REMEDIATION = 'REMEDIATION',
  RESTAURANT = 'RESTAURANT',
  WEB = 'WEB',
}

export interface IRbiError {
  readonly __typename?: 'RbiError';
  readonly errorCode: Maybe<Scalars['String']['output']>;
  readonly errorName: Maybe<Scalars['String']['output']>;
  readonly message: Maybe<Scalars['String']['output']>;
  readonly rbiErrorCode: Maybe<Scalars['String']['output']>;
  readonly rbiErrorDomain: Maybe<RbiErrorDomain>;
}

export enum RbiErrorDomain {
  PAYMENTS = 'PAYMENTS',
  POS = 'POS',
}

export interface IRbiOrder {
  readonly __typename?: 'RbiOrder';
  readonly refund: Maybe<IRefund>;
}

export enum RbiOrderStatus {
  CATERING_DECLINED = 'CATERING_DECLINED',
  CATERING_ERROR = 'CATERING_ERROR',
  CATERING_PLACED = 'CATERING_PLACED',
  CATERING_SUCCESSFUL = 'CATERING_SUCCESSFUL',
  COMMIT_ERROR = 'COMMIT_ERROR',
  INSERT_ERROR = 'INSERT_ERROR',
  INSERT_REQUESTED = 'INSERT_REQUESTED',
  INSERT_SUCCESSFUL = 'INSERT_SUCCESSFUL',
  KOUNT_DENIAL = 'KOUNT_DENIAL',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
  PAYMENT_REQUIRED = 'PAYMENT_REQUIRED',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PRICE_ERROR = 'PRICE_ERROR',
  PRICE_REQUESTED = 'PRICE_REQUESTED',
  PRICE_SUCCESSFUL = 'PRICE_SUCCESSFUL',
  QUEUED = 'QUEUED',
  QUEUE_ERROR = 'QUEUE_ERROR',
  REFUND_ERROR = 'REFUND_ERROR',
  REFUND_REQUESTED = 'REFUND_REQUESTED',
  REFUND_SUCCESSFUL = 'REFUND_SUCCESSFUL',
  UPDATE_ERROR = 'UPDATE_ERROR',
  UPDATE_REQUESTED = 'UPDATE_REQUESTED',
  UPDATE_SUCCESSFUL = 'UPDATE_SUCCESSFUL',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export interface IRbiParentChildPlu {
  readonly __typename?: 'RbiParentChildPlu';
  readonly childPlu: Maybe<Scalars['String']['output']>;
  readonly plu: Maybe<Scalars['String']['output']>;
}

export interface IRbiParentChildPluInput {
  readonly childPlu?: InputMaybe<Scalars['String']['input']>;
  readonly plu?: InputMaybe<Scalars['String']['input']>;
}

export interface IRbiQuantityPlu {
  readonly __typename?: 'RbiQuantityPlu';
  readonly plu: Maybe<Scalars['String']['output']>;
  readonly qualifier: Maybe<Scalars['String']['output']>;
  readonly quantity: Maybe<Scalars['Int']['output']>;
}

export interface IRbiQuantityPluInput {
  readonly plu?: InputMaybe<Scalars['String']['input']>;
  readonly qualifier?: InputMaybe<Scalars['String']['input']>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
}

export interface IRbiSizeBasedPlu {
  readonly __typename?: 'RbiSizeBasedPlu';
  readonly comboPlu: Maybe<Scalars['String']['output']>;
  readonly comboSize: Maybe<Scalars['String']['output']>;
}

export interface IRbiSizeBasedPluInput {
  readonly comboPlu?: InputMaybe<Scalars['String']['input']>;
  readonly comboSize?: InputMaybe<Scalars['String']['input']>;
}

export interface IRbiVendorConfig {
  readonly __typename?: 'RbiVendorConfig';
  readonly constantPlu: Maybe<Scalars['String']['output']>;
  readonly discountPlu: Maybe<Scalars['String']['output']>;
  readonly multiConstantPlus: Maybe<ReadonlyArray<Maybe<IRbiQuantityPlu>>>;
  readonly parentChildPlu: Maybe<IRbiParentChildPlu>;
  readonly parentSanityId: Maybe<Scalars['String']['output']>;
  readonly pluType: Maybe<Scalars['String']['output']>;
  readonly pullUpLevels: Maybe<Scalars['Int']['output']>;
  readonly quantityBasedPlu: Maybe<ReadonlyArray<Maybe<IRbiQuantityPlu>>>;
  readonly sizeBasedPlu: Maybe<IRbiSizeBasedPlu>;
}

export interface IRbiVendorConfigInput {
  readonly constantPlu?: InputMaybe<Scalars['String']['input']>;
  readonly discountPlu?: InputMaybe<Scalars['String']['input']>;
  readonly multiConstantPlus?: InputMaybe<ReadonlyArray<InputMaybe<IRbiQuantityPluInput>>>;
  readonly parentChildPlu?: InputMaybe<IRbiParentChildPluInput>;
  readonly parentSanityId?: InputMaybe<Scalars['String']['input']>;
  readonly pluType?: InputMaybe<Scalars['String']['input']>;
  readonly pullUpLevels?: InputMaybe<Scalars['Int']['input']>;
  readonly quantityBasedPlu?: InputMaybe<ReadonlyArray<InputMaybe<IRbiQuantityPluInput>>>;
  readonly sizeBasedPlu?: InputMaybe<IRbiSizeBasedPluInput>;
}

export interface IRbiVendorConfigs {
  readonly __typename?: 'RbiVendorConfigs';
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrols: Maybe<IRbiVendorConfig>;
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrolsDelivery: Maybe<IRbiVendorConfig>;
  /** HDX is not currently supported */
  readonly hdx: Maybe<IRbiVendorConfig>;
  readonly ncr: Maybe<IRbiVendorConfig>;
  readonly ncrDelivery: Maybe<IRbiVendorConfig>;
  /** Deprecated */
  readonly oheics: Maybe<IRbiVendorConfig>;
  /** Deprecated */
  readonly oheicsDelivery: Maybe<IRbiVendorConfig>;
  readonly partner: Maybe<IRbiVendorConfig>;
  readonly partnerDelivery: Maybe<IRbiVendorConfig>;
  readonly productNumber: Maybe<IRbiVendorConfig>;
  readonly productNumberDelivery: Maybe<IRbiVendorConfig>;
  readonly qdi: Maybe<IRbiVendorConfig>;
  readonly qdiDelivery: Maybe<IRbiVendorConfig>;
  /** QST is not currently supported */
  readonly qst: Maybe<IRbiVendorConfig>;
  readonly rpos: Maybe<IRbiVendorConfig>;
  readonly rposDelivery: Maybe<IRbiVendorConfig>;
  readonly sicom: Maybe<IRbiVendorConfig>;
  readonly sicomDelivery: Maybe<IRbiVendorConfig>;
  readonly tablet: Maybe<IRbiVendorConfig>;
  readonly tabletDelivery: Maybe<IRbiVendorConfig>;
  readonly toshibaLoyalty: Maybe<IRbiVendorConfig>;
}

export interface IRbiVendorConfigsInput {
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrols?: InputMaybe<IRbiVendorConfigInput>;
  /**
   * @Deprecated
   * Carrols is not currently supported
   */
  readonly carrolsDelivery?: InputMaybe<IRbiVendorConfigInput>;
  /** HDX is not currently supported */
  readonly hdx?: InputMaybe<IRbiVendorConfigInput>;
  readonly ncr?: InputMaybe<IRbiVendorConfigInput>;
  readonly ncrDelivery?: InputMaybe<IRbiVendorConfigInput>;
  /** Deprecated */
  readonly oheics?: InputMaybe<IRbiVendorConfigInput>;
  /** Deprecated */
  readonly oheicsDelivery?: InputMaybe<IRbiVendorConfigInput>;
  readonly partner?: InputMaybe<IRbiVendorConfigInput>;
  readonly partnerDelivery?: InputMaybe<IRbiVendorConfigInput>;
  readonly productNumber?: InputMaybe<IRbiVendorConfigInput>;
  readonly productNumberDelivery?: InputMaybe<IRbiVendorConfigInput>;
  readonly qdi?: InputMaybe<IRbiVendorConfigInput>;
  readonly qdiDelivery?: InputMaybe<IRbiVendorConfigInput>;
  /** QST is not currently supported */
  readonly qst?: InputMaybe<IRbiVendorConfigInput>;
  readonly rpos?: InputMaybe<IRbiVendorConfigInput>;
  readonly rposDelivery?: InputMaybe<IRbiVendorConfigInput>;
  readonly sicom?: InputMaybe<IRbiVendorConfigInput>;
  readonly sicomDelivery?: InputMaybe<IRbiVendorConfigInput>;
  readonly tablet?: InputMaybe<IRbiVendorConfigInput>;
  readonly tabletDelivery?: InputMaybe<IRbiVendorConfigInput>;
  readonly toshibaLoyalty?: InputMaybe<IRbiVendorConfigInput>;
}

export interface IRedeemedCouponResponse {
  readonly code: Scalars['String']['output'];
  readonly isRedeemed: Scalars['Boolean']['output'];
  readonly message: Maybe<Scalars['String']['output']>;
  readonly reason: Maybe<Scalars['String']['output']>;
}

export interface IRefund {
  readonly __typename?: 'Refund';
  /** Reason picked from a predefined dropdown list */
  readonly primaryReason: RefundReason;
  /** Items that were refunded. Undefined if order was fully refunded. */
  readonly refundedItems: Maybe<ReadonlyArray<Maybe<IRefundedItem>>>;
  /** Optional reason either added by a support agent or an user via the support form */
  readonly secondaryReason: Maybe<Scalars['String']['output']>;
  readonly subtotal: Scalars['String']['output'];
  readonly tax: Scalars['String']['output'];
  /** Date time when refund was issued */
  readonly transactionDateTime: Scalars['String']['output'];
}

export interface IRefundEligibilityResponse {
  readonly __typename?: 'RefundEligibilityResponse';
  readonly eligible: Scalars['Boolean']['output'];
  readonly ineligibilityReason: Maybe<RefundIneligibilityReason>;
}

export enum RefundIneligibilityReason {
  ALREADY_REFUNDED = 'ALREADY_REFUNDED',
  DISABLED = 'DISABLED',
  INVALID_ORDER_STATUS = 'INVALID_ORDER_STATUS',
  INVALID_PAYMENT_TYPE = 'INVALID_PAYMENT_TYPE',
  INVALID_USER_ID = 'INVALID_USER_ID',
  ORDER_BEING_DELIVERED = 'ORDER_BEING_DELIVERED',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  OVER_ALLOWED_REFUND_AMOUNT = 'OVER_ALLOWED_REFUND_AMOUNT',
  REFUNDED_MAX_TIMES = 'REFUNDED_MAX_TIMES',
}

export enum RefundReason {
  CATERING_ORDER_DECLINED = 'CATERING_ORDER_DECLINED',
  CATERING_ORDER_ERROR = 'CATERING_ORDER_ERROR',
  CATERING_ORDER_TIME_OUT = 'CATERING_ORDER_TIME_OUT',
  CUSTOMER_OVERCHARGED = 'CUSTOMER_OVERCHARGED',
  CUSTOMER_REQUESTED_CANCEL_ORDER = 'CUSTOMER_REQUESTED_CANCEL_ORDER',
  FOOD_QUALITY_ISSUE = 'FOOD_QUALITY_ISSUE',
  INCORRECT_CUSTOMIZATION = 'INCORRECT_CUSTOMIZATION',
  ITEMS_OUT_OF_STOCK = 'ITEMS_OUT_OF_STOCK',
  MISSING_OR_INCORRECT_ITEM = 'MISSING_OR_INCORRECT_ITEM',
  MOBILE_ORDERING_TECHNICAL_ISSUE = 'MOBILE_ORDERING_TECHNICAL_ISSUE',
  ORDER_ARRIVED_LATE = 'ORDER_ARRIVED_LATE',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_COMMIT_ERROR = 'ORDER_COMMIT_ERROR',
  ORDER_NEVER_ARRIVED = 'ORDER_NEVER_ARRIVED',
  ORDER_WAS_DAMAGED = 'ORDER_WAS_DAMAGED',
  OTHER_ADD_COMMENT = 'OTHER_ADD_COMMENT',
  RECEIVED_INCORRECT_ORDER = 'RECEIVED_INCORRECT_ORDER',
  RESTAURANT_CLOSED = 'RESTAURANT_CLOSED',
  RESTAURANT_COULDNT_FIND_ORDER = 'RESTAURANT_COULDNT_FIND_ORDER',
}

export interface IRefundRequestResponse {
  readonly __typename?: 'RefundRequestResponse';
  /** Requested refund amount in cents */
  readonly refundRequestAmountCents: Scalars['Int']['output'];
  /** Date time when refund was requested */
  readonly requestDateTime: Maybe<Scalars['String']['output']>;
}

export interface IRefundedItem {
  readonly __typename?: 'RefundedItem';
  /** Name of item refunded */
  readonly name: Maybe<Scalars['String']['output']>;
  /** Plu of item refunded */
  readonly plu: Maybe<Scalars['String']['output']>;
  /** Amount refunded in dollars for item */
  readonly refundAmount: Maybe<Scalars['String']['output']>;
  /** Quantity of item refunded */
  readonly refundQuantity: Maybe<Scalars['String']['output']>;
  /** SanityId of item refunded */
  readonly sanityId: Maybe<Scalars['String']['output']>;
  /** Type of item refunded */
  readonly type: Maybe<CartEntryType>;
}

export interface IReloadPrepaidAuthorized {
  readonly __typename?: 'ReloadPrepaidAuthorized';
  readonly prepaidDetails: IPrepaidDetails;
}

export interface IReloadPrepaidInput {
  /** Payment amount */
  readonly amount: Scalars['Int']['input'];
  /** Billing address if applicable */
  readonly billingAddress?: InputMaybe<IPaymentBillingAddressInput>;
  /** Payment currency */
  readonly currency: Scalars['String']['input'];
  /** Destination payment method ID of the prepaid card to reload */
  readonly destinationPaymentMethodId: Scalars['String']['input'];
  /** PSP formatted payment details (stringified) */
  readonly paymentMethod: Scalars['String']['input'];
  /** Platform used by user to purchase prepaid */
  readonly platform?: InputMaybe<Platform>;
  /** Fraud risk data (stringified) */
  readonly riskData: Scalars['String']['input'];
}

export type IReloadPrepaidPayload = IReloadPrepaidAuthorized | IReloadPrepaidRefused;

export interface IReloadPrepaidRefused {
  readonly __typename?: 'ReloadPrepaidRefused';
  readonly code: PurchasePrepaidRefusalCode;
}

export interface IRemoveDeliveryAddressInput {
  readonly addressLine1?: InputMaybe<Scalars['String']['input']>;
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly alias?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IRequiredAcceptanceAgreementInfoInput {
  readonly id: Scalars['String']['input'];
  readonly updatedAt: Scalars['String']['input'];
}

export interface IResendOtpInput {
  /**
   * User email. Only one of email or phone number should be provided
   * Example foobar@example.com
   */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /**
   * User phone number. Only one of email or phone number should be provided
   * Example 4258675309
   */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Requestor's platform */
  readonly platform: Platform;
}

export interface IRestaurant {
  readonly __typename?: 'Restaurant';
  /**
   * Boolean value for whether or not the restaurant is available
   * This is determined by the restaurant's heartbeat data stored in sanity
   */
  readonly available: Scalars['Boolean']['output'];
  /** Curbside operating hours */
  readonly curbsideHours: Maybe<IOperatingHours>;
  /** Day parts from sanity */
  readonly dayParts: Maybe<ReadonlyArray<Maybe<IDayPart>>>;
  /** Delivery operating hours */
  readonly deliveryHours: Maybe<IOperatingHours>;
  /** Dining room operating hours */
  readonly diningRoomHours: Maybe<IOperatingHours>;
  /** Drive through operating hours */
  readonly driveThruHours: Maybe<IOperatingHours>;
  /**
   * Contains information about the store service hours for a range of X consecutive days,
   * where X is specified in the rolling window param
   * starting from the day before the request is made.
   */
  readonly futureServiceHours: ReadonlyArray<IFutureServiceHours>;
  /**
   * DEPRECATED
   * Last record heartbeat timestamp on this restaurant
   * @deprecated Only for internal use
   */
  readonly lastHeartbeatTimestamp: Maybe<Scalars['String']['output']>;
  /** Pos Vendor associated with restaurant */
  readonly posVendor: Maybe<Scalars['String']['output']>;
  /**
   * The MDM number identifier. This is the `number` property on the sanity store obj
   * this is NOT the `_id` property
   */
  readonly storeId: Maybe<Scalars['String']['output']>;
  /** Restaurant Vat Number for BK UK */
  readonly vatNumber: Maybe<Scalars['String']['output']>;
}

export interface IRestaurantEdge extends IEdge {
  readonly __typename?: 'RestaurantEdge';
  readonly cursor: Scalars['String']['output'];
  readonly node: IRestaurantNode;
}

/** Extended restaurant data containing all fields from Sanity + Dynamo */
export interface IRestaurantNode extends INode {
  readonly __typename?: 'RestaurantNode';
  /** While pagination spec requires an id, Sanity and Frontend use _id */
  readonly _id: Maybe<Scalars['String']['output']>;
  readonly chaseMerchantId: Maybe<Scalars['String']['output']>;
  readonly curbsideHours: Maybe<IOperatingHours>;
  readonly cybersourceTransactingId: Maybe<Scalars['String']['output']>;
  readonly deliveryHours: Maybe<IOperatingHours>;
  readonly deliveryOrderAmountLimit: Maybe<IDeliveryOrderAmountLimit>;
  readonly diningRoomHours: Maybe<IOperatingHours>;
  readonly distanceInMiles: Maybe<Scalars['Int']['output']>;
  readonly drinkStationType: Maybe<Scalars['String']['output']>;
  readonly driveThruHours: Maybe<IOperatingHours>;
  readonly driveThruLaneType: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly environment: Maybe<RestaurantStatus>;
  readonly franchiseGroupId: Maybe<Scalars['Int']['output']>;
  readonly franchiseGroupName: Maybe<Scalars['String']['output']>;
  readonly frontCounterClosed: Maybe<Scalars['Boolean']['output']>;
  readonly hasBreakfast: Maybe<Scalars['Boolean']['output']>;
  readonly hasBurgersForBreakfast: Maybe<Scalars['Boolean']['output']>;
  readonly hasCatering: Maybe<Scalars['Boolean']['output']>;
  readonly hasCurbside: Maybe<Scalars['Boolean']['output']>;
  readonly hasDelivery: Maybe<Scalars['Boolean']['output']>;
  readonly hasDineIn: Maybe<Scalars['Boolean']['output']>;
  readonly hasDriveThru: Maybe<Scalars['Boolean']['output']>;
  readonly hasLateNightMenu: Maybe<Scalars['Boolean']['output']>;
  readonly hasLoyalty: Maybe<Scalars['Boolean']['output']>;
  readonly hasMobileOrdering: Maybe<Scalars['Boolean']['output']>;
  readonly hasParking: Maybe<Scalars['Boolean']['output']>;
  readonly hasPlayground: Maybe<Scalars['Boolean']['output']>;
  readonly hasTableService: Maybe<Scalars['Boolean']['output']>;
  readonly hasTakeOut: Maybe<Scalars['Boolean']['output']>;
  readonly hasWifi: Maybe<Scalars['Boolean']['output']>;
  /** Arbitrary unique identifier. */
  readonly id: Maybe<Scalars['ID']['output']>;
  /**
   * Boolean value for whether or not the restaurant is available
   * This is determined by the restaurant's heartbeat data stored in sanity
   */
  readonly isAvailable: Scalars['Boolean']['output'];
  readonly isDarkKitchen: Maybe<Scalars['Boolean']['output']>;
  readonly isFavorite: Scalars['Boolean']['output'];
  readonly isHalal: Maybe<Scalars['Boolean']['output']>;
  readonly isRecent: Scalars['Boolean']['output'];
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly mobileOrderingStatus: Maybe<OrderingStatus>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly number: Maybe<Scalars['String']['output']>;
  readonly parkingType: Maybe<Scalars['String']['output']>;
  readonly paymentMethods: Maybe<ReadonlyArray<Maybe<IPaymentMethods>>>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly physicalAddress: Maybe<IPhysicalAddress>;
  readonly playgroundType: Maybe<Scalars['String']['output']>;
  readonly pos: Maybe<IRestaurantPos>;
  readonly posRestaurantId: Maybe<Scalars['String']['output']>;
  /** Pos Vendor associated with restaurant */
  readonly posVendor: Maybe<Scalars['String']['output']>;
  readonly restaurantImage: Maybe<ISanityImage>;
  readonly restaurantPosData: Maybe<IRestaurantPosData>;
  readonly status: Maybe<Scalars['String']['output']>;
  /**
   * The MDM number identifier. This is the `number` property on the sanity store obj
   * this is NOT the `_id` property
   */
  readonly storeId: Maybe<Scalars['String']['output']>;
  readonly vatNumber: Maybe<Scalars['String']['output']>;
}

export interface IRestaurantPos {
  readonly __typename?: 'RestaurantPos';
  readonly vendor: Maybe<Scalars['String']['output']>;
}

export interface IRestaurantPosData {
  readonly __typename?: 'RestaurantPosData';
  readonly _id: Maybe<Scalars['String']['output']>;
}

export enum RestaurantStatus {
  DEV = 'DEV',
  PROD = 'PROD',
}

export interface IRestaurantTimeSlot {
  readonly __typename?: 'RestaurantTimeSlot';
  /** Store close time. */
  readonly closes: Scalars['String']['output'];
  /** Calendar day that this time slot reflects. */
  readonly date: Scalars['String']['output'];
  /** Flag to determine if the store is closed. */
  readonly isClosed: Maybe<Scalars['Boolean']['output']>;
  /** Store open time. */
  readonly opens: Scalars['String']['output'];
}

export interface IRestaurantsConnection extends IConnection {
  readonly __typename?: 'RestaurantsConnection';
  readonly edges: ReadonlyArray<IRestaurantEdge>;
  readonly nodes: ReadonlyArray<IRestaurantNode>;
  readonly pageInfo: IPageInfo;
  readonly totalCount: Maybe<Scalars['Int']['output']>;
}

export interface IRestaurantsInput {
  /**
   * An opaque cursor for pagination. Return results after provided cursor.
   * Used in combination with first field.
   */
  readonly after?: InputMaybe<Scalars['String']['input']>;
  /**
   * An opaque cursor for pagination. Return results before provided cursor.
   * Used in combination with last field.
   */
  readonly before?: InputMaybe<Scalars['String']['input']>;
  /** A coordinates object that sorts results based on location provided. */
  readonly coordinates?: InputMaybe<ICoordinates>;
  /**
   * Filter to a subset of stores
   * Defaults to NEARBY
   */
  readonly filter?: InputMaybe<FilterRestaurantType>;
  /**
   * Return first provided number of edges for pagination query.
   * Used in combination with after field.
   */
  readonly first?: InputMaybe<Scalars['Int']['input']>;
  /** A list of store IDs to filter to */
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  /**
   * Return last provided number of edges for pagination query.
   * Used in combination with before field.
   */
  readonly last?: InputMaybe<Scalars['Int']['input']>;
  /**
   * A flag to enable strict radius checking. When enabled, stores will be filtered
   * based on a circle radius. Any stores outside the radius are not included.
   */
  readonly radiusStrictMode?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * A list of service modes to search for.
   * Filters list to subset of stores that have one of the service modes available.
   */
  readonly serviceModes?: InputMaybe<ReadonlyArray<ServiceMode>>;
  /**
   * Restaurant operational status.
   * Filters list to subset of stores that have the provided status.
   */
  readonly status?: InputMaybe<OperationalStatus>;
}

export interface IResumeAddPaymentMethodInput {
  readonly challengeResponse: Scalars['String']['input'];
  readonly transactionId: Scalars['String']['input'];
}

/** Resume 3DS sale transaction input */
export interface IResumeThreeDSaleTransaction {
  /** saleId of the transaction */
  readonly saleId: Scalars['String']['input'];
  /** threeDSOptions to resume 3ds flow transaction */
  readonly threeDSOptions: IThreeDsOptions;
}

export interface IReward extends IBaseIncentive {
  readonly __typename?: 'Reward';
  readonly cartRequirements: Maybe<ReadonlyArray<Maybe<ReadonlyArray<Maybe<ICartRequirement>>>>>;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<IRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<IExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly limitPerOrder: Maybe<Scalars['Int']['output']>;
  readonly locked: Maybe<Scalars['Boolean']['output']>;
  readonly maxRedemptionsDuration: Maybe<DurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly metadata: Maybe<ReadonlyArray<Maybe<IObjectType>>>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly minimumTotalSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<PaymentMethod>;
  readonly pointCost: Scalars['Int']['output'];
  readonly redemptionPercentage: Maybe<Scalars['Int']['output']>;
  readonly remainingPointsNeededForRedemption: Maybe<Scalars['Int']['output']>;
  readonly requiredLoyaltyTier: Maybe<LoyaltyTierKey>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly rewardBenefitId: Scalars['String']['output'];
  readonly rewardPrice: Maybe<Scalars['Int']['output']>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<ServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<IStoreRestrictions>;
  readonly type: RewardType;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<BirthdayDuration>;
  readonly userEnrollment: Maybe<IDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<DayOfTheWeek>>;
}

export enum RewardType {
  GLOBAL = 'GLOBAL',
  PERSONALIZED = 'PERSONALIZED',
}

export interface IRewardsInput {
  readonly appliedIncentives?: InputMaybe<ReadonlyArray<IPromotionInput>>;
  readonly cartEntries?: InputMaybe<ReadonlyArray<IRbiCartEntryInput>>;
  readonly configId?: InputMaybe<Scalars['ID']['input']>;
  readonly ignorePointBalance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly ignoreRules?: InputMaybe<Scalars['Boolean']['input']>;
  readonly incentiveIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly isTransactionCreation?: InputMaybe<Scalars['Boolean']['input']>;
  readonly omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  readonly paymentMethod?: InputMaybe<PaymentMethod>;
  readonly rewardIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly serviceMode?: InputMaybe<ServiceMode>;
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  readonly subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  readonly totalAmount?: InputMaybe<Scalars['Int']['input']>;
}

export interface IRuleConditionEvaluation {
  readonly __typename?: 'RuleConditionEvaluation';
  readonly code: Scalars['String']['output'];
  readonly currentValue: Maybe<Scalars['JSON']['output']>;
  readonly message: Maybe<Scalars['String']['output']>;
  readonly ruleId: Scalars['String']['output'];
  readonly targetValue: Maybe<Scalars['JSON']['output']>;
}

export interface ISanityImage {
  readonly __typename?: 'SanityImage';
  readonly asset: Maybe<ISanityImageAsset>;
  readonly crop: Maybe<ISanityImageCrop>;
  readonly hotspot: Maybe<ISanityImageHotspot>;
}

export interface ISanityImageAsset {
  readonly __typename?: 'SanityImageAsset';
  readonly _id: Maybe<Scalars['String']['output']>;
  readonly metadata: Maybe<ISanityImageMetaData>;
}

export interface ISanityImageCrop {
  readonly __typename?: 'SanityImageCrop';
  readonly bottom: Maybe<Scalars['Float']['output']>;
  readonly left: Maybe<Scalars['Float']['output']>;
  readonly right: Maybe<Scalars['Float']['output']>;
  readonly top: Maybe<Scalars['Float']['output']>;
}

export interface ISanityImageHotspot {
  readonly __typename?: 'SanityImageHotspot';
  readonly height: Maybe<Scalars['Float']['output']>;
  readonly width: Maybe<Scalars['Float']['output']>;
  readonly x: Maybe<Scalars['Float']['output']>;
  readonly y: Maybe<Scalars['Float']['output']>;
}

export interface ISanityImageMetaData {
  readonly __typename?: 'SanityImageMetaData';
  readonly lqip: Maybe<Scalars['String']['output']>;
  readonly palette: Maybe<ISanityImagePalette>;
}

export interface ISanityImagePalette {
  readonly __typename?: 'SanityImagePalette';
  readonly dominant: Maybe<ISanityImagePaletteSwatch>;
}

export interface ISanityImagePaletteSwatch {
  readonly __typename?: 'SanityImagePaletteSwatch';
  readonly background: Maybe<Scalars['String']['output']>;
  readonly foreground: Maybe<Scalars['String']['output']>;
}

export interface ISaveDeliveryAddressInput {
  readonly addressLine1: Scalars['String']['input'];
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly alias?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  readonly latitude?: InputMaybe<Scalars['Float']['input']>;
  readonly longitude?: InputMaybe<Scalars['Float']['input']>;
  readonly phoneNumber: Scalars['String']['input'];
  readonly route?: InputMaybe<Scalars['String']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber?: InputMaybe<Scalars['String']['input']>;
  readonly zip?: InputMaybe<Scalars['String']['input']>;
}

export interface ISchemeStoredPaymentMethod extends IStoredPaymentMethod {
  readonly __typename?: 'SchemeStoredPaymentMethod';
  /** Card Brand. Ex: Visa */
  readonly brand: CardBrand;
  /** Card expiration month. Ex: 04 */
  readonly expiryMonth: Scalars['String']['output'];
  /** Card expiration year. Ex: 2030 */
  readonly expiryYear: Scalars['String']['output'];
  /** Stored payment method id */
  readonly id: Scalars['ID']['output'];
  /** Last 4 digits of card number */
  readonly last4: Scalars['String']['output'];
  /** Card pan token */
  readonly panToken: Maybe<Scalars['String']['output']>;
  /** Type of payment method */
  readonly type: PaymentMethodType;
}

export interface ISecondaryIdentifier {
  readonly __typename?: 'SecondaryIdentifier';
  readonly createdAt: Maybe<Scalars['String']['output']>;
  readonly identifier: Scalars['String']['output'];
  readonly identifierType: SecondaryIdentifierType;
  readonly loyaltyId: Maybe<Scalars['ID']['output']>;
  readonly verified: Maybe<Scalars['Boolean']['output']>;
}

export enum SecondaryIdentifierType {
  INVITATIONCODE = 'INVITATIONCODE',
  LOYALTY12DIGITSWIPECARD = 'LOYALTY12DIGITSWIPECARD',
  LOYALTYSWIPECARD = 'LOYALTYSWIPECARD',
  PANTOKEN = 'PANTOKEN',
  PHONENUMBER = 'PHONENUMBER',
}

export enum ServiceMode {
  CATERING_DELIVERY = 'CATERING_DELIVERY',
  CATERING_PICKUP = 'CATERING_PICKUP',
  CURBSIDE = 'CURBSIDE',
  DELIVERY = 'DELIVERY',
  DRIVE_THRU = 'DRIVE_THRU',
  EAT_IN = 'EAT_IN',
  TABLE_SERVICE = 'TABLE_SERVICE',
  TAKEOUT = 'TAKEOUT',
}

export interface ISignInUserInput {
  readonly email: Scalars['String']['input'];
  readonly platform: Platform;
  readonly stage: Stage;
}

export interface ISignUpUserInput {
  readonly country: Scalars['String']['input'];
  readonly dob?: InputMaybe<Scalars['String']['input']>;
  readonly favoriteStores?: InputMaybe<ReadonlyArray<IFavoriteStoreInput>>;
  readonly gender?: InputMaybe<Gender>;
  readonly name: Scalars['String']['input'];
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly platform: Platform;
  readonly providerType?: InputMaybe<ProviderType>;
  readonly referralCode?: InputMaybe<Scalars['String']['input']>;
  readonly requiredAcceptanceAgreementInfo?: InputMaybe<
    ReadonlyArray<IRequiredAcceptanceAgreementInfoInput>
  >;
  readonly stage: Stage;
  readonly userName: Scalars['String']['input'];
  readonly wantsPromotionalEmails: Scalars['Boolean']['input'];
  readonly wantsPromotionalSms?: InputMaybe<Scalars['Boolean']['input']>;
  readonly zipcode?: InputMaybe<Scalars['String']['input']>;
}

export interface ISizeBasedPlu {
  readonly __typename?: 'SizeBasedPlu';
  readonly comboPlu: Maybe<Scalars['String']['output']>;
  readonly comboSize: Maybe<Scalars['String']['output']>;
}

export interface ISizeBasedPluInput {
  readonly comboPlu?: InputMaybe<Scalars['String']['input']>;
  readonly comboSize?: InputMaybe<Scalars['String']['input']>;
}

export enum SmgCodeStatus {
  ACCEPTED = 'ACCEPTED',
  CODE_EXISTS = 'CODE_EXISTS',
  EXPIRED = 'EXPIRED',
  INVALID_FORMAT = 'INVALID_FORMAT',
  INVALID_RESTAURANT = 'INVALID_RESTAURANT',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
  NOT_FOUND = 'NOT_FOUND',
  RETRY_REQUESTED = 'RETRY_REQUESTED',
  THROTTLING_ERROR = 'THROTTLING_ERROR',
  TRANSACTION_CLAIMED = 'TRANSACTION_CLAIMED',
}

export interface ISocialLoginInput {
  readonly providerEmail?: InputMaybe<Scalars['String']['input']>;
  readonly providerToken: Scalars['String']['input'];
  readonly providerType: ProviderType;
}

export enum Stage {
  ALPHA = 'alpha',
  BETA = 'beta',
  DEV = 'dev',
  LOCALDEV = 'localdev',
  PROD = 'prod',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
  TEST = 'test',
}

export interface IStoreAddress {
  readonly __typename?: 'StoreAddress';
  readonly addressLine1: Scalars['String']['output'];
  readonly addressLine2: Maybe<Scalars['String']['output']>;
  readonly city: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly phoneNumber: Scalars['String']['output'];
  readonly state: Scalars['String']['output'];
  readonly zip: Scalars['String']['output'];
}

export interface IStoreAddressInput {
  readonly addressLine1: Scalars['String']['input'];
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly country: Scalars['String']['input'];
  readonly phoneNumber: Scalars['String']['input'];
  readonly state: Scalars['String']['input'];
  readonly zip: Scalars['String']['input'];
}

export interface IStoreDetails {
  readonly __typename?: 'StoreDetails';
  readonly deliveryHours: Maybe<IStoreHours>;
  readonly diningRoomHours: Maybe<IStoreHours>;
  readonly driveThruHours: Maybe<IStoreHours>;
  readonly franchiseGroupId: Maybe<Scalars['Int']['output']>;
  readonly franchiseGroupName: Maybe<Scalars['String']['output']>;
  readonly hasCatering: Maybe<Scalars['Boolean']['output']>;
  readonly hasDelivery: Maybe<Scalars['Boolean']['output']>;
  readonly hasDriveThru: Maybe<Scalars['Boolean']['output']>;
  readonly hasMobileOrdering: Maybe<Scalars['Boolean']['output']>;
  /** Last date time when the store made an order */
  readonly lastActiveDateTime: Maybe<Scalars['String']['output']>;
  readonly latitude: Maybe<Scalars['Float']['output']>;
  readonly longitude: Maybe<Scalars['Float']['output']>;
  readonly mobileOrderingStatus: Maybe<Scalars['String']['output']>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly posVendor: Maybe<Scalars['String']['output']>;
  readonly status: Maybe<Scalars['String']['output']>;
  readonly storeNumber: Maybe<Scalars['String']['output']>;
}

export interface IStoreHours {
  readonly __typename?: 'StoreHours';
  readonly friday: Maybe<IDay>;
  readonly monday: Maybe<IDay>;
  readonly saturday: Maybe<IDay>;
  readonly sunday: Maybe<IDay>;
  readonly thursday: Maybe<IDay>;
  readonly tuesday: Maybe<IDay>;
  readonly wednesday: Maybe<IDay>;
}

export interface IStoreRestrictions {
  readonly __typename?: 'StoreRestrictions';
  readonly ids: ReadonlyArray<Maybe<Scalars['String']['output']>>;
  readonly inclusive: Scalars['Boolean']['output'];
}

export interface IStoredPaymentMethod {
  /** Stored payment method id */
  readonly id: Scalars['ID']['output'];
  /** Type of payment method */
  readonly type: PaymentMethodType;
}

export interface ISupportCase {
  readonly __typename?: 'SupportCase';
  /** Identifier for the case. Useful for the user to have */
  readonly caseId: Maybe<Scalars['String']['output']>;
}

export interface ISupportCaseInput {
  /** Description of the issue the user is having */
  readonly comments: Scalars['String']['input'];
  /** User's email */
  readonly email: Scalars['String']['input'];
  /** Id corresponding to the category of the issue. These are set in Sanity */
  readonly issueId: Scalars['String']['input'];
  /** Name of subcategory of the issue.  These are set in Sanity */
  readonly issueSubCategory?: InputMaybe<Scalars['String']['input']>;
  /** The local time the order associated with the issue was placed */
  readonly localStoreOrderTime?: InputMaybe<Scalars['String']['input']>;
  /** User's name */
  readonly name: Scalars['String']['input'];
  /** The time and date the order associated with the issue was placed */
  readonly orderDateTime?: InputMaybe<Scalars['String']['input']>;
  /** Unique order identifier in RBI */
  readonly rbiOrderId?: InputMaybe<Scalars['String']['input']>;
  /** Service mode of the order associated with the issue */
  readonly serviceMode?: InputMaybe<Scalars['String']['input']>;
  /** Address of the store associated with the issue */
  readonly storeAddress?: InputMaybe<Scalars['String']['input']>;
  /** Store number from MDS */
  readonly storeId?: InputMaybe<Scalars['String']['input']>;
  /** Region user signed up in (account region) */
  readonly userAccountRegion?: InputMaybe<Scalars['String']['input']>;
  /** User's region */
  readonly userRegion?: InputMaybe<Scalars['String']['input']>;
}

export interface ISwap {
  readonly __typename?: 'Swap';
  readonly from: Scalars['String']['output'];
  readonly to: Scalars['String']['output'];
  readonly type: SwapType;
}

export interface ISwapInput {
  readonly cartId: Scalars['String']['input'];
  readonly from: Scalars['String']['input'];
  readonly swapType: Scalars['String']['input'];
  readonly to: Scalars['String']['input'];
}

export enum SwapType {
  UPSIZE = 'UPSIZE',
}

export interface ISystemWidePromotion extends IBaseIncentive {
  readonly __typename?: 'SystemWidePromotion';
  readonly bonusPoints: Maybe<Scalars['Int']['output']>;
  readonly cartRequirements: Maybe<ReadonlyArray<Maybe<ReadonlyArray<Maybe<ICartRequirement>>>>>;
  readonly coolDown: Maybe<Scalars['Int']['output']>;
  readonly createdAt: Scalars['String']['output'];
  readonly endDate: Scalars['String']['output'];
  readonly endTime: Maybe<Scalars['String']['output']>;
  readonly errors: Maybe<ReadonlyArray<IRuleConditionEvaluation>>;
  readonly externalIdentifiers: Maybe<ReadonlyArray<Maybe<IExternalIdentifiers>>>;
  readonly firstOrder: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly maxRedemptionsDuration: Maybe<DurationPeriod>;
  readonly maxRedemptionsLimit: Maybe<Scalars['Int']['output']>;
  readonly metadata: Maybe<Scalars['JSON']['output']>;
  readonly minimumSpend: Maybe<Scalars['Int']['output']>;
  readonly name: Scalars['String']['output'];
  readonly paymentMethod: Maybe<PaymentMethod>;
  readonly pointMultiplier: Maybe<Scalars['Int']['output']>;
  readonly restaurantGroups: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly sanityId: Maybe<Scalars['String']['output']>;
  readonly serviceModes: Maybe<ReadonlyArray<Maybe<ServiceMode>>>;
  readonly startDate: Scalars['String']['output'];
  readonly startTime: Maybe<Scalars['String']['output']>;
  readonly storeRestrictions: Maybe<IStoreRestrictions>;
  readonly updatedAt: Scalars['String']['output'];
  readonly userBirthdayDuration: Maybe<BirthdayDuration>;
  readonly userEnrollment: Maybe<IDateSpan>;
  readonly validDaysOfTheWeek: Maybe<ReadonlyArray<DayOfTheWeek>>;
}

export interface IThreeDsOptions {
  /** challenge response obtained in challengeCallbackUrl to resume challenge flow */
  readonly challengeResponse?: InputMaybe<Scalars['String']['input']>;
  /** transactionId to resume method or challenge flow */
  readonly transactionId: Scalars['String']['input'];
}

export interface IThreeDsChallenge {
  readonly __typename?: 'ThreeDsChallenge';
  readonly acsUrl: Scalars['String']['output'];
  readonly challengeRequestToken: Scalars['String']['output'];
  readonly transactionId: Scalars['String']['output'];
}

export interface IThreeDsFrictionless {
  readonly __typename?: 'ThreeDsFrictionless';
  readonly iFrameContent: Scalars['String']['output'];
  readonly transactionId: Scalars['String']['output'];
}

export interface ITransaction {
  readonly __typename?: 'Transaction';
  readonly additionalReferenceIds: Maybe<ReadonlyArray<ITransactionAdditionalReferenceId>>;
  readonly bonusPointsEarned: Scalars['Int']['output'];
  readonly channel: Scalars['String']['output'];
  readonly createdAt: Scalars['String']['output'];
  readonly dateUpdated: Maybe<Scalars['String']['output']>;
  readonly hasRewards: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly loyaltyId: Maybe<Scalars['String']['output']>;
  readonly maxTransactionLimitReached: Scalars['Boolean']['output'];
  readonly pointsDeducted: Maybe<Scalars['Int']['output']>;
  readonly pointsEarned: Scalars['Int']['output'];
  readonly pointsUsed: Scalars['Int']['output'];
  readonly shortId: Scalars['String']['output'];
  readonly status: TransactionStatus;
  readonly transactionDetails: Maybe<ITransactionDetails>;
}

export interface ITransactionAdditionalReferenceId {
  readonly __typename?: 'TransactionAdditionalReferenceId';
  readonly description: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
}

export interface ITransactionDetails {
  readonly __typename?: 'TransactionDetails';
  readonly currency: Scalars['String']['output'];
  readonly fulfillment: ITransactionFulfillment;
  readonly language: Maybe<Language>;
  readonly orders: ReadonlyArray<ITransactionOrder>;
  readonly payments: ReadonlyArray<ITransactionPayment>;
  readonly person: Maybe<IPerson>;
  readonly pickupContact: Maybe<IPickupContact>;
  readonly posVendor: Maybe<ITransactionPosVendor>;
  readonly promotions: Maybe<ReadonlyArray<IPromotion>>;
}

export interface ITransactionFulfillment {
  readonly __typename?: 'TransactionFulfillment';
  readonly autoRelease: Maybe<Scalars['Boolean']['output']>;
  readonly type: Scalars['String']['output'];
}

export interface ITransactionOrder {
  readonly __typename?: 'TransactionOrder';
  readonly parentReferenceId: Maybe<Scalars['String']['output']>;
  readonly price: Scalars['Float']['output'];
  readonly productId: Maybe<Scalars['String']['output']>;
  readonly quantity: Scalars['Int']['output'];
}

export interface ITransactionPayment {
  readonly __typename?: 'TransactionPayment';
  readonly amount: Scalars['Float']['output'];
  readonly description: Maybe<Scalars['String']['output']>;
  readonly expiration: Maybe<IPaymentExpiration>;
  readonly token: Maybe<Scalars['String']['output']>;
  readonly type: Scalars['String']['output'];
}

export interface ITransactionPosVendor {
  readonly __typename?: 'TransactionPosVendor';
  readonly id: Maybe<Scalars['String']['output']>;
  readonly transactionId: Scalars['String']['output'];
  readonly type: Scalars['String']['output'];
}

export enum TransactionStatus {
  CLAIMED = 'CLAIMED',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  UNCLAIMED = 'UNCLAIMED',
  VOID = 'VOID',
}

export interface ITransactionsInput {
  readonly excludedChannels?: InputMaybe<ReadonlyArray<RbiChannel>>;
  readonly ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly statuses?: InputMaybe<ReadonlyArray<TransactionStatus>>;
}

export interface IUnavailableCartEntry {
  readonly __typename?: 'UnavailableCartEntry';
  readonly lineId: Maybe<Scalars['String']['output']>;
  readonly sanityId: Scalars['String']['output'];
}

export interface IUpdateDefaultPaymentMethodInput {
  readonly storedPaymentMethodId: Scalars['String']['input'];
}

export interface IUpdateDefaultPaymentMethodPayload {
  readonly __typename?: 'UpdateDefaultPaymentMethodPayload';
  readonly defaultPaymentMethodId: Scalars['String']['output'];
}

export interface IUpdateDeliveryAddressInput {
  readonly addressLine1?: InputMaybe<Scalars['String']['input']>;
  readonly addressLine2?: InputMaybe<Scalars['String']['input']>;
  readonly alias: Scalars['String']['input'];
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly deliveryInstructions?: InputMaybe<Scalars['String']['input']>;
  readonly latitude?: InputMaybe<Scalars['Float']['input']>;
  readonly longitude?: InputMaybe<Scalars['Float']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly route?: InputMaybe<Scalars['String']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber?: InputMaybe<Scalars['String']['input']>;
  readonly zip?: InputMaybe<Scalars['String']['input']>;
}

export interface IUpdateOrderInput {
  /** number of seconds from now to inject order */
  readonly fireOrderIn: Scalars['Int']['input'];
  readonly rbiOrderId: Scalars['ID']['input'];
}

export interface IUpdateUserAttributesEventInput {
  readonly age?: InputMaybe<Scalars['Int']['input']>;
  readonly androidLocationPermissions?: InputMaybe<Scalars['String']['input']>;
  readonly batteryLevel?: InputMaybe<Scalars['Int']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly currentBuild?: InputMaybe<Scalars['String']['input']>;
  readonly currentScreen?: InputMaybe<Scalars['String']['input']>;
  readonly customerId?: InputMaybe<Scalars['String']['input']>;
  readonly dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  readonly dob?: InputMaybe<Scalars['String']['input']>;
  readonly emailOptIn?: InputMaybe<Scalars['String']['input']>;
  readonly emailSubscribe?: InputMaybe<Scalars['String']['input']>;
  readonly enableFlavorFlow?: InputMaybe<Scalars['String']['input']>;
  readonly favoriteOffers?: InputMaybe<Scalars['String']['input']>;
  readonly favoriteStores?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly iosLocationPermissions?: InputMaybe<Scalars['String']['input']>;
  readonly isGuestUser?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isSmallScreen?: InputMaybe<Scalars['String']['input']>;
  readonly joinDate?: InputMaybe<Scalars['String']['input']>;
  readonly language?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly layer?: InputMaybe<Scalars['String']['input']>;
  readonly legacyUser?: InputMaybe<Scalars['String']['input']>;
  readonly locale?: InputMaybe<Scalars['String']['input']>;
  readonly locationServices?: InputMaybe<Scalars['String']['input']>;
  readonly loyalty?: InputMaybe<Scalars['String']['input']>;
  readonly marketingEmail?: InputMaybe<Scalars['String']['input']>;
  readonly marketingPush?: InputMaybe<Scalars['String']['input']>;
  readonly mediaServicesPreferences?: InputMaybe<Scalars['String']['input']>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
  readonly orderStatus?: InputMaybe<Scalars['String']['input']>;
  readonly paybackUserId?: InputMaybe<Scalars['String']['input']>;
  readonly pickupMode?: InputMaybe<Scalars['String']['input']>;
  readonly promotionalEmails?: InputMaybe<Scalars['String']['input']>;
  readonly pushSubscribe?: InputMaybe<Scalars['String']['input']>;
  readonly rbiCognitoId?: InputMaybe<Scalars['String']['input']>;
  readonly rewardsEmail?: InputMaybe<Scalars['String']['input']>;
  readonly rewardsPush?: InputMaybe<Scalars['String']['input']>;
  readonly serviceMode?: InputMaybe<Scalars['String']['input']>;
  readonly sizePreference?: InputMaybe<Scalars['String']['input']>;
  readonly smsSubscribe?: InputMaybe<Scalars['String']['input']>;
  readonly snackPreference?: InputMaybe<Scalars['String']['input']>;
  readonly sourcePage?: InputMaybe<Scalars['String']['input']>;
  readonly state?: InputMaybe<Scalars['String']['input']>;
  readonly tier?: InputMaybe<Scalars['String']['input']>;
  readonly timePreference?: InputMaybe<Scalars['String']['input']>;
  readonly timezone?: InputMaybe<Scalars['String']['input']>;
  readonly typePreference?: InputMaybe<Scalars['String']['input']>;
  readonly utmCampaign?: InputMaybe<Scalars['String']['input']>;
  readonly utmContent?: InputMaybe<Scalars['String']['input']>;
  readonly utmMedium?: InputMaybe<Scalars['String']['input']>;
  readonly utmSource?: InputMaybe<Scalars['String']['input']>;
  readonly utmTerm?: InputMaybe<Scalars['String']['input']>;
  readonly zip?: InputMaybe<Scalars['String']['input']>;
}

export interface IUpdateWaitTimeResult {
  readonly __typename?: 'UpdateWaitTimeResult';
  readonly code: Scalars['String']['output'];
  readonly message: Maybe<Scalars['String']['output']>;
  readonly storeId: Scalars['String']['output'];
}

export interface IUser {
  readonly __typename?: 'User';
  readonly bestPromotion: Maybe<ISystemWidePromotion>;
  readonly createdAt: Scalars['String']['output'];
  readonly dateOfBirth: Maybe<Scalars['String']['output']>;
  readonly email: Maybe<Scalars['String']['output']>;
  /**
   * DEPRECATED
   * Incentive evaluation errors are now provided as part of the incentive data.
   */
  readonly evaluatedIncentives: IEvaluatedIncentives;
  readonly expirationBuckets: Maybe<ReadonlyArray<IExpirationBucket>>;
  readonly id: Scalars['ID']['output'];
  readonly loyaltyTier: Maybe<ILoyaltyTier>;
  readonly metadata: Maybe<ReadonlyArray<Maybe<IObjectType>>>;
  readonly name: Maybe<Scalars['String']['output']>;
  readonly offerRedemptionAvailability: Maybe<IOfferRedemptionAvailability>;
  /**
   * DEPRECATED
   * This field is replaced by field offerRedemptionAvailability
   */
  readonly offerRedemptionAvailableAfter: Maybe<Scalars['String']['output']>;
  readonly offers: Maybe<ReadonlyArray<IOffer>>;
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  readonly points: Scalars['Int']['output'];
  readonly pointsExpiryDateKey: Maybe<Scalars['String']['output']>;
  readonly promotions: Maybe<ReadonlyArray<ISystemWidePromotion>>;
  readonly quests: Maybe<ReadonlyArray<IQuest>>;
  readonly rewards: Maybe<ReadonlyArray<IReward>>;
  readonly secondaryIdentifiers: Maybe<ReadonlyArray<Maybe<ISecondaryIdentifier>>>;
  readonly transactions: Maybe<ReadonlyArray<ITransaction>>;
}

export interface IUserBestPromotionArgs {
  where?: InputMaybe<IPromotionsInput>;
}

export interface IUserEvaluatedIncentivesArgs {
  where: IEvaluateIncentivesInput;
}

export interface IUserOffersArgs {
  where?: InputMaybe<IOffersInput>;
}

export interface IUserPromotionsArgs {
  where?: InputMaybe<IPromotionsInput>;
}

export interface IUserRewardsArgs {
  where?: InputMaybe<IRewardsInput>;
}

export interface IUserTransactionsArgs {
  where?: InputMaybe<ITransactionsInput>;
}

export interface IUserDetails {
  readonly __typename?: 'UserDetails';
  /** Giftcard can be auto reloaded */
  readonly autoReloadEnabled: Maybe<Scalars['Boolean']['output']>;
  /** Amount in cents that balance must reach for auto reload */
  readonly autoReloadThreshold: Maybe<Scalars['Int']['output']>;
  /** Array of Communication Preferences */
  readonly communicationPreferences: Maybe<ReadonlyArray<Maybe<ICommunicationPreference>>>;
  /** Account (Adyen or First-Data) ID of auto reload default payment method */
  readonly defaultAccountIdentifier: Maybe<Scalars['String']['output']>;
  /**
   * First Data ID of auto reload default payment method
   * @deprecated Use `defaultAccountIdentifier`.
   */
  readonly defaultFdAccountId: Maybe<Scalars['String']['output']>;
  /** Adyen or First Data ID of default payment method */
  readonly defaultPaymentAccountId: Maybe<Scalars['String']['output']>;
  /** Reload amount to default to for user's gift cards */
  readonly defaultReloadAmt: Maybe<Scalars['Int']['output']>;
  /** Default Account Id for Scan & Pay */
  readonly defaultScanAndPayAccountIdentifier: Maybe<Scalars['String']['output']>;
  /**
   * User Adresses and phone number combinations to be used to alllow users
   * to select from multiple saced addresses
   */
  readonly deliveryAddresses: Maybe<ReadonlyArray<Maybe<IDeliveryAddressFulfilment>>>;
  /** Date of birth (format TBD) */
  readonly dob: Maybe<Scalars['String']['output']>;
  /** User's email */
  readonly email: Maybe<Scalars['String']['output']>;
  /** Whether the user has verified the email */
  readonly emailVerified: Maybe<Scalars['Boolean']['output']>;
  /** User's saved list of favorite stores */
  readonly favoriteStores: Maybe<ReadonlyArray<Maybe<IFavoriteStore>>>;
  /** User's Country code in ISO 3166 alpha-3 format, e.g. GBR, DEU, CHE, NZL. */
  readonly isoCountryCode: Maybe<Scalars['String']['output']>;
  /** User's selected loyalty tier */
  readonly loyaltyTier: Maybe<Scalars['String']['output']>;
  /** User's name */
  readonly name: Maybe<Scalars['String']['output']>;
  /** User's phone number */
  readonly phoneNumber: Maybe<Scalars['String']['output']>;
  /** Whether the user has opted in to promotional emails */
  readonly promotionalEmails: Maybe<Scalars['Boolean']['output']>;
  /** User's ZIP Code */
  readonly zipcode: Maybe<Scalars['String']['output']>;
}

export interface IUserGeo {
  readonly __typename?: 'UserGeo';
  readonly city: Maybe<Scalars['String']['output']>;
  readonly country: Maybe<Scalars['String']['output']>;
  readonly state: Maybe<Scalars['String']['output']>;
}

export interface IUserOrdersInput {
  readonly createdAfter?: InputMaybe<Scalars['String']['input']>;
  readonly createdBefore?: InputMaybe<Scalars['String']['input']>;
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus>>;
  readonly resumeAfter?: InputMaybe<Scalars['String']['input']>;
  readonly shouldFilterByRegion?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sortAscending?: InputMaybe<Scalars['Boolean']['input']>;
  /** Deprecated. This field will be ignored. */
  readonly userId?: InputMaybe<Scalars['ID']['input']>;
}

export interface IUserPaymentDetails {
  /** Represents the email of the user who owns the requested payment */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** Represents the phone number of the user who owns the requested payment */
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
}

export interface IUserReportsMissingOrderInput {
  /** Id corresponding to the category of the issue. These are set in Sanity */
  readonly issueId?: InputMaybe<Scalars['String']['input']>;
  /** Name of subcategory of the issue.  These are set in Sanity */
  readonly issueSubCategory?: InputMaybe<Scalars['String']['input']>;
  /** List of reported missing items. Undefined if entire order is missing */
  readonly missingItems?: InputMaybe<ReadonlyArray<InputMaybe<IMissingItem>>>;
  /** Order ID that the user is reporting */
  readonly rbiOrderId: Scalars['String']['input'];
  /** Optional user comment as part of the report */
  readonly userComment?: InputMaybe<Scalars['String']['input']>;
}

export interface IUserRequestsRefundInput {
  /** Id corresponding to the category of the issue. These are set in Sanity */
  readonly issueId?: InputMaybe<Scalars['String']['input']>;
  /** Name of subcategory of the issue.  These are set in Sanity */
  readonly issueSubCategory?: InputMaybe<Scalars['String']['input']>;
  /** List of reported missing items. Undefined if entire order is missing */
  readonly missingItems?: InputMaybe<ReadonlyArray<InputMaybe<IMissingItem>>>;
  /** Order ID that the user is reporting */
  readonly rbiOrderId: Scalars['String']['input'];
  /** Optional user comment as part of the report */
  readonly userComment?: InputMaybe<Scalars['String']['input']>;
}

export interface IValidateCommitOrderInput {
  /** Delivery information */
  readonly delivery?: InputMaybe<ICommitDeliveryInput>;
  /** Takes in a time in seconds to delay the order commit */
  readonly fireOrderIn?: InputMaybe<Scalars['Int']['input']>;
  /** ID for a previous order that has been priced */
  readonly rbiOrderId: Scalars['String']['input'];
  /**
   * The moment of redemption - the user's local date+time - in ISO format.
   * Only ISO-formatted timestamp values of the format "YYYY-MM-DDTHH:mm:ss.SSSZ" are accepted.
   */
  readonly redeemedOn?: InputMaybe<Scalars['String']['input']>;
  /** Whether to skip cooling period or not */
  readonly skipCoolingPeriod?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface IValidateGuestOtpInput {
  /** The code associated with the user's current OTP, for validation */
  readonly code: Scalars['String']['input'];
  /** The email of the user requesting a One-Time Password */
  readonly email: Scalars['String']['input'];
  /** The unique identifier provided when the OTP was created */
  readonly sessionId: Scalars['ID']['input'];
}

export interface IVendor {
  readonly __typename?: 'Vendor';
  readonly vendor: Maybe<Scalars['String']['output']>;
}

export interface IVendorConfig {
  readonly __typename?: 'VendorConfig';
  readonly constantPlu: Maybe<Scalars['String']['output']>;
  readonly discountPlu: Maybe<Scalars['String']['output']>;
  readonly multiConstantPlus: Maybe<ReadonlyArray<Maybe<IQuantityPlu>>>;
  readonly parentChildPlu: Maybe<IParentChildPlu>;
  readonly parentSanityId: Maybe<Scalars['String']['output']>;
  readonly pluType: Scalars['String']['output'];
  readonly pullUpLevels: Maybe<Scalars['Int']['output']>;
  readonly quantityBasedPlu: Maybe<ReadonlyArray<Maybe<IQuantityPlu>>>;
  readonly sizeBasedPlu: Maybe<ISizeBasedPlu>;
}

export interface IVendorConfigInput {
  readonly constantPlu?: InputMaybe<Scalars['String']['input']>;
  readonly discountPlu?: InputMaybe<Scalars['String']['input']>;
  readonly multiConstantPlus?: InputMaybe<ReadonlyArray<InputMaybe<IQuantityPluInput>>>;
  readonly parentChildPlu?: InputMaybe<IParentChildPluInput>;
  readonly parentSanityId?: InputMaybe<Scalars['String']['input']>;
  readonly pluType: Scalars['String']['input'];
  readonly pullUpLevels?: InputMaybe<Scalars['Int']['input']>;
  readonly quantityBasedPlu?: InputMaybe<ReadonlyArray<InputMaybe<IQuantityPluInput>>>;
  readonly sizeBasedPlu?: InputMaybe<ISizeBasedPluInput>;
}

export interface IVendorConfigs {
  readonly __typename?: 'VendorConfigs';
  readonly carrols: Maybe<IVendorConfig>;
  readonly carrolsDelivery: Maybe<IVendorConfig>;
  readonly ncr: Maybe<IVendorConfig>;
  readonly ncrDelivery: Maybe<IVendorConfig>;
  readonly oheics: Maybe<IVendorConfig>;
  readonly oheicsDelivery: Maybe<IVendorConfig>;
  readonly partner: Maybe<IVendorConfig>;
  readonly partnerDelivery: Maybe<IVendorConfig>;
  readonly productNumber: Maybe<IVendorConfig>;
  readonly productNumberDelivery: Maybe<IVendorConfig>;
  readonly qdi: Maybe<IVendorConfig>;
  readonly qdiDelivery: Maybe<IVendorConfig>;
  readonly qst: Maybe<IVendorConfig>;
  readonly qstDelivery: Maybe<IVendorConfig>;
  readonly rpos: Maybe<IVendorConfig>;
  readonly rposDelivery: Maybe<IVendorConfig>;
  readonly sicom: Maybe<IVendorConfig>;
  readonly sicomDelivery: Maybe<IVendorConfig>;
  readonly simplyDelivery: Maybe<IVendorConfig>;
  readonly simplyDeliveryDelivery: Maybe<IVendorConfig>;
  readonly tablet: Maybe<IVendorConfig>;
  readonly tabletDelivery: Maybe<IVendorConfig>;
  readonly toshibaLoyalty: Maybe<IVendorConfig>;
}

export interface IVendorConfigsInput {
  readonly carrols?: InputMaybe<IVendorConfigInput>;
  readonly carrolsDelivery?: InputMaybe<IVendorConfigInput>;
  /** HDX is not currently supported */
  readonly hdx?: InputMaybe<IVendorConfigInput>;
  readonly ncr?: InputMaybe<IVendorConfigInput>;
  readonly ncrDelivery?: InputMaybe<IVendorConfigInput>;
  readonly oheics?: InputMaybe<IVendorConfigInput>;
  readonly oheicsDelivery?: InputMaybe<IVendorConfigInput>;
  readonly partner?: InputMaybe<IVendorConfigInput>;
  readonly partnerDelivery?: InputMaybe<IVendorConfigInput>;
  readonly productNumber?: InputMaybe<IVendorConfigInput>;
  readonly productNumberDelivery?: InputMaybe<IVendorConfigInput>;
  readonly qdi?: InputMaybe<IVendorConfigInput>;
  readonly qdiDelivery?: InputMaybe<IVendorConfigInput>;
  readonly qst?: InputMaybe<IVendorConfigInput>;
  readonly qstDelivery?: InputMaybe<IVendorConfigInput>;
  readonly rpos?: InputMaybe<IVendorConfigInput>;
  readonly rposDelivery?: InputMaybe<IVendorConfigInput>;
  readonly sicom?: InputMaybe<IVendorConfigInput>;
  readonly sicomDelivery?: InputMaybe<IVendorConfigInput>;
  readonly simplyDelivery?: InputMaybe<IVendorConfigInput>;
  readonly simplyDeliveryDelivery?: InputMaybe<IVendorConfigInput>;
  readonly tablet?: InputMaybe<IVendorConfigInput>;
  readonly tabletDelivery?: InputMaybe<IVendorConfigInput>;
  readonly toshibaLoyalty?: InputMaybe<IVendorConfigInput>;
}

export interface IVrPayment {
  /** Represents the PayPal id for the payment */
  readonly billingAgreementId?: InputMaybe<Scalars['String']['input']>;
  /** Device token provided by Braintree for paypal payments */
  readonly deviceToken?: InputMaybe<Scalars['String']['input']>;
  /** Merchant Account */
  readonly merchantAccount: Scalars['String']['input'];
  /** Represents the PSP id for the payment */
  readonly pspReference: Scalars['String']['input'];
  /** Whether to store payment method or not */
  readonly storePaymentMethod: Scalars['Boolean']['input'];
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IWaitTime {
  readonly __typename?: 'WaitTime';
  readonly firingTimestamp: Scalars['String']['output'];
  readonly position: Scalars['Int']['output'];
  readonly queueLength: Scalars['Int']['output'];
  readonly rbiOrderId: Maybe<Scalars['String']['output']>;
  readonly storeId: Scalars['String']['output'];
}

export interface IWorldpayPayment {
  /** Low Value Token */
  readonly lowValueToken?: InputMaybe<Scalars['String']['input']>;
  /** Represents the PSP id for the payment */
  readonly pspReference?: InputMaybe<Scalars['String']['input']>;
  /** Whether to store payment method or not */
  readonly storePaymentMethod?: InputMaybe<Scalars['Boolean']['input']>;
  /** Stored payment method identifier */
  readonly storedPaymentMethodId?: InputMaybe<Scalars['String']['input']>;
}

export interface IResendPhoneVerificationInput {
  /** user's current platform */
  readonly platform: Platform;
  /** The unique identifier provided when the OTP was created */
  readonly sessionId?: InputMaybe<Scalars['ID']['input']>;
  /** user's current stage */
  readonly stage: Stage;
}

export interface IResendVerificationEmailInput {
  /** user's current platform */
  readonly platform: Platform;
  /** user's current stage */
  readonly stage: Stage;
}

export interface IValidateAuthJwtInput {
  readonly jwt: Scalars['String']['input'];
}

export interface IVerifyPhoneNumberInput {
  /** The code associated with the user's current OTP, for validation */
  readonly code: Scalars['String']['input'];
  /** user's current platform */
  readonly platform: Platform;
  /** The unique identifier provided when the OTP was created */
  readonly sessionId: Scalars['ID']['input'];
  /** user's current stage */
  readonly stage: Stage;
}

export type IDayFragment = {
  readonly __typename?: 'Day';
  readonly close: string | null;
  readonly open: string | null;
};

export type IStoreHoursFragment = {
  readonly __typename?: 'StoreHours';
  readonly monday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly tuesday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly wednesday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly thursday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly friday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly saturday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
  readonly sunday: {
    readonly __typename?: 'Day';
    readonly close: string | null;
    readonly open: string | null;
  } | null;
};

export type IUserOrderFragment = {
  readonly __typename?: 'Order';
  readonly rbiOrderId: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly fireOrderIn: number | null;
  readonly status: RbiOrderStatus;
  readonly orderNumberFormatted: string | null;
  readonly _id: string;
  readonly delivery: {
    readonly __typename?: 'Delivery';
    readonly feeCents: number;
    readonly tipCents: number | null;
    readonly feeDiscountCents: number | null;
    readonly status: DeliveryStatus;
    readonly serviceFeeCents: number | null;
    readonly smallCartFeeCents: number | null;
    readonly geographicalFeeCents: number | null;
    readonly serviceFeeDiscountCents: number | null;
    readonly unavailabilityReason: string | null;
    readonly dropoff: {
      readonly __typename?: 'DeliveryWaypoint';
      readonly state: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
    };
    readonly cancellation: {
      readonly __typename?: 'DeliveryCancellation';
      readonly reason: string | null;
      readonly details: string | null;
    } | null;
  } | null;
  readonly cart: {
    readonly __typename?: 'Cart';
    readonly ticketNumber: string | null;
    readonly storeId: string;
    readonly vatNumber: string | null;
    readonly serviceMode: ServiceMode;
    readonly posVendor: PosVendor;
    readonly cartVersion: number | null;
    readonly subTotalCents: number | null;
    readonly taxCents: number | null;
    readonly totalCents: number | null;
    readonly wasLoyaltyEarned: boolean | null;
    readonly discounts: ReadonlyArray<{
      readonly __typename?: 'Discount';
      readonly name: DiscountTypes | null;
      readonly value: number | null;
    } | null> | null;
    readonly payment: {
      readonly __typename?: 'Payment';
      readonly paymentType: CartPaymentType | null;
      readonly paymentMethodBrand: PaymentMethodBrand | null;
      readonly paymentRequestTimeLimit: string | null;
    } | null;
    readonly fees: ReadonlyArray<{
      readonly __typename?: 'Fees';
      readonly name: string | null;
      readonly priceCents: number | null;
      readonly quantity: number | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly donations: ReadonlyArray<{
      readonly __typename?: 'Donation';
      readonly id: string | null;
      readonly name: string | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly storeAddress: {
      readonly __typename?: 'StoreAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly state: string;
      readonly zip: string;
    };
    readonly storeDetails: {
      readonly __typename?: 'StoreDetails';
      readonly franchiseGroupName: string | null;
      readonly franchiseGroupId: number | null;
      readonly hasCatering: boolean | null;
      readonly hasDelivery: boolean | null;
      readonly hasDriveThru: boolean | null;
      readonly hasMobileOrdering: boolean | null;
      readonly lastActiveDateTime: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly mobileOrderingStatus: string | null;
      readonly phoneNumber: string | null;
      readonly posVendor: string | null;
      readonly status: string | null;
      readonly storeNumber: string | null;
      readonly deliveryHours: {
        readonly __typename?: 'StoreHours';
        readonly monday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly tuesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly wednesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly thursday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly friday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly saturday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly sunday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
      } | null;
      readonly diningRoomHours: {
        readonly __typename?: 'StoreHours';
        readonly monday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly tuesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly wednesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly thursday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly friday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly saturday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly sunday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
      } | null;
      readonly driveThruHours: {
        readonly __typename?: 'StoreHours';
        readonly monday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly tuesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly wednesday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly thursday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly friday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly saturday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
        readonly sunday: {
          readonly __typename?: 'Day';
          readonly close: string | null;
          readonly open: string | null;
        } | null;
      } | null;
    } | null;
    readonly cartEntries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    readonly reorderCartEntries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly isInMenu: boolean | null;
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly isInMenu: boolean | null;
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly isInMenu: boolean | null;
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    readonly unavailableCartEntries: ReadonlyArray<{
      readonly __typename?: 'UnavailableCartEntry';
      readonly lineId: string | null;
      readonly sanityId: string;
    } | null> | null;
  };
  readonly fulfillmentDetails: {
    readonly __typename?: 'FulfillmentDetails';
    readonly pickupType: FulfillmentPickupType | null;
    readonly pickupPin: string | null;
    readonly pickupReady: boolean | null;
  } | null;
  readonly order: {
    readonly __typename?: 'RbiOrder';
    readonly refund: {
      readonly __typename?: 'Refund';
      readonly primaryReason: RefundReason;
      readonly secondaryReason: string | null;
      readonly subtotal: string;
      readonly tax: string;
      readonly transactionDateTime: string;
      readonly refundedItems: ReadonlyArray<{
        readonly __typename?: 'RefundedItem';
        readonly refundAmount: string | null;
        readonly refundQuantity: string | null;
        readonly sanityId: string | null;
        readonly type: CartEntryType | null;
        readonly name: string | null;
        readonly plu: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ISaveDeliveryAddressMutationVariables = Exact<{
  input: ISaveDeliveryAddressInput;
}>;

export type ISaveDeliveryAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly saveDeliveryAddress: {
    readonly __typename?: 'DeliveryAddressResponse';
    readonly deliveryAddresses: ReadonlyArray<{
      readonly __typename?: 'DeliveryAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly country: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly phoneNumber: string;
      readonly route: string | null;
      readonly state: string | null;
      readonly streetNumber: string | null;
      readonly zip: string | null;
      readonly alias: string | null;
    }> | null;
  };
};

export type IUpdateDeliveryAddressMutationVariables = Exact<{
  input: IUpdateDeliveryAddressInput;
}>;

export type IUpdateDeliveryAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDeliveryAddress: {
    readonly __typename?: 'DeliveryAddressResponse';
    readonly deliveryAddresses: ReadonlyArray<{
      readonly __typename?: 'DeliveryAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly country: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly phoneNumber: string;
      readonly route: string | null;
      readonly state: string | null;
      readonly streetNumber: string | null;
      readonly zip: string | null;
      readonly alias: string | null;
    }> | null;
  };
};

export type IRemoveDeliveryAddressMutationVariables = Exact<{
  input: IRemoveDeliveryAddressInput;
}>;

export type IRemoveDeliveryAddressMutation = {
  readonly __typename?: 'Mutation';
  readonly removeDeliveryAddress: {
    readonly __typename?: 'DeliveryAddressResponse';
    readonly deliveryAddresses: ReadonlyArray<{
      readonly __typename?: 'DeliveryAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly country: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly phoneNumber: string;
      readonly route: string | null;
      readonly state: string | null;
      readonly streetNumber: string | null;
      readonly zip: string | null;
      readonly alias: string | null;
    }> | null;
  };
};

export type IGetListFeesByTierQueryVariables = Exact<{
  options: IGetFeesByTierInput;
}>;

export type IGetListFeesByTierQuery = {
  readonly __typename?: 'Query';
  readonly listFeesByTier: ReadonlyArray<{
    readonly __typename?: 'GetFeesByTierResponse';
    readonly minTier: number | null;
    readonly maxTier: number | null;
    readonly deliveryFee: number | null;
    readonly serviceFee: number | null;
    readonly totalDeliveryFee: number | null;
    readonly totalServiceFee: number | null;
  } | null>;
};

export type IDeliveryAddressFragment = {
  readonly __typename?: 'DeliveryAddress';
  readonly addressLine1: string;
  readonly addressLine2: string | null;
  readonly city: string;
  readonly country: string | null;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly phoneNumber: string;
  readonly route: string | null;
  readonly state: string | null;
  readonly streetNumber: string | null;
  readonly zip: string | null;
  readonly alias: string | null;
};

export type ITransactionFragment = {
  readonly __typename?: 'Transaction';
  readonly id: string;
  readonly shortId: string;
  readonly loyaltyId: string | null;
  readonly channel: string;
  readonly pointsEarned: number;
  readonly bonusPointsEarned: number;
  readonly pointsUsed: number;
  readonly pointsDeducted: number | null;
  readonly hasRewards: boolean;
  readonly status: TransactionStatus;
  readonly createdAt: string;
  readonly dateUpdated: string | null;
  readonly transactionDetails: {
    readonly __typename?: 'TransactionDetails';
    readonly currency: string;
    readonly language: Language | null;
    readonly promotions: ReadonlyArray<{
      readonly __typename?: 'Promotion';
      readonly type: PromotionType;
      readonly id: string;
    }> | null;
    readonly orders: ReadonlyArray<{
      readonly __typename?: 'TransactionOrder';
      readonly productId: string | null;
      readonly quantity: number;
      readonly price: number;
      readonly parentReferenceId: string | null;
    }>;
    readonly fulfillment: {
      readonly __typename?: 'TransactionFulfillment';
      readonly type: string;
      readonly autoRelease: boolean | null;
    };
    readonly posVendor: {
      readonly __typename?: 'TransactionPosVendor';
      readonly id: string | null;
      readonly type: string;
      readonly transactionId: string;
    } | null;
    readonly pickupContact: {
      readonly __typename?: 'PickupContact';
      readonly name: string | null;
      readonly phone: string | null;
      readonly hasArrived: boolean;
      readonly vehicle: {
        readonly __typename?: 'PickupContactVehicle';
        readonly make: string | null;
        readonly model: string | null;
        readonly color: string | null;
        readonly licensePlate: string | null;
      } | null;
    } | null;
    readonly payments: ReadonlyArray<{
      readonly __typename?: 'TransactionPayment';
      readonly amount: number;
      readonly description: string | null;
      readonly type: string;
      readonly token: string | null;
      readonly expiration: {
        readonly __typename?: 'PaymentExpiration';
        readonly month: number | null;
        readonly year: number | null;
      } | null;
    }>;
    readonly person: {
      readonly __typename?: 'Person';
      readonly phone: string | null;
      readonly email: string | null;
      readonly externalIds: ReadonlyArray<{
        readonly __typename?: 'ExternalIdentifier';
        readonly type: string;
        readonly value: string;
      } | null> | null;
    } | null;
  } | null;
  readonly additionalReferenceIds: ReadonlyArray<{
    readonly __typename?: 'TransactionAdditionalReferenceId';
    readonly id: string;
    readonly type: string;
    readonly description: string | null;
  }> | null;
};

export type IRewardFragment = {
  readonly __typename?: 'Reward';
  readonly id: string;
  readonly rewardBenefitId: string;
  readonly name: string;
  readonly pointCost: number;
  readonly sanityId: string | null;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly locked: boolean | null;
  readonly minimumSpend: number | null;
  readonly minimumTotalSpend: number | null;
  readonly redemptionPercentage: number | null;
  readonly limitPerOrder: number | null;
  readonly remainingPointsNeededForRedemption: number | null;
  readonly requiredLoyaltyTier: LoyaltyTierKey | null;
  readonly rewardPrice: number | null;
};

export type ILoyaltyUserFragment = {
  readonly __typename?: 'User';
  readonly id: string;
  readonly name: string | null;
  readonly email: string | null;
  readonly phoneNumber: string | null;
  readonly dateOfBirth: string | null;
  readonly points: number;
  readonly createdAt: string;
  readonly pointsExpiryDateKey: string | null;
  readonly metadata: ReadonlyArray<{
    readonly __typename?: 'ObjectType';
    readonly key: string | null;
    readonly value: any | null;
  } | null> | null;
  readonly bestPromotion: {
    readonly __typename?: 'SystemWidePromotion';
    readonly sanityId: string | null;
  } | null;
  readonly expirationBuckets: ReadonlyArray<{
    readonly __typename?: 'ExpirationBucket';
    readonly expirationDate: string;
    readonly pointsToExpire: number;
  }> | null;
  readonly offerRedemptionAvailability: {
    readonly __typename?: 'OfferRedemptionAvailability';
    readonly availableAfter: string | null;
  } | null;
  readonly secondaryIdentifiers: ReadonlyArray<{
    readonly __typename?: 'SecondaryIdentifier';
    readonly identifier: string;
    readonly identifierType: SecondaryIdentifierType;
  } | null> | null;
  readonly loyaltyTier: {
    readonly __typename?: 'LoyaltyTier';
    readonly loyaltyTierKey: LoyaltyTierKey;
    readonly loyaltyTierExpiryDate: string;
    readonly pointsEarnedInTimeConstraint: number | null;
  } | null;
};

export type IOffersFragment = {
  readonly __typename?: 'Offer';
  readonly configId: string | null;
  readonly coolDown: number | null;
  readonly endDate: string;
  readonly endTime: string | null;
  readonly id: string;
  readonly isStackable: boolean | null;
  readonly maxRedemptionsDuration: DurationPeriod | null;
  readonly maxRedemptionsLimit: number | null;
  readonly minimumSpend: number | null;
  readonly loyaltyId: string | null;
  readonly paymentMethod: PaymentMethod | null;
  readonly questId: string | null;
  readonly redemptionType: OfferRedemptionType | null;
  readonly restaurantGroups: ReadonlyArray<string | null> | null;
  readonly sanityId: string | null;
  readonly serviceModes: ReadonlyArray<ServiceMode | null> | null;
  readonly startDate: string;
  readonly startTime: string | null;
  readonly templateId: string | null;
  readonly type: OfferType;
  readonly validDaysOfTheWeek: ReadonlyArray<DayOfTheWeek> | null;
  readonly benefits: ReadonlyArray<
    | {
        readonly __typename?: 'BenefitReference';
        readonly type: BenefitType;
        readonly reference: string;
      }
    | {
        readonly __typename?: 'BenefitSwap';
        readonly type: BenefitType;
        readonly value: {
          readonly __typename?: 'Swap';
          readonly type: SwapType;
          readonly from: string;
          readonly to: string;
        };
      }
  > | null;
  readonly errors: ReadonlyArray<{
    readonly __typename?: 'RuleConditionEvaluation';
    readonly code: string;
    readonly currentValue: any | null;
    readonly message: string | null;
    readonly ruleId: string;
    readonly targetValue: any | null;
  }> | null;
  readonly metadata: ReadonlyArray<{
    readonly __typename?: 'ObjectType';
    readonly key: string | null;
    readonly value: any | null;
  } | null> | null;
};

export type IInvalidIncentiveFragment = {
  readonly __typename?: 'InvalidIncentives';
  readonly id: string;
  readonly errors: ReadonlyArray<{
    readonly __typename?: 'RuleConditionEvaluation';
    readonly code: string;
    readonly currentValue: any | null;
    readonly message: string | null;
    readonly ruleId: string;
    readonly targetValue: any | null;
  }>;
};

export type ILoyaltyCouponFragment = {
  readonly __typename?: 'LoyaltyCouponResponse';
  readonly code: string;
  readonly isValid: boolean;
  readonly message: string;
  readonly reason: string | null;
  readonly isRedeemed: boolean | null;
  readonly shouldRedeem: boolean | null;
  readonly personalizedOfferId: string | null;
  readonly configId: string | null;
};

export type ICartEntryFragment = {
  readonly __typename?: 'CartEntries';
  readonly lineId: string;
  readonly image: string | null;
  readonly isDonation: boolean | null;
  readonly isExtra: boolean | null;
  readonly name: string | null;
  readonly pickerSelections: string | null;
  readonly price: number | null;
  readonly quantity: number;
  readonly sanityId: string;
  readonly type: CartEntryType;
  readonly url: string | null;
  readonly redeemable: boolean | null;
  readonly _id: string;
  readonly vendorConfigs: {
    readonly __typename?: 'VendorConfigs';
    readonly ncr: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly ncrDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partner: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly partnerDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumber: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly productNumberDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicom: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly sicomDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdi: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly qdiDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rpos: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly rposDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly simplyDeliveryDelivery: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
    readonly toshibaLoyalty: {
      readonly __typename?: 'VendorConfig';
      readonly pluType: string;
      readonly parentSanityId: string | null;
      readonly pullUpLevels: number | null;
      readonly constantPlu: string | null;
      readonly quantityBasedPlu: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly multiConstantPlus: ReadonlyArray<{
        readonly __typename?: 'QuantityPlu';
        readonly quantity: number | null;
        readonly plu: string | null;
        readonly qualifier: string | null;
      } | null> | null;
      readonly parentChildPlu: {
        readonly __typename?: 'ParentChildPlu';
        readonly plu: string | null;
        readonly childPlu: string | null;
      } | null;
      readonly sizeBasedPlu: {
        readonly __typename?: 'SizeBasedPlu';
        readonly comboPlu: string | null;
        readonly comboSize: string | null;
      } | null;
    } | null;
  } | null;
};

export type IVendorConfigsFragment = {
  readonly __typename?: 'VendorConfigs';
  readonly ncr: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly ncrDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly partner: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly partnerDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly productNumber: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly productNumberDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly sicom: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly sicomDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly qdi: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly qdiDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly rpos: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly rposDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly simplyDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly simplyDeliveryDelivery: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
  readonly toshibaLoyalty: {
    readonly __typename?: 'VendorConfig';
    readonly pluType: string;
    readonly parentSanityId: string | null;
    readonly pullUpLevels: number | null;
    readonly constantPlu: string | null;
    readonly quantityBasedPlu: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly multiConstantPlus: ReadonlyArray<{
      readonly __typename?: 'QuantityPlu';
      readonly quantity: number | null;
      readonly plu: string | null;
      readonly qualifier: string | null;
    } | null> | null;
    readonly parentChildPlu: {
      readonly __typename?: 'ParentChildPlu';
      readonly plu: string | null;
      readonly childPlu: string | null;
    } | null;
    readonly sizeBasedPlu: {
      readonly __typename?: 'SizeBasedPlu';
      readonly comboPlu: string | null;
      readonly comboSize: string | null;
    } | null;
  } | null;
};

export type IVendorConfigFragment = {
  readonly __typename?: 'VendorConfig';
  readonly pluType: string;
  readonly parentSanityId: string | null;
  readonly pullUpLevels: number | null;
  readonly constantPlu: string | null;
  readonly quantityBasedPlu: ReadonlyArray<{
    readonly __typename?: 'QuantityPlu';
    readonly quantity: number | null;
    readonly plu: string | null;
    readonly qualifier: string | null;
  } | null> | null;
  readonly multiConstantPlus: ReadonlyArray<{
    readonly __typename?: 'QuantityPlu';
    readonly quantity: number | null;
    readonly plu: string | null;
    readonly qualifier: string | null;
  } | null> | null;
  readonly parentChildPlu: {
    readonly __typename?: 'ParentChildPlu';
    readonly plu: string | null;
    readonly childPlu: string | null;
  } | null;
  readonly sizeBasedPlu: {
    readonly __typename?: 'SizeBasedPlu';
    readonly comboPlu: string | null;
    readonly comboSize: string | null;
  } | null;
};

export type IOrderFragment = {
  readonly __typename?: 'Order';
  readonly paymentStatus: PaymentStatus | null;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly posOrderId: string | null;
  readonly rbiOrderId: string;
  readonly status: RbiOrderStatus;
  readonly fireOrderIn: number | null;
  readonly orderNumberFormatted: string | null;
  readonly order: {
    readonly __typename?: 'RbiOrder';
    readonly refund: {
      readonly __typename?: 'Refund';
      readonly primaryReason: RefundReason;
      readonly secondaryReason: string | null;
      readonly subtotal: string;
      readonly tax: string;
      readonly transactionDateTime: string;
      readonly refundedItems: ReadonlyArray<{
        readonly __typename?: 'RefundedItem';
        readonly refundAmount: string | null;
        readonly refundQuantity: string | null;
        readonly sanityId: string | null;
        readonly type: CartEntryType | null;
        readonly name: string | null;
        readonly plu: string | null;
      } | null> | null;
    } | null;
  } | null;
  readonly orderErrors: {
    readonly __typename?: 'OrderErrors';
    readonly orderInjectionErrors: {
      readonly __typename?: 'OrderInjectionErrors';
      readonly isConfigurationError: boolean;
      readonly isMenuError: boolean;
      readonly isStoreAvailabilityError: boolean;
      readonly isTimeoutError: boolean;
      readonly isUnknownError: boolean;
    };
    readonly posErrors: ReadonlyArray<{
      readonly __typename?: 'RbiError';
      readonly errorCode: string | null;
      readonly errorName: string | null;
      readonly message: string | null;
      readonly rbiErrorCode: string | null;
      readonly rbiErrorDomain: RbiErrorDomain | null;
    } | null> | null;
  } | null;
  readonly loyaltyTransaction: {
    readonly __typename?: 'LoyaltyTransaction';
    readonly id: string;
    readonly pointsEarned: number;
    readonly bonusPointsEarned: number;
    readonly pointsUsed: number;
    readonly pointsBalance: number;
    readonly rewardsUsed: number;
  } | null;
  readonly cart: {
    readonly __typename?: 'Cart';
    readonly customerName: string;
    readonly cartVersion: number | null;
    readonly guestId: string | null;
    readonly posVendor: PosVendor;
    readonly serviceMode: ServiceMode;
    readonly subTotalCents: number | null;
    readonly taxCents: number | null;
    readonly ticketNumber: string | null;
    readonly totalCents: number | null;
    readonly wasLoyaltyEarned: boolean | null;
    readonly vatNumber: string | null;
    readonly cartEntries: ReadonlyArray<{
      readonly __typename?: 'CartEntries';
      readonly lineId: string;
      readonly image: string | null;
      readonly isDonation: boolean | null;
      readonly isExtra: boolean | null;
      readonly name: string | null;
      readonly pickerSelections: string | null;
      readonly price: number | null;
      readonly quantity: number;
      readonly sanityId: string;
      readonly type: CartEntryType;
      readonly url: string | null;
      readonly redeemable: boolean | null;
      readonly _id: string;
      readonly children: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      } | null> | null;
      readonly vendorConfigs: {
        readonly __typename?: 'VendorConfigs';
        readonly ncr: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly ncrDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partner: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly partnerDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumber: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly productNumberDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicom: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly sicomDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdi: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly qdiDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rpos: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly rposDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly simplyDeliveryDelivery: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
        readonly toshibaLoyalty: {
          readonly __typename?: 'VendorConfig';
          readonly pluType: string;
          readonly parentSanityId: string | null;
          readonly pullUpLevels: number | null;
          readonly constantPlu: string | null;
          readonly quantityBasedPlu: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly multiConstantPlus: ReadonlyArray<{
            readonly __typename?: 'QuantityPlu';
            readonly quantity: number | null;
            readonly plu: string | null;
            readonly qualifier: string | null;
          } | null> | null;
          readonly parentChildPlu: {
            readonly __typename?: 'ParentChildPlu';
            readonly plu: string | null;
            readonly childPlu: string | null;
          } | null;
          readonly sizeBasedPlu: {
            readonly __typename?: 'SizeBasedPlu';
            readonly comboPlu: string | null;
            readonly comboSize: string | null;
          } | null;
        } | null;
      } | null;
    }> | null;
    readonly discounts: ReadonlyArray<{
      readonly __typename?: 'Discount';
      readonly name: DiscountTypes | null;
      readonly value: number | null;
    } | null> | null;
    readonly donations: ReadonlyArray<{
      readonly __typename?: 'Donation';
      readonly id: string | null;
      readonly name: string | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly fees: ReadonlyArray<{
      readonly __typename?: 'Fees';
      readonly name: string | null;
      readonly priceCents: number | null;
      readonly quantity: number | null;
      readonly totalCents: number | null;
    } | null> | null;
    readonly guestDetails: {
      readonly __typename?: 'GuestDetails';
      readonly isoCountryCode: string | null;
    } | null;
    readonly payment: {
      readonly __typename?: 'Payment';
      readonly cardType: string | null;
      readonly ccLast4: string | null;
      readonly panToken: string | null;
      readonly paymentType: CartPaymentType | null;
      readonly paymentMethodBrand: PaymentMethodBrand | null;
      readonly paymentRequestTimeLimit: string | null;
    } | null;
    readonly rewardsApplied: ReadonlyArray<{
      readonly __typename?: 'CartAppliedReward';
      readonly cartId: string;
      readonly rewardId: string;
      readonly timesApplied: number;
    } | null> | null;
    readonly storeAddress: {
      readonly __typename?: 'StoreAddress';
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly state: string;
      readonly zip: string;
    };
    readonly storeDetails: {
      readonly __typename?: 'StoreDetails';
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly storeNumber: string | null;
    } | null;
    readonly unavailableCartEntries: ReadonlyArray<{
      readonly __typename?: 'UnavailableCartEntry';
      readonly lineId: string | null;
      readonly sanityId: string;
    } | null> | null;
  };
  readonly delivery: {
    readonly __typename?: 'Delivery';
    readonly feeCents: number;
    readonly feeDiscountCents: number | null;
    readonly serviceFeeDiscountCents: number | null;
    readonly instructions: string | null;
    readonly status: DeliveryStatus;
    readonly tipCents: number | null;
    readonly serviceFeeCents: number | null;
    readonly smallCartFeeCents: number | null;
    readonly geographicalFeeCents: number | null;
    readonly quotedFeeCents: number | null;
    readonly baseDeliveryFeeCents: number | null;
    readonly deliverySurchargeFeeCents: number | null;
    readonly unavailabilityReason: string | null;
    readonly pickup: {
      readonly __typename?: 'DeliveryWaypoint';
      readonly latitude: number | null;
      readonly longitude: number | null;
    };
    readonly dropoff: {
      readonly __typename?: 'DeliveryWaypoint';
      readonly arrivalTime: string | null;
      readonly beforeTime: string | null;
      readonly afterTime: string | null;
      readonly addressLine1: string;
      readonly addressLine2: string | null;
      readonly city: string;
      readonly state: string | null;
      readonly zip: string | null;
      readonly latitude: number | null;
      readonly longitude: number | null;
      readonly phoneNumber: string;
    };
    readonly driver: {
      readonly __typename?: 'DeliveryDriver';
      readonly name: string | null;
      readonly profileImageUrl: string | null;
      readonly phoneNumber: string | null;
    } | null;
    readonly preOrderTimeSlots: ReadonlyArray<{
      readonly __typename?: 'PreOrderTimeSlot';
      readonly start: string;
      readonly end: string;
    } | null> | null;
  } | null;
};

export type IPricingAndAvailabilityFragment = {
  readonly __typename?: 'Entity';
  readonly id: string;
  readonly isAvailable: boolean | null;
  readonly price: {
    readonly __typename?: 'Price';
    readonly default: number | null;
    readonly max: number | null;
    readonly min: number | null;
    readonly overrides: ReadonlyArray<{
      readonly __typename?: 'Override';
      readonly key: ReadonlyArray<string> | null;
      readonly price: number | null;
    }> | null;
  } | null;
};

export type ICaloriesFragment = {
  readonly __typename?: 'Entity';
  readonly calories: {
    readonly __typename?: 'Calories';
    readonly default: number | null;
    readonly min: number | null;
    readonly max: number | null;
  } | null;
};

export type IGetOrderQueryVariables = Exact<{
  rbiOrderId: Scalars['ID']['input'];
  handleCommitError?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type IGetOrderQuery = {
  readonly __typename?: 'Query';
  readonly order: {
    readonly __typename?: 'Order';
    readonly id: string | null;
    readonly paymentStatus: PaymentStatus | null;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly posOrderId: string | null;
    readonly rbiOrderId: string;
    readonly status: RbiOrderStatus;
    readonly fireOrderIn: number | null;
    readonly orderNumberFormatted: string | null;
    readonly order: {
      readonly __typename?: 'RbiOrder';
      readonly refund: {
        readonly __typename?: 'Refund';
        readonly primaryReason: RefundReason;
        readonly secondaryReason: string | null;
        readonly subtotal: string;
        readonly tax: string;
        readonly transactionDateTime: string;
        readonly refundedItems: ReadonlyArray<{
          readonly __typename?: 'RefundedItem';
          readonly refundAmount: string | null;
          readonly refundQuantity: string | null;
          readonly sanityId: string | null;
          readonly type: CartEntryType | null;
          readonly name: string | null;
          readonly plu: string | null;
        } | null> | null;
      } | null;
    } | null;
    readonly orderErrors: {
      readonly __typename?: 'OrderErrors';
      readonly orderInjectionErrors: {
        readonly __typename?: 'OrderInjectionErrors';
        readonly isConfigurationError: boolean;
        readonly isMenuError: boolean;
        readonly isStoreAvailabilityError: boolean;
        readonly isTimeoutError: boolean;
        readonly isUnknownError: boolean;
      };
      readonly posErrors: ReadonlyArray<{
        readonly __typename?: 'RbiError';
        readonly errorCode: string | null;
        readonly errorName: string | null;
        readonly message: string | null;
        readonly rbiErrorCode: string | null;
        readonly rbiErrorDomain: RbiErrorDomain | null;
      } | null> | null;
    } | null;
    readonly loyaltyTransaction: {
      readonly __typename?: 'LoyaltyTransaction';
      readonly id: string;
      readonly pointsEarned: number;
      readonly bonusPointsEarned: number;
      readonly pointsUsed: number;
      readonly pointsBalance: number;
      readonly rewardsUsed: number;
    } | null;
    readonly cart: {
      readonly __typename?: 'Cart';
      readonly customerName: string;
      readonly cartVersion: number | null;
      readonly guestId: string | null;
      readonly posVendor: PosVendor;
      readonly serviceMode: ServiceMode;
      readonly subTotalCents: number | null;
      readonly taxCents: number | null;
      readonly ticketNumber: string | null;
      readonly totalCents: number | null;
      readonly wasLoyaltyEarned: boolean | null;
      readonly vatNumber: string | null;
      readonly cartEntries: ReadonlyArray<{
        readonly __typename?: 'CartEntries';
        readonly lineId: string;
        readonly image: string | null;
        readonly isDonation: boolean | null;
        readonly isExtra: boolean | null;
        readonly name: string | null;
        readonly pickerSelections: string | null;
        readonly price: number | null;
        readonly quantity: number;
        readonly sanityId: string;
        readonly type: CartEntryType;
        readonly url: string | null;
        readonly redeemable: boolean | null;
        readonly _id: string;
        readonly children: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        } | null> | null;
        readonly vendorConfigs: {
          readonly __typename?: 'VendorConfigs';
          readonly ncr: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly ncrDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partner: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly partnerDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumber: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly productNumberDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicom: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly sicomDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdi: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly qdiDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rpos: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly rposDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly simplyDeliveryDelivery: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
          readonly toshibaLoyalty: {
            readonly __typename?: 'VendorConfig';
            readonly pluType: string;
            readonly parentSanityId: string | null;
            readonly pullUpLevels: number | null;
            readonly constantPlu: string | null;
            readonly quantityBasedPlu: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly multiConstantPlus: ReadonlyArray<{
              readonly __typename?: 'QuantityPlu';
              readonly quantity: number | null;
              readonly plu: string | null;
              readonly qualifier: string | null;
            } | null> | null;
            readonly parentChildPlu: {
              readonly __typename?: 'ParentChildPlu';
              readonly plu: string | null;
              readonly childPlu: string | null;
            } | null;
            readonly sizeBasedPlu: {
              readonly __typename?: 'SizeBasedPlu';
              readonly comboPlu: string | null;
              readonly comboSize: string | null;
            } | null;
          } | null;
        } | null;
      }> | null;
      readonly discounts: ReadonlyArray<{
        readonly __typename?: 'Discount';
        readonly name: DiscountTypes | null;
        readonly value: number | null;
      } | null> | null;
      readonly donations: ReadonlyArray<{
        readonly __typename?: 'Donation';
        readonly id: string | null;
        readonly name: string | null;
        readonly totalCents: number | null;
      } | null> | null;
      readonly fees: ReadonlyArray<{
        readonly __typename?: 'Fees';
        readonly name: string | null;
        readonly priceCents: number | null;
        readonly quantity: number | null;
        readonly totalCents: number | null;
      } | null> | null;
      readonly guestDetails: {
        readonly __typename?: 'GuestDetails';
        readonly isoCountryCode: string | null;
      } | null;
      readonly payment: {
        readonly __typename?: 'Payment';
        readonly cardType: string | null;
        readonly ccLast4: string | null;
        readonly panToken: string | null;
        readonly paymentType: CartPaymentType | null;
        readonly paymentMethodBrand: PaymentMethodBrand | null;
        readonly paymentRequestTimeLimit: string | null;
      } | null;
      readonly rewardsApplied: ReadonlyArray<{
        readonly __typename?: 'CartAppliedReward';
        readonly cartId: string;
        readonly rewardId: string;
        readonly timesApplied: number;
      } | null> | null;
      readonly storeAddress: {
        readonly __typename?: 'StoreAddress';
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly state: string;
        readonly zip: string;
      };
      readonly storeDetails: {
        readonly __typename?: 'StoreDetails';
        readonly latitude: number | null;
        readonly longitude: number | null;
        readonly storeNumber: string | null;
      } | null;
      readonly unavailableCartEntries: ReadonlyArray<{
        readonly __typename?: 'UnavailableCartEntry';
        readonly lineId: string | null;
        readonly sanityId: string;
      } | null> | null;
    };
    readonly delivery: {
      readonly __typename?: 'Delivery';
      readonly feeCents: number;
      readonly feeDiscountCents: number | null;
      readonly serviceFeeDiscountCents: number | null;
      readonly instructions: string | null;
      readonly status: DeliveryStatus;
      readonly tipCents: number | null;
      readonly serviceFeeCents: number | null;
      readonly smallCartFeeCents: number | null;
      readonly geographicalFeeCents: number | null;
      readonly quotedFeeCents: number | null;
      readonly baseDeliveryFeeCents: number | null;
      readonly deliverySurchargeFeeCents: number | null;
      readonly unavailabilityReason: string | null;
      readonly pickup: {
        readonly __typename?: 'DeliveryWaypoint';
        readonly latitude: number | null;
        readonly longitude: number | null;
      };
      readonly dropoff: {
        readonly __typename?: 'DeliveryWaypoint';
        readonly arrivalTime: string | null;
        readonly beforeTime: string | null;
        readonly afterTime: string | null;
        readonly addressLine1: string;
        readonly addressLine2: string | null;
        readonly city: string;
        readonly state: string | null;
        readonly zip: string | null;
        readonly latitude: number | null;
        readonly longitude: number | null;
        readonly phoneNumber: string;
      };
      readonly driver: {
        readonly __typename?: 'DeliveryDriver';
        readonly name: string | null;
        readonly profileImageUrl: string | null;
        readonly phoneNumber: string | null;
      } | null;
      readonly preOrderTimeSlots: ReadonlyArray<{
        readonly __typename?: 'PreOrderTimeSlot';
        readonly start: string;
        readonly end: string;
      } | null> | null;
    } | null;
  } | null;
};

export type IGetUserOrdersQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  resumeAfter?: InputMaybe<Scalars['String']['input']>;
  orderStatuses?: InputMaybe<ReadonlyArray<RbiOrderStatus> | RbiOrderStatus>;
  createdAfter?: InputMaybe<Scalars['String']['input']>;
  shouldFilterByRegion?: InputMaybe<Scalars['Boolean']['input']>;
  serviceModes?: InputMaybe<ReadonlyArray<InputMaybe<ServiceMode>> | InputMaybe<ServiceMode>>;
}>;

export type IGetUserOrdersQuery = {
  readonly __typename?: 'Query';
  readonly userOrders: {
    readonly __typename?: 'OrderListResponse';
    readonly count: number;
    readonly resumeAfter: string | null;
    readonly orders: ReadonlyArray<{
      readonly __typename?: 'Order';
      readonly rbiOrderId: string;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly fireOrderIn: number | null;
      readonly status: RbiOrderStatus;
      readonly orderNumberFormatted: string | null;
      readonly _id: string;
      readonly delivery: {
        readonly __typename?: 'Delivery';
        readonly feeCents: number;
        readonly tipCents: number | null;
        readonly feeDiscountCents: number | null;
        readonly status: DeliveryStatus;
        readonly serviceFeeCents: number | null;
        readonly smallCartFeeCents: number | null;
        readonly geographicalFeeCents: number | null;
        readonly serviceFeeDiscountCents: number | null;
        readonly unavailabilityReason: string | null;
        readonly dropoff: {
          readonly __typename?: 'DeliveryWaypoint';
          readonly state: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
        };
        readonly cancellation: {
          readonly __typename?: 'DeliveryCancellation';
          readonly reason: string | null;
          readonly details: string | null;
        } | null;
      } | null;
      readonly cart: {
        readonly __typename?: 'Cart';
        readonly ticketNumber: string | null;
        readonly storeId: string;
        readonly vatNumber: string | null;
        readonly serviceMode: ServiceMode;
        readonly posVendor: PosVendor;
        readonly cartVersion: number | null;
        readonly subTotalCents: number | null;
        readonly taxCents: number | null;
        readonly totalCents: number | null;
        readonly wasLoyaltyEarned: boolean | null;
        readonly discounts: ReadonlyArray<{
          readonly __typename?: 'Discount';
          readonly name: DiscountTypes | null;
          readonly value: number | null;
        } | null> | null;
        readonly payment: {
          readonly __typename?: 'Payment';
          readonly paymentType: CartPaymentType | null;
          readonly paymentMethodBrand: PaymentMethodBrand | null;
          readonly paymentRequestTimeLimit: string | null;
        } | null;
        readonly fees: ReadonlyArray<{
          readonly __typename?: 'Fees';
          readonly name: string | null;
          readonly priceCents: number | null;
          readonly quantity: number | null;
          readonly totalCents: number | null;
        } | null> | null;
        readonly donations: ReadonlyArray<{
          readonly __typename?: 'Donation';
          readonly id: string | null;
          readonly name: string | null;
          readonly totalCents: number | null;
        } | null> | null;
        readonly storeAddress: {
          readonly __typename?: 'StoreAddress';
          readonly addressLine1: string;
          readonly addressLine2: string | null;
          readonly city: string;
          readonly state: string;
          readonly zip: string;
        };
        readonly storeDetails: {
          readonly __typename?: 'StoreDetails';
          readonly franchiseGroupName: string | null;
          readonly franchiseGroupId: number | null;
          readonly hasCatering: boolean | null;
          readonly hasDelivery: boolean | null;
          readonly hasDriveThru: boolean | null;
          readonly hasMobileOrdering: boolean | null;
          readonly lastActiveDateTime: string | null;
          readonly latitude: number | null;
          readonly longitude: number | null;
          readonly mobileOrderingStatus: string | null;
          readonly phoneNumber: string | null;
          readonly posVendor: string | null;
          readonly status: string | null;
          readonly storeNumber: string | null;
          readonly deliveryHours: {
            readonly __typename?: 'StoreHours';
            readonly monday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly tuesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly wednesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly thursday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly friday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly saturday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly sunday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
          } | null;
          readonly diningRoomHours: {
            readonly __typename?: 'StoreHours';
            readonly monday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly tuesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly wednesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly thursday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly friday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly saturday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly sunday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
          } | null;
          readonly driveThruHours: {
            readonly __typename?: 'StoreHours';
            readonly monday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly tuesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly wednesday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly thursday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly friday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly saturday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
            readonly sunday: {
              readonly __typename?: 'Day';
              readonly close: string | null;
              readonly open: string | null;
            } | null;
          } | null;
        } | null;
        readonly cartEntries: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly children: ReadonlyArray<{
                    readonly __typename?: 'CartEntries';
                    readonly lineId: string;
                    readonly image: string | null;
                    readonly isDonation: boolean | null;
                    readonly isExtra: boolean | null;
                    readonly name: string | null;
                    readonly pickerSelections: string | null;
                    readonly price: number | null;
                    readonly quantity: number;
                    readonly sanityId: string;
                    readonly type: CartEntryType;
                    readonly url: string | null;
                    readonly redeemable: boolean | null;
                    readonly _id: string;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null> | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        }> | null;
        readonly reorderCartEntries: ReadonlyArray<{
          readonly __typename?: 'CartEntries';
          readonly isInMenu: boolean | null;
          readonly lineId: string;
          readonly image: string | null;
          readonly isDonation: boolean | null;
          readonly isExtra: boolean | null;
          readonly name: string | null;
          readonly pickerSelections: string | null;
          readonly price: number | null;
          readonly quantity: number;
          readonly sanityId: string;
          readonly type: CartEntryType;
          readonly url: string | null;
          readonly redeemable: boolean | null;
          readonly _id: string;
          readonly children: ReadonlyArray<{
            readonly __typename?: 'CartEntries';
            readonly isInMenu: boolean | null;
            readonly lineId: string;
            readonly image: string | null;
            readonly isDonation: boolean | null;
            readonly isExtra: boolean | null;
            readonly name: string | null;
            readonly pickerSelections: string | null;
            readonly price: number | null;
            readonly quantity: number;
            readonly sanityId: string;
            readonly type: CartEntryType;
            readonly url: string | null;
            readonly redeemable: boolean | null;
            readonly _id: string;
            readonly children: ReadonlyArray<{
              readonly __typename?: 'CartEntries';
              readonly isInMenu: boolean | null;
              readonly lineId: string;
              readonly image: string | null;
              readonly isDonation: boolean | null;
              readonly isExtra: boolean | null;
              readonly name: string | null;
              readonly pickerSelections: string | null;
              readonly price: number | null;
              readonly quantity: number;
              readonly sanityId: string;
              readonly type: CartEntryType;
              readonly url: string | null;
              readonly redeemable: boolean | null;
              readonly _id: string;
              readonly children: ReadonlyArray<{
                readonly __typename?: 'CartEntries';
                readonly isInMenu: boolean | null;
                readonly lineId: string;
                readonly image: string | null;
                readonly isDonation: boolean | null;
                readonly isExtra: boolean | null;
                readonly name: string | null;
                readonly pickerSelections: string | null;
                readonly price: number | null;
                readonly quantity: number;
                readonly sanityId: string;
                readonly type: CartEntryType;
                readonly url: string | null;
                readonly redeemable: boolean | null;
                readonly _id: string;
                readonly children: ReadonlyArray<{
                  readonly __typename?: 'CartEntries';
                  readonly isInMenu: boolean | null;
                  readonly lineId: string;
                  readonly image: string | null;
                  readonly isDonation: boolean | null;
                  readonly isExtra: boolean | null;
                  readonly name: string | null;
                  readonly pickerSelections: string | null;
                  readonly price: number | null;
                  readonly quantity: number;
                  readonly sanityId: string;
                  readonly type: CartEntryType;
                  readonly url: string | null;
                  readonly redeemable: boolean | null;
                  readonly _id: string;
                  readonly children: ReadonlyArray<{
                    readonly __typename?: 'CartEntries';
                    readonly isInMenu: boolean | null;
                    readonly lineId: string;
                    readonly image: string | null;
                    readonly isDonation: boolean | null;
                    readonly isExtra: boolean | null;
                    readonly name: string | null;
                    readonly pickerSelections: string | null;
                    readonly price: number | null;
                    readonly quantity: number;
                    readonly sanityId: string;
                    readonly type: CartEntryType;
                    readonly url: string | null;
                    readonly redeemable: boolean | null;
                    readonly _id: string;
                    readonly vendorConfigs: {
                      readonly __typename?: 'VendorConfigs';
                      readonly ncr: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly ncrDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partner: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly partnerDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumber: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly productNumberDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicom: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly sicomDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdi: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly qdiDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rpos: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly rposDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly simplyDeliveryDelivery: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                      readonly toshibaLoyalty: {
                        readonly __typename?: 'VendorConfig';
                        readonly pluType: string;
                        readonly parentSanityId: string | null;
                        readonly pullUpLevels: number | null;
                        readonly constantPlu: string | null;
                        readonly quantityBasedPlu: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly multiConstantPlus: ReadonlyArray<{
                          readonly __typename?: 'QuantityPlu';
                          readonly quantity: number | null;
                          readonly plu: string | null;
                          readonly qualifier: string | null;
                        } | null> | null;
                        readonly parentChildPlu: {
                          readonly __typename?: 'ParentChildPlu';
                          readonly plu: string | null;
                          readonly childPlu: string | null;
                        } | null;
                        readonly sizeBasedPlu: {
                          readonly __typename?: 'SizeBasedPlu';
                          readonly comboPlu: string | null;
                          readonly comboSize: string | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null> | null;
                  readonly vendorConfigs: {
                    readonly __typename?: 'VendorConfigs';
                    readonly ncr: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly ncrDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partner: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly partnerDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumber: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly productNumberDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicom: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly sicomDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdi: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly qdiDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rpos: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly rposDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly simplyDeliveryDelivery: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                    readonly toshibaLoyalty: {
                      readonly __typename?: 'VendorConfig';
                      readonly pluType: string;
                      readonly parentSanityId: string | null;
                      readonly pullUpLevels: number | null;
                      readonly constantPlu: string | null;
                      readonly quantityBasedPlu: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly multiConstantPlus: ReadonlyArray<{
                        readonly __typename?: 'QuantityPlu';
                        readonly quantity: number | null;
                        readonly plu: string | null;
                        readonly qualifier: string | null;
                      } | null> | null;
                      readonly parentChildPlu: {
                        readonly __typename?: 'ParentChildPlu';
                        readonly plu: string | null;
                        readonly childPlu: string | null;
                      } | null;
                      readonly sizeBasedPlu: {
                        readonly __typename?: 'SizeBasedPlu';
                        readonly comboPlu: string | null;
                        readonly comboSize: string | null;
                      } | null;
                    } | null;
                  } | null;
                } | null> | null;
                readonly vendorConfigs: {
                  readonly __typename?: 'VendorConfigs';
                  readonly ncr: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly ncrDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partner: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly partnerDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumber: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly productNumberDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicom: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly sicomDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdi: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly qdiDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rpos: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly rposDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly simplyDeliveryDelivery: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                  readonly toshibaLoyalty: {
                    readonly __typename?: 'VendorConfig';
                    readonly pluType: string;
                    readonly parentSanityId: string | null;
                    readonly pullUpLevels: number | null;
                    readonly constantPlu: string | null;
                    readonly quantityBasedPlu: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly multiConstantPlus: ReadonlyArray<{
                      readonly __typename?: 'QuantityPlu';
                      readonly quantity: number | null;
                      readonly plu: string | null;
                      readonly qualifier: string | null;
                    } | null> | null;
                    readonly parentChildPlu: {
                      readonly __typename?: 'ParentChildPlu';
                      readonly plu: string | null;
                      readonly childPlu: string | null;
                    } | null;
                    readonly sizeBasedPlu: {
                      readonly __typename?: 'SizeBasedPlu';
                      readonly comboPlu: string | null;
                      readonly comboSize: string | null;
                    } | null;
                  } | null;
                } | null;
              } | null> | null;
              readonly vendorConfigs: {
                readonly __typename?: 'VendorConfigs';
                readonly ncr: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly ncrDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partner: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly partnerDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumber: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly productNumberDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicom: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly sicomDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdi: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly qdiDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rpos: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly rposDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly simplyDeliveryDelivery: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
                readonly toshibaLoyalty: {
                  readonly __typename?: 'VendorConfig';
                  readonly pluType: string;
                  readonly parentSanityId: string | null;
                  readonly pullUpLevels: number | null;
                  readonly constantPlu: string | null;
                  readonly quantityBasedPlu: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly multiConstantPlus: ReadonlyArray<{
                    readonly __typename?: 'QuantityPlu';
                    readonly quantity: number | null;
                    readonly plu: string | null;
                    readonly qualifier: string | null;
                  } | null> | null;
                  readonly parentChildPlu: {
                    readonly __typename?: 'ParentChildPlu';
                    readonly plu: string | null;
                    readonly childPlu: string | null;
                  } | null;
                  readonly sizeBasedPlu: {
                    readonly __typename?: 'SizeBasedPlu';
                    readonly comboPlu: string | null;
                    readonly comboSize: string | null;
                  } | null;
                } | null;
              } | null;
            } | null> | null;
            readonly vendorConfigs: {
              readonly __typename?: 'VendorConfigs';
              readonly ncr: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly ncrDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partner: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly partnerDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumber: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly productNumberDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicom: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly sicomDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdi: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly qdiDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rpos: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly rposDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly simplyDeliveryDelivery: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
              readonly toshibaLoyalty: {
                readonly __typename?: 'VendorConfig';
                readonly pluType: string;
                readonly parentSanityId: string | null;
                readonly pullUpLevels: number | null;
                readonly constantPlu: string | null;
                readonly quantityBasedPlu: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly multiConstantPlus: ReadonlyArray<{
                  readonly __typename?: 'QuantityPlu';
                  readonly quantity: number | null;
                  readonly plu: string | null;
                  readonly qualifier: string | null;
                } | null> | null;
                readonly parentChildPlu: {
                  readonly __typename?: 'ParentChildPlu';
                  readonly plu: string | null;
                  readonly childPlu: string | null;
                } | null;
                readonly sizeBasedPlu: {
                  readonly __typename?: 'SizeBasedPlu';
                  readonly comboPlu: string | null;
                  readonly comboSize: string | null;
                } | null;
              } | null;
            } | null;
          } | null> | null;
          readonly vendorConfigs: {
            readonly __typename?: 'VendorConfigs';
            readonly ncr: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly ncrDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partner: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly partnerDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumber: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly productNumberDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicom: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly sicomDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdi: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly qdiDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rpos: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly rposDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly simplyDeliveryDelivery: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
            readonly toshibaLoyalty: {
              readonly __typename?: 'VendorConfig';
              readonly pluType: string;
              readonly parentSanityId: string | null;
              readonly pullUpLevels: number | null;
              readonly constantPlu: string | null;
              readonly quantityBasedPlu: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly multiConstantPlus: ReadonlyArray<{
                readonly __typename?: 'QuantityPlu';
                readonly quantity: number | null;
                readonly plu: string | null;
                readonly qualifier: string | null;
              } | null> | null;
              readonly parentChildPlu: {
                readonly __typename?: 'ParentChildPlu';
                readonly plu: string | null;
                readonly childPlu: string | null;
              } | null;
              readonly sizeBasedPlu: {
                readonly __typename?: 'SizeBasedPlu';
                readonly comboPlu: string | null;
                readonly comboSize: string | null;
              } | null;
            } | null;
          } | null;
        }> | null;
        readonly unavailableCartEntries: ReadonlyArray<{
          readonly __typename?: 'UnavailableCartEntry';
          readonly lineId: string | null;
          readonly sanityId: string;
        } | null> | null;
      };
      readonly fulfillmentDetails: {
        readonly __typename?: 'FulfillmentDetails';
        readonly pickupType: FulfillmentPickupType | null;
        readonly pickupPin: string | null;
        readonly pickupReady: boolean | null;
      } | null;
      readonly order: {
        readonly __typename?: 'RbiOrder';
        readonly refund: {
          readonly __typename?: 'Refund';
          readonly primaryReason: RefundReason;
          readonly secondaryReason: string | null;
          readonly subtotal: string;
          readonly tax: string;
          readonly transactionDateTime: string;
          readonly refundedItems: ReadonlyArray<{
            readonly __typename?: 'RefundedItem';
            readonly refundAmount: string | null;
            readonly refundQuantity: string | null;
            readonly sanityId: string | null;
            readonly type: CartEntryType | null;
            readonly name: string | null;
            readonly plu: string | null;
          } | null> | null;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type IPriceOrderMutationVariables = Exact<{
  input: IPriceOrderInput;
  delivery?: InputMaybe<IPriceDeliveryInput>;
}>;

export type IPriceOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly priceOrder: {
    readonly __typename?: 'Order';
    readonly rbiOrderId: string;
    readonly status: RbiOrderStatus;
  };
};

export type ICommitOrderMutationVariables = Exact<{
  input: ICommitOrderInput;
  delivery?: InputMaybe<ICommitDeliveryInput>;
  skipCoolingPeriod?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ICommitOrderMutation = {
  readonly __typename?: 'Mutation';
  readonly commitOrder: {
    readonly __typename?: 'Order';
    readonly rbiOrderId: string;
    readonly status: RbiOrderStatus;
  };
};

export type ICreateEvertecPaymentSessionMutationVariables = Exact<{
  input: IInitiatePaymentInput;
}>;

export type ICreateEvertecPaymentSessionMutation = {
  readonly __typename?: 'Mutation';
  readonly createEvertecPaymentSession: {
    readonly __typename?: 'InitiatePaymentResponse';
    readonly processUrl: string;
  };
};

export type IGuestTokenMutationVariables = Exact<{
  input: IGenerateGuestTokenInput;
}>;

export type IGuestTokenMutation = {
  readonly __typename?: 'Mutation';
  readonly generateGuestToken: string | null;
};

export type ILoyaltyUserQueryVariables = Exact<{
  loyaltyId: Scalars['ID']['input'];
}>;

export type ILoyaltyUserQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyUser: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly name: string | null;
    readonly email: string | null;
    readonly phoneNumber: string | null;
    readonly dateOfBirth: string | null;
    readonly points: number;
    readonly createdAt: string;
    readonly pointsExpiryDateKey: string | null;
    readonly metadata: ReadonlyArray<{
      readonly __typename?: 'ObjectType';
      readonly key: string | null;
      readonly value: any | null;
    } | null> | null;
    readonly bestPromotion: {
      readonly __typename?: 'SystemWidePromotion';
      readonly sanityId: string | null;
    } | null;
    readonly expirationBuckets: ReadonlyArray<{
      readonly __typename?: 'ExpirationBucket';
      readonly expirationDate: string;
      readonly pointsToExpire: number;
    }> | null;
    readonly offerRedemptionAvailability: {
      readonly __typename?: 'OfferRedemptionAvailability';
      readonly availableAfter: string | null;
    } | null;
    readonly secondaryIdentifiers: ReadonlyArray<{
      readonly __typename?: 'SecondaryIdentifier';
      readonly identifier: string;
      readonly identifierType: SecondaryIdentifierType;
    } | null> | null;
    readonly loyaltyTier: {
      readonly __typename?: 'LoyaltyTier';
      readonly loyaltyTierKey: LoyaltyTierKey;
      readonly loyaltyTierExpiryDate: string;
      readonly pointsEarnedInTimeConstraint: number | null;
    } | null;
  } | null;
};

export type ILoyaltyUserTransactionsQueryVariables = Exact<{
  loyaltyId: Scalars['ID']['input'];
}>;

export type ILoyaltyUserTransactionsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyUser: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly transactions: ReadonlyArray<{
      readonly __typename?: 'Transaction';
      readonly id: string;
      readonly shortId: string;
      readonly loyaltyId: string | null;
      readonly channel: string;
      readonly pointsEarned: number;
      readonly bonusPointsEarned: number;
      readonly pointsUsed: number;
      readonly pointsDeducted: number | null;
      readonly hasRewards: boolean;
      readonly status: TransactionStatus;
      readonly createdAt: string;
      readonly dateUpdated: string | null;
      readonly transactionDetails: {
        readonly __typename?: 'TransactionDetails';
        readonly currency: string;
        readonly language: Language | null;
        readonly promotions: ReadonlyArray<{
          readonly __typename?: 'Promotion';
          readonly type: PromotionType;
          readonly id: string;
        }> | null;
        readonly orders: ReadonlyArray<{
          readonly __typename?: 'TransactionOrder';
          readonly productId: string | null;
          readonly quantity: number;
          readonly price: number;
          readonly parentReferenceId: string | null;
        }>;
        readonly fulfillment: {
          readonly __typename?: 'TransactionFulfillment';
          readonly type: string;
          readonly autoRelease: boolean | null;
        };
        readonly posVendor: {
          readonly __typename?: 'TransactionPosVendor';
          readonly id: string | null;
          readonly type: string;
          readonly transactionId: string;
        } | null;
        readonly pickupContact: {
          readonly __typename?: 'PickupContact';
          readonly name: string | null;
          readonly phone: string | null;
          readonly hasArrived: boolean;
          readonly vehicle: {
            readonly __typename?: 'PickupContactVehicle';
            readonly make: string | null;
            readonly model: string | null;
            readonly color: string | null;
            readonly licensePlate: string | null;
          } | null;
        } | null;
        readonly payments: ReadonlyArray<{
          readonly __typename?: 'TransactionPayment';
          readonly amount: number;
          readonly description: string | null;
          readonly type: string;
          readonly token: string | null;
          readonly expiration: {
            readonly __typename?: 'PaymentExpiration';
            readonly month: number | null;
            readonly year: number | null;
          } | null;
        }>;
        readonly person: {
          readonly __typename?: 'Person';
          readonly phone: string | null;
          readonly email: string | null;
          readonly externalIds: ReadonlyArray<{
            readonly __typename?: 'ExternalIdentifier';
            readonly type: string;
            readonly value: string;
          } | null> | null;
        } | null;
      } | null;
      readonly additionalReferenceIds: ReadonlyArray<{
        readonly __typename?: 'TransactionAdditionalReferenceId';
        readonly id: string;
        readonly type: string;
        readonly description: string | null;
      }> | null;
    }> | null;
  } | null;
};

export type ILoyaltyUserRewardsQueryVariables = Exact<{
  loyaltyId: Scalars['ID']['input'];
  where?: InputMaybe<IRewardsInput>;
}>;

export type ILoyaltyUserRewardsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyUser: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly rewards: ReadonlyArray<{
      readonly __typename?: 'Reward';
      readonly id: string;
      readonly rewardBenefitId: string;
      readonly name: string;
      readonly pointCost: number;
      readonly sanityId: string | null;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly startDate: string;
      readonly endDate: string;
      readonly locked: boolean | null;
      readonly minimumSpend: number | null;
      readonly minimumTotalSpend: number | null;
      readonly redemptionPercentage: number | null;
      readonly limitPerOrder: number | null;
      readonly remainingPointsNeededForRedemption: number | null;
      readonly requiredLoyaltyTier: LoyaltyTierKey | null;
      readonly rewardPrice: number | null;
    }> | null;
  } | null;
};

export type ILoyaltyUserOffersQueryVariables = Exact<{
  loyaltyId: Scalars['ID']['input'];
  where?: InputMaybe<IOffersInput>;
}>;

export type ILoyaltyUserOffersQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyUser: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly offerRedemptionAvailability: {
      readonly __typename?: 'OfferRedemptionAvailability';
      readonly availableAfter: string | null;
    } | null;
    readonly offers: ReadonlyArray<{
      readonly __typename?: 'Offer';
      readonly configId: string | null;
      readonly coolDown: number | null;
      readonly endDate: string;
      readonly endTime: string | null;
      readonly id: string;
      readonly isStackable: boolean | null;
      readonly maxRedemptionsDuration: DurationPeriod | null;
      readonly maxRedemptionsLimit: number | null;
      readonly minimumSpend: number | null;
      readonly loyaltyId: string | null;
      readonly paymentMethod: PaymentMethod | null;
      readonly questId: string | null;
      readonly redemptionType: OfferRedemptionType | null;
      readonly restaurantGroups: ReadonlyArray<string | null> | null;
      readonly sanityId: string | null;
      readonly serviceModes: ReadonlyArray<ServiceMode | null> | null;
      readonly startDate: string;
      readonly startTime: string | null;
      readonly templateId: string | null;
      readonly type: OfferType;
      readonly validDaysOfTheWeek: ReadonlyArray<DayOfTheWeek> | null;
      readonly benefits: ReadonlyArray<
        | {
            readonly __typename?: 'BenefitReference';
            readonly type: BenefitType;
            readonly reference: string;
          }
        | {
            readonly __typename?: 'BenefitSwap';
            readonly type: BenefitType;
            readonly value: {
              readonly __typename?: 'Swap';
              readonly type: SwapType;
              readonly from: string;
              readonly to: string;
            };
          }
      > | null;
      readonly errors: ReadonlyArray<{
        readonly __typename?: 'RuleConditionEvaluation';
        readonly code: string;
        readonly currentValue: any | null;
        readonly message: string | null;
        readonly ruleId: string;
        readonly targetValue: any | null;
      }> | null;
      readonly metadata: ReadonlyArray<{
        readonly __typename?: 'ObjectType';
        readonly key: string | null;
        readonly value: any | null;
      } | null> | null;
    }> | null;
  } | null;
};

export type ILoyaltyOffersQueryVariables = Exact<{
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  loyaltyId?: InputMaybe<Scalars['ID']['input']>;
  omitInvalids?: InputMaybe<Scalars['Boolean']['input']>;
  redemptionTypes?: InputMaybe<ReadonlyArray<OfferRedemptionType> | OfferRedemptionType>;
  serviceMode?: InputMaybe<ServiceMode>;
  storeId?: InputMaybe<Scalars['String']['input']>;
  subtotalAmount?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IOffersInput>;
}>;

export type ILoyaltyOffersQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyOffers: ReadonlyArray<{
    readonly __typename?: 'Offer';
    readonly configId: string | null;
    readonly coolDown: number | null;
    readonly endDate: string;
    readonly endTime: string | null;
    readonly id: string;
    readonly isStackable: boolean | null;
    readonly maxRedemptionsDuration: DurationPeriod | null;
    readonly maxRedemptionsLimit: number | null;
    readonly minimumSpend: number | null;
    readonly loyaltyId: string | null;
    readonly paymentMethod: PaymentMethod | null;
    readonly questId: string | null;
    readonly redemptionType: OfferRedemptionType | null;
    readonly restaurantGroups: ReadonlyArray<string | null> | null;
    readonly sanityId: string | null;
    readonly serviceModes: ReadonlyArray<ServiceMode | null> | null;
    readonly startDate: string;
    readonly startTime: string | null;
    readonly templateId: string | null;
    readonly type: OfferType;
    readonly validDaysOfTheWeek: ReadonlyArray<DayOfTheWeek> | null;
    readonly benefits: ReadonlyArray<
      | {
          readonly __typename?: 'BenefitReference';
          readonly type: BenefitType;
          readonly reference: string;
        }
      | {
          readonly __typename?: 'BenefitSwap';
          readonly type: BenefitType;
          readonly value: {
            readonly __typename?: 'Swap';
            readonly type: SwapType;
            readonly from: string;
            readonly to: string;
          };
        }
    > | null;
    readonly errors: ReadonlyArray<{
      readonly __typename?: 'RuleConditionEvaluation';
      readonly code: string;
      readonly currentValue: any | null;
      readonly message: string | null;
      readonly ruleId: string;
      readonly targetValue: any | null;
    }> | null;
    readonly metadata: ReadonlyArray<{
      readonly __typename?: 'ObjectType';
      readonly key: string | null;
      readonly value: any | null;
    } | null> | null;
  }> | null;
};

export type ILoyaltyEngineRewardsQueryVariables = Exact<{
  loyaltyId: Scalars['ID']['input'];
}>;

export type ILoyaltyEngineRewardsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyRewards: ReadonlyArray<{
    readonly __typename?: 'Reward';
    readonly id: string;
    readonly rewardBenefitId: string;
    readonly name: string;
    readonly pointCost: number;
    readonly sanityId: string | null;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly startDate: string;
    readonly endDate: string;
    readonly locked: boolean | null;
    readonly minimumSpend: number | null;
    readonly minimumTotalSpend: number | null;
    readonly redemptionPercentage: number | null;
    readonly limitPerOrder: number | null;
    readonly remainingPointsNeededForRedemption: number | null;
    readonly requiredLoyaltyTier: LoyaltyTierKey | null;
    readonly rewardPrice: number | null;
  }> | null;
};

export type ILoyaltyNotificationsRedeemableQueryVariables = Exact<{
  loyaltyId: Scalars['ID']['input'];
  rewardsInput?: InputMaybe<IRewardsInput>;
  offersInput?: InputMaybe<IOffersInput>;
}>;

export type ILoyaltyNotificationsRedeemableQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyUser: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly rewards: ReadonlyArray<{ readonly __typename?: 'Reward'; readonly id: string }> | null;
    readonly promotions: ReadonlyArray<{
      readonly __typename?: 'SystemWidePromotion';
      readonly id: string;
    }> | null;
    readonly offers: ReadonlyArray<{ readonly __typename?: 'Offer'; readonly id: string }> | null;
  } | null;
};

export type ILoyaltyGenerateOtpMutationVariables = Exact<{
  inRestaurantOrder: ReadonlyArray<IInRestaurantOrderLineItem> | IInRestaurantOrderLineItem;
  loyaltyId: Scalars['ID']['input'];
  restaurantId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ILoyaltyGenerateOtpMutation = {
  readonly __typename?: 'Mutation';
  readonly generateOtp: {
    readonly __typename?: 'Otp';
    readonly password: string;
    readonly expirationTime: string;
  };
};

export type IProcessSmgCodeMutationVariables = Exact<{
  loyaltyId: Scalars['ID']['input'];
  smgCode: Scalars['String']['input'];
  transactionDate?: InputMaybe<Scalars['String']['input']>;
}>;

export type IProcessSmgCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly processSmgCode: {
    readonly __typename?: 'ProcessSmgCodeResponse';
    readonly pointsEarned: number;
    readonly status: SmgCodeStatus;
  };
};

export type IOrderSelectionQueryVariables = Exact<{
  otp: Scalars['String']['input'];
}>;

export type IOrderSelectionQuery = {
  readonly __typename?: 'Query';
  readonly orderSelection: {
    readonly __typename?: 'OrderSelectionTransfer';
    readonly status: OrderSelectionStatus | null;
    readonly expiry: number | null;
    readonly _id: string | null;
  } | null;
};

export type ICreateOrderlessTransactionMutationVariables = Exact<{
  input: IOrderlessTransactionInput;
}>;

export type ICreateOrderlessTransactionMutation = {
  readonly __typename?: 'Mutation';
  readonly createOrderlessTransaction: { readonly __typename?: 'Transaction'; readonly id: string };
};

export type ILinkPhysicalCardToLoyaltyUserMutationVariables = Exact<{
  cardNumber: Scalars['String']['input'];
  loyaltyUserId: Scalars['ID']['input'];
}>;

export type ILinkPhysicalCardToLoyaltyUserMutation = {
  readonly __typename?: 'Mutation';
  readonly associateGuestUser: {
    readonly __typename?: 'AssociateGuestUserResponse';
    readonly activationDateTime: string;
    readonly guestBalance: number;
    readonly totalBalance: number;
    readonly linkingDateTime: string;
  } | null;
};

export type IUnlinkPhysicalCardFromLoyaltyUserMutationVariables = Exact<{
  cardNumber: Scalars['String']['input'];
}>;

export type IUnlinkPhysicalCardFromLoyaltyUserMutation = {
  readonly __typename?: 'Mutation';
  readonly removeSecondaryIdAssociation: boolean | null;
};

export type IOrderSelectionByTypeQueryVariables = Exact<{
  key: Scalars['String']['input'];
  ostType: OstType;
}>;

export type IOrderSelectionByTypeQuery = {
  readonly __typename?: 'Query';
  readonly orderSelectionByType: {
    readonly __typename?: 'OrderSelectionTransfer';
    readonly status: OrderSelectionStatus | null;
    readonly expiry: number | null;
    readonly storeHasLoyalty: boolean | null;
    readonly _id: string | null;
  } | null;
};

export type ICreateOrderSelectionByTypeMutationVariables = Exact<{
  ostType: OstType;
  inRestaurantOrder: ReadonlyArray<IInRestaurantOrderLineItem> | IInRestaurantOrderLineItem;
  loyaltyId: Scalars['ID']['input'];
  restaurantId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ICreateOrderSelectionByTypeMutation = {
  readonly __typename?: 'Mutation';
  readonly createOrderSelectionByType: {
    readonly __typename?: 'OstCreationPayload';
    readonly expirationTime: string;
    readonly key: string;
  };
};

export type ILoyaltyRedeemPromoCodeMutationVariables = Exact<{
  input?: InputMaybe<ILoyaltyValidatePromoCodeInput>;
}>;

export type ILoyaltyRedeemPromoCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly loyaltyValidatePromoCode: {
    readonly __typename?: 'LoyaltyCouponResponse';
    readonly code: string;
    readonly isValid: boolean;
    readonly message: string;
    readonly reason: string | null;
    readonly isRedeemed: boolean | null;
    readonly shouldRedeem: boolean | null;
    readonly personalizedOfferId: string | null;
    readonly configId: string | null;
  } | null;
};

export type ILoyaltyQuestsQueryVariables = Exact<{
  loyaltyId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type ILoyaltyQuestsQuery = {
  readonly __typename?: 'Query';
  readonly loyaltyQuests: ReadonlyArray<{
    readonly __typename?: 'Quest';
    readonly endDate: string;
    readonly id: string;
    readonly incentiveType: QuestIncentiveType;
    readonly incentiveId: string | null;
    readonly incentiveRedeemed: boolean;
    readonly sanityId: string | null;
    readonly startDate: string;
    readonly status: QuestStatus;
    readonly steps: ReadonlyArray<{
      readonly __typename?: 'QuestStep';
      readonly completedAt: string | null;
      readonly status: QuestStepStatus;
      readonly id: string;
      readonly cartRequirements: ReadonlyArray<ReadonlyArray<{
        readonly __typename?: 'CartRequirement';
        readonly id: string;
        readonly quantity: number;
      } | null> | null> | null;
    }> | null;
  }> | null;
};

export type ILinkBlueLightCardMembershipMutationVariables = Exact<{
  input: IBlueLightCardInput;
}>;

export type ILinkBlueLightCardMembershipMutation = {
  readonly __typename?: 'Mutation';
  readonly linkBlueLightCardMembership: {
    readonly __typename?: 'PartnerMembershipOutput';
    readonly userId: string;
    readonly partnerName: string;
    readonly loyaltyId: string;
    readonly region: string;
  };
};

export type IGetUserMembershipsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type IGetUserMembershipsQuery = {
  readonly __typename?: 'Query';
  readonly getUserMemberships: ReadonlyArray<{
    readonly __typename?: 'PartnerMembershipOutput';
    readonly partnerName: string;
    readonly metadata: {
      readonly __typename?: 'BlueLightCardMetadataOutput';
      readonly cardNumber: string;
      readonly cardHolderName: string;
    };
  }>;
};

export type IPlusDataQueryVariables = Exact<{
  storeId: Scalars['ID']['input'];
  serviceMode?: InputMaybe<PosDataServiceMode>;
}>;

export type IPlusDataQuery = {
  readonly __typename?: 'Query';
  readonly plus: ReadonlyArray<{
    readonly __typename?: 'MenuItem';
    readonly plu: string;
    readonly price: string;
  }> | null;
};

export type IStoreMenuQueryVariables = Exact<{
  region: Scalars['String']['input'];
  channel: Channel;
  storeId: Scalars['ID']['input'];
  serviceMode?: InputMaybe<PosDataServiceMode>;
}>;

export type IStoreMenuQuery = {
  readonly __typename?: 'Query';
  readonly storeMenu: ReadonlyArray<{
    readonly __typename?: 'Entity';
    readonly id: string;
    readonly isAvailable: boolean | null;
    readonly price: {
      readonly __typename?: 'Price';
      readonly default: number | null;
      readonly max: number | null;
      readonly min: number | null;
      readonly overrides: ReadonlyArray<{
        readonly __typename?: 'Override';
        readonly key: ReadonlyArray<string> | null;
        readonly price: number | null;
      }> | null;
    } | null;
    readonly calories: {
      readonly __typename?: 'Calories';
      readonly default: number | null;
      readonly min: number | null;
      readonly max: number | null;
    } | null;
  }> | null;
};

export type IPromotionValidatePromoCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
  cognitoId: Scalars['String']['input'];
  shouldRedeem?: InputMaybe<Scalars['Boolean']['input']>;
  offers: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type IPromotionValidatePromoCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly validatePromoCode: {
    readonly __typename?: 'CBACouponResponse';
    readonly code: string;
    readonly reason: string | null;
    readonly message: string;
    readonly isValid: boolean;
    readonly isRedeemed: boolean | null;
    readonly offerId: string | null;
  } | null;
};

export type IPromotionRedeemPromoCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
  cognitoId: Scalars['String']['input'];
}>;

export type IPromotionRedeemPromoCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly redeemPromoCode: {
    readonly __typename?: 'CBARedeemCouponResponse';
    readonly code: string;
    readonly reason: string | null;
    readonly message: string | null;
    readonly isRedeemed: boolean;
    readonly offerId: string | null;
  } | null;
};

export type ISocialLoginMutationVariables = Exact<{
  userInfo: ISocialLoginInput;
}>;

export type ISocialLoginMutation = {
  readonly __typename?: 'Mutation';
  readonly socialLogin: {
    readonly __typename?: 'CognitoCredentials';
    readonly sessionId: string;
    readonly challengeCode: string;
    readonly email: string | null;
  } | null;
};

export type ISignInJwtMutationVariables = Exact<{
  input: ISignInUserInput;
}>;

export type ISignInJwtMutation = {
  readonly __typename?: 'Mutation';
  readonly signInJWT: boolean | null;
};

export type ISignUpMutationVariables = Exact<{
  input: ISignUpUserInput;
}>;

export type ISignUpMutation = { readonly __typename?: 'Mutation'; readonly signUp: string | null };

export type IValidateAuthJwtMutationVariables = Exact<{
  input: IValidateAuthJwtInput;
}>;

export type IValidateAuthJwtMutation = {
  readonly __typename?: 'Mutation';
  readonly validateAuthJwt: {
    readonly __typename?: 'CognitoCredentials';
    readonly challengeCode: string;
    readonly sessionId: string;
    readonly email: string | null;
  };
};

export type ICreateOtpMutationVariables = Exact<{
  input: ICreateOtpInput;
}>;

export type ICreateOtpMutation = {
  readonly __typename?: 'Mutation';
  readonly createOTP: {
    readonly __typename?: 'CreateOTP';
    readonly maxValidateAttempts: number;
    readonly ttl: number;
    readonly redirectToUS: boolean | null;
  };
};

export type ICreateGuestOtpMutationVariables = Exact<{
  input: ICreateGuestOtpInput;
}>;

export type ICreateGuestOtpMutation = {
  readonly __typename?: 'Mutation';
  readonly createGuestOTP: {
    readonly __typename?: 'CreateOTP';
    readonly maxValidateAttempts: number;
    readonly ttl: number;
    readonly redirectToUS: boolean | null;
  };
};

export type IResendCurrentLoginOtpMutationVariables = Exact<{
  input: IResendOtpInput;
}>;

export type IResendCurrentLoginOtpMutation = {
  readonly __typename?: 'Mutation';
  readonly resendCurrentLoginOTP: {
    readonly __typename?: 'CreateOTP';
    readonly maxValidateAttempts: number;
    readonly ttl: number;
  };
};

export type IResendCurrentGuestOtpMutationVariables = Exact<{
  input: IResendOtpInput;
}>;

export type IResendCurrentGuestOtpMutation = {
  readonly __typename?: 'Mutation';
  readonly resendCurrentGuestOTP: {
    readonly __typename?: 'CreateOTP';
    readonly maxValidateAttempts: number;
    readonly ttl: number;
  };
};

export type IValidateOtpMutationVariables = Exact<{
  input: IExchangeOtpCodeForCredentialsInput;
}>;

export type IValidateOtpMutation = {
  readonly __typename?: 'Mutation';
  readonly exchangeOTPCodeForCognitoCredentials: {
    readonly __typename?: 'CognitoCredentials';
    readonly challengeCode: string;
    readonly sessionId: string;
    readonly email: string | null;
  };
};

export type IValidateGuestOtpCodeMutationVariables = Exact<{
  input: IValidateGuestOtpInput;
}>;

export type IValidateGuestOtpCodeMutation = {
  readonly __typename?: 'Mutation';
  readonly validateGuestOTPCode: {
    readonly __typename?: 'GuestCognitoCredentials';
    readonly challengeCode: string | null;
    readonly sessionId: string | null;
    readonly email: string;
  };
};

export type ISignUpVerifiedGuestMutationVariables = Exact<{
  input: ISignUpUserInput;
}>;

export type ISignUpVerifiedGuestMutation = {
  readonly __typename?: 'Mutation';
  readonly signUpVerifiedGuest: {
    readonly __typename?: 'GuestCognitoCredentials';
    readonly challengeCode: string | null;
    readonly sessionId: string | null;
    readonly email: string;
  };
};

export type ISignUpCompleteQueryVariables = Exact<{
  jwt: Scalars['String']['input'];
}>;

export type ISignUpCompleteQuery = {
  readonly __typename?: 'Query';
  readonly signUpComplete: boolean | null;
};

export type IResendVerificationEmailMutationVariables = Exact<{
  input: IResendVerificationEmailInput;
}>;

export type IResendVerificationEmailMutation = {
  readonly __typename?: 'Mutation';
  readonly resendVerificationEmail: boolean | null;
};

export type IUserPhoneFromBrazeQueryVariables = Exact<{
  brazeId: Scalars['String']['input'];
}>;

export type IUserPhoneFromBrazeQuery = {
  readonly __typename?: 'Query';
  readonly userPhoneFromBraze: {
    readonly __typename?: 'BrazeUserPhone';
    readonly phone: string | null;
  } | null;
};

export type ISendUpdateUserAttributesEventMutationVariables = Exact<{
  input: IUpdateUserAttributesEventInput;
}>;

export type ISendUpdateUserAttributesEventMutation = {
  readonly __typename?: 'Mutation';
  readonly sendUpdateUserAttributesEvent: boolean | null;
};

export type IGetWaitTimeQueryVariables = Exact<{
  storeId: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
}>;

export type IGetWaitTimeQuery = {
  readonly __typename?: 'Query';
  readonly waitTime: {
    readonly __typename?: 'WaitTime';
    readonly storeId: string;
    readonly rbiOrderId: string | null;
    readonly position: number;
    readonly firingTimestamp: string;
    readonly queueLength: number;
  } | null;
};

export type IExitQueueMutationVariables = Exact<{
  storeId: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
}>;

export type IExitQueueMutation = {
  readonly __typename?: 'Mutation';
  readonly exitQueue: {
    readonly __typename?: 'UpdateWaitTimeResult';
    readonly code: string;
    readonly storeId: string;
  };
};

export type IInitiatePaymentAttemptMutationVariables = Exact<{
  input: IInitiatePaymentAttemptInput;
}>;

export type IInitiatePaymentAttemptMutation = {
  readonly __typename?: 'Mutation';
  readonly initiatePaymentAttempt: {
    readonly __typename?: 'PaymentAttempt';
    readonly paymentAttemptId: string;
    readonly processorId: string;
    readonly status: string;
    readonly pspSpecific: string;
    readonly paymentMethodType: PaymentMethodEnum;
    readonly paymentIntentId: string;
  } | null;
};

export type IMakePaymentAttemptMutationVariables = Exact<{
  input: IMakePaymentAttemptInput;
}>;

export type IMakePaymentAttemptMutation = {
  readonly __typename?: 'Mutation';
  readonly makePaymentAttempt: {
    readonly __typename?: 'PaymentAttempt';
    readonly paymentAttemptId: string;
    readonly paymentIntentId: string;
    readonly processorId: string;
    readonly status: string;
    readonly pspSpecific: string;
    readonly paymentMethodType: PaymentMethodEnum;
  } | null;
};

export type ICreatePaymentIntentMutationVariables = Exact<{
  input: ICreatePaymentIntentInput;
}>;

export type ICreatePaymentIntentMutation = {
  readonly __typename?: 'Mutation';
  readonly createPaymentIntent: {
    readonly __typename?: 'PaymentIntent';
    readonly paymentIntentId: string;
    readonly clientApp: string;
    readonly amount: number;
    readonly currency: string;
    readonly clientUserId: string;
    readonly referenceId: string;
    readonly paymentMethodTypeConfigurations: ReadonlyArray<{
      readonly __typename?: 'PaymentConfiguration';
      readonly paymentMethodTypeId: string;
      readonly status: string;
      readonly processorId: string;
      readonly enableCreateShopper: boolean;
      readonly enableVaultPaymentMethod: boolean;
      readonly enableRetrieveVaultedPaymentMethods: boolean;
      readonly market: {
        readonly __typename?: 'Market';
        readonly brand: string;
        readonly country: string;
        readonly store: string;
        readonly marketId: string;
      };
    }>;
  } | null;
};

export const DayFragmentDoc = gql`
  fragment DayFragment on Day {
    close
    open
  }
`;
export const StoreHoursFragmentDoc = gql`
  fragment StoreHoursFragment on StoreHours {
    monday {
      ...DayFragment
    }
    tuesday {
      ...DayFragment
    }
    wednesday {
      ...DayFragment
    }
    thursday {
      ...DayFragment
    }
    friday {
      ...DayFragment
    }
    saturday {
      ...DayFragment
    }
    sunday {
      ...DayFragment
    }
  }
  ${DayFragmentDoc}
`;
export const VendorConfigFragmentDoc = gql`
  fragment VendorConfigFragment on VendorConfig {
    pluType
    parentSanityId
    pullUpLevels
    constantPlu
    quantityBasedPlu {
      quantity
      plu
      qualifier
    }
    multiConstantPlus {
      quantity
      plu
      qualifier
    }
    parentChildPlu {
      plu
      childPlu
    }
    sizeBasedPlu {
      comboPlu
      comboSize
    }
  }
`;
export const VendorConfigsFragmentDoc = gql`
  fragment VendorConfigsFragment on VendorConfigs {
    ncr {
      ...VendorConfigFragment
    }
    ncrDelivery {
      ...VendorConfigFragment
    }
    partner {
      ...VendorConfigFragment
    }
    partnerDelivery {
      ...VendorConfigFragment
    }
    productNumber {
      ...VendorConfigFragment
    }
    productNumberDelivery {
      ...VendorConfigFragment
    }
    sicom {
      ...VendorConfigFragment
    }
    sicomDelivery {
      ...VendorConfigFragment
    }
    qdi {
      ...VendorConfigFragment
    }
    qdiDelivery {
      ...VendorConfigFragment
    }
    rpos {
      ...VendorConfigFragment
    }
    rposDelivery {
      ...VendorConfigFragment
    }
    simplyDelivery {
      ...VendorConfigFragment
    }
    simplyDeliveryDelivery {
      ...VendorConfigFragment
    }
    toshibaLoyalty {
      ...VendorConfigFragment
    }
  }
  ${VendorConfigFragmentDoc}
`;
export const CartEntryFragmentDoc = gql`
  fragment CartEntryFragment on CartEntries {
    _id: lineId
    lineId
    image
    isDonation
    isExtra
    name
    pickerSelections
    price
    quantity
    sanityId
    type
    url
    redeemable
    vendorConfigs {
      ...VendorConfigsFragment
    }
  }
  ${VendorConfigsFragmentDoc}
`;
export const UserOrderFragmentDoc = gql`
  fragment UserOrderFragment on Order {
    _id: rbiOrderId
    rbiOrderId
    createdAt
    updatedAt
    fireOrderIn
    status
    orderNumberFormatted
    delivery {
      dropoff {
        state
        latitude
        longitude
      }
      cancellation {
        reason
        details
      }
      feeCents
      tipCents
      feeDiscountCents
      status
      serviceFeeCents
      smallCartFeeCents
      geographicalFeeCents
      serviceFeeDiscountCents
      unavailabilityReason
    }
    cart {
      discounts {
        name
        value
      }
      payment {
        paymentType
        paymentMethodBrand
        paymentRequestTimeLimit
      }
      fees {
        name
        priceCents
        quantity
        totalCents
      }
      ticketNumber
      storeId
      vatNumber
      serviceMode
      posVendor
      cartVersion
      donations {
        id
        name
        totalCents
      }
      storeAddress {
        addressLine1
        addressLine2
        city
        state
        zip
      }
      storeDetails {
        deliveryHours {
          ...StoreHoursFragment
        }
        diningRoomHours {
          ...StoreHoursFragment
        }
        driveThruHours {
          ...StoreHoursFragment
        }
        franchiseGroupName
        franchiseGroupId
        hasCatering
        hasDelivery
        hasDriveThru
        hasMobileOrdering
        lastActiveDateTime
        latitude
        longitude
        mobileOrderingStatus
        phoneNumber
        posVendor
        status
        storeNumber
      }
      cartEntries {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                }
              }
            }
          }
        }
      }
      reorderCartEntries {
        ...CartEntryFragment
        isInMenu
        children {
          ...CartEntryFragment
          isInMenu
          children {
            ...CartEntryFragment
            isInMenu
            children {
              ...CartEntryFragment
              isInMenu
              children {
                ...CartEntryFragment
                isInMenu
                children {
                  ...CartEntryFragment
                  isInMenu
                }
              }
            }
          }
        }
      }
      subTotalCents
      taxCents
      ticketNumber
      totalCents
      unavailableCartEntries {
        lineId
        sanityId
      }
      wasLoyaltyEarned
      vatNumber
    }
    fulfillmentDetails {
      pickupType
      pickupPin
      pickupReady
    }
    order {
      refund {
        primaryReason
        refundedItems {
          refundAmount
          refundQuantity
          sanityId
          type
          name
          plu
        }
        secondaryReason
        subtotal
        tax
        transactionDateTime
      }
    }
  }
  ${StoreHoursFragmentDoc}
  ${CartEntryFragmentDoc}
`;
export const DeliveryAddressFragmentDoc = gql`
  fragment DeliveryAddressFragment on DeliveryAddress {
    addressLine1
    addressLine2
    city
    country
    latitude
    longitude
    phoneNumber
    route
    state
    streetNumber
    zip
    alias
  }
`;
export const TransactionFragmentDoc = gql`
  fragment TransactionFragment on Transaction {
    id
    shortId
    loyaltyId
    channel
    pointsEarned
    bonusPointsEarned
    pointsUsed
    pointsDeducted
    hasRewards
    transactionDetails {
      currency
      language
      promotions {
        type
        id
      }
      orders {
        productId
        quantity
        price
        parentReferenceId
      }
      fulfillment {
        type
        autoRelease
      }
      posVendor {
        id
        type
        transactionId
      }
      pickupContact {
        name
        phone
        hasArrived
        vehicle {
          make
          model
          color
          licensePlate
        }
      }
      payments {
        amount
        description
        type
        token
        expiration {
          month
          year
        }
      }
      person {
        externalIds {
          type
          value
        }
        phone
        email
      }
    }
    status
    createdAt
    dateUpdated
    additionalReferenceIds {
      id
      type
      description
    }
  }
`;
export const RewardFragmentDoc = gql`
  fragment RewardFragment on Reward {
    id
    rewardBenefitId
    name
    pointCost
    sanityId
    createdAt
    updatedAt
    startDate
    endDate
    locked
    minimumSpend
    minimumTotalSpend
    redemptionPercentage
    limitPerOrder
    remainingPointsNeededForRedemption
    requiredLoyaltyTier
    rewardPrice
  }
`;
export const LoyaltyUserFragmentDoc = gql`
  fragment LoyaltyUserFragment on User {
    id
    name
    email
    phoneNumber
    dateOfBirth
    points
    metadata {
      key
      value
    }
    createdAt
    bestPromotion {
      sanityId
    }
    expirationBuckets {
      expirationDate
      pointsToExpire
    }
    pointsExpiryDateKey
    offerRedemptionAvailability {
      availableAfter
    }
    secondaryIdentifiers {
      identifier
      identifierType
    }
    loyaltyTier {
      loyaltyTierKey
      loyaltyTierExpiryDate
      pointsEarnedInTimeConstraint
    }
  }
`;
export const OffersFragmentDoc = gql`
  fragment OffersFragment on Offer {
    benefits {
      type
      ... on BenefitReference {
        reference: value
      }
      ... on BenefitSwap {
        value {
          ... on Swap {
            type
            from
            to
          }
        }
      }
    }
    configId
    coolDown
    endDate
    endTime
    errors {
      code
      currentValue
      message
      ruleId
      targetValue
    }
    id
    isStackable
    maxRedemptionsDuration
    maxRedemptionsLimit
    metadata {
      key
      value
    }
    minimumSpend
    loyaltyId
    paymentMethod
    questId
    redemptionType
    restaurantGroups
    sanityId
    serviceModes
    startDate
    startTime
    templateId
    type
    validDaysOfTheWeek
  }
`;
export const InvalidIncentiveFragmentDoc = gql`
  fragment InvalidIncentiveFragment on InvalidIncentives {
    errors {
      code
      currentValue
      message
      ruleId
      targetValue
    }
    id
  }
`;
export const LoyaltyCouponFragmentDoc = gql`
  fragment LoyaltyCouponFragment on LoyaltyCouponResponse {
    code
    isValid
    message
    reason
    isRedeemed
    shouldRedeem
    personalizedOfferId
    configId
  }
`;
export const OrderFragmentDoc = gql`
  fragment OrderFragment on Order {
    order {
      refund {
        primaryReason
        refundedItems {
          refundAmount
          refundQuantity
          sanityId
          type
          name
          plu
        }
        secondaryReason
        subtotal
        tax
        transactionDateTime
      }
    }
    orderErrors {
      orderInjectionErrors {
        isConfigurationError
        isMenuError
        isStoreAvailabilityError
        isTimeoutError
        isUnknownError
      }
      posErrors {
        errorCode
        errorName
        message
        rbiErrorCode
        rbiErrorDomain
      }
    }
    loyaltyTransaction {
      id
      pointsEarned
      bonusPointsEarned
      pointsUsed
      pointsBalance
      rewardsUsed
    }
    cart {
      customerName
      cartEntries {
        ...CartEntryFragment
        children {
          ...CartEntryFragment
          children {
            ...CartEntryFragment
            children {
              ...CartEntryFragment
              children {
                ...CartEntryFragment
                children {
                  ...CartEntryFragment
                }
              }
            }
          }
        }
      }
      cartVersion
      discounts {
        name
        value
      }
      donations {
        id
        name
        totalCents
      }
      fees {
        name
        priceCents
        quantity
        totalCents
      }
      guestId
      guestDetails {
        isoCountryCode
      }
      payment {
        cardType
        ccLast4
        panToken
        paymentType
        paymentMethodBrand
        paymentRequestTimeLimit
      }
      posVendor
      rewardsApplied {
        cartId
        rewardId
        timesApplied
      }
      serviceMode
      storeAddress {
        addressLine1
        addressLine2
        city
        state
        zip
      }
      storeDetails {
        latitude
        longitude
        storeNumber
      }
      subTotalCents
      taxCents
      ticketNumber
      totalCents
      unavailableCartEntries {
        lineId
        sanityId
      }
      wasLoyaltyEarned
      vatNumber
    }
    delivery {
      pickup {
        latitude
        longitude
      }
      dropoff {
        arrivalTime
        beforeTime
        afterTime
        addressLine1
        addressLine2
        city
        state
        zip
        latitude
        longitude
        phoneNumber
      }
      driver {
        name
        profileImageUrl
        phoneNumber
      }
      feeCents
      feeDiscountCents
      serviceFeeDiscountCents
      instructions
      status
      tipCents
      serviceFeeCents
      smallCartFeeCents
      geographicalFeeCents
      quotedFeeCents
      baseDeliveryFeeCents
      deliverySurchargeFeeCents
      unavailabilityReason
      preOrderTimeSlots {
        start
        end
      }
    }
    paymentStatus
    createdAt
    updatedAt
    posOrderId
    rbiOrderId
    status
    fireOrderIn
    orderNumberFormatted
  }
  ${CartEntryFragmentDoc}
`;
export const PricingAndAvailabilityFragmentDoc = gql`
  fragment PricingAndAvailability on Entity {
    id
    isAvailable
    price {
      default
      max
      min
      overrides {
        key
        price
      }
    }
  }
`;
export const CaloriesFragmentDoc = gql`
  fragment Calories on Entity {
    calories {
      default
      min
      max
    }
  }
`;
export const SaveDeliveryAddressDocument = gql`
  mutation SaveDeliveryAddress($input: SaveDeliveryAddressInput!) @gateway {
    saveDeliveryAddress(input: $input) {
      deliveryAddresses {
        ...DeliveryAddressFragment
      }
    }
  }
  ${DeliveryAddressFragmentDoc}
`;
export type ISaveDeliveryAddressMutationFn = Apollo.MutationFunction<
  ISaveDeliveryAddressMutation,
  ISaveDeliveryAddressMutationVariables
>;

/**
 * __useSaveDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useSaveDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDeliveryAddressMutation, { data, loading, error }] = useSaveDeliveryAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDeliveryAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveDeliveryAddressMutation,
    ISaveDeliveryAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISaveDeliveryAddressMutation, ISaveDeliveryAddressMutationVariables>(
    SaveDeliveryAddressDocument,
    options
  );
}
export type SaveDeliveryAddressMutationHookResult = ReturnType<
  typeof useSaveDeliveryAddressMutation
>;
export type SaveDeliveryAddressMutationResult = Apollo.MutationResult<ISaveDeliveryAddressMutation>;
export type SaveDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<
  ISaveDeliveryAddressMutation,
  ISaveDeliveryAddressMutationVariables
>;
export const UpdateDeliveryAddressDocument = gql`
  mutation UpdateDeliveryAddress($input: UpdateDeliveryAddressInput!) @gateway {
    updateDeliveryAddress(input: $input) {
      deliveryAddresses {
        ...DeliveryAddressFragment
      }
    }
  }
  ${DeliveryAddressFragmentDoc}
`;
export type IUpdateDeliveryAddressMutationFn = Apollo.MutationFunction<
  IUpdateDeliveryAddressMutation,
  IUpdateDeliveryAddressMutationVariables
>;

/**
 * __useUpdateDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryAddressMutation, { data, loading, error }] = useUpdateDeliveryAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateDeliveryAddressMutation,
    IUpdateDeliveryAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IUpdateDeliveryAddressMutation,
    IUpdateDeliveryAddressMutationVariables
  >(UpdateDeliveryAddressDocument, options);
}
export type UpdateDeliveryAddressMutationHookResult = ReturnType<
  typeof useUpdateDeliveryAddressMutation
>;
export type UpdateDeliveryAddressMutationResult =
  Apollo.MutationResult<IUpdateDeliveryAddressMutation>;
export type UpdateDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<
  IUpdateDeliveryAddressMutation,
  IUpdateDeliveryAddressMutationVariables
>;
export const RemoveDeliveryAddressDocument = gql`
  mutation RemoveDeliveryAddress($input: RemoveDeliveryAddressInput!) @authRequired @gateway {
    removeDeliveryAddress(input: $input) {
      deliveryAddresses {
        ...DeliveryAddressFragment
      }
    }
  }
  ${DeliveryAddressFragmentDoc}
`;
export type IRemoveDeliveryAddressMutationFn = Apollo.MutationFunction<
  IRemoveDeliveryAddressMutation,
  IRemoveDeliveryAddressMutationVariables
>;

/**
 * __useRemoveDeliveryAddressMutation__
 *
 * To run a mutation, you first call `useRemoveDeliveryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeliveryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeliveryAddressMutation, { data, loading, error }] = useRemoveDeliveryAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveDeliveryAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRemoveDeliveryAddressMutation,
    IRemoveDeliveryAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IRemoveDeliveryAddressMutation,
    IRemoveDeliveryAddressMutationVariables
  >(RemoveDeliveryAddressDocument, options);
}
export type RemoveDeliveryAddressMutationHookResult = ReturnType<
  typeof useRemoveDeliveryAddressMutation
>;
export type RemoveDeliveryAddressMutationResult =
  Apollo.MutationResult<IRemoveDeliveryAddressMutation>;
export type RemoveDeliveryAddressMutationOptions = Apollo.BaseMutationOptions<
  IRemoveDeliveryAddressMutation,
  IRemoveDeliveryAddressMutationVariables
>;
export const GetListFeesByTierDocument = gql`
  query GetListFeesByTier($options: GetFeesByTierInput!) @authRequired @authGuestAllowed @gateway {
    listFeesByTier(options: $options) {
      minTier
      maxTier
      deliveryFee
      serviceFee
      totalDeliveryFee
      totalServiceFee
    }
  }
`;

/**
 * __useGetListFeesByTierQuery__
 *
 * To run a query within a React component, call `useGetListFeesByTierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListFeesByTierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListFeesByTierQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetListFeesByTierQuery(
  baseOptions: Apollo.QueryHookOptions<IGetListFeesByTierQuery, IGetListFeesByTierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetListFeesByTierQuery, IGetListFeesByTierQueryVariables>(
    GetListFeesByTierDocument,
    options
  );
}
export function useGetListFeesByTierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetListFeesByTierQuery,
    IGetListFeesByTierQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetListFeesByTierQuery, IGetListFeesByTierQueryVariables>(
    GetListFeesByTierDocument,
    options
  );
}
export type GetListFeesByTierQueryHookResult = ReturnType<typeof useGetListFeesByTierQuery>;
export type GetListFeesByTierLazyQueryHookResult = ReturnType<typeof useGetListFeesByTierLazyQuery>;
export type GetListFeesByTierQueryResult = Apollo.QueryResult<
  IGetListFeesByTierQuery,
  IGetListFeesByTierQueryVariables
>;
export const GetOrderDocument = gql`
  query GetOrder($rbiOrderId: ID!, $handleCommitError: Boolean) @gateway {
    order(rbiOrderId: $rbiOrderId, handleCommitError: $handleCommitError) {
      id
      ...OrderFragment
    }
  }
  ${OrderFragmentDoc}
`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *      rbiOrderId: // value for 'rbiOrderId'
 *      handleCommitError: // value for 'handleCommitError'
 *   },
 * });
 */
export function useGetOrderQuery(
  baseOptions: Apollo.QueryHookOptions<IGetOrderQuery, IGetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetOrderQuery, IGetOrderQueryVariables>(GetOrderDocument, options);
}
export function useGetOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetOrderQuery, IGetOrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetOrderQuery, IGetOrderQueryVariables>(GetOrderDocument, options);
}
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<IGetOrderQuery, IGetOrderQueryVariables>;
export const GetUserOrdersDocument = gql`
  query GetUserOrders(
    $userId: ID
    $limit: Int
    $resumeAfter: String
    $orderStatuses: [RbiOrderStatus!]
    $createdAfter: String
    $shouldFilterByRegion: Boolean
    $serviceModes: [ServiceMode]
  ) @authRequired @authGuestAllowed @gateway {
    userOrders(
      userId: $userId
      limit: $limit
      resumeAfter: $resumeAfter
      orderStatuses: $orderStatuses
      createdAfter: $createdAfter
      shouldFilterByRegion: $shouldFilterByRegion
      serviceModes: $serviceModes
    ) {
      count
      resumeAfter
      orders {
        ...UserOrderFragment
      }
    }
  }
  ${UserOrderFragmentDoc}
`;

/**
 * __useGetUserOrdersQuery__
 *
 * To run a query within a React component, call `useGetUserOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserOrdersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      resumeAfter: // value for 'resumeAfter'
 *      orderStatuses: // value for 'orderStatuses'
 *      createdAfter: // value for 'createdAfter'
 *      shouldFilterByRegion: // value for 'shouldFilterByRegion'
 *      serviceModes: // value for 'serviceModes'
 *   },
 * });
 */
export function useGetUserOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>(
    GetUserOrdersDocument,
    options
  );
}
export function useGetUserOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserOrdersQuery, IGetUserOrdersQueryVariables>(
    GetUserOrdersDocument,
    options
  );
}
export type GetUserOrdersQueryHookResult = ReturnType<typeof useGetUserOrdersQuery>;
export type GetUserOrdersLazyQueryHookResult = ReturnType<typeof useGetUserOrdersLazyQuery>;
export type GetUserOrdersQueryResult = Apollo.QueryResult<
  IGetUserOrdersQuery,
  IGetUserOrdersQueryVariables
>;
export const PriceOrderDocument = gql`
  mutation PriceOrder($input: PriceOrderInput!, $delivery: PriceDeliveryInput)
  @authRequired
  @authGuestAllowed
  @gateway {
    priceOrder(input: $input, delivery: $delivery) {
      rbiOrderId
      status
    }
  }
`;
export type IPriceOrderMutationFn = Apollo.MutationFunction<
  IPriceOrderMutation,
  IPriceOrderMutationVariables
>;

/**
 * __usePriceOrderMutation__
 *
 * To run a mutation, you first call `usePriceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePriceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [priceOrderMutation, { data, loading, error }] = usePriceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      delivery: // value for 'delivery'
 *   },
 * });
 */
export function usePriceOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<IPriceOrderMutation, IPriceOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IPriceOrderMutation, IPriceOrderMutationVariables>(
    PriceOrderDocument,
    options
  );
}
export type PriceOrderMutationHookResult = ReturnType<typeof usePriceOrderMutation>;
export type PriceOrderMutationResult = Apollo.MutationResult<IPriceOrderMutation>;
export type PriceOrderMutationOptions = Apollo.BaseMutationOptions<
  IPriceOrderMutation,
  IPriceOrderMutationVariables
>;
export const CommitOrderDocument = gql`
  mutation CommitOrder(
    $input: CommitOrderInput!
    $delivery: CommitDeliveryInput
    $skipCoolingPeriod: Boolean
  ) @authRequired @authGuestAllowed @gateway {
    commitOrder(input: $input, delivery: $delivery, skipCoolingPeriod: $skipCoolingPeriod) {
      rbiOrderId
      status
    }
  }
`;
export type ICommitOrderMutationFn = Apollo.MutationFunction<
  ICommitOrderMutation,
  ICommitOrderMutationVariables
>;

/**
 * __useCommitOrderMutation__
 *
 * To run a mutation, you first call `useCommitOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitOrderMutation, { data, loading, error }] = useCommitOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      delivery: // value for 'delivery'
 *      skipCoolingPeriod: // value for 'skipCoolingPeriod'
 *   },
 * });
 */
export function useCommitOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ICommitOrderMutation, ICommitOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICommitOrderMutation, ICommitOrderMutationVariables>(
    CommitOrderDocument,
    options
  );
}
export type CommitOrderMutationHookResult = ReturnType<typeof useCommitOrderMutation>;
export type CommitOrderMutationResult = Apollo.MutationResult<ICommitOrderMutation>;
export type CommitOrderMutationOptions = Apollo.BaseMutationOptions<
  ICommitOrderMutation,
  ICommitOrderMutationVariables
>;
export const CreateEvertecPaymentSessionDocument = gql`
  mutation CreateEvertecPaymentSession($input: InitiatePaymentInput!)
  @authRequired
  @authGuestAllowed
  @gateway {
    createEvertecPaymentSession(input: $input) {
      processUrl
    }
  }
`;
export type ICreateEvertecPaymentSessionMutationFn = Apollo.MutationFunction<
  ICreateEvertecPaymentSessionMutation,
  ICreateEvertecPaymentSessionMutationVariables
>;

/**
 * __useCreateEvertecPaymentSessionMutation__
 *
 * To run a mutation, you first call `useCreateEvertecPaymentSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEvertecPaymentSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEvertecPaymentSessionMutation, { data, loading, error }] = useCreateEvertecPaymentSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEvertecPaymentSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateEvertecPaymentSessionMutation,
    ICreateEvertecPaymentSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ICreateEvertecPaymentSessionMutation,
    ICreateEvertecPaymentSessionMutationVariables
  >(CreateEvertecPaymentSessionDocument, options);
}
export type CreateEvertecPaymentSessionMutationHookResult = ReturnType<
  typeof useCreateEvertecPaymentSessionMutation
>;
export type CreateEvertecPaymentSessionMutationResult =
  Apollo.MutationResult<ICreateEvertecPaymentSessionMutation>;
export type CreateEvertecPaymentSessionMutationOptions = Apollo.BaseMutationOptions<
  ICreateEvertecPaymentSessionMutation,
  ICreateEvertecPaymentSessionMutationVariables
>;
export const GuestTokenDocument = gql`
  mutation GuestToken($input: GenerateGuestTokenInput!) @gateway {
    generateGuestToken(guestInfo: $input)
  }
`;
export type IGuestTokenMutationFn = Apollo.MutationFunction<
  IGuestTokenMutation,
  IGuestTokenMutationVariables
>;

/**
 * __useGuestTokenMutation__
 *
 * To run a mutation, you first call `useGuestTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestTokenMutation, { data, loading, error }] = useGuestTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<IGuestTokenMutation, IGuestTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IGuestTokenMutation, IGuestTokenMutationVariables>(
    GuestTokenDocument,
    options
  );
}
export type GuestTokenMutationHookResult = ReturnType<typeof useGuestTokenMutation>;
export type GuestTokenMutationResult = Apollo.MutationResult<IGuestTokenMutation>;
export type GuestTokenMutationOptions = Apollo.BaseMutationOptions<
  IGuestTokenMutation,
  IGuestTokenMutationVariables
>;
export const LoyaltyUserDocument = gql`
  query LoyaltyUser($loyaltyId: ID!) @gateway {
    loyaltyUser(loyaltyId: $loyaltyId) {
      ...LoyaltyUserFragment
    }
  }
  ${LoyaltyUserFragmentDoc}
`;

/**
 * __useLoyaltyUserQuery__
 *
 * To run a query within a React component, call `useLoyaltyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyUserQuery({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *   },
 * });
 */
export function useLoyaltyUserQuery(
  baseOptions: Apollo.QueryHookOptions<ILoyaltyUserQuery, ILoyaltyUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILoyaltyUserQuery, ILoyaltyUserQueryVariables>(
    LoyaltyUserDocument,
    options
  );
}
export function useLoyaltyUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ILoyaltyUserQuery, ILoyaltyUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILoyaltyUserQuery, ILoyaltyUserQueryVariables>(
    LoyaltyUserDocument,
    options
  );
}
export type LoyaltyUserQueryHookResult = ReturnType<typeof useLoyaltyUserQuery>;
export type LoyaltyUserLazyQueryHookResult = ReturnType<typeof useLoyaltyUserLazyQuery>;
export type LoyaltyUserQueryResult = Apollo.QueryResult<
  ILoyaltyUserQuery,
  ILoyaltyUserQueryVariables
>;
export const LoyaltyUserTransactionsDocument = gql`
  query LoyaltyUserTransactions($loyaltyId: ID!) @gateway {
    loyaltyUser(loyaltyId: $loyaltyId) {
      id
      transactions(where: { statuses: [CLAIMED, PENDING], excludedChannels: [ORDERLESS] }) {
        ...TransactionFragment
      }
    }
  }
  ${TransactionFragmentDoc}
`;

/**
 * __useLoyaltyUserTransactionsQuery__
 *
 * To run a query within a React component, call `useLoyaltyUserTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyUserTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyUserTransactionsQuery({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *   },
 * });
 */
export function useLoyaltyUserTransactionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ILoyaltyUserTransactionsQuery,
    ILoyaltyUserTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILoyaltyUserTransactionsQuery, ILoyaltyUserTransactionsQueryVariables>(
    LoyaltyUserTransactionsDocument,
    options
  );
}
export function useLoyaltyUserTransactionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ILoyaltyUserTransactionsQuery,
    ILoyaltyUserTransactionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILoyaltyUserTransactionsQuery, ILoyaltyUserTransactionsQueryVariables>(
    LoyaltyUserTransactionsDocument,
    options
  );
}
export type LoyaltyUserTransactionsQueryHookResult = ReturnType<
  typeof useLoyaltyUserTransactionsQuery
>;
export type LoyaltyUserTransactionsLazyQueryHookResult = ReturnType<
  typeof useLoyaltyUserTransactionsLazyQuery
>;
export type LoyaltyUserTransactionsQueryResult = Apollo.QueryResult<
  ILoyaltyUserTransactionsQuery,
  ILoyaltyUserTransactionsQueryVariables
>;
export const LoyaltyUserRewardsDocument = gql`
  query LoyaltyUserRewards($loyaltyId: ID!, $where: RewardsInput) @gateway {
    loyaltyUser(loyaltyId: $loyaltyId) {
      id
      rewards(where: $where) {
        ...RewardFragment
      }
    }
  }
  ${RewardFragmentDoc}
`;

/**
 * __useLoyaltyUserRewardsQuery__
 *
 * To run a query within a React component, call `useLoyaltyUserRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyUserRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyUserRewardsQuery({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoyaltyUserRewardsQuery(
  baseOptions: Apollo.QueryHookOptions<ILoyaltyUserRewardsQuery, ILoyaltyUserRewardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILoyaltyUserRewardsQuery, ILoyaltyUserRewardsQueryVariables>(
    LoyaltyUserRewardsDocument,
    options
  );
}
export function useLoyaltyUserRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ILoyaltyUserRewardsQuery,
    ILoyaltyUserRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILoyaltyUserRewardsQuery, ILoyaltyUserRewardsQueryVariables>(
    LoyaltyUserRewardsDocument,
    options
  );
}
export type LoyaltyUserRewardsQueryHookResult = ReturnType<typeof useLoyaltyUserRewardsQuery>;
export type LoyaltyUserRewardsLazyQueryHookResult = ReturnType<
  typeof useLoyaltyUserRewardsLazyQuery
>;
export type LoyaltyUserRewardsQueryResult = Apollo.QueryResult<
  ILoyaltyUserRewardsQuery,
  ILoyaltyUserRewardsQueryVariables
>;
export const LoyaltyUserOffersDocument = gql`
  query LoyaltyUserOffers($loyaltyId: ID!, $where: OffersInput) @gateway {
    loyaltyUser(loyaltyId: $loyaltyId) {
      id
      offerRedemptionAvailability {
        availableAfter
      }
      offers(where: $where) {
        ...OffersFragment
      }
    }
  }
  ${OffersFragmentDoc}
`;

/**
 * __useLoyaltyUserOffersQuery__
 *
 * To run a query within a React component, call `useLoyaltyUserOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyUserOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyUserOffersQuery({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoyaltyUserOffersQuery(
  baseOptions: Apollo.QueryHookOptions<ILoyaltyUserOffersQuery, ILoyaltyUserOffersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILoyaltyUserOffersQuery, ILoyaltyUserOffersQueryVariables>(
    LoyaltyUserOffersDocument,
    options
  );
}
export function useLoyaltyUserOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ILoyaltyUserOffersQuery,
    ILoyaltyUserOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILoyaltyUserOffersQuery, ILoyaltyUserOffersQueryVariables>(
    LoyaltyUserOffersDocument,
    options
  );
}
export type LoyaltyUserOffersQueryHookResult = ReturnType<typeof useLoyaltyUserOffersQuery>;
export type LoyaltyUserOffersLazyQueryHookResult = ReturnType<typeof useLoyaltyUserOffersLazyQuery>;
export type LoyaltyUserOffersQueryResult = Apollo.QueryResult<
  ILoyaltyUserOffersQuery,
  ILoyaltyUserOffersQueryVariables
>;
export const LoyaltyOffersDocument = gql`
  query LoyaltyOffers(
    $ids: [ID!]
    $loyaltyId: ID
    $omitInvalids: Boolean
    $redemptionTypes: [OfferRedemptionType!]
    $serviceMode: ServiceMode
    $storeId: String
    $subtotalAmount: Int
    $where: OffersInput
  ) @gateway {
    loyaltyOffers(
      ids: $ids
      loyaltyId: $loyaltyId
      omitInvalids: $omitInvalids
      redemptionTypes: $redemptionTypes
      serviceMode: $serviceMode
      storeId: $storeId
      subtotalAmount: $subtotalAmount
      where: $where
    ) {
      ...OffersFragment
    }
  }
  ${OffersFragmentDoc}
`;

/**
 * __useLoyaltyOffersQuery__
 *
 * To run a query within a React component, call `useLoyaltyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyOffersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      loyaltyId: // value for 'loyaltyId'
 *      omitInvalids: // value for 'omitInvalids'
 *      redemptionTypes: // value for 'redemptionTypes'
 *      serviceMode: // value for 'serviceMode'
 *      storeId: // value for 'storeId'
 *      subtotalAmount: // value for 'subtotalAmount'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLoyaltyOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<ILoyaltyOffersQuery, ILoyaltyOffersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILoyaltyOffersQuery, ILoyaltyOffersQueryVariables>(
    LoyaltyOffersDocument,
    options
  );
}
export function useLoyaltyOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ILoyaltyOffersQuery, ILoyaltyOffersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILoyaltyOffersQuery, ILoyaltyOffersQueryVariables>(
    LoyaltyOffersDocument,
    options
  );
}
export type LoyaltyOffersQueryHookResult = ReturnType<typeof useLoyaltyOffersQuery>;
export type LoyaltyOffersLazyQueryHookResult = ReturnType<typeof useLoyaltyOffersLazyQuery>;
export type LoyaltyOffersQueryResult = Apollo.QueryResult<
  ILoyaltyOffersQuery,
  ILoyaltyOffersQueryVariables
>;
export const LoyaltyEngineRewardsDocument = gql`
  query LoyaltyEngineRewards($loyaltyId: ID!) @gateway {
    loyaltyRewards(loyaltyId: $loyaltyId) {
      ...RewardFragment
    }
  }
  ${RewardFragmentDoc}
`;

/**
 * __useLoyaltyEngineRewardsQuery__
 *
 * To run a query within a React component, call `useLoyaltyEngineRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyEngineRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyEngineRewardsQuery({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *   },
 * });
 */
export function useLoyaltyEngineRewardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ILoyaltyEngineRewardsQuery,
    ILoyaltyEngineRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILoyaltyEngineRewardsQuery, ILoyaltyEngineRewardsQueryVariables>(
    LoyaltyEngineRewardsDocument,
    options
  );
}
export function useLoyaltyEngineRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ILoyaltyEngineRewardsQuery,
    ILoyaltyEngineRewardsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILoyaltyEngineRewardsQuery, ILoyaltyEngineRewardsQueryVariables>(
    LoyaltyEngineRewardsDocument,
    options
  );
}
export type LoyaltyEngineRewardsQueryHookResult = ReturnType<typeof useLoyaltyEngineRewardsQuery>;
export type LoyaltyEngineRewardsLazyQueryHookResult = ReturnType<
  typeof useLoyaltyEngineRewardsLazyQuery
>;
export type LoyaltyEngineRewardsQueryResult = Apollo.QueryResult<
  ILoyaltyEngineRewardsQuery,
  ILoyaltyEngineRewardsQueryVariables
>;
export const LoyaltyNotificationsRedeemableDocument = gql`
  query LoyaltyNotificationsRedeemable(
    $loyaltyId: ID!
    $rewardsInput: RewardsInput
    $offersInput: OffersInput
  ) @gateway {
    loyaltyUser(loyaltyId: $loyaltyId) {
      id
      rewards(where: $rewardsInput) {
        id
      }
      promotions {
        id
      }
      offers(where: $offersInput) {
        id
      }
    }
  }
`;

/**
 * __useLoyaltyNotificationsRedeemableQuery__
 *
 * To run a query within a React component, call `useLoyaltyNotificationsRedeemableQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyNotificationsRedeemableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyNotificationsRedeemableQuery({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *      rewardsInput: // value for 'rewardsInput'
 *      offersInput: // value for 'offersInput'
 *   },
 * });
 */
export function useLoyaltyNotificationsRedeemableQuery(
  baseOptions: Apollo.QueryHookOptions<
    ILoyaltyNotificationsRedeemableQuery,
    ILoyaltyNotificationsRedeemableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ILoyaltyNotificationsRedeemableQuery,
    ILoyaltyNotificationsRedeemableQueryVariables
  >(LoyaltyNotificationsRedeemableDocument, options);
}
export function useLoyaltyNotificationsRedeemableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ILoyaltyNotificationsRedeemableQuery,
    ILoyaltyNotificationsRedeemableQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ILoyaltyNotificationsRedeemableQuery,
    ILoyaltyNotificationsRedeemableQueryVariables
  >(LoyaltyNotificationsRedeemableDocument, options);
}
export type LoyaltyNotificationsRedeemableQueryHookResult = ReturnType<
  typeof useLoyaltyNotificationsRedeemableQuery
>;
export type LoyaltyNotificationsRedeemableLazyQueryHookResult = ReturnType<
  typeof useLoyaltyNotificationsRedeemableLazyQuery
>;
export type LoyaltyNotificationsRedeemableQueryResult = Apollo.QueryResult<
  ILoyaltyNotificationsRedeemableQuery,
  ILoyaltyNotificationsRedeemableQueryVariables
>;
export const LoyaltyGenerateOtpDocument = gql`
  mutation LoyaltyGenerateOtp(
    $inRestaurantOrder: [InRestaurantOrderLineItem!]!
    $loyaltyId: ID!
    $restaurantId: ID
  ) @gateway {
    generateOtp(
      inRestaurantOrder: $inRestaurantOrder
      loyaltyId: $loyaltyId
      restaurantId: $restaurantId
    ) {
      password
      expirationTime
    }
  }
`;
export type ILoyaltyGenerateOtpMutationFn = Apollo.MutationFunction<
  ILoyaltyGenerateOtpMutation,
  ILoyaltyGenerateOtpMutationVariables
>;

/**
 * __useLoyaltyGenerateOtpMutation__
 *
 * To run a mutation, you first call `useLoyaltyGenerateOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyGenerateOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loyaltyGenerateOtpMutation, { data, loading, error }] = useLoyaltyGenerateOtpMutation({
 *   variables: {
 *      inRestaurantOrder: // value for 'inRestaurantOrder'
 *      loyaltyId: // value for 'loyaltyId'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useLoyaltyGenerateOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILoyaltyGenerateOtpMutation,
    ILoyaltyGenerateOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ILoyaltyGenerateOtpMutation, ILoyaltyGenerateOtpMutationVariables>(
    LoyaltyGenerateOtpDocument,
    options
  );
}
export type LoyaltyGenerateOtpMutationHookResult = ReturnType<typeof useLoyaltyGenerateOtpMutation>;
export type LoyaltyGenerateOtpMutationResult = Apollo.MutationResult<ILoyaltyGenerateOtpMutation>;
export type LoyaltyGenerateOtpMutationOptions = Apollo.BaseMutationOptions<
  ILoyaltyGenerateOtpMutation,
  ILoyaltyGenerateOtpMutationVariables
>;
export const ProcessSmgCodeDocument = gql`
  mutation ProcessSmgCode($loyaltyId: ID!, $smgCode: String!, $transactionDate: String) @gateway {
    processSmgCode(loyaltyId: $loyaltyId, smgCode: $smgCode, transactionDate: $transactionDate) {
      pointsEarned
      status
    }
  }
`;
export type IProcessSmgCodeMutationFn = Apollo.MutationFunction<
  IProcessSmgCodeMutation,
  IProcessSmgCodeMutationVariables
>;

/**
 * __useProcessSmgCodeMutation__
 *
 * To run a mutation, you first call `useProcessSmgCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessSmgCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processSmgCodeMutation, { data, loading, error }] = useProcessSmgCodeMutation({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *      smgCode: // value for 'smgCode'
 *      transactionDate: // value for 'transactionDate'
 *   },
 * });
 */
export function useProcessSmgCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IProcessSmgCodeMutation,
    IProcessSmgCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IProcessSmgCodeMutation, IProcessSmgCodeMutationVariables>(
    ProcessSmgCodeDocument,
    options
  );
}
export type ProcessSmgCodeMutationHookResult = ReturnType<typeof useProcessSmgCodeMutation>;
export type ProcessSmgCodeMutationResult = Apollo.MutationResult<IProcessSmgCodeMutation>;
export type ProcessSmgCodeMutationOptions = Apollo.BaseMutationOptions<
  IProcessSmgCodeMutation,
  IProcessSmgCodeMutationVariables
>;
export const OrderSelectionDocument = gql`
  query orderSelection($otp: String!) @gateway {
    orderSelection(otp: $otp) {
      _id: loyaltyId
      status
      expiry
    }
  }
`;

/**
 * __useOrderSelectionQuery__
 *
 * To run a query within a React component, call `useOrderSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderSelectionQuery({
 *   variables: {
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useOrderSelectionQuery(
  baseOptions: Apollo.QueryHookOptions<IOrderSelectionQuery, IOrderSelectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOrderSelectionQuery, IOrderSelectionQueryVariables>(
    OrderSelectionDocument,
    options
  );
}
export function useOrderSelectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IOrderSelectionQuery, IOrderSelectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOrderSelectionQuery, IOrderSelectionQueryVariables>(
    OrderSelectionDocument,
    options
  );
}
export type OrderSelectionQueryHookResult = ReturnType<typeof useOrderSelectionQuery>;
export type OrderSelectionLazyQueryHookResult = ReturnType<typeof useOrderSelectionLazyQuery>;
export type OrderSelectionQueryResult = Apollo.QueryResult<
  IOrderSelectionQuery,
  IOrderSelectionQueryVariables
>;
export const CreateOrderlessTransactionDocument = gql`
  mutation CreateOrderlessTransaction($input: OrderlessTransactionInput!) @gateway {
    createOrderlessTransaction(input: $input) {
      id
    }
  }
`;
export type ICreateOrderlessTransactionMutationFn = Apollo.MutationFunction<
  ICreateOrderlessTransactionMutation,
  ICreateOrderlessTransactionMutationVariables
>;

/**
 * __useCreateOrderlessTransactionMutation__
 *
 * To run a mutation, you first call `useCreateOrderlessTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderlessTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderlessTransactionMutation, { data, loading, error }] = useCreateOrderlessTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderlessTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateOrderlessTransactionMutation,
    ICreateOrderlessTransactionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ICreateOrderlessTransactionMutation,
    ICreateOrderlessTransactionMutationVariables
  >(CreateOrderlessTransactionDocument, options);
}
export type CreateOrderlessTransactionMutationHookResult = ReturnType<
  typeof useCreateOrderlessTransactionMutation
>;
export type CreateOrderlessTransactionMutationResult =
  Apollo.MutationResult<ICreateOrderlessTransactionMutation>;
export type CreateOrderlessTransactionMutationOptions = Apollo.BaseMutationOptions<
  ICreateOrderlessTransactionMutation,
  ICreateOrderlessTransactionMutationVariables
>;
export const LinkPhysicalCardToLoyaltyUserDocument = gql`
  mutation LinkPhysicalCardToLoyaltyUser($cardNumber: String!, $loyaltyUserId: ID!) @gateway {
    associateGuestUser(identifier: $cardNumber, loyaltyId: $loyaltyUserId) {
      activationDateTime
      guestBalance
      totalBalance
      linkingDateTime
    }
  }
`;
export type ILinkPhysicalCardToLoyaltyUserMutationFn = Apollo.MutationFunction<
  ILinkPhysicalCardToLoyaltyUserMutation,
  ILinkPhysicalCardToLoyaltyUserMutationVariables
>;

/**
 * __useLinkPhysicalCardToLoyaltyUserMutation__
 *
 * To run a mutation, you first call `useLinkPhysicalCardToLoyaltyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkPhysicalCardToLoyaltyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkPhysicalCardToLoyaltyUserMutation, { data, loading, error }] = useLinkPhysicalCardToLoyaltyUserMutation({
 *   variables: {
 *      cardNumber: // value for 'cardNumber'
 *      loyaltyUserId: // value for 'loyaltyUserId'
 *   },
 * });
 */
export function useLinkPhysicalCardToLoyaltyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILinkPhysicalCardToLoyaltyUserMutation,
    ILinkPhysicalCardToLoyaltyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ILinkPhysicalCardToLoyaltyUserMutation,
    ILinkPhysicalCardToLoyaltyUserMutationVariables
  >(LinkPhysicalCardToLoyaltyUserDocument, options);
}
export type LinkPhysicalCardToLoyaltyUserMutationHookResult = ReturnType<
  typeof useLinkPhysicalCardToLoyaltyUserMutation
>;
export type LinkPhysicalCardToLoyaltyUserMutationResult =
  Apollo.MutationResult<ILinkPhysicalCardToLoyaltyUserMutation>;
export type LinkPhysicalCardToLoyaltyUserMutationOptions = Apollo.BaseMutationOptions<
  ILinkPhysicalCardToLoyaltyUserMutation,
  ILinkPhysicalCardToLoyaltyUserMutationVariables
>;
export const UnlinkPhysicalCardFromLoyaltyUserDocument = gql`
  mutation UnlinkPhysicalCardFromLoyaltyUser($cardNumber: String!) @gateway {
    removeSecondaryIdAssociation(identifier: $cardNumber, identifierType: LOYALTY12DIGITSWIPECARD)
  }
`;
export type IUnlinkPhysicalCardFromLoyaltyUserMutationFn = Apollo.MutationFunction<
  IUnlinkPhysicalCardFromLoyaltyUserMutation,
  IUnlinkPhysicalCardFromLoyaltyUserMutationVariables
>;

/**
 * __useUnlinkPhysicalCardFromLoyaltyUserMutation__
 *
 * To run a mutation, you first call `useUnlinkPhysicalCardFromLoyaltyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkPhysicalCardFromLoyaltyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkPhysicalCardFromLoyaltyUserMutation, { data, loading, error }] = useUnlinkPhysicalCardFromLoyaltyUserMutation({
 *   variables: {
 *      cardNumber: // value for 'cardNumber'
 *   },
 * });
 */
export function useUnlinkPhysicalCardFromLoyaltyUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUnlinkPhysicalCardFromLoyaltyUserMutation,
    IUnlinkPhysicalCardFromLoyaltyUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IUnlinkPhysicalCardFromLoyaltyUserMutation,
    IUnlinkPhysicalCardFromLoyaltyUserMutationVariables
  >(UnlinkPhysicalCardFromLoyaltyUserDocument, options);
}
export type UnlinkPhysicalCardFromLoyaltyUserMutationHookResult = ReturnType<
  typeof useUnlinkPhysicalCardFromLoyaltyUserMutation
>;
export type UnlinkPhysicalCardFromLoyaltyUserMutationResult =
  Apollo.MutationResult<IUnlinkPhysicalCardFromLoyaltyUserMutation>;
export type UnlinkPhysicalCardFromLoyaltyUserMutationOptions = Apollo.BaseMutationOptions<
  IUnlinkPhysicalCardFromLoyaltyUserMutation,
  IUnlinkPhysicalCardFromLoyaltyUserMutationVariables
>;
export const OrderSelectionByTypeDocument = gql`
  query orderSelectionByType($key: String!, $ostType: OstType!) @gateway {
    orderSelectionByType(key: $key, ostType: $ostType) {
      _id: loyaltyId
      status
      expiry
      status
      storeHasLoyalty
    }
  }
`;

/**
 * __useOrderSelectionByTypeQuery__
 *
 * To run a query within a React component, call `useOrderSelectionByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderSelectionByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderSelectionByTypeQuery({
 *   variables: {
 *      key: // value for 'key'
 *      ostType: // value for 'ostType'
 *   },
 * });
 */
export function useOrderSelectionByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    IOrderSelectionByTypeQuery,
    IOrderSelectionByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IOrderSelectionByTypeQuery, IOrderSelectionByTypeQueryVariables>(
    OrderSelectionByTypeDocument,
    options
  );
}
export function useOrderSelectionByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IOrderSelectionByTypeQuery,
    IOrderSelectionByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IOrderSelectionByTypeQuery, IOrderSelectionByTypeQueryVariables>(
    OrderSelectionByTypeDocument,
    options
  );
}
export type OrderSelectionByTypeQueryHookResult = ReturnType<typeof useOrderSelectionByTypeQuery>;
export type OrderSelectionByTypeLazyQueryHookResult = ReturnType<
  typeof useOrderSelectionByTypeLazyQuery
>;
export type OrderSelectionByTypeQueryResult = Apollo.QueryResult<
  IOrderSelectionByTypeQuery,
  IOrderSelectionByTypeQueryVariables
>;
export const CreateOrderSelectionByTypeDocument = gql`
  mutation CreateOrderSelectionByType(
    $ostType: OstType!
    $inRestaurantOrder: [InRestaurantOrderLineItem!]!
    $loyaltyId: ID!
    $restaurantId: ID
  ) @gateway {
    createOrderSelectionByType(
      ostType: $ostType
      inRestaurantOrder: $inRestaurantOrder
      loyaltyId: $loyaltyId
      restaurantId: $restaurantId
    ) {
      expirationTime
      key
    }
  }
`;
export type ICreateOrderSelectionByTypeMutationFn = Apollo.MutationFunction<
  ICreateOrderSelectionByTypeMutation,
  ICreateOrderSelectionByTypeMutationVariables
>;

/**
 * __useCreateOrderSelectionByTypeMutation__
 *
 * To run a mutation, you first call `useCreateOrderSelectionByTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderSelectionByTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderSelectionByTypeMutation, { data, loading, error }] = useCreateOrderSelectionByTypeMutation({
 *   variables: {
 *      ostType: // value for 'ostType'
 *      inRestaurantOrder: // value for 'inRestaurantOrder'
 *      loyaltyId: // value for 'loyaltyId'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useCreateOrderSelectionByTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateOrderSelectionByTypeMutation,
    ICreateOrderSelectionByTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ICreateOrderSelectionByTypeMutation,
    ICreateOrderSelectionByTypeMutationVariables
  >(CreateOrderSelectionByTypeDocument, options);
}
export type CreateOrderSelectionByTypeMutationHookResult = ReturnType<
  typeof useCreateOrderSelectionByTypeMutation
>;
export type CreateOrderSelectionByTypeMutationResult =
  Apollo.MutationResult<ICreateOrderSelectionByTypeMutation>;
export type CreateOrderSelectionByTypeMutationOptions = Apollo.BaseMutationOptions<
  ICreateOrderSelectionByTypeMutation,
  ICreateOrderSelectionByTypeMutationVariables
>;
export const LoyaltyRedeemPromoCodeDocument = gql`
  mutation LoyaltyRedeemPromoCode($input: LoyaltyValidatePromoCodeInput) @gateway {
    loyaltyValidatePromoCode(input: $input) {
      ...LoyaltyCouponFragment
    }
  }
  ${LoyaltyCouponFragmentDoc}
`;
export type ILoyaltyRedeemPromoCodeMutationFn = Apollo.MutationFunction<
  ILoyaltyRedeemPromoCodeMutation,
  ILoyaltyRedeemPromoCodeMutationVariables
>;

/**
 * __useLoyaltyRedeemPromoCodeMutation__
 *
 * To run a mutation, you first call `useLoyaltyRedeemPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyRedeemPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loyaltyRedeemPromoCodeMutation, { data, loading, error }] = useLoyaltyRedeemPromoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoyaltyRedeemPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILoyaltyRedeemPromoCodeMutation,
    ILoyaltyRedeemPromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ILoyaltyRedeemPromoCodeMutation,
    ILoyaltyRedeemPromoCodeMutationVariables
  >(LoyaltyRedeemPromoCodeDocument, options);
}
export type LoyaltyRedeemPromoCodeMutationHookResult = ReturnType<
  typeof useLoyaltyRedeemPromoCodeMutation
>;
export type LoyaltyRedeemPromoCodeMutationResult =
  Apollo.MutationResult<ILoyaltyRedeemPromoCodeMutation>;
export type LoyaltyRedeemPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  ILoyaltyRedeemPromoCodeMutation,
  ILoyaltyRedeemPromoCodeMutationVariables
>;
export const LoyaltyQuestsDocument = gql`
  query LoyaltyQuests($loyaltyId: ID) @gateway {
    loyaltyQuests(loyaltyId: $loyaltyId) {
      endDate
      id
      incentiveType
      incentiveId
      incentiveRedeemed
      sanityId
      startDate
      status
      steps {
        cartRequirements {
          id
          quantity
        }
        completedAt
        status
        id
      }
    }
  }
`;

/**
 * __useLoyaltyQuestsQuery__
 *
 * To run a query within a React component, call `useLoyaltyQuestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyQuestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyQuestsQuery({
 *   variables: {
 *      loyaltyId: // value for 'loyaltyId'
 *   },
 * });
 */
export function useLoyaltyQuestsQuery(
  baseOptions?: Apollo.QueryHookOptions<ILoyaltyQuestsQuery, ILoyaltyQuestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ILoyaltyQuestsQuery, ILoyaltyQuestsQueryVariables>(
    LoyaltyQuestsDocument,
    options
  );
}
export function useLoyaltyQuestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ILoyaltyQuestsQuery, ILoyaltyQuestsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ILoyaltyQuestsQuery, ILoyaltyQuestsQueryVariables>(
    LoyaltyQuestsDocument,
    options
  );
}
export type LoyaltyQuestsQueryHookResult = ReturnType<typeof useLoyaltyQuestsQuery>;
export type LoyaltyQuestsLazyQueryHookResult = ReturnType<typeof useLoyaltyQuestsLazyQuery>;
export type LoyaltyQuestsQueryResult = Apollo.QueryResult<
  ILoyaltyQuestsQuery,
  ILoyaltyQuestsQueryVariables
>;
export const LinkBlueLightCardMembershipDocument = gql`
  mutation LinkBlueLightCardMembership($input: BlueLightCardInput!) @gateway @authRequired {
    linkBlueLightCardMembership(input: $input) {
      userId
      partnerName
      loyaltyId
      region
    }
  }
`;
export type ILinkBlueLightCardMembershipMutationFn = Apollo.MutationFunction<
  ILinkBlueLightCardMembershipMutation,
  ILinkBlueLightCardMembershipMutationVariables
>;

/**
 * __useLinkBlueLightCardMembershipMutation__
 *
 * To run a mutation, you first call `useLinkBlueLightCardMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkBlueLightCardMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkBlueLightCardMembershipMutation, { data, loading, error }] = useLinkBlueLightCardMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkBlueLightCardMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ILinkBlueLightCardMembershipMutation,
    ILinkBlueLightCardMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ILinkBlueLightCardMembershipMutation,
    ILinkBlueLightCardMembershipMutationVariables
  >(LinkBlueLightCardMembershipDocument, options);
}
export type LinkBlueLightCardMembershipMutationHookResult = ReturnType<
  typeof useLinkBlueLightCardMembershipMutation
>;
export type LinkBlueLightCardMembershipMutationResult =
  Apollo.MutationResult<ILinkBlueLightCardMembershipMutation>;
export type LinkBlueLightCardMembershipMutationOptions = Apollo.BaseMutationOptions<
  ILinkBlueLightCardMembershipMutation,
  ILinkBlueLightCardMembershipMutationVariables
>;
export const GetUserMembershipsDocument = gql`
  query GetUserMemberships($userId: String!) @gateway @authRequired {
    getUserMemberships(userId: $userId) {
      partnerName
      metadata {
        ... on BlueLightCardMetadataOutput {
          cardNumber
          cardHolderName
        }
      }
    }
  }
`;

/**
 * __useGetUserMembershipsQuery__
 *
 * To run a query within a React component, call `useGetUserMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMembershipsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserMembershipsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetUserMembershipsQuery, IGetUserMembershipsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetUserMembershipsQuery, IGetUserMembershipsQueryVariables>(
    GetUserMembershipsDocument,
    options
  );
}
export function useGetUserMembershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetUserMembershipsQuery,
    IGetUserMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetUserMembershipsQuery, IGetUserMembershipsQueryVariables>(
    GetUserMembershipsDocument,
    options
  );
}
export type GetUserMembershipsQueryHookResult = ReturnType<typeof useGetUserMembershipsQuery>;
export type GetUserMembershipsLazyQueryHookResult = ReturnType<
  typeof useGetUserMembershipsLazyQuery
>;
export type GetUserMembershipsQueryResult = Apollo.QueryResult<
  IGetUserMembershipsQuery,
  IGetUserMembershipsQueryVariables
>;
export const PlusDataDocument = gql`
  query plusData($storeId: ID!, $serviceMode: PosDataServiceMode) @gateway @useCache {
    plus(storeId: $storeId, serviceMode: $serviceMode) {
      plu
      price
    }
  }
`;

/**
 * __usePlusDataQuery__
 *
 * To run a query within a React component, call `usePlusDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlusDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlusDataQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      serviceMode: // value for 'serviceMode'
 *   },
 * });
 */
export function usePlusDataQuery(
  baseOptions: Apollo.QueryHookOptions<IPlusDataQuery, IPlusDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IPlusDataQuery, IPlusDataQueryVariables>(PlusDataDocument, options);
}
export function usePlusDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPlusDataQuery, IPlusDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IPlusDataQuery, IPlusDataQueryVariables>(PlusDataDocument, options);
}
export type PlusDataQueryHookResult = ReturnType<typeof usePlusDataQuery>;
export type PlusDataLazyQueryHookResult = ReturnType<typeof usePlusDataLazyQuery>;
export type PlusDataQueryResult = Apollo.QueryResult<IPlusDataQuery, IPlusDataQueryVariables>;
export const StoreMenuDocument = gql`
  query storeMenu(
    $region: String!
    $channel: Channel!
    $storeId: ID!
    $serviceMode: PosDataServiceMode
  ) @gateway @useCache {
    storeMenu(region: $region, channel: $channel, storeId: $storeId, serviceMode: $serviceMode) {
      ...PricingAndAvailability
      ...Calories
    }
  }
  ${PricingAndAvailabilityFragmentDoc}
  ${CaloriesFragmentDoc}
`;

/**
 * __useStoreMenuQuery__
 *
 * To run a query within a React component, call `useStoreMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreMenuQuery({
 *   variables: {
 *      region: // value for 'region'
 *      channel: // value for 'channel'
 *      storeId: // value for 'storeId'
 *      serviceMode: // value for 'serviceMode'
 *   },
 * });
 */
export function useStoreMenuQuery(
  baseOptions: Apollo.QueryHookOptions<IStoreMenuQuery, IStoreMenuQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IStoreMenuQuery, IStoreMenuQueryVariables>(StoreMenuDocument, options);
}
export function useStoreMenuLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IStoreMenuQuery, IStoreMenuQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IStoreMenuQuery, IStoreMenuQueryVariables>(StoreMenuDocument, options);
}
export type StoreMenuQueryHookResult = ReturnType<typeof useStoreMenuQuery>;
export type StoreMenuLazyQueryHookResult = ReturnType<typeof useStoreMenuLazyQuery>;
export type StoreMenuQueryResult = Apollo.QueryResult<IStoreMenuQuery, IStoreMenuQueryVariables>;
export const PromotionValidatePromoCodeDocument = gql`
  mutation PromotionValidatePromoCode(
    $code: String!
    $cognitoId: String!
    $shouldRedeem: Boolean
    $offers: [String!]!
  ) @gateway {
    validatePromoCode(
      code: $code
      cognitoId: $cognitoId
      shouldRedeem: $shouldRedeem
      offers: $offers
    ) {
      code
      reason
      message
      isValid
      isRedeemed
      offerId
    }
  }
`;
export type IPromotionValidatePromoCodeMutationFn = Apollo.MutationFunction<
  IPromotionValidatePromoCodeMutation,
  IPromotionValidatePromoCodeMutationVariables
>;

/**
 * __usePromotionValidatePromoCodeMutation__
 *
 * To run a mutation, you first call `usePromotionValidatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotionValidatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotionValidatePromoCodeMutation, { data, loading, error }] = usePromotionValidatePromoCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      cognitoId: // value for 'cognitoId'
 *      shouldRedeem: // value for 'shouldRedeem'
 *      offers: // value for 'offers'
 *   },
 * });
 */
export function usePromotionValidatePromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IPromotionValidatePromoCodeMutation,
    IPromotionValidatePromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IPromotionValidatePromoCodeMutation,
    IPromotionValidatePromoCodeMutationVariables
  >(PromotionValidatePromoCodeDocument, options);
}
export type PromotionValidatePromoCodeMutationHookResult = ReturnType<
  typeof usePromotionValidatePromoCodeMutation
>;
export type PromotionValidatePromoCodeMutationResult =
  Apollo.MutationResult<IPromotionValidatePromoCodeMutation>;
export type PromotionValidatePromoCodeMutationOptions = Apollo.BaseMutationOptions<
  IPromotionValidatePromoCodeMutation,
  IPromotionValidatePromoCodeMutationVariables
>;
export const PromotionRedeemPromoCodeDocument = gql`
  mutation PromotionRedeemPromoCode($code: String!, $cognitoId: String!) @gateway {
    redeemPromoCode(code: $code, cognitoId: $cognitoId) {
      code
      reason
      message
      isRedeemed
      offerId
    }
  }
`;
export type IPromotionRedeemPromoCodeMutationFn = Apollo.MutationFunction<
  IPromotionRedeemPromoCodeMutation,
  IPromotionRedeemPromoCodeMutationVariables
>;

/**
 * __usePromotionRedeemPromoCodeMutation__
 *
 * To run a mutation, you first call `usePromotionRedeemPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotionRedeemPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotionRedeemPromoCodeMutation, { data, loading, error }] = usePromotionRedeemPromoCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      cognitoId: // value for 'cognitoId'
 *   },
 * });
 */
export function usePromotionRedeemPromoCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IPromotionRedeemPromoCodeMutation,
    IPromotionRedeemPromoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IPromotionRedeemPromoCodeMutation,
    IPromotionRedeemPromoCodeMutationVariables
  >(PromotionRedeemPromoCodeDocument, options);
}
export type PromotionRedeemPromoCodeMutationHookResult = ReturnType<
  typeof usePromotionRedeemPromoCodeMutation
>;
export type PromotionRedeemPromoCodeMutationResult =
  Apollo.MutationResult<IPromotionRedeemPromoCodeMutation>;
export type PromotionRedeemPromoCodeMutationOptions = Apollo.BaseMutationOptions<
  IPromotionRedeemPromoCodeMutation,
  IPromotionRedeemPromoCodeMutationVariables
>;
export const SocialLoginDocument = gql`
  mutation SocialLogin($userInfo: SocialLoginInput!) @gateway {
    socialLogin(userInfo: $userInfo) {
      sessionId
      challengeCode
      email
    }
  }
`;
export type ISocialLoginMutationFn = Apollo.MutationFunction<
  ISocialLoginMutation,
  ISocialLoginMutationVariables
>;

/**
 * __useSocialLoginMutation__
 *
 * To run a mutation, you first call `useSocialLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialLoginMutation, { data, loading, error }] = useSocialLoginMutation({
 *   variables: {
 *      userInfo: // value for 'userInfo'
 *   },
 * });
 */
export function useSocialLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<ISocialLoginMutation, ISocialLoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISocialLoginMutation, ISocialLoginMutationVariables>(
    SocialLoginDocument,
    options
  );
}
export type SocialLoginMutationHookResult = ReturnType<typeof useSocialLoginMutation>;
export type SocialLoginMutationResult = Apollo.MutationResult<ISocialLoginMutation>;
export type SocialLoginMutationOptions = Apollo.BaseMutationOptions<
  ISocialLoginMutation,
  ISocialLoginMutationVariables
>;
export const SignInJwtDocument = gql`
  mutation SignInJWT($input: SignInUserInput!) @gateway {
    signInJWT(userInfo: $input)
  }
`;
export type ISignInJwtMutationFn = Apollo.MutationFunction<
  ISignInJwtMutation,
  ISignInJwtMutationVariables
>;

/**
 * __useSignInJwtMutation__
 *
 * To run a mutation, you first call `useSignInJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInJwtMutation, { data, loading, error }] = useSignInJwtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInJwtMutation(
  baseOptions?: Apollo.MutationHookOptions<ISignInJwtMutation, ISignInJwtMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISignInJwtMutation, ISignInJwtMutationVariables>(
    SignInJwtDocument,
    options
  );
}
export type SignInJwtMutationHookResult = ReturnType<typeof useSignInJwtMutation>;
export type SignInJwtMutationResult = Apollo.MutationResult<ISignInJwtMutation>;
export type SignInJwtMutationOptions = Apollo.BaseMutationOptions<
  ISignInJwtMutation,
  ISignInJwtMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp($input: SignUpUserInput!) @gateway {
    signUp(userInfo: $input)
  }
`;
export type ISignUpMutationFn = Apollo.MutationFunction<ISignUpMutation, ISignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<ISignUpMutation, ISignUpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISignUpMutation, ISignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<ISignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  ISignUpMutation,
  ISignUpMutationVariables
>;
export const ValidateAuthJwtDocument = gql`
  mutation ValidateAuthJwt($input: validateAuthJwtInput!) @gateway {
    validateAuthJwt(authInput: $input) {
      challengeCode
      sessionId
      email
    }
  }
`;
export type IValidateAuthJwtMutationFn = Apollo.MutationFunction<
  IValidateAuthJwtMutation,
  IValidateAuthJwtMutationVariables
>;

/**
 * __useValidateAuthJwtMutation__
 *
 * To run a mutation, you first call `useValidateAuthJwtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAuthJwtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAuthJwtMutation, { data, loading, error }] = useValidateAuthJwtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateAuthJwtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IValidateAuthJwtMutation,
    IValidateAuthJwtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IValidateAuthJwtMutation, IValidateAuthJwtMutationVariables>(
    ValidateAuthJwtDocument,
    options
  );
}
export type ValidateAuthJwtMutationHookResult = ReturnType<typeof useValidateAuthJwtMutation>;
export type ValidateAuthJwtMutationResult = Apollo.MutationResult<IValidateAuthJwtMutation>;
export type ValidateAuthJwtMutationOptions = Apollo.BaseMutationOptions<
  IValidateAuthJwtMutation,
  IValidateAuthJwtMutationVariables
>;
export const CreateOtpDocument = gql`
  mutation CreateOTP($input: CreateOTPInput!) @gateway {
    createOTP(input: $input) {
      maxValidateAttempts
      ttl
      redirectToUS
    }
  }
`;
export type ICreateOtpMutationFn = Apollo.MutationFunction<
  ICreateOtpMutation,
  ICreateOtpMutationVariables
>;

/**
 * __useCreateOtpMutation__
 *
 * To run a mutation, you first call `useCreateOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOtpMutation, { data, loading, error }] = useCreateOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateOtpMutation, ICreateOtpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateOtpMutation, ICreateOtpMutationVariables>(
    CreateOtpDocument,
    options
  );
}
export type CreateOtpMutationHookResult = ReturnType<typeof useCreateOtpMutation>;
export type CreateOtpMutationResult = Apollo.MutationResult<ICreateOtpMutation>;
export type CreateOtpMutationOptions = Apollo.BaseMutationOptions<
  ICreateOtpMutation,
  ICreateOtpMutationVariables
>;
export const CreateGuestOtpDocument = gql`
  mutation CreateGuestOTP($input: CreateGuestOTPInput!) @gateway {
    createGuestOTP(input: $input) {
      maxValidateAttempts
      ttl
      redirectToUS
    }
  }
`;
export type ICreateGuestOtpMutationFn = Apollo.MutationFunction<
  ICreateGuestOtpMutation,
  ICreateGuestOtpMutationVariables
>;

/**
 * __useCreateGuestOtpMutation__
 *
 * To run a mutation, you first call `useCreateGuestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuestOtpMutation, { data, loading, error }] = useCreateGuestOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGuestOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreateGuestOtpMutation,
    ICreateGuestOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreateGuestOtpMutation, ICreateGuestOtpMutationVariables>(
    CreateGuestOtpDocument,
    options
  );
}
export type CreateGuestOtpMutationHookResult = ReturnType<typeof useCreateGuestOtpMutation>;
export type CreateGuestOtpMutationResult = Apollo.MutationResult<ICreateGuestOtpMutation>;
export type CreateGuestOtpMutationOptions = Apollo.BaseMutationOptions<
  ICreateGuestOtpMutation,
  ICreateGuestOtpMutationVariables
>;
export const ResendCurrentLoginOtpDocument = gql`
  mutation ResendCurrentLoginOTP($input: ResendOTPInput!) @gateway {
    resendCurrentLoginOTP(input: $input) {
      maxValidateAttempts
      ttl
    }
  }
`;
export type IResendCurrentLoginOtpMutationFn = Apollo.MutationFunction<
  IResendCurrentLoginOtpMutation,
  IResendCurrentLoginOtpMutationVariables
>;

/**
 * __useResendCurrentLoginOtpMutation__
 *
 * To run a mutation, you first call `useResendCurrentLoginOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCurrentLoginOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCurrentLoginOtpMutation, { data, loading, error }] = useResendCurrentLoginOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendCurrentLoginOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IResendCurrentLoginOtpMutation,
    IResendCurrentLoginOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IResendCurrentLoginOtpMutation,
    IResendCurrentLoginOtpMutationVariables
  >(ResendCurrentLoginOtpDocument, options);
}
export type ResendCurrentLoginOtpMutationHookResult = ReturnType<
  typeof useResendCurrentLoginOtpMutation
>;
export type ResendCurrentLoginOtpMutationResult =
  Apollo.MutationResult<IResendCurrentLoginOtpMutation>;
export type ResendCurrentLoginOtpMutationOptions = Apollo.BaseMutationOptions<
  IResendCurrentLoginOtpMutation,
  IResendCurrentLoginOtpMutationVariables
>;
export const ResendCurrentGuestOtpDocument = gql`
  mutation ResendCurrentGuestOTP($input: ResendOTPInput!) @gateway {
    resendCurrentGuestOTP(input: $input) {
      maxValidateAttempts
      ttl
    }
  }
`;
export type IResendCurrentGuestOtpMutationFn = Apollo.MutationFunction<
  IResendCurrentGuestOtpMutation,
  IResendCurrentGuestOtpMutationVariables
>;

/**
 * __useResendCurrentGuestOtpMutation__
 *
 * To run a mutation, you first call `useResendCurrentGuestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendCurrentGuestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendCurrentGuestOtpMutation, { data, loading, error }] = useResendCurrentGuestOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendCurrentGuestOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IResendCurrentGuestOtpMutation,
    IResendCurrentGuestOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IResendCurrentGuestOtpMutation,
    IResendCurrentGuestOtpMutationVariables
  >(ResendCurrentGuestOtpDocument, options);
}
export type ResendCurrentGuestOtpMutationHookResult = ReturnType<
  typeof useResendCurrentGuestOtpMutation
>;
export type ResendCurrentGuestOtpMutationResult =
  Apollo.MutationResult<IResendCurrentGuestOtpMutation>;
export type ResendCurrentGuestOtpMutationOptions = Apollo.BaseMutationOptions<
  IResendCurrentGuestOtpMutation,
  IResendCurrentGuestOtpMutationVariables
>;
export const ValidateOtpDocument = gql`
  mutation ValidateOTP($input: ExchangeOTPCodeForCredentialsInput!) @gateway {
    exchangeOTPCodeForCognitoCredentials(input: $input) {
      challengeCode
      sessionId
      email
    }
  }
`;
export type IValidateOtpMutationFn = Apollo.MutationFunction<
  IValidateOtpMutation,
  IValidateOtpMutationVariables
>;

/**
 * __useValidateOtpMutation__
 *
 * To run a mutation, you first call `useValidateOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOtpMutation, { data, loading, error }] = useValidateOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<IValidateOtpMutation, IValidateOtpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IValidateOtpMutation, IValidateOtpMutationVariables>(
    ValidateOtpDocument,
    options
  );
}
export type ValidateOtpMutationHookResult = ReturnType<typeof useValidateOtpMutation>;
export type ValidateOtpMutationResult = Apollo.MutationResult<IValidateOtpMutation>;
export type ValidateOtpMutationOptions = Apollo.BaseMutationOptions<
  IValidateOtpMutation,
  IValidateOtpMutationVariables
>;
export const ValidateGuestOtpCodeDocument = gql`
  mutation ValidateGuestOTPCode($input: ValidateGuestOTPInput!) @gateway {
    validateGuestOTPCode(input: $input) {
      challengeCode
      sessionId
      email
    }
  }
`;
export type IValidateGuestOtpCodeMutationFn = Apollo.MutationFunction<
  IValidateGuestOtpCodeMutation,
  IValidateGuestOtpCodeMutationVariables
>;

/**
 * __useValidateGuestOtpCodeMutation__
 *
 * To run a mutation, you first call `useValidateGuestOtpCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateGuestOtpCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateGuestOtpCodeMutation, { data, loading, error }] = useValidateGuestOtpCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateGuestOtpCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IValidateGuestOtpCodeMutation,
    IValidateGuestOtpCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IValidateGuestOtpCodeMutation, IValidateGuestOtpCodeMutationVariables>(
    ValidateGuestOtpCodeDocument,
    options
  );
}
export type ValidateGuestOtpCodeMutationHookResult = ReturnType<
  typeof useValidateGuestOtpCodeMutation
>;
export type ValidateGuestOtpCodeMutationResult =
  Apollo.MutationResult<IValidateGuestOtpCodeMutation>;
export type ValidateGuestOtpCodeMutationOptions = Apollo.BaseMutationOptions<
  IValidateGuestOtpCodeMutation,
  IValidateGuestOtpCodeMutationVariables
>;
export const SignUpVerifiedGuestDocument = gql`
  mutation SignUpVerifiedGuest($input: SignUpUserInput!) @gateway {
    signUpVerifiedGuest(userInfo: $input) {
      challengeCode
      sessionId
      email
    }
  }
`;
export type ISignUpVerifiedGuestMutationFn = Apollo.MutationFunction<
  ISignUpVerifiedGuestMutation,
  ISignUpVerifiedGuestMutationVariables
>;

/**
 * __useSignUpVerifiedGuestMutation__
 *
 * To run a mutation, you first call `useSignUpVerifiedGuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpVerifiedGuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpVerifiedGuestMutation, { data, loading, error }] = useSignUpVerifiedGuestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpVerifiedGuestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISignUpVerifiedGuestMutation,
    ISignUpVerifiedGuestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ISignUpVerifiedGuestMutation, ISignUpVerifiedGuestMutationVariables>(
    SignUpVerifiedGuestDocument,
    options
  );
}
export type SignUpVerifiedGuestMutationHookResult = ReturnType<
  typeof useSignUpVerifiedGuestMutation
>;
export type SignUpVerifiedGuestMutationResult = Apollo.MutationResult<ISignUpVerifiedGuestMutation>;
export type SignUpVerifiedGuestMutationOptions = Apollo.BaseMutationOptions<
  ISignUpVerifiedGuestMutation,
  ISignUpVerifiedGuestMutationVariables
>;
export const SignUpCompleteDocument = gql`
  query SignUpComplete($jwt: String!) @gateway {
    signUpComplete(jwt: $jwt)
  }
`;

/**
 * __useSignUpCompleteQuery__
 *
 * To run a query within a React component, call `useSignUpCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignUpCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignUpCompleteQuery({
 *   variables: {
 *      jwt: // value for 'jwt'
 *   },
 * });
 */
export function useSignUpCompleteQuery(
  baseOptions: Apollo.QueryHookOptions<ISignUpCompleteQuery, ISignUpCompleteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ISignUpCompleteQuery, ISignUpCompleteQueryVariables>(
    SignUpCompleteDocument,
    options
  );
}
export function useSignUpCompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISignUpCompleteQuery, ISignUpCompleteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ISignUpCompleteQuery, ISignUpCompleteQueryVariables>(
    SignUpCompleteDocument,
    options
  );
}
export type SignUpCompleteQueryHookResult = ReturnType<typeof useSignUpCompleteQuery>;
export type SignUpCompleteLazyQueryHookResult = ReturnType<typeof useSignUpCompleteLazyQuery>;
export type SignUpCompleteQueryResult = Apollo.QueryResult<
  ISignUpCompleteQuery,
  ISignUpCompleteQueryVariables
>;
export const ResendVerificationEmailDocument = gql`
  mutation ResendVerificationEmail($input: resendVerificationEmailInput!) @gateway @authRequired {
    resendVerificationEmail(input: $input)
  }
`;
export type IResendVerificationEmailMutationFn = Apollo.MutationFunction<
  IResendVerificationEmailMutation,
  IResendVerificationEmailMutationVariables
>;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IResendVerificationEmailMutation,
    IResendVerificationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IResendVerificationEmailMutation,
    IResendVerificationEmailMutationVariables
  >(ResendVerificationEmailDocument, options);
}
export type ResendVerificationEmailMutationHookResult = ReturnType<
  typeof useResendVerificationEmailMutation
>;
export type ResendVerificationEmailMutationResult =
  Apollo.MutationResult<IResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<
  IResendVerificationEmailMutation,
  IResendVerificationEmailMutationVariables
>;
export const UserPhoneFromBrazeDocument = gql`
  query UserPhoneFromBraze($brazeId: String!) @gateway {
    userPhoneFromBraze(brazeId: $brazeId) {
      phone
    }
  }
`;

/**
 * __useUserPhoneFromBrazeQuery__
 *
 * To run a query within a React component, call `useUserPhoneFromBrazeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPhoneFromBrazeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPhoneFromBrazeQuery({
 *   variables: {
 *      brazeId: // value for 'brazeId'
 *   },
 * });
 */
export function useUserPhoneFromBrazeQuery(
  baseOptions: Apollo.QueryHookOptions<IUserPhoneFromBrazeQuery, IUserPhoneFromBrazeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IUserPhoneFromBrazeQuery, IUserPhoneFromBrazeQueryVariables>(
    UserPhoneFromBrazeDocument,
    options
  );
}
export function useUserPhoneFromBrazeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IUserPhoneFromBrazeQuery,
    IUserPhoneFromBrazeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IUserPhoneFromBrazeQuery, IUserPhoneFromBrazeQueryVariables>(
    UserPhoneFromBrazeDocument,
    options
  );
}
export type UserPhoneFromBrazeQueryHookResult = ReturnType<typeof useUserPhoneFromBrazeQuery>;
export type UserPhoneFromBrazeLazyQueryHookResult = ReturnType<
  typeof useUserPhoneFromBrazeLazyQuery
>;
export type UserPhoneFromBrazeQueryResult = Apollo.QueryResult<
  IUserPhoneFromBrazeQuery,
  IUserPhoneFromBrazeQueryVariables
>;
export const SendUpdateUserAttributesEventDocument = gql`
  mutation SendUpdateUserAttributesEvent($input: UpdateUserAttributesEventInput!)
  @gateway
  @authRequired {
    sendUpdateUserAttributesEvent(input: $input)
  }
`;
export type ISendUpdateUserAttributesEventMutationFn = Apollo.MutationFunction<
  ISendUpdateUserAttributesEventMutation,
  ISendUpdateUserAttributesEventMutationVariables
>;

/**
 * __useSendUpdateUserAttributesEventMutation__
 *
 * To run a mutation, you first call `useSendUpdateUserAttributesEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUpdateUserAttributesEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUpdateUserAttributesEventMutation, { data, loading, error }] = useSendUpdateUserAttributesEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendUpdateUserAttributesEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISendUpdateUserAttributesEventMutation,
    ISendUpdateUserAttributesEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ISendUpdateUserAttributesEventMutation,
    ISendUpdateUserAttributesEventMutationVariables
  >(SendUpdateUserAttributesEventDocument, options);
}
export type SendUpdateUserAttributesEventMutationHookResult = ReturnType<
  typeof useSendUpdateUserAttributesEventMutation
>;
export type SendUpdateUserAttributesEventMutationResult =
  Apollo.MutationResult<ISendUpdateUserAttributesEventMutation>;
export type SendUpdateUserAttributesEventMutationOptions = Apollo.BaseMutationOptions<
  ISendUpdateUserAttributesEventMutation,
  ISendUpdateUserAttributesEventMutationVariables
>;
export const GetWaitTimeDocument = gql`
  query GetWaitTime($storeId: String!, $orderId: String) @gateway {
    waitTime(storeId: $storeId, orderId: $orderId) {
      storeId
      rbiOrderId
      position
      firingTimestamp
      queueLength
    }
  }
`;

/**
 * __useGetWaitTimeQuery__
 *
 * To run a query within a React component, call `useGetWaitTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitTimeQuery({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetWaitTimeQuery(
  baseOptions: Apollo.QueryHookOptions<IGetWaitTimeQuery, IGetWaitTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IGetWaitTimeQuery, IGetWaitTimeQueryVariables>(
    GetWaitTimeDocument,
    options
  );
}
export function useGetWaitTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetWaitTimeQuery, IGetWaitTimeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IGetWaitTimeQuery, IGetWaitTimeQueryVariables>(
    GetWaitTimeDocument,
    options
  );
}
export type GetWaitTimeQueryHookResult = ReturnType<typeof useGetWaitTimeQuery>;
export type GetWaitTimeLazyQueryHookResult = ReturnType<typeof useGetWaitTimeLazyQuery>;
export type GetWaitTimeQueryResult = Apollo.QueryResult<
  IGetWaitTimeQuery,
  IGetWaitTimeQueryVariables
>;
export const ExitQueueDocument = gql`
  mutation ExitQueue($storeId: String!, $orderId: String!) @gateway {
    exitQueue(storeId: $storeId, orderId: $orderId) {
      code
      storeId
    }
  }
`;
export type IExitQueueMutationFn = Apollo.MutationFunction<
  IExitQueueMutation,
  IExitQueueMutationVariables
>;

/**
 * __useExitQueueMutation__
 *
 * To run a mutation, you first call `useExitQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExitQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exitQueueMutation, { data, loading, error }] = useExitQueueMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useExitQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<IExitQueueMutation, IExitQueueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IExitQueueMutation, IExitQueueMutationVariables>(
    ExitQueueDocument,
    options
  );
}
export type ExitQueueMutationHookResult = ReturnType<typeof useExitQueueMutation>;
export type ExitQueueMutationResult = Apollo.MutationResult<IExitQueueMutation>;
export type ExitQueueMutationOptions = Apollo.BaseMutationOptions<
  IExitQueueMutation,
  IExitQueueMutationVariables
>;
export const InitiatePaymentAttemptDocument = gql`
  mutation InitiatePaymentAttempt($input: InitiatePaymentAttemptInput!) @authRequired @gateway {
    initiatePaymentAttempt(input: $input) {
      paymentAttemptId
      processorId
      status
      pspSpecific
      paymentMethodType
      paymentIntentId
    }
  }
`;
export type IInitiatePaymentAttemptMutationFn = Apollo.MutationFunction<
  IInitiatePaymentAttemptMutation,
  IInitiatePaymentAttemptMutationVariables
>;

/**
 * __useInitiatePaymentAttemptMutation__
 *
 * To run a mutation, you first call `useInitiatePaymentAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiatePaymentAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiatePaymentAttemptMutation, { data, loading, error }] = useInitiatePaymentAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitiatePaymentAttemptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IInitiatePaymentAttemptMutation,
    IInitiatePaymentAttemptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IInitiatePaymentAttemptMutation,
    IInitiatePaymentAttemptMutationVariables
  >(InitiatePaymentAttemptDocument, options);
}
export type InitiatePaymentAttemptMutationHookResult = ReturnType<
  typeof useInitiatePaymentAttemptMutation
>;
export type InitiatePaymentAttemptMutationResult =
  Apollo.MutationResult<IInitiatePaymentAttemptMutation>;
export type InitiatePaymentAttemptMutationOptions = Apollo.BaseMutationOptions<
  IInitiatePaymentAttemptMutation,
  IInitiatePaymentAttemptMutationVariables
>;
export const MakePaymentAttemptDocument = gql`
  mutation MakePaymentAttempt($input: MakePaymentAttemptInput!) @authRequired @gateway {
    makePaymentAttempt(input: $input) {
      paymentAttemptId
      paymentIntentId
      processorId
      status
      pspSpecific
      paymentMethodType
    }
  }
`;
export type IMakePaymentAttemptMutationFn = Apollo.MutationFunction<
  IMakePaymentAttemptMutation,
  IMakePaymentAttemptMutationVariables
>;

/**
 * __useMakePaymentAttemptMutation__
 *
 * To run a mutation, you first call `useMakePaymentAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakePaymentAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makePaymentAttemptMutation, { data, loading, error }] = useMakePaymentAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMakePaymentAttemptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IMakePaymentAttemptMutation,
    IMakePaymentAttemptMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IMakePaymentAttemptMutation, IMakePaymentAttemptMutationVariables>(
    MakePaymentAttemptDocument,
    options
  );
}
export type MakePaymentAttemptMutationHookResult = ReturnType<typeof useMakePaymentAttemptMutation>;
export type MakePaymentAttemptMutationResult = Apollo.MutationResult<IMakePaymentAttemptMutation>;
export type MakePaymentAttemptMutationOptions = Apollo.BaseMutationOptions<
  IMakePaymentAttemptMutation,
  IMakePaymentAttemptMutationVariables
>;
export const CreatePaymentIntentDocument = gql`
  mutation CreatePaymentIntent($input: CreatePaymentIntentInput!) @authRequired @gateway {
    createPaymentIntent(input: $input) {
      paymentIntentId
      clientApp
      amount
      currency
      paymentMethodTypeConfigurations {
        paymentMethodTypeId
        status
        processorId
        enableCreateShopper
        enableVaultPaymentMethod
        enableRetrieveVaultedPaymentMethods
        market {
          brand
          country
          store
          marketId
        }
      }
      clientUserId
      referenceId
    }
  }
`;
export type ICreatePaymentIntentMutationFn = Apollo.MutationFunction<
  ICreatePaymentIntentMutation,
  ICreatePaymentIntentMutationVariables
>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ICreatePaymentIntentMutation,
    ICreatePaymentIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ICreatePaymentIntentMutation, ICreatePaymentIntentMutationVariables>(
    CreatePaymentIntentDocument,
    options
  );
}
export type CreatePaymentIntentMutationHookResult = ReturnType<
  typeof useCreatePaymentIntentMutation
>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<ICreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<
  ICreatePaymentIntentMutation,
  ICreatePaymentIntentMutationVariables
>;

import React, { FC, useCallback } from 'react';

import { Icon } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { PointsDisplay } from 'ui-system/components/points-display';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { IconLoyaltyPoints } from 'components/icons/loyalty-points-icon';
import { IconTrash } from 'components/icons/trash';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LoyaltyIdentificationCodeTypeVariation } from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import {
  ICartEntryDetailsReward,
  ICartEntryType,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import {
  ICartEntryOffer,
  ICartEntryReward,
} from 'state/loyalty/in-restaurant-redemption/hooks/use-in-restaurant-redemption-cart/types';
import { isSystemwideOffer } from 'state/loyalty/in-restaurant-redemption/utils';
import { isDiscountReward } from 'state/loyalty/types';
import { primitive } from 'styles/constants/primitives';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';
import { routes } from 'utils/routing';

import { OfferRedemptionIncrementor } from '../../loyalty-in-restaurant-redemption/reward-redemption-list/badge-and-incrementor/offer-redemption-incrementor';
import { RewardRedemptionIncrementor } from '../../loyalty-in-restaurant-redemption/reward-redemption-list/badge-and-incrementor/reward-redemption-incrementor';
import { componentMapping } from '../../loyalty-in-restaurant-redemption/reward-redemption-list/reward-redemption-item';
import { CustomScrollElement } from '../custom-scroll-element';
import {
  ActionsWrapper,
  AddIncentiveButton,
  CartItem,
  CartItemActions,
  CartItemDetails,
  CartItemHeader,
  CartItemPoints,
  CartItemRemove,
  CartList,
  Container,
  ScanRedeemButton,
  ScanRedeemIcon,
} from '../loyalty-cart-drawer.styled';

import { InRestaurantScreen } from './types';

interface CartListDrawerProps {
  setIsOpen: (value: boolean) => void;
  setDrawerType: (type: InRestaurantScreen) => void;
}

export const CartListDrawer: FC<CartListDrawerProps> = ({ setIsOpen, setDrawerType }) => {
  const { formatMessage } = useIntl();
  const {
    inRestaurantRedemptionCart,
    updateInRestaurantRedemptionEntryQuantity,
    removeInRestaurantRedemptionEntry,
  } = useInRestaurantRedemptionContext();
  const { trackEvent } = useCdpContext();
  const { navigate, location } = useLocationContext();

  const enableLoyaltyInApps = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_IN_APPS);
  const loyaltyIdentificationCodeType = useFlag<LoyaltyIdentificationCodeTypeVariation>(
    LaunchDarklyFlag.LOYALTY_IDENTIFICATION_CODE_TYPE
  );

  const handleUpdateEntry = useCallback(
    (cartEntry: ICartEntryReward | ICartEntryOffer, quantity: number) => {
      updateInRestaurantRedemptionEntryQuantity(cartEntry, quantity);
      hapticNotification({ type: HapticsNotificationType.SUCCESS });
    },
    [updateInRestaurantRedemptionEntryQuantity]
  );

  const onCartRemove = useCallback(
    (entry: ICartEntryReward | ICartEntryOffer) => {
      trackEvent({
        name: CustomEventNames.BUTTON_CLICK,
        type: EventTypes.Other,
        attributes: {
          name: 'Remove',
          path: location.pathname,
        },
      });
      removeInRestaurantRedemptionEntry(entry);
    },
    [location.pathname, removeInRestaurantRedemptionEntry, trackEvent]
  );
  return (
    <Container>
      <CartList>
        <CustomScrollElement>
          <TransitionGroup component={null}>
            {inRestaurantRedemptionCart.map(cartEntry => {
              const { details, type } = cartEntry;
              const components = componentMapping[type];
              const { ItemPicture, ItemName } = components;
              return (
                <CSSTransition key={cartEntry.referenceId} timeout={{ exit: 500 }}>
                  <CartItem $isReward={cartEntry.type === ICartEntryType.REWARD}>
                    {cartEntry.type === ICartEntryType.REWARD ? (
                      <CartItemHeader>
                        <IconLoyaltyPoints />
                        {formatMessage({ id: 'crownsApplied' })}
                      </CartItemHeader>
                    ) : null}
                    <CartItemDetails>
                      <ItemPicture entryCartDetails={details} objectFitContain />
                      <ItemName entryCartDetails={details} />
                      {cartEntry.type === ICartEntryType.REWARD ? (
                        <CartItemPoints>
                          <PointsDisplay value={cartEntry.details.engineReward.pointCost} />
                        </CartItemPoints>
                      ) : null}
                    </CartItemDetails>
                    <CartItemActions>
                      <CartItemRemove onClick={() => onCartRemove(cartEntry)}>
                        <IconTrash fill={primitive.bk.$bbqBrown} height="16" />
                        {formatMessage({ id: 'remove' })}
                      </CartItemRemove>

                      {cartEntry.type === ICartEntryType.REWARD &&
                        !isDiscountReward(
                          (cartEntry.details as ICartEntryDetailsReward).reward
                        ) && (
                          <RewardRedemptionIncrementor
                            quantity={cartEntry.quantity}
                            details={cartEntry.details}
                            onChange={(value: number) => handleUpdateEntry(cartEntry, value)}
                          />
                        )}
                      {isSystemwideOffer(cartEntry) && (
                        <OfferRedemptionIncrementor
                          quantity={cartEntry.quantity}
                          onChange={(value: number) => handleUpdateEntry(cartEntry, value)}
                        />
                      )}
                    </CartItemActions>
                  </CartItem>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          <ActionsWrapper>
            {enableLoyaltyInApps && (
              <AddIncentiveButton
                variant={ActionButtonVariants.OUTLINE}
                data-testid="redemption-add-rewards-button"
                size={ActionButtonSizes.LARGE}
                perceptible
                startIcon={<Icon icon="add" color="primary" aria-hidden />}
                onClick={() => {
                  setIsOpen(false);
                  navigate(routes.rewardsList);
                }}
              >
                {formatMessage({ id: 'loyaltyAddRewards' })}
              </AddIncentiveButton>
            )}
            {loyaltyIdentificationCodeType === LoyaltyIdentificationCodeTypeVariation.DYNAMIC && (
              <AddIncentiveButton
                variant={ActionButtonVariants.OUTLINE}
                data-testid="redemption-add-offers-button"
                size={ActionButtonSizes.LARGE}
                perceptible
                startIcon={<Icon icon="add" color="primary" aria-hidden />}
                onClick={() => {
                  setIsOpen(false);
                  navigate(routes.rewardsOffers);
                }}
              >
                {formatMessage({ id: 'loyaltyAddOffers' })}
              </AddIncentiveButton>
            )}
          </ActionsWrapper>
        </CustomScrollElement>
      </CartList>
      <ScanRedeemButton
        onClick={() => {
          trackEvent({
            name: CustomEventNames.SHEET,
            type: EventTypes.Other,
            attributes: {
              path: '/redeem',
            },
          });
          setDrawerType(InRestaurantScreen.QR_CODE);
        }}
        aria-label={formatMessage({ id: 'yourInRestaurantCartScanRedeem' })}
        data-testid="your-in-restaurant-cart-scan-redeem-button"
        variant={ActionButtonVariants.BOX_SHADOW_PRIMARY}
        eventAttributes={{
          name: CustomEventNames.SCAN_AND_REDEEM,
        }}
      >
        <ScanRedeemIcon />
        {formatMessage({ id: 'yourInRestaurantCartScanRedeem' })}
      </ScanRedeemButton>
    </Container>
  );
};

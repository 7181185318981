import { differenceInYears, formatISO, parse } from 'date-fns';
import delv from 'dlv';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import { getName } from 'utils/attributes';
import * as Braze from 'utils/braze';
import { isNativeIOS } from 'utils/environment';
import { getNativeLocationPermissions } from 'utils/geolocation';
import { logger } from 'utils/logger';

import { CustomEventNames, EventTypes } from '../constants';
import {
  ExtendedCdpAttributes,
  ICdpAttributes,
  ICdpCtx,
  ICdpUser,
  SendUpdateUserAttributesEventType,
  UpdatedUserAttributes,
} from '../types';
import {
  flattenCommunicationPreferences,
  getCommunicationPreferenceFromPromotionalEmails,
  mapApiCdpUserAttributes,
  normalizeBooleans,
  sanitizeValues,
} from '../utils';

const getAge = ({
  age,
  dob,
  updatedAttributes,
}: {
  age?: number | null;
  dob?: string | null;
  updatedAttributes?: UpdatedUserAttributes;
}): number | undefined | null => {
  if (updatedAttributes?.dobDeleted === 'True') {
    return null;
  }
  if (dob) {
    return differenceInYears(new Date(), parse(dob, 'yyyy-MM-dd', new Date()));
  }
  return age ?? null;
};

// safely merge existing and new attributes
export const mergeUserAttributes = (
  {
    $FirstName,
    $LastName,
    Zip: zipcode,
    $Age: age,
    $Gender: gender,
    $City: city,
    $State: state,
    $Mobile: phoneNumber,
    'Legacy User': legacyUser,
    'Date of Birth': dob,
    ...rest
  }: ICdpAttributes = {},
  updatedAttributes: UpdatedUserAttributes = {},
  enableCommunicationPreferences: boolean
): Partial<ICdpAttributes> => {
  const flattenedCommunicationPreferences = flattenCommunicationPreferences(
    updatedAttributes.communicationPreferences
  );

  const isBrazeForwarderConfigured = window.mParticle
    ?._getActiveForwarders?.()
    ?.find(f => f.name.toLowerCase() === 'braze');

  const getDob = () => {
    if (isBrazeForwarderConfigured && updatedAttributes?.dobDeleted === 'True') {
      //Workaround for removing the DOB directly on Braze (context: ICU-44)
      //see braze-compat.ts l54 & l55 - verify if initialization was successful
      if (Braze?.initialize()) {
        Braze?.getUser?.()?.setDateOfBirth(null, null, null);
      }
      return null;
    }
    return dob !== updatedAttributes?.dob ? updatedAttributes?.dob : dob;
  };

  const getPhoneNumber = () => {
    if (isBrazeForwarderConfigured && !updatedAttributes?.phoneNumber) {
      //Workaround for removing the Phone Number directly on Braze (context: ICU-44)
      //see braze-compat.ts l54 & l55 - verify if initialization was successful
      if (Braze?.initialize()) {
        Braze?.getUser?.()?.setPhoneNumber(null);
      }
      return null;
    }
    return phoneNumber !== updatedAttributes?.phoneNumber
      ? updatedAttributes?.phoneNumber
      : phoneNumber;
  };

  const sanitizedValues = sanitizeValues({
    ...rest,
    ...getName(updatedAttributes, { $FirstName, $LastName }),
    ...flattenedCommunicationPreferences,
    ...getCommunicationPreferenceFromPromotionalEmails(
      updatedAttributes,
      enableCommunicationPreferences
    ),
    Zip: delv(updatedAttributes, 'zipcode', zipcode),
    $Gender: delv(updatedAttributes, 'gender', gender),
    $City: delv(updatedAttributes, 'city', city),
    $State: delv(updatedAttributes, 'state', state),
    Timezone: delv(updatedAttributes, 'timezone', ''),
    'Join Date': delv(updatedAttributes, 'joinDate', ''),
    'Legacy User': delv(updatedAttributes, 'Legacy User', legacyUser || ''),
    'RBI Cognito ID': delv(updatedAttributes, 'rbiCognitoId', ''),
    favoriteStores: delv(updatedAttributes, 'favoriteStores', ''),
    favoriteOffers: delv(updatedAttributes, 'favoriteOffers', ''),
    language: delv(updatedAttributes, 'language', ''),
    'Battery Level': delv(updatedAttributes, 'batteryLevel', ''),
    'App Build': delv(updatedAttributes, 'App Build', ''),
    'Browser Type': delv(updatedAttributes, 'Browser Type', ''),
    'Browser Version': delv(updatedAttributes, 'Browser Version', ''),
    'Mobile Web': delv(updatedAttributes, 'Mobile Web', ''),
    Locale: delv(updatedAttributes, 'locale', ''),
    'Pickup Mode': delv(updatedAttributes, 'Pickup Mode', ''),
    'Service Mode': delv(updatedAttributes, 'Service Mode', ''),
    'Loyalty ID': delv(updatedAttributes, 'Loyalty ID', ''),
    'Restaurant Address': delv(updatedAttributes, 'Restaurant Address', ''),
    'Restaurant ID': delv(updatedAttributes, 'Restaurant ID', ''),
    'Restaurant Name': delv(updatedAttributes, 'Restaurant Name', ''),
    'Restaurant Number': delv(updatedAttributes, 'Restaurant Number', ''),
    'Sanity Restaurant ID': delv(updatedAttributes, 'Sanity Restaurant ID', ''),
  });

  let dobDateFormat = null;
  const dobStringFormat = getDob();

  if (dobStringFormat && dob) {
    // Parse the date string as a local date
    const localDate = parse(dobStringFormat, 'yyyy-MM-dd', new Date());
    // Format the date as ISO without adjusting for UTC (keep it in local time)
    dobDateFormat = new Date(`${formatISO(localDate, { representation: 'date' })}T00:00:00.000Z`);
  }

  // Omit some values that can be get it from the existingAttributes but they are already deprecated
  const refinedSanitizedValues = omit(sanitizedValues, [
    'orderStatus',
    'loyalty',
    'marketingPush',
    'rewardsEmail',
    'rewardsPush',
    'marketingEmail',
  ]);
  return {
    ...refinedSanitizedValues,
    //If the user deletes his birthday or Phone Number from the app we need to send null as a value
    'Date of Birth': getDob(), // Custom attribute
    dob: dobDateFormat, // Braze's standard attribute
    $Age: getAge({ age, dob: getDob(), updatedAttributes }), // Cdp mapped attribute for dob
    $Mobile: getPhoneNumber(),
    FirstName: sanitizedValues.$FirstName,
    LastName: sanitizedValues.$LastName,
  };
};

export const setUserAttributes = (
  user: ICdpUser,
  newAttributes: UpdatedUserAttributes,
  trackEvent: ICdpCtx['trackEvent'],
  sendUpdateUserAttributesEvent: SendUpdateUserAttributesEventType,
  enableCommunicationPreferences: boolean
) => {
  const existingAttributes = user?.getAllUserAttributes?.();
  if (!existingAttributes) {
    return;
  }

  const updated = mergeUserAttributes(
    existingAttributes,
    newAttributes,
    enableCommunicationPreferences
  );

  if (!isEqual(existingAttributes, updated)) {
    const attributes = {
      'Is Guest User': newAttributes.isGuest,
      'Promotional Emails': newAttributes.promotionalEmails,
      ...updated,
    };

    if (attributes?.email_subscribe === existingAttributes?.email_subscribe) {
      delete attributes?.email_subscribe;
    }
    if (attributes?.push_subscribe === existingAttributes?.push_subscribe) {
      delete attributes?.push_subscribe;
    }

    if (attributes['RBI Cognito ID']) {
      sendUpdateUserAttributesEvent({
        variables: {
          input: mapApiCdpUserAttributes(attributes as ExtendedCdpAttributes),
        },
      }).catch((error: Error) =>
        logger.warn({ message: 'Error sending user attributes to the API', error })
      );
    }

    try {
      trackEvent({
        name: CustomEventNames.UPDATE_USER_ATTRIBUTES,
        type: EventTypes.Other,
        attributes,
      });

      const normalizedUpdated = normalizeBooleans(updated);
      user.setUserAttributes(normalizedUpdated);
    } catch (error) {
      logger.error({ message: 'Error sending user attributes to the API', error });
    }
  }
};

export const getUtmAttributes = (params: URLSearchParams) => {
  return sanitizeValues({
    'UTM Source': params.get('utm_source') || '',
    'UTM Medium': params.get('utm_medium') || '',
    'UTM Campaign': params.get('utm_campaign') || '',
    'UTM Term': params.get('utm_term') || '',
    'UTM Content': params.get('utm_content') || '',
  });
};

export const setUserUtmAttributes = (user: ICdpUser | undefined, params: URLSearchParams) => {
  const utmAttrs = getUtmAttributes(params);
  if (user?.setUserAttributes) {
    const mergedAttributes: ICdpAttributes = {
      ...user.getAllUserAttributes(),
      ...utmAttrs,
    };
    user.setUserAttributes(mergedAttributes);
  }
};

export const updateLocationPermissionStatus = async (user: ICdpUser | undefined) => {
  if (!user) {
    return;
  }
  const status = await getNativeLocationPermissions();
  if (!status) {
    return;
  }
  if (isNativeIOS()) {
    user.setUserAttributes({
      'IOS Location Permissions': status,
    });
  } else {
    user.setUserAttributes({
      'Android Location Permissions': status,
    });
  }
};

import { useCallback, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router';

import { useGetUserOrdersLazyQuery as useGetUserOrdersLazyQueryFulfillment } from 'generated/graphql-gateway';
import { RbiOrderStatus, useGetUserOrdersLazyQuery } from 'generated/rbi-graphql';
import { IWaitingPaymentInformation } from 'pages/cart/payment/order-payment/waiting-payment';
import { AuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { LocalStorage, StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing/routes';

export const useForceWaitingPaymentRedirects = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const authenticated = isAuthenticated();
  const navigate = useNavigate();

  const isFulfillmentServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_FULFILLMENT_SERVICE_GET_USER_ORDERS
  );

  const variables = {
    orderStatuses: [RbiOrderStatus.PRICE_SUCCESSFUL],
  };

  const [getOrderFulfillment] = useGetUserOrdersLazyQueryFulfillment({
    variables,
  });
  const [getOrder] = useGetUserOrdersLazyQuery({ variables });

  const fetchOrders = useCallback(async () => {
    const { data } = isFulfillmentServiceEnabled ? await getOrderFulfillment() : await getOrder();
    const order = data?.userOrders?.orders?.[0];

    const paymentRequestTimeLimit =
      parseInt(order?.cart.payment?.paymentRequestTimeLimit ?? '') || 0;
    const hasPaymentRequestTimeLimit = paymentRequestTimeLimit > 0;
    const orderIsPriceSuccessful = order?.status === RbiOrderStatus.PRICE_SUCCESSFUL;
    const timestampExpired = Date.now() > paymentRequestTimeLimit;

    const isOrderWaitingPayment = !!order && !!hasPaymentRequestTimeLimit && orderIsPriceSuccessful;

    if (!isOrderWaitingPayment || timestampExpired) {
      return;
    }

    const waitingPaymentParsed: IWaitingPaymentInformation = {
      amount: order?.cart.totalCents || 0,
      paymentMethod: order?.cart.payment?.paymentType || '',
      paymentRequestTimeLimit,
      rbiOrderId: order?.rbiOrderId || '',
    };
    LocalStorage.setItem(StorageKeys.WAITING_PAYMENT, waitingPaymentParsed);
    navigate(routes.waitingPayment, { replace: true });
  }, [getOrder, getOrderFulfillment, isFulfillmentServiceEnabled, navigate]);

  useEffect(() => {
    if (!authenticated) {
      return;
    }
    const localStorageWaitingPayment: IWaitingPaymentInformation | null = LocalStorage.getItem(
      StorageKeys.WAITING_PAYMENT
    );

    const paymentRequestTimeLimit = localStorageWaitingPayment?.paymentRequestTimeLimit
      ? localStorageWaitingPayment?.paymentRequestTimeLimit
      : 0;
    const timestampExpired = Date.now() > paymentRequestTimeLimit;

    const shouldIgnoreWaitingPayment = localStorageWaitingPayment && timestampExpired;
    const shouldRedirect = localStorageWaitingPayment && !timestampExpired;

    if (shouldIgnoreWaitingPayment) {
      return;
    }

    if (shouldRedirect) {
      navigate(routes.waitingPayment, { replace: true });
      return;
    }

    fetchOrders();
  }, [
    authenticated,
    fetchOrders,
    getOrder,
    getOrderFulfillment,
    isFulfillmentServiceEnabled,
    navigate,
  ]);
};

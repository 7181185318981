import styled from 'styled-components';

import { ActionButton } from 'components/action-button';
import { BottomModal } from 'components/bottom-modal';
import { IconBKFries } from 'components/icons/bk-fries';
import { IconLoyaltyMyCode } from 'components/icons/loyalty-my-code';
import { UnstyledButton } from 'components/unstyled-button';
import { primitive } from 'styles/constants/primitives';
import { fadeOut } from 'utils/style/animations';

import { LoyaltyInRestaurantRedemptionSignupV3 } from '../loyalty-in-restaurant-redemption-signup-v3';

import { CrownIcon } from './your-code-drawer/icons/CrownIcon';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-block-end: 2rem;
  margin-inline-start: 0;
  margin-inline-end: auto;
  > * {
    margin-block-end: 1rem;
  }

  ${Styles.desktop} {
    max-width: 36rem;
    margin-inline: auto;
  }
`;

export const StyledBottomModal = styled(BottomModal)`
  transform: translate(0, 100%);
  transition: transform 350ms ease-in-out;

  & > div {
    border-block-end: none;
  }
`;

export const CartList = styled.div`
  & > div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 70vh;
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);
    gap: 12px;
    overflow: auto;
    padding-block-end: 1rem;
  }
`;

export const CartItem = styled.div<{ $isReward?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
  min-height: 8rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0.25rem 1rem;
  padding-block-start: ${({ $isReward }) => ($isReward ? '0' : '0.5rem')};
  padding-block-end: 0.25rem;
  padding-inline-start: 0;

  &.exit {
    animation: ${fadeOut} 0.5s forwards;
  }
`;

export const CartItemHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 0.3rem;
  background: #0000000a;
  padding: 0.5rem;
  font-size: 0.75rem;
  font-weight: 400;

  svg {
    margin-block-start: -0.125rem;
  }
`;

export const CartItemDetails = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-inline-end: 0.75rem;

  h2 {
    font-size: 0.875rem;
  }
`;

export const CartItemPoints = styled.div`
  margin-inline-start: auto;
  padding-inline-end: 0.5rem;
  font-family: ${Styles.fontFamily.brand};
  font-weight: 700;
  font-size: 1rem;
`;

export const CartItemActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-block-start: auto;
  padding: 0.55rem 0;
  padding-inline-end: 0.75rem;
  margin-block-start: 0.45rem;

  & > div > div {
    gap: 1.5rem;

    & > button > svg {
      border: none;

      path:last-child {
        fill: ${Styles.color.primary} !important;
      }
    }
  }
`;

export const CartItemRemove = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  margin-inline-end: auto;
  padding-inline-start: 1rem;
  gap: 0.4rem;
  font-family: ${Styles.fontFamily.brand};
  font-size: 0.875rem;

  svg {
    margin-block-start: -0.125rem;
  }
`;

export const ScanRedeemButton = styled(ActionButton)`
  margin: 0 1rem;
`;

export const ScanRedeemIcon = styled(IconLoyaltyMyCode)`
  padding-block-start: 0.5rem;
  margin-inline-end: 0.5rem;
`;

export const InfoButton = styled(UnstyledButton)`
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  margin: 1rem;
`;

export const InfoBackButton = styled(UnstyledButton)`
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  margin: 1rem;
  background: ${primitive.$white};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  height: 70vh;
  padding-inline: 1rem;
  padding-block-end: 1rem;

  ${Styles.mobileTiny} {
    flex-wrap: nowrap;
  }
`;

export const InfoTitle = styled.div`
  font-size: 1.125rem;
  margin-block-start: 1rem;
  margin-block-end: 0.5rem;
  font-family: ${Styles.fontFamily.brand};
`;

export const InfoDescription = styled.div`
  font-size: 0.75rem;
  margin-block-end: 1rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  padding-inline: 2rem;
  padding-block-start: 0.5rem;
  width: 100%;

  ${Styles.mobileTiny} {
    flex-wrap: wrap;
  }
`;

export const AddIncentiveButton = styled(ActionButton)`
  flex: 1;
  font-size: 0.85rem;
  padding-block: 0.5rem;
  padding-inline: 0.8rem;
  justify-content: center;
  background: ${primitive.$white};

  ${Styles.mobileTiny} {
    flex: unset;
    width: 100%;
    margin-block-end: 0.3rem;
    justify-content: center;
  }
`;

export const HowToEarnContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  margin: 0 1rem;
  padding-block-start: 0.785rem;
  padding-block-end: 0;
  padding-inline: 0.785rem;
  background: #0000000a;

  .paging-item {
    fill: #00000033 !important;

    svg {
      width: 0.5rem;
      height: 0.5rem;
    }
  }

  .paging-item.active {
    fill: ${Styles.color.black} !important;
  }
`;

export const HowToEarnTitle = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  font-family: ${Styles.fontFamily.base};
  font-weight: 400;
  text-align: center;
  margin-block-end: 1rem;
`;

export const HowToEarnCarouselItem = styled.div`
  text-align: center;
  margin-block-end: 2rem;
`;

export const HowToEarnText = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-block-end: 1rem;
`;

export const HowToEarnStep = styled.div<{ step: number }>`
  margin-inline-start: 0.125rem;

  &::after {
    display: block;
    border: 1px solid ${Styles.color.contrastBackground};
    border-radius: 5rem;
    content: '${(p: any) => p.step}';
    font-family: ${Styles.fontFamily.body};
    font-weight: 400;
    font-size: 0.75rem;
    text-align: center;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.4rem;
  }
`;

export const HowToEarnDescription = styled.div`
  line-height: 1.25rem;
  text-align: start;
`;

export const StyledCrownIcon = styled(CrownIcon)`
  margin-block-start: 1rem;
`;

export const StyledFriesIcon = styled(IconBKFries)`
  transform: scale(0.8);
`;

export const StyledUnauthenticatedDrawer = styled(LoyaltyInRestaurantRedemptionSignupV3)`
  margin-block-end: 10rem;
`;

export const ConfirmationContainer = styled.div`
  padding: 0 1rem;
  text-align: center;
`;

export const ConfirmationHeader = styled.div`
  font-family: ${Styles.fontFamily.brand};
  font-size: 1.5rem;
  font-weight: 400;
  padding-block-end: 0.5rem;
  text-align: start;
`;

export const ConfirmationDescription = styled.div`
  font-size: 1rem;
  font-weight: 400;
  text-align: start;
  margin-block-end: 2rem;
`;

export const ConfirmationActions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
  margin-block-start: 2rem;
  margin-block-end: 4rem;
`;

export const ConfirmationButton = styled(ActionButton)`
  flex: 1;
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 2.625rem;
  padding-block: 0.5rem;
  padding-inline: 0.8rem;
  justify-content: center;

  ${Styles.mobileTiny} {
    flex: unset;
    width: 100%;
    margin-block-end: 0.3rem;
    justify-content: center;
  }
`;

import { AllowedEvent } from '@rbilabs/intl-mparticle-client';
import delv from 'dlv';
import { isEqual, isNil, pick } from 'lodash';

import { NonNullableObject } from '@rbi-ctg/frontend';
import { ICartEntry } from '@rbi-ctg/menu';
import { IUpdateUserAttributesEventInput } from 'generated/graphql-gateway';
import { HttpErrorCodes } from 'remote/constants';
import { CommunicationPreferences } from 'state/auth';
import { ServiceMode } from 'state/service-mode/types';
import { getName } from 'utils/attributes';
import { AuthStorage } from 'utils/cognito';
import { LDUser, LaunchDarklyHelper } from 'utils/launchdarkly';
import { Keys } from 'utils/local-storage/constants';
import { logger } from 'utils/logger';

import { CartPaymentType } from '../order/types';

import { CustomEventNames, EventTypes, FALSE, FALSE_VALS, TRUE, TRUE_VALS } from './constants';
import {
  ExtendedCdpAttributes,
  HTTPCode,
  ICdpAttributes,
  IGenericEvent,
  ILogEvent,
  UpdatedUserAttributes,
} from './types';

export function serializePaymentType(paymentType: CartPaymentType | null) {
  switch (paymentType) {
    case CartPaymentType.APPLE_PAY:
      return 'APPLE_PAY';
    case CartPaymentType.GOOGLE_PAY:
      return 'GOOGLE_PAY';
    case CartPaymentType.CREDIT_ANONYMOUS:
      return 'CREDIT_ANONYMOUS';
    default:
      return 'VAULTED_ACCOUNT';
  }
}

export function serializeServiceMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
    case ServiceMode.EAT_IN:
    case ServiceMode.TAKEOUT:
    case ServiceMode.CURBSIDE:
    case ServiceMode.TABLE_SERVICE:
      return 'Pickup';
    default:
      return 'None';
  }
}

export function serializePickupMode(serviceMode: ServiceMode | null) {
  switch (serviceMode) {
    case ServiceMode.DELIVERY:
      return 'Delivery';
    case ServiceMode.DRIVE_THRU:
      return 'Drive Thru';
    case ServiceMode.EAT_IN:
      return 'Eat In';
    case ServiceMode.TAKEOUT:
      return 'Take Out';
    case ServiceMode.CURBSIDE:
      return 'Curbside';
    case ServiceMode.TABLE_SERVICE:
      return 'Table Service';
    default:
      return 'None';
  }
}

export function serializeNumberOfDriveThruWindows(driveThruLaneType: string | null) {
  switch (driveThruLaneType) {
    case 'single':
      return 1;
    case 'dual':
      return 2;
    default:
      return 0;
  }
}

// Removes null, undefined and empty string values
export function sanitizeValues<M extends object = ICdpAttributes>(
  attributes: M
): NonNullableObject<Partial<M>> {
  return Object.entries(attributes).reduce<Record<string, unknown>>(
    (memo, [key, value]) => {
      const attrIsEmptyString = typeof value === 'string' && value.length === 0;
      if (!isNil(value) && !attrIsEmptyString) {
        memo[key] = value;
      }
      return memo;
    },
    {} as NonNullableObject<Partial<M>>
  ) as NonNullableObject<Partial<M>>;
}

export const booleanToString = (bool: boolean): typeof TRUE | typeof FALSE => (bool ? TRUE : FALSE);

const normalizeStringBoolean = (str: string): string => {
  if (TRUE_VALS.includes(str)) {
    return TRUE;
  }
  if (FALSE_VALS.includes(str)) {
    return FALSE;
  }
  return str;
};

export function normalizeBooleans(attributes: Record<string, unknown>): any {
  const copy: Record<string, unknown> = {};
  Object.keys(attributes).forEach(key => {
    const value = attributes[key];
    if (typeof value === 'boolean') {
      copy[key] = booleanToString(value);
    } else if (typeof value === 'string') {
      copy[key] = normalizeStringBoolean(value);
    } else {
      copy[key] = value;
    }
  });
  return copy;
}

export function flattenCartEntryItems(cartEntry: ICartEntry): ICartEntry[] {
  const children = cartEntry.children.reduce((accum, current) => {
    return [...accum, ...flattenCartEntryItems(current)];
  }, []);
  return [cartEntry, ...children];
}

// returns window location path name
export const getSourcePage = (pathname: string) => {
  // removes menu child routes
  return pathname.replace(/(menu)\/(.*)/i, '$1/');
};

export const getUserHasLoyalty = () => (AuthStorage.getItem(Keys.USER)?.loyaltyId ? true : false);

export const getAllowedAttributes = (event: AllowedEvent | IGenericEvent) => {
  const allowedAttributes = [
    // from IPageView
    'path',
    'pathWithQuery',
    'serviceMode',
    'Pick up Mode',
    'Platform',
    'Locale',
    'referrer',
    'restaurantId',
    'restaurantAddress',
    'restaurantZip',
    'restaurantCity',
    'restaurantState',
    'restaurantCountry',
    'storeId',
    // from IClickEvent
    'component',
    'text',
    'componentId',
    // for Modal Appearence
    'Message',
    'ErrorMessage',
    'ModalHeader',
    'ModalMessage',
    'Source Page',
    'Tab',
    'component',
    'componentId',
    'redemptionMode',
    'sanityId',
    'engineId',
    'name',
    'Marketing Card ID',
    'Marketing Card URL',
    'menuType',
    'my_code_page_load_time',
    'rewards_page_load_time',
    'offers_page_load_time',
    'offer_redemption_page_load_time',
  ];
  if (!event.attributes) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(event.attributes).filter(([key]) => allowedAttributes.includes(key))
  );
};

export const errorIdentityCallback = ({
  identityFn,
  callback,
  result,
  tryAgain = true,
  params,
}: {
  identityFn: any;
  callback: any;
  result: {
    httpCode?: any;
    body?: any;
    status?: string;
  };
  tryAgain?: boolean;
  params?: any;
}) => {
  switch (result.httpCode) {
    case HTTPCode.NATIVE_IDENTITY_REQUEST:
      return;
    case HTTPCode.NO_HTTP_COVERAGE:
      if (tryAgain) {
        return identityFn(params, { callback, tryAgain });
      }
      break;
    case HTTPCode.ACTIVE_IDENTITY_REQUEST:
    case HttpErrorCodes.TooManyRequests:
      if (tryAgain) {
        return identityFn(params, { callback, tryAgain: false });
      }
      break;
    case HTTPCode.VALIDATION_ISSUE:
    case 400:
    default:
      logger.error({ error: result.body });
  }
};

export enum ProductItemType {
  Parent = 'Parent',
  Child = 'Child',
}

export const flattenCommunicationPreferences = (
  userCommPreferences?: CommunicationPreferences | null
) =>
  isNil(userCommPreferences)
    ? {}
    : userCommPreferences?.reduce?.(
        (acc, commPreference) => ({
          ...acc,
          [commPreference.id]: commPreference.value.toLowerCase(),
        }),
        {}
      );

export const logFilteredLDFlags = async (
  cdpName: string,
  prevFlags: React.MutableRefObject<{}>,
  logFlagsEvaluatedEvent: React.MutableRefObject<boolean>,
  logEvent: ILogEvent,
  abTestFlags: Readonly<string[]>,
  ldUser: LDUser | null
) => {
  try {
    // 1. Evaluate LD Flags
    const ldFlags = await LaunchDarklyHelper.getInstance().evaluateFlagVariants();

    // 2. Filter ldFlags with the valid flags (abTestFlags)
    const newFilteredLdFlags = pick(ldFlags, abTestFlags);

    // Store flags for event logging
    const hasLoggedFlags = logFlagsEvaluatedEvent.current;

    const hasNewFlags = !isEqual(prevFlags.current, newFilteredLdFlags);

    prevFlags.current = newFilteredLdFlags;

    // 3. Log the event if conditions are met
    // Bail out early if flags are already logged.
    // Bail out if we don't have flags.
    if (!hasLoggedFlags || hasNewFlags) {
      logFlagsEvaluatedEvent.current = true;

      // Trigger Flags Evaluated events only if LaunchDarkly API is initiated
      // and the current user has been evaluated
      if (ldUser?.custom?.device_id) {
        logEvent(CustomEventNames.FLAGS_EVALUATED, EventTypes.Other, newFilteredLdFlags);
      }
    }
  } catch (error) {
    logger.error({ error, message: `${cdpName} > processLDFlags error` });
  }
};

export const getCommunicationPreferenceFromPromotionalEmails = (
  updatedAttributes: UpdatedUserAttributes,
  enableCommunicationPreferences: boolean
) => {
  if (enableCommunicationPreferences) {
    return {};
  }

  const promotionalEmails = delv(updatedAttributes, 'promotionalEmails');
  if (promotionalEmails?.toLowerCase?.() === 'true') {
    return {
      email_subscribe: 'opted_in',
      push_subscribe: 'opted_in',
    };
  } else if (promotionalEmails?.toLowerCase?.() === 'false') {
    return {
      email_subscribe: 'unsubscribed',
      push_subscribe: 'unsubscribed',
    };
  }

  return {};
};

export const getCdpSanitizedAttributes = (
  updatedAttributes: UpdatedUserAttributes = {},
  enableCommunicationPreferences: boolean
) => {
  const flattenedCommunicationPreferences = flattenCommunicationPreferences(
    updatedAttributes.communicationPreferences
  );

  delete updatedAttributes.communicationPreferences;
  const sanitizedValues = sanitizeValues({
    ...getName(updatedAttributes, { first_name: '', last_name: '' }),
    ...flattenedCommunicationPreferences,
    ...getCommunicationPreferenceFromPromotionalEmails(
      updatedAttributes,
      enableCommunicationPreferences
    ),
    ...updatedAttributes,
    $Zip: delv(updatedAttributes, 'zipcode', ''),
    $Gender: delv(updatedAttributes, 'gender', ''),
    $City: delv(updatedAttributes, 'city', ''),
    $State: delv(updatedAttributes, 'state', ''),
    'Location Services': delv(updatedAttributes, 'locationServices', ''),
    Timezone: delv(updatedAttributes, 'timezone', ''),
    'Join Date': delv(updatedAttributes, 'joinDate', ''),
    'Legacy User': delv(updatedAttributes, 'Legacy User', ''),
    'RBI Cognito ID': delv(updatedAttributes, 'rbiCognitoId', ''),
    favoriteStores: delv(updatedAttributes, 'favoriteStores', ''),
    favoriteOffers: delv(updatedAttributes, 'favoriteOffers', ''),
    language: delv(updatedAttributes, 'language', ''),
    rewardsEmail: delv(updatedAttributes, 'promotionalEmails', ''),
    'Battery Level': delv(updatedAttributes, 'batteryLevel', ''),
    'App Build': delv(updatedAttributes, 'App Build', ''),
    'Browser Type': delv(updatedAttributes, 'Browser Type', ''),
    'Browser Version': delv(updatedAttributes, 'Browser Version', ''),
    'Mobile Web': delv(updatedAttributes, 'Mobile Web', ''),
    Locale: delv(updatedAttributes, 'locale', ''),
    'Pickup Mode': delv(updatedAttributes, 'Pickup Mode', ''),
    'Service Mode': delv(updatedAttributes, 'Service Mode', ''),
    'Loyalty ID': delv(updatedAttributes, 'Loyalty ID', ''),
    'Restaurant Address': delv(updatedAttributes, 'Restaurant Address', ''),
    'Restaurant ID': delv(updatedAttributes, 'Restaurant ID', ''),
    'Restaurant Name': delv(updatedAttributes, 'Restaurant Name', ''),
    'Restaurant Number': delv(updatedAttributes, 'Restaurant Number', ''),
    'Sanity Restaurant ID': delv(updatedAttributes, 'Sanity Restaurant ID', ''),
  });

  return {
    ...sanitizedValues,
  };
};

export const mapApiCdpUserAttributes = (
  attributes: ExtendedCdpAttributes
): IUpdateUserAttributesEventInput => {
  const needsStringify = (val: unknown) => val && typeof val === 'object';

  return {
    dob: attributes.dob?.toISOString?.(),
    dateOfBirth: attributes['Date of Birth'],
    age: attributes.$Age,
    mobile: attributes.$Mobile,
    isGuestUser: attributes['Is Guest User'],
    ...sanitizeValues({
      firstName: attributes.$FirstName,
      lastName: attributes.$LastName,
      zip: attributes.Zip,
      gender: attributes.$Gender,
      city: attributes.$City,
      state: attributes.$State,
      legacyUser: attributes['Legacy User'],
      locale: attributes.Locale,
      timezone: attributes.Timezone,
      joinDate: attributes['Join Date'],
      rbiCognitoId: attributes['RBI Cognito ID'],
      favoriteStores: needsStringify(attributes.favoriteStores)
        ? JSON.stringify(attributes.favoriteStores)
        : attributes.favoriteStores,
      favoriteOffers: needsStringify(attributes.favoriteOffers)
        ? JSON.stringify(attributes.favoriteOffers)
        : attributes.favoriteOffers,
      batteryLevel: attributes['Battery Level'],
      marketingPush: attributes.marketingPush,
      mediaServicesPreferences: attributes.mediaServicesPreferences,
      paybackUserId: attributes.paybackUserId,
      emailSubscribe: attributes.email_subscribe,
      pushSubscribe: attributes.push_subscribe,
      utmSource: attributes['UTM Source'],
      utmMedium: attributes['UTM Medium'],
      utmCampaign: attributes['UTM Campaign'],
      utmTerm: attributes['UTM Term'],
      utmContent: attributes['UTM Content'],
      iosLocationPermissions: attributes['IOS Location Permissions'],
      androidLocationPermissions: attributes['Android Location Permissions'],
      smsSubscribe: attributes.sms_subscribe,
      serviceMode: attributes.serviceMode,
      pickupMode: attributes['Pickup Mode'],
      sourcePage: attributes['Source Page'],
      isSmallScreen: attributes.isSmallScreen,
      layer: attributes.layer,
      enableFlavorFlow: attributes.enableFlavorFlow,
      typePreference: attributes['Type Preference'],
      customerId: attributes.CustomerID,
      currentScreen: attributes.currentScreen,
      sizePreference: attributes['Size Preference'],
      timePreference: attributes['Time Preference'],
      snackPreference: attributes['Snack Preference'],
      language: attributes.language,
      currentBuild: attributes.currentBuild,
    }),
  };
};

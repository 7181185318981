import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';

import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

import { IconLoyaltyMyCode } from 'components/icons/loyalty-my-code';
import { Animation } from 'components/lottie-animations/loyalty-crowns';
import { Button, NavLinkText } from 'components/mobile-web-navigation-bar/styled';
import { View } from 'components/view';
import { useEffectOnUpdates } from 'hooks/use-effect-on-updates';
import { useFeatureNavigation } from 'hooks/use-feature-navigation';
import { useOrderHref } from 'hooks/use-order-href';
import { usePrevious } from 'hooks/use-previous';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLocationContext, useMatch } from 'state/location';
import { useInRestaurantRedemptionContext } from 'state/loyalty/in-restaurant-redemption';
import { useOrderContext } from 'state/order';
import { useScrollContext } from 'state/scroll';
import { useStoreContext } from 'state/store';
import { primitive } from 'styles/constants/primitives';
import { isRecentItemsRoute, routes } from 'utils/routing';

import { CheckoutButtonLink } from './checkout-button-link';
import { HomeIcon } from './icons/HomeIcon';
import { MenuIcon } from './icons/MenuIcon';
import { OffersIcon } from './icons/OffersIcon';
import { RewardsIcon } from './icons/RewardsIcon';
import { MobileWebNavigationBarSkeleton } from './mobile-web-navigation-bar.skeleton';
import { MobileWebNavigationBorder } from './mobile-web-navigation-border';
import { NavLinkIcon } from './nav-link-icon';
import { getNavLinks } from './nav-links';
import { NavigationMobileTab } from './types';
import { generateNavigationMobileTabs } from './utils-sanity';

const MobileNavInner = styled(View)<{ hasPadding: boolean }>`
  position: relative;
  padding: ${p => (p.hasPadding ? '0.25rem 0' : '0')};
`;

const MobileWebNavigationContainer = styled(View)`
  justify-content: space-around;
  flex-direction: row;
  background-color: ${p => p.theme.token('bottom-nav-bar-background-color')};
`;

const NavButton = styled(Button)`
  flex: 1 1 0;
  padding-block-start: 0.1rem;
  padding-block-end: 0.2rem;
  padding-inline: 0;
`;

const ScanNavButton = styled(NavButton)<{ $badgeContent?: string | number }>`
  position: relative;
  width: 4.25rem;
  max-width: 4.25rem;
  border: ${p => (p.$badgeContent ? 'none' : '2px solid #5023141a')};
  border-radius: 50%;
  background: ${p => (p.$badgeContent ? Styles.color.primary : Styles.color.white)};
  color: ${p => (p.$badgeContent ? primitive.$white : '')};
  margin-block-start: -0.688rem;
  margin-block-end: 0;
  margin-inline: 0;
  z-index: 1;

  ${({ $badgeContent }) =>
    $badgeContent &&
    css`
      &::after {
        background: ${Styles.color.contrastBackground};
        color: ${Styles.color.white};
        border-radius: 5rem;
        content: '${$badgeContent}';
        font-family: ${Styles.fontFamily.body};
        font-weight: 400;
        font-size: 0.75rem;
        position: absolute;
        inset-inline-end: 0rem;
        text-align: center;
        inset-block-start: -0.15rem;
        width: 1.5rem;
        height: 1.5rem;
        line-height: 1.4rem;
      }
    `}
`;

const StyledIconLoyaltyMyCode = styled(IconLoyaltyMyCode)`
  width: 1.125rem;
  height: 1.125rem;
`;

const LoyaltyNavLinkText = styled(NavLinkText)`
  margin-block-start: 0.1rem;
  margin-block-end: -0.3rem;
`;

const StyledAnimation = styled(Animation)`
  position: absolute;
  z-index: 0;
  margin-block-start: -9rem;
  height: auto !important;
  width: auto !important;
`;

const StyledBackdrop = styled.div`
  backdrop-filter: blur(1px);
  background: linear-gradient(
    180deg,
    rgba(249, 195, 2, 0) -5.99%,
    rgba(249, 195, 2, 0.04) 38%,
    rgba(77, 56, 0, 0.12) 100%
  );
  position: absolute;
  width: 100%;
  height: 10rem;
  inset-block-start: 0;
  margin-block-start: -10rem;
  z-index: -1;
  animation: fadeOut 2s forwards;

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

interface IMobileCheckoutButtonProps {
  checkoutRoute: string;
  enableBottomService: boolean;
}

const MobileCheckoutButton: React.FC<IMobileCheckoutButtonProps> = ({
  checkoutRoute,
  enableBottomService,
}) => {
  const baseRouteMatch = useMatch(`${routes.base}*`);
  const storeLocatorMatch = useMatch(`${routes.storeLocator}/*`);
  const menuMatch = useMatch(`${routes.menu}/*`);
  const cartMatch = useMatch(`${routes.cart}/*`);
  const orderConfirmationMatch = useMatch(`${routes.orderConfirmation}/*`);
  const order = useOrderContext();
  const cartButton = useRef<HTMLAnchorElement>(null);
  // Do not show checkout button when bottom service mode is enabled
  if (enableBottomService) {
    return null;
  }
  if (!baseRouteMatch) {
    return null;
  }
  // We don't want to show the checkout cart on the store-locator page
  if (storeLocatorMatch) {
    return null;
  }

  if (menuMatch) {
    const menuObjectId = menuMatch.params['*'];
    if (menuObjectId && !menuObjectId!.startsWith('section')) {
      return null;
    }
  }

  if ((order.cartEntries.length && !cartMatch) || orderConfirmationMatch) {
    return (
      <CheckoutButtonLink amount={order.calculateCartTotal()} to={checkoutRoute} ref={cartButton} />
    );
  }
  return null;
};

export const MobileWebNavigationBar: React.FC = () => {
  const menuHref = useOrderHref();
  const order: any = useOrderContext();
  const { isStoreOpenAndAvailable } = useStoreContext();
  const { setIsDrawerOpen, inRestaurantRedemptionCart } = useInRestaurantRedemptionContext();
  const enableOffers = useFlag(LaunchDarklyFlag.ENABLE_OFFERS);
  const enableBottomService = useFlag(LaunchDarklyFlag.ENABLE_BOTTOM_SERVICE_MODE);
  const enableStaticMenu = useFlag(LaunchDarklyFlag.ENABLE_STATIC_MENU);
  const enableAccountInFooter = useFlag(LaunchDarklyFlag.ENABLE_ACCOUNT_IN_FOOTER);
  const navbarWithServiceModeSelected = useFlag(LaunchDarklyFlag.NAVBAR_WITH_SERVICE_MODE_SELECTED);
  const enableImprovePreselectionDesign = useFlag(
    LaunchDarklyFlag.ENABLE_IMPROVE_PRESELECTION_DESIGN
  );

  const { featureNavigationMobile, featureNavigatonMobileWithStore, featureNavigationLoading } =
    useFeatureNavigation();
  const { formatMessage } = useIntl();
  const { trackEvent } = useCdpContext();
  const { location, pathMatches } = useLocationContext();
  const { store } = useStoreContext();

  const cartButton = useRef<HTMLAnchorElement>(null);
  const { flags: LDFlags } = useLDContext();

  const { scrollTo } = useScrollContext();
  const shouldHideNavigationBar = pathMatches([
    routes.signUp,
    routes.signIn,
    routes.confirmOtp,
    routes.createAccount,
  ]);

  const [showAnimation, setShowAnimation] = useState(false);
  const badgeCount = inRestaurantRedemptionCart.reduce(
    (acc, currentValue) => acc + currentValue.quantity,
    0
  );
  const prevBadgeCount = usePrevious<number>(badgeCount);

  useEffectOnUpdates(() => {
    // If we removed an item, do not show animation
    if (prevBadgeCount && prevBadgeCount > badgeCount) {
      return;
    }

    // Start the animation with a small delay to give time for the layout to finish rendering
    setTimeout(() => {
      setShowAnimation(true);
    }, 200);

    // Hide animation after one loop
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000);
  }, [badgeCount]);

  const logMobileNavOrderEvent = useCallback(() => {
    trackEvent({ name: CustomEventNames.MOBILE_NAV_ORDER, type: EventTypes.Navigation });
  }, [trackEvent]);

  const navLinks: NavigationMobileTab[] = useMemo(() => {
    if (featureNavigationLoading) {
      return [];
    }

    const sanityNavEnabled = featureNavigationMobile;

    // The Navigation Tabs With Service Mode / Store Selected should only be used when flag is enabled
    // and items are configured in Sanity. Otherwise we should display default menu items
    const shouldDisplayServiceModeMenu =
      navbarWithServiceModeSelected && store._id && featureNavigatonMobileWithStore.length;

    const links = shouldDisplayServiceModeMenu
      ? featureNavigatonMobileWithStore
      : featureNavigationMobile;

    const isLocationInRecentItemsRoute = isRecentItemsRoute(location.pathname);

    return sanityNavEnabled
      ? generateNavigationMobileTabs(links, LDFlags, isLocationInRecentItemsRoute)
      : getNavLinks({
          menuHref,
          formatMessage,
          enableOffers,
          enableStaticMenu,
          enableAccountInFooter,
          logMobileNavOrderEvent,
        });
  }, [
    featureNavigationLoading,
    featureNavigationMobile,
    navbarWithServiceModeSelected,
    store._id,
    featureNavigatonMobileWithStore,
    location.pathname,
    LDFlags,
    menuHref,
    formatMessage,
    enableOffers,
    enableStaticMenu,
    enableAccountInFooter,
    logMobileNavOrderEvent,
  ]);

  useEffectOnUpdates(() => {
    window.setTimeout(() => {
      cartButton.current?.focus();
    }, 100);
  }, [order.cartEntries.length]);

  const onClickNavLink = useCallback(
    (href: string) => {
      if (location.pathname === href) {
        // whenever a mobile nav button is pressed when the route already matches,
        // scroll to the top of the view
        scrollTo({ x: 0, y: 0 });
      }
    },
    [location.pathname, scrollTo]
  );

  const onScanClick = useCallback(() => {
    setIsDrawerOpen(true);
    trackEvent({
      name: CustomEventNames.BUTTON_CLICK,
      type: EventTypes.Navigation,
      attributes: {
        Name: 'Scan Code Nav Button',
      },
    });
  }, [setIsDrawerOpen, trackEvent]);

  const { serviceMode } = order;

  // TODO: @glengal checkoutRoute is not used. Expected? this is potentially a bug
  let checkoutRoute = routes.cart;
  if (!serviceMode) {
    checkoutRoute = routes.serviceMode;
  } else if (!isStoreOpenAndAvailable) {
    checkoutRoute = routes.storeLocator;
  }

  // not showing mobile navigation bar on sign up flow
  if (shouldHideNavigationBar) {
    return null;
  }

  return (
    <MobileWebNavigationBorder data-mediaquery="headerMobile">
      <MobileNavInner hasPadding={enableImprovePreselectionDesign}>
        <MobileWebNavigationContainer>
          {enableImprovePreselectionDesign ? (
            <>
              <NavButton>
                <NavLinkIcon
                  label={formatMessage({ id: 'home' })}
                  icon={<HomeIcon />}
                  route={routes.base}
                  forceExactMatch={true}
                />
              </NavButton>
              <NavButton>
                <NavLinkIcon
                  label={formatMessage({ id: 'menu' })}
                  icon={<MenuIcon />}
                  route={routes.menu}
                />
              </NavButton>
              <ScanNavButton $badgeContent={badgeCount} onClick={onScanClick}>
                <StyledIconLoyaltyMyCode
                  fill={badgeCount ? primitive.$white : primitive.bk.$bbqBrown}
                />
                <LoyaltyNavLinkText>{formatMessage({ id: 'scan' })}</LoyaltyNavLinkText>
              </ScanNavButton>
              <Suspense fallback={null}>{showAnimation ? <StyledAnimation /> : null}</Suspense>
              {showAnimation ? <StyledBackdrop /> : null}
              <NavButton>
                <NavLinkIcon
                  label={formatMessage({ id: 'offers' })}
                  icon={<OffersIcon />}
                  route={routes.rewardsOffers}
                />
              </NavButton>
              <NavButton>
                <NavLinkIcon
                  label={formatMessage({ id: 'rewards' })}
                  icon={<RewardsIcon />}
                  route={routes.rewardsList}
                />
              </NavButton>
            </>
          ) : (
            <>
              {navLinks?.length ? (
                navLinks.map((item, index) => (
                  <React.Fragment key={index}>
                    <NavButton
                      key={index}
                      onClick={() => item.selectedHrefs[0] && onClickNavLink(item.selectedHrefs[0])}
                    >
                      <item.Component />
                    </NavButton>
                  </React.Fragment>
                ))
              ) : (
                <MobileWebNavigationBarSkeleton />
              )}
            </>
          )}
        </MobileWebNavigationContainer>
        {isStoreOpenAndAvailable && (
          <MobileCheckoutButton
            checkoutRoute={checkoutRoute}
            enableBottomService={enableBottomService}
          />
        )}
      </MobileNavInner>
    </MobileWebNavigationBorder>
  );
};
